import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { FiEdit } from 'react-icons/fi'
import { BiSort } from 'react-icons/bi';
import { RiLoginBoxLine } from 'react-icons/ri'
import SearchLayout from '../partials/SearchLayout';
import * as moment from 'moment'
import SortingByColumn from 'views/partials/SortingByColumn';
import {
    Card,
    Container,
    Row,
    Col,
} from "react-bootstrap";
import { getOrganization, searchOrganization, getOrganizationById } from '../../services/organizationService';
import { getOrganizationId, getRolePermission, getUserType as getUserRole } from '../../services/roleService';
import { ToastContainer, toast } from 'react-toastify';
import ToastLayout from '../../views/partials/Toastify';
const searchOptions = {
    searchValues: ["organization_name", "email", "address", "city"], //same len as values
    searchNames: ['Organization', 'Email', 'Address', 'City'],
}
const enableAddButton = () => (getRolePermission('organizations', getUserRole()) > 1);
const getEditButton = (organizationId, setSelectedOrganizations) => {
    return (
        <center>
            {getUserRole() == "super-admin" ?
                <a onClick={() => { selectOrganization(organizationId, setSelectedOrganizations) }} style={{ color: 'black' }}>
                    <RiLoginBoxLine />
                </a> : <a></a>}
            <a href={"#/organization-detail/" + organizationId} style={{ color: 'black', marginLeft: '6px' }} >
                <FiEdit />
            </a>
        </center>
    );
}
const selectOrganization = async (organizationId, setSelectedOrganizations) => {
    let organizations = await getOrganizationById(organizationId)
    setSelectedOrganizations({ organizations })

}
export default function OrganizationList({ setSelectedOrganizations }) {
    var organizationScreenSort;
    var organizations;
    const searchInput = React.useRef();
    const [flag, setFlag] = useState();
    const [items, setItems] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    const [clearField, setClearField] = useState(true);
    const [inputField, setInputField] = useState({ selectField: "organization_name" });
    const history = useHistory();
    useEffect(async () => {
        await reloadOrganization();
        if (localStorage.getItem('organizationScreenSort') != null) {
            organizationScreenSort = JSON.parse(localStorage.getItem('organizationScreenSort'))
        }
        if (organizationScreenSort && organizationScreenSort.field != null && organizationScreenSort.ascFlag != undefined) {
            asecDsec(organizationScreenSort.field, organizationScreenSort.ascFlag, organizations);
        }
    }, [])
    // sorting    
    let asecDsec = (field, flag, items) => {
        let toggle = flag;
        if (flag == undefined) {
            toggle = 1
        }
        localStorage.setItem("organizationScreenSort", JSON.stringify({ ascFlag: toggle, field: field }));
        let sorted = SortingByColumn(field, items, toggle, setFlag)
        setItems(sorted)
        setOriginalData(sorted)
    }
    let reloadOrganization = async () => {
        organizations = await getOrganization();
        if (organizations == "Unauthorized") {
            toast.error(ToastLayout("Your session has expired!"))
            localStorage.clear();
            history.push("/login")
        } else {
            setItems(organizations);
            setOriginalData(organizations);
        }
    }
    // search details
    const searchDetails = async (fieldValue, dataValue) => {
        const searchParams = {
            field: fieldValue,
            data: dataValue
        };
        let searchItems = await searchOrganization(searchParams);
        setItems(searchItems);
    }
    //Clear the details
    let clearDetails = async () => {
        setItems(originalData);
        searchInput.current.value = "";
    }
    return (
        <div>
            <ToastContainer />
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Card style={{ boxShadow: '0 2px 10px -1px rgb(69 90 100 / 30%)' }}>
                            <Card.Header>
                                <SearchLayout
                                    searchOptions={searchOptions}
                                    doSearch={searchDetails}
                                    clearDetails={clearDetails}
                                    searchInput={searchInput}
                                    addButtonCallback={() => { history.push("/organization-add") }}
                                    isAddButtonEnable={enableAddButton()} showHide={true}
                                />
                            </Card.Header>
                            <Card.Body className="table-full-width table-responsive px-0" style={{ marginTop: "0" }}>
                                <SetOrganizationList items={items}
                                    setItems={setItems}
                                    setSelectedOrganizations={setSelectedOrganizations}
                                    asecDsec={asecDsec}
                                    flag={flag}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                </Row>
            </Container>
        </div>
    );
}
function SetOrganizationList({ items, setItems, setSelectedOrganizations, asecDsec, flag }) {
    return (
        <div>
            <ol className="collection collection-container" style={{ marginTop: "0px", paddingTop: "0px" }}>
                <li className="item item-container table-header">
                    <div className="attribute-container information">
                        <div className="attribute-container name-organization">
                            <div className="attribute" data-name="Name">Organization
                                <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("organization_name", flag, items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
                            </div>
                            <div className="attribute" data-name="Email">Email
                                <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("email", flag, items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
                            </div>
                        </div>
                        <div className="attribute-container price-currency">
                            <div className="attribute" data-name="Address">Address
                                <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("address", flag, items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
                            </div>
                            <div className="attribute" data-name="City">City
                                <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("city", flag, items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
                            </div>
                        </div>
                    </div>
                    <div className="attribute-container amount-edit">
                        <div className="attribute" data-name="Edit"><center>Actions</center></div>
                    </div>
                </li>
                {(items.length > 0) ?
                    <div>
                        {items.map((item) => {
                            return (<li key={item.id} className="item item-container hover">
                                <div className="attribute-container information">
                                    <div className="attribute-container name-organization">
                                        <div className="attribute" data-name="Name">{item.organization_name}</div>
                                        <div className="attribute" data-name="Email">{item.email}</div>
                                    </div>
                                    <div className="attribute-container price-currency">
                                        <div className="attribute" data-name="Address">{item.address}</div>
                                        <div className="attribute" data-name="City">{item.city}</div>
                                    </div>
                                </div>
                                <div className="attribute-container amount-edit">
                                    <div className="attribute" data-name="Actions">
                                        {getEditButton(item.id, setSelectedOrganizations)}
                                    </div>
                                </div>
                            </li>)
                        })}
                    </div>
                    :
                    <div className="no-data-available-text">No Records Found</div>
                }
            </ol>
        </div>
    )
}