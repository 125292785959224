import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import * as moment from 'moment';
import Select from "react-select";
import { BsArrowLeftShort } from "react-icons/bs";
// import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastLayout from '../../views/partials/Toastify';
import { ToastContainer, toast } from 'react-toastify';
import {
    Card,
    Container,
    Row,
    Col,
    Form    
} from "react-bootstrap";
import '../../assets/css/pages/table-list.css'
import { getOrganizationById, addOrganization, getOrganization, updateOrganization } from '../../services/organizationService';
import { getCountry } from '../../services/countryService';
import { deleteOrganization } from '../../services/organizationService';
import { getUserType as getUserRole, getRolePermission } from '../../services/roleService'

export default function OrganizationAddEdit({ setUpdatedOrganizationList }) {
    const regExp = /^[a-z0-9.]+@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    var user;
    const [country, setCountry] = useState();
    const [state, setState] = useState({
        id: 0, bLabel: 'Add', FormLabel: 'Add'
    });
    const history = useHistory();
    const { id } = useParams();
    useEffect(async () => {
        if (typeof (id) !== 'undefined') {
            await fetchUser(id);
        }
        reloadCountry();
    }, [])
    let fetchUser = async (id) => {
        user = await getOrganizationById(id);
        if (typeof (id) !== 'undefined') {
            setState({ ...user, bLabel: 'Update', FormLabel: 'Edit' })
        }
    }
    //display country name
    const [countryData, setCountryData] = useState({
        data: []
    });
    let reloadCountry = async () => {
        let record = await getCountry();
        setCountryData({ ...countryData, data: record });
        if (typeof (id) !== 'undefined') {
            setCountry(record.filter((pre) => {
                if (pre.id === user.country_id)
                    return true;
            })[0])
        }
    }
    let selectCountry = (e) => {
        setState({ ...state, ["country_id"]: e.id });
        setCountry(e);
    }
    let handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    }
    let handleCancel = () => {
        history.push("/organization");
    }
    let handleSubmit = async (e) => {
        e.preventDefault();
        if (!state.organization_name) {
            return;
        }
        const newItem = {
            organization_name: state.organization_name,
            email: state.email,
            address: state.address,
            city: state.city,
            zip: state.zip,
            country_id: state.country_id
        };
        if (regExp.test(newItem.email)) {
            if (state.id !== 0) { //update
                setUpdatedOrganizationList();
                newItem.id = state.id;
                let updateId = newItem.id
                let response = await updateOrganization(newItem);
                if (response.result == "fail") {
                    if (response.msg.errors[0].message == 'unique_organization_name must be unique') {
                        toast.error(ToastLayout("Organization name already exists!"))
                    } else if (response.msg.errors[0].message == 'unique_email must be unique') {
                        toast.error(ToastLayout("Email already exists!"))
                    } else {
                        toast.error(ToastLayout("Organization name or Email  already exists!"))
                    }
                } else {
                    history.push("/organization");
                }
            } else { //add
                setUpdatedOrganizationList();
                if (newItem.country_id) {
                    let response = await addOrganization(newItem);
                    if (response.result == "fail") {
                        if (response.msg.errors[0].message == 'unique_organization_name must be unique') {
                            toast.error(ToastLayout("Organization name already exists!"))
                        } else if (response.msg.errors[0].message == 'unique_email must be unique') {
                            toast.error(ToastLayout("Email already exists!"))
                        } else {
                            toast.error(ToastLayout("Organization name or Email  already exists!"))
                        }
                    } else {
                        history.push("/organization");
                    }
                } else {
                    toast.error(ToastLayout("Please select country"))
                }
            }
        }
        else {
            toast.error(ToastLayout("Enter Valid Email Id"))
        }
    }
    let backButton = () => {
        history.push("/organization/");
    }
    let doDelete = async () => {
        setUpdatedOrganizationList();
        await deleteOrganization({ id })
        history.push("/organization");
    }
    let getDeleteButton = () => {
        const history = useHistory();
        if (getRolePermission('users', getUserRole()) > 2) {
            if (typeof (id) !== 'undefined') {
                return (<button onClick={(e) => { e.preventDefault(); doDelete() }} className="cancle-button-style">
                    Delete
                </button>
                )
            };
        }
    }

    return (<>
        <Container fluid>
            <Row>
                <Col md="12">
                    <Card className="striped-table-with-hover">
                        <Card.Header>
                            <div className='display-back-button'>
                                <div><button className="back" data-toggle="tooltip" title="click to go back" onClick={() => { backButton() }} ><BsArrowLeftShort /></button></div>
                                <div className="form-header-container form-header" style={{ marginTop: "05px" }}>
                                    <center>{state.FormLabel} Organization</center>
                                </div>
                            </div>
                        </Card.Header>
                        <Card.Body className="px-0">
                            <Col lg="9">
                                <Form >
                                    <Form.Group as={Row} className="mb-3" >
                                        <Col sm="3">
                                            <div className="add-responsive">Organization :</div>
                                        </Col>
                                        <Col sm="9">
                                            <input
                                                name="organization_name"
                                                placeholder="Name"
                                                onChange={handleChange}
                                                value={state.organization_name}
                                                className="input-style-responsive"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" >
                                        <Col sm="3">
                                            <div className="add-responsive">Email :</div>
                                        </Col>
                                        <Col sm="9">
                                            <input
                                                type="email"
                                                name="email"
                                                placeholder="Email"
                                                onChange={handleChange}
                                                value={state.email}
                                                className="input-style-responsive"

                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" >
                                        <Col sm="3">
                                            <div className="add-responsive">Address :</div>
                                        </Col>
                                        <Col sm="9">
                                            <input
                                                name="address"
                                                placeholder="Address"
                                                onChange={handleChange}
                                                value={state.address}
                                                className="input-style-responsive"
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" >
                                        <Col sm="3">
                                            <div className="add-responsive">City :</div>
                                        </Col>
                                        <Col sm="9">
                                            <input
                                                name="city"
                                                placeholder="City"
                                                onChange={handleChange}
                                                value={state.city}
                                                className="input-style-responsive"
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" >
                                        <Col sm="3">
                                            <div className="add-responsive">Zip code :</div>
                                        </Col>
                                        <Col sm="9">
                                            <input
                                                name="zip"
                                                placeholder="Zip"
                                                onChange={handleChange}
                                                value={state.zip}
                                                className="input-style-responsive"
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" >
                                        <Col sm="3">
                                            <div className="add-responsive">Country :</div>
                                        </Col>
                                        <Col sm="9">
                                            <Select 
                                                className="dropDown-style"
                                                value={country}
                                                options={countryData.data}
                                                getOptionLabel={e => e.country}
                                                getOptionValue={e => e.id}
                                                onChange={(e) => selectCountry(e)}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" >
                                        <Col>
                                            <div className="button-container-responsive">
                                                <ToastContainer/>
                                                <button onClick={handleSubmit} className="add-button-style">
                                                    {state.bLabel}
                                                </button> &nbsp;&nbsp;
                                                {getDeleteButton()}
                                                &nbsp;&nbsp;
                                                <button onClick={handleCancel} className="cancle-button-style">
                                                    Cancel
                                                </button>
                                            </div>
                                        </Col>
                                    </Form.Group>
                                </Form>
                            </Col>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    </>
    );
}