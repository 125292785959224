import { apiUrl } from "./globalService";
import { getUserToken } from "./roleService";
let apiPermission = apiUrl + "installations/get-permission";
let setApiPermission = apiUrl + "installations/update-permission";

export let getInstallationPermission = (deviceGroupId) => {
  return fetch(apiPermission + "/" + deviceGroupId, {
    method: 'get',
    headers: {
      'Authorization': getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    }
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}
export let setPermissions = (records) => {
  return fetch(setApiPermission, {
    method: 'post',
    headers: {
      'Authorization': getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify(records)
  })
    .then(response => response.json())
    .then(response => {
      console.log("response>>", response);
      return response;
    })
    .catch(function (error) {
      console.log(error);
    });
}