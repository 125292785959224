import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { FiEdit } from 'react-icons/fi';
import { BiSort } from 'react-icons/bi';
import * as moment from 'moment'
import SearchLayout from '../partials/SearchLayout';
import SortingByColumn from 'views/partials/SortingByColumn';
import { ToastContainer, toast } from 'react-toastify';
import ToastLayout from '../../views/partials/Toastify';
import {
  Card,
  Container,
  Row,
  Col
} from "react-bootstrap";
import '../../assets/css/pages/table-list.css'
import { getHardware, deleteHardware, searchHardware } from '../../services/hardwareService';
import { getUserType as getUserRole, getRolePermission } from '../../services/roleService';
const searchOptions = {
  searchValues: ["model", "product_no", "company_name", "asset_type"], //same len as values
  searchNames: ['Model', 'Product Number', 'Company', "Type"],
}
const enableAddButton = () => (getRolePermission('hardware', getUserRole()) > 1);
const getEditButton = (id) => {
  if (getRolePermission('hardware', getUserRole()) > 1) {
    return (
      <center><a href={"#/hardware-edit/" + id} style={{ color: 'black' }} >
        <FiEdit />
      </a></center>
    );
  }
}
let getAction = () => {
  const history = useHistory();
  if (getRolePermission('hardware', getUserRole()) > 1) {
    return (
      <div className="attribute" data-name="Edit"><center>Actions</center></div>
    );
  }
}

export default function HardwareList() {
  var hardwareScreenSort;
  var hardware;
  const searchInput = React.useRef();
  const [state, setState] = useState({
    items: []
  });
  const [items, setItems] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [flag, setFlag] = useState();
  const history = useHistory();
  useEffect(async () => {
    await reloadHardware();
    if (localStorage.getItem('hardwareScreenSort') != null) {
      hardwareScreenSort = JSON.parse(localStorage.getItem('hardwareScreenSort'))
    }
    if (hardwareScreenSort && hardwareScreenSort.field != null && hardwareScreenSort.ascFlag != undefined) {
      asecDsec(hardwareScreenSort.field, hardwareScreenSort.ascFlag, hardware);
    }
  }, [])
  // sorting    
  let asecDsec = (field, flag, items) => {
    let toggle = flag;
    if (flag == undefined) {
      toggle = 1
    }
    localStorage.setItem("hardwareScreenSort", JSON.stringify({ ascFlag: toggle, field: field }));
    let sorted = SortingByColumn(field, items, toggle, setFlag)
    setItems(sorted);
    setOriginalData(sorted);
  }
  let reloadHardware = async () => {
    hardware = await getHardware();
    if (hardware == "Unauthorized") {
      toast.error(ToastLayout("Your session has expired!"))
      localStorage.clear();
      history.push("/login")
    }
    setItems(hardware)
    setOriginalData(hardware);
  }

  // Search field
  const searchDetails = async (fieldValue, dataValue) => {
    const searchParams = {
      field: fieldValue,
      data: dataValue
    };
    let searchItems = await searchHardware(searchParams);
    setItems(searchItems);
  }
  //Clear the details
  const clearDetails = async () => {
    setItems(originalData);
    searchInput.current.value = "";
  }
  return (
    <div>
      <ToastContainer />
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="striped-table-with-hover">
              <Card.Header>
                <SearchLayout
                  searchInput={searchInput}
                  searchOptions={searchOptions}
                  doSearch={searchDetails}
                  clearDetails={clearDetails}
                  addButtonCallback={() => { history.push("/hardware-add") }}
                  isAddButtonEnable={enableAddButton()} showHide={true}
                />
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0" style={{ marginTop: "0" }}>
                <SetHardwareList items={items}
                  setItems={setItems}
                  asecDsec={asecDsec}
                  flag={flag}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
        </Row>
      </Container>
    </div>
  );
}
function SetHardwareList({ items, setItems, asecDsec, flag }) {
  return (
    <div>
      <ol className="collection collection-container">
        <li className="item item-container table-header">
          <div className="attribute-container information">
            <div className="attribute-container name-organization">
              <div className="attribute" data-name="Model">Model
                <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("model", flag, items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
              </div>
            </div>
            <div className="attribute-container price-currency">
              <div className="attribute" data-name="Product Number">Product Number
                <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("product_no", flag, items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
              </div>
            </div>
            <div className="attribute-container price-currency">
              <div className="attribute" data-name="Company Name">Company
                <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("company_name", flag, items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
              </div>
            </div>
          </div>
          <div className="attribute-container amount-edit">
            <div className="attribute" data-name="Type">Type
              <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("asset_type", flag, items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
            </div>
            {getAction()}
          </div>
        </li>
        {(items.length > 0) ?
          <div>
            {items.map((item) => {
              return (<li key={item.id} className="item item-container hover">
                <div className="attribute-container information">
                  <div className="attribute-container name-organization">
                    <div className="attribute" data-name="Model">{item.model}</div>
                  </div>
                  <div className="attribute-container price-currency">
                    <div className="attribute" data-name="Product Number">{item.product_no}</div>
                  </div>
                  <div className="attribute-container price-currency">
                    <div className="attribute" data-name="Company Name">{item.company_name}</div>
                  </div>
                </div>
                <div className="attribute-container amount-edit">
                  <div className="attribute" data-name="Company Name">{item.asset_type}</div>
                  {getEditButton(item.id)}
                </div>
              </li>)
            })}
          </div>
          :
          <div className="no-data-available-text">No Records Found</div>
        }
      </ol>
    </div>

  );
}

