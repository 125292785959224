import React, { useState, useContext, useEffect } from 'react';
import Select from 'react-select';
import { useHistory } from 'react-router';
import { uuid } from '../../services/CommonUtils';
import {
   Modal,
   Row,
   Col,
   Card,
   Container,
   Form
} from "react-bootstrap";
import '../../assets/css/pages/table-list.css'
import { getPackagesByOrg } from '../../services/packageService';
import { me, isRoleAdminManager, getCustomerById } from '../../services/userService';
import { getUserType as getUserRole, getRolePermission } from '../../services/roleService';
import { getCurrency, getCurrencyNameById } from '../../services/currencyService';
import { getMyWallet, processPackageTxn } from '../../services/walletUserService';
import { ToastContainer, toast } from 'react-toastify';
import ToastLayout from '../../views/partials/Toastify';
//Vivek: packages should be selected based on org , 
//Vivek: meter owned or OrgX should packages offer by OrgX
export default function PaymentModal({ selectedMeter, reloadMeter, setSelectedMeter, show, handleClose, meterUser, customerId }) {
   const history = useHistory();
   if (selectedMeter == null) {
      return null; //no meter selected so far
   }
   const [defaultLabel, setDefaultLabel] = useState("Select Packages");
   const [packages, setPackages] = useState([]);
   const [currentmeterUser, setMeterUser] = useState(meterUser);
   const [selectedPackage, setSelectedPackage] = useState(null);
   const [myWallet, setMyWallet] = useState({ balance: '' });
   const [currencyData, setCurrencyData] = useState([]);
   const [loginUser, setLoginUser] = useState({});
   const [details, setDetails] = useState({ amount: '', selectedCurrency: { currency: '', id: 0 } });

   //Default source options
   const sourceOptions = [{ value: 'Cash', label: 'Cash' },
   { value: 'Mobile Money', label: 'Mobile Money' },
   { value: 'Wire Transfer', label: 'Wire Transfer' },
   { value: 'Credit Card', label: 'Credit Card' },
   { value: 'Debit Card', label: 'Debit Card' }];
   let defaultSource = sourceOptions[0].value;
   if (!isRoleAdminManager()) {
      defaultSource = ""
   }
   const [sourceType, setSource] = useState(defaultSource)

   //Fetch the currency
   const reloadCurrency = async () => {
      let user = await me();
      let currencyList = await getCurrency();
      setCurrencyData(currencyList);
      setLoginUser(user[0]);
   }
   //Get user wallet
   const loadMyWallet = async () => {
      let wallet = await getMyWallet();
      setMyWallet(wallet)
   }
   //set the selected source
   const onSelect = sourceOptions => {
      setSource(sourceOptions.value);
   }
   useEffect(() => {
      if (selectedMeter != null) {
         // console.log("selectedMeter",selectedMeter);
         setSelectedPackage(null);
         setDefaultLabel("Select Packages of " + selectedMeter.organization_name);
         fetchOrgPackages(selectedMeter.owner_organization_id);
         setDetails({
            amount: '', selectedCurrency: { currency: '', id: 0 }
         });
         selectedMeterUser();
      }
   }, [selectedMeter])

   //Get and Set the payment details from the form
   const handleChange = (e) => {
      setDetails({ ...details, [e.target.name]: e.target.value });
   }
   //Get and set the customer details of selected meter
   const selectedMeterUser = async () => {
      if (customerId === undefined) {
         let userMeter = await getCustomerById(selectedMeter.customer_id);
         setMeterUser(userMeter);
      }
      else {
         setMeterUser(meterUser);
      }
   }
   //Source block
   const getSourceGUI = () => {
      if (isRoleAdminManager()) {
         return (
            <Form.Group as={Row}>
               <Col sm="6"><div >Source :</div></Col>
               {/* <Col style={{ paddingLeft: '18px', paddingBottom: '10px' }}> */}
               <Col sm="6">
                  <Select
                     className="source-dropDown-style"
                     defaultValue={sourceOptions[0]}
                     options={sourceOptions}
                     onChange={onSelect}
                  />
               </Col>
            </Form.Group>
         )
      } else {
         return null;
      }
   }
   //Reference code block
   const getRefCodeGUI = () => {
      if (isRoleAdminManager()) {
         return (
            <Form.Group as={Row} >
               <Col sm="6">
                  <div >Reference Code :</div>
               </Col>
               <Col sm="6">
                  <input
                     name="reference"
                     placeholder="Receipt no ref"
                     onChange={handleChange}
                     className="input-style-pop-up"
                     value={details.reference}
                  />
               </Col>
            </Form.Group>
         )
      } else {
         return null;
      }
   }
   //Type block
   const getTypeGUI = () => {
      const { role_type } = loginUser;
      if (isRoleAdminManager()) {
         return (
            <Form.Group as={Row} >
               <Col sm="6">
                  <div >Type :</div></Col>
               <Col sm="6">
                  <input
                     name="type"
                     placeholder="Deposit / Purchase"
                     onChange={handleChange}
                     value={details.type}
                     className="input-style-pop-up"
                  />
               </Col>
            </Form.Group>
         )
      } else {
         return null;
      }
   }
   //Wallet balance block
   const getWalletGUI = () => {
      const { role_type } = loginUser;
      if (isRoleAdminManager()) {
         return (null)
      } else {
         return (
            <Form.Group as={Row} className="mb-1">
               <Col sm="6">
                  <div className="add">Wallet Balance :</div></Col>
               <Col sm="6">
                  {myWallet.balance} {getCurrencyNameById(myWallet.currency_id)} {myWallet.msg}
               </Col>
            </Form.Group>
         );
      }
   }
   //Process to activate the package
   const activatePackage = async () => {
      let { amount, reference, source, type } = details;
      if (selectedPackage == null) {
         toast.error(ToastLayout("Select package from drop down list."))
         return;
      }
      if (!isRoleAdminManager() && myWallet.msg && myWallet.msg.length > 3) {
         toast.error(ToastLayout("Contact your manager or admin to activate your Wallet."))
         return;
      }
      // source:'',amount:'',selectedCurrency:{currency:'',id:0}
      if (!isRoleAdminManager() && myWallet.currency_id != details.selectedCurrency.id) {
         toast.error(ToastLayout("Package and wallet curreny does not match."))

         return;
      }
      if (!isRoleAdminManager() && myWallet.balance < amount) {
         toast.error(ToastLayout("Your wallet does not have enough funds, Please contact your manager."))
         return;
      }
      let paymentData = {
         packageId: selectedPackage.id,
         amount, reference,
         source: sourceType,
         type,
         walletId: myWallet.id,
         customerId: selectedMeter.customer_id,
         meterSerial: selectedMeter.serial
      };
      console.log("paymentData::", paymentData);
      //return;
      let serverRes = await processPackageTxn(paymentData)
      if (serverRes && serverRes.result == 'success') {
         toast.error(ToastLayout("Successfully added package to meter."))
         reloadMeter();
         handleClose();
      } else if (serverRes) {
         toast.error(ToastLayout(serverRes.msg))
      }
   }
   //Fetch and set the packages available
   const fetchOrgPackages = async (orgId) => {
      reloadCurrency();
      loadMyWallet();
      let orgPackages = await getPackagesByOrg(orgId);
      if (orgPackages == "Unauthorized") {
         toast.error(ToastLayout("Your session has expired!"))
         localStorage.clear();
         history.push("/login")
      } else {
         setPackages(orgPackages);
      }
   }
   return (<Modal show={show} onHide={handleClose} size="lg">
      <ToastContainer />
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body className="paymentModal" style={{ "overflow-y": "hidden" }}>
         <Select
            options={packages}
            placeholder={defaultLabel}
            onChange={(selectedRecord) => {
               setSelectedPackage(selectedRecord);
               let amount = selectedRecord.price;
               let selectedCurrencyList = currencyData.filter((item) => (item.id == selectedRecord.currency_id));
               let selectedCurrency = {};
               if (selectedCurrencyList.length > 0) {
                  selectedCurrency = selectedCurrencyList[0];
               }
               setDetails({ ...details, amount, selectedCurrency })
            }}
            getOptionLabel={e => {
               return (
                  <div key={Date.now() + 'a'}>soss
                     <ul style={{
                        listStylePosition: "outside",
                        marginBottom: "0px",
                        paddingLeft: "20px"
                     }}>
                        <li>
                           <span style={{ fontSize: 15, color: "#053763" }}>Content: {e.amount} {e.unit}</span>
                           <span style={{ fontSize: 15, color: "#053763" }}> ( Product:{e.product_name},</span>
                           <span style={{ fontWeight: "bold", fontSize: 14, color: "#053763" }}> Price:{e.price} )</span>
                        </li>
                     </ul>
                  </div>)
            }}
            getOptionValue={e => e}
         />
         <Container fluid>
            <Row>
               <Col md="8">
                  <Card.Body className="table-full-width table-responsive px-0" style={{ overflow: 'hidden !important' }}>
                     <Col lg="9">
                        <Form >
                           <Col style={{ "color": "#ed1b24" }}>Payment Details :</Col>
                           <Form.Group as={Row}>
                              <Col sm="6">
                                 <div >Meters Id :</div>
                              </Col>
                              <Col sm="6">
                                 {selectedMeter.serial}
                              </Col>
                           </Form.Group>
                           <Form.Group as={Row}>
                              <Col sm="6">
                                 <div >Customer :</div>
                              </Col>
                              <Col sm="6">
                                 {currentmeterUser.first_name} {currentmeterUser.last_name} ({currentmeterUser.username})
                              </Col>
                           </Form.Group>

                           <Form.Group as={Row}>
                              <Col sm="6">
                                 <div >Amount :</div>
                              </Col>
                              <Col style={{ paddingLeft: '18px', paddingBottom: '10px' }}>
                                 {details.amount}&nbsp;
                              </Col>
                           </Form.Group>
                           <Form.Group as={Row}>
                              <Col sm="6">
                                 <div >Currency :</div>
                              </Col>
                              <Col sm="6">
                                 &nbsp;{details.selectedCurrency.currency} </Col>
                           </Form.Group>
                           {getSourceGUI()}
                           {getRefCodeGUI()}
                           {getTypeGUI()}
                           {getWalletGUI()}
                        </Form>
                     </Col>
                  </Card.Body>
               </Col>
            </Row>
        ` </Container>`
      </Modal.Body>
      <Modal.Footer>
         <br />
         <button className="btn rounded-pill add-btn" onClick={() => activatePackage()}>Activate Package</button>
      </Modal.Footer>
   </Modal>
   );
}