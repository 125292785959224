import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router'
import { getPbxDevices, deletePbxDevice, getPbxDeviceByAddress, updatePbxDevice, addPbxDevice } from '../../services/deviceService';
import { BsArrowLeftShort } from "react-icons/bs";
import Select from "react-select";
import {
    Badge,
    Button,
    Card,
    Table,
    Container,
    Row,
    Col,
    Form
} from "react-bootstrap";
import { getOrganization } from '../../services/organizationService';
import { getUsers, me, getAllCustomerUsers } from '../../services/userService';
import { getGateway, getGatewayByAddress, getGatewayByGroup, searchGatewayByGroup } from '../../services/gatewayService';
import { getHardware, getHardwareByAssetType } from '../../services/hardwareService';
import { getInstallation, getInstallationById, searchInstallationGroup } from '../../services/installationService';
import { getUserType as getUserRole, getRolePermission, getUserId } from '../../services/roleService'
import { AsyncPaginate } from "react-select-async-paginate";
import ToastLayout from '../../views/partials/Toastify';
import { ToastContainer, toast } from 'react-toastify';

export default function DeviceAddEdit() {
    const [state, setState] = useState({
        mac_address: '', bLabel: 'Add', FormLabel: 'Add'
    });
    const [organizationData, setOrganizationData] = useState({});
    const [groupName, setGroupName] = useState();
    const [gatewayHubName, setGatewayHubName] = useState();
    const [hardwareModel, setHardwareModel] = useState();
    const [customer, setCustomer] = useState();
    const history = useHistory();
    const { mac_address } = useParams();
    var groupId;
    var device;
    var groupRecord;
    let fetchDevice = async (device_mac_address) => {
        device = await getPbxDeviceByAddress(device_mac_address);
        console.log("device", mac_address)
        if (typeof (mac_address) !== undefined) {
            setState({ ...device, bLabel: 'Update', FormLabel: 'Edit' })
            groupId = device.device_group_id
            groupRecord = await getInstallationById(device.device_group_id);
            setOrganizationData(groupRecord[0])
        }
    }
    useEffect(async () => {
        if (mac_address !== undefined) {
            await fetchDevice(mac_address);
        }
        // reloadUser();
        reloadInstallation();
        reloadDeviceGroup();
        reloadHardware();
        getCurrentOrganizationName();
    }, [])
    // const [userData, setUserData] = useState({
    //     usrData: []
    // });
    // let reloadUser = async () => {
    //     let usrRecord = await getAllCustomerUsers();
    //     setUserData({ ...userData, usrData: usrRecord });
    //     if (mac_address !== undefined) {
    //         setCustomer(usrRecord.filter((pre) => {
    //             if (pre.id === device.owner_user_id)
    //                 return true;
    //         })[0])
    //     }
    // }
    //Display device group name
    let reloadDeviceGroup = async () => {
        if (mac_address !== undefined) {
            let gatewayName = await getGatewayByAddress(device.gateway_mac_address)
            if (gatewayName.result == 'fail') {
                setGatewayHubName({ mac_address: null, hub_name: "None" });
            } else {
                setGatewayHubName(gatewayName)
            }
        }
    }
    const loadGatewayHub = async (search, prevOptions) => {
        let filteredOptions = [];
        if (search) {
            let searchData = {
                groupId: groupName.id,
                searchKey: search
            }
            filteredOptions = await searchGatewayByGroup(searchData);
            filteredOptions = [{ mac_address: null, hub_name: "None" }, ...filteredOptions]
        } else {
            let options = [{ mac_address: null, hub_name: "None" }]
            filteredOptions = options;
        }
        const slicedOptions = filteredOptions.slice(
            prevOptions.length,
            prevOptions.length + 10
        );
        return {
            options: slicedOptions
        };
    }
    //Display hardware name
    const [hardwareData, setHardwareData] = useState({
        hardware: []
    });
    let reloadHardware = async () => {
        let hardwareRecord = await getHardwareByAssetType("PBX Device");
        setHardwareData({ ...hardwareData, hardware: hardwareRecord });
        if (mac_address !== undefined) {
            setHardwareModel(hardwareRecord.filter((pre) => {
                if (pre.id === device.hardware_id)
                    return true;
            })[0])
        }
    }
    let reloadInstallation = async () => {
        if (mac_address !== undefined) {
            setGroupName(groupRecord[0])
        }
    }
    const loadInstallations = async (search, prevOptions) => {
        let filteredOptions = [];
        if (search) {
            let searchData = {
                searchKey: search
            }
            filteredOptions = await searchInstallationGroup(searchData);
        } else {
            filteredOptions = options;
        }
        const slicedOptions = filteredOptions.slice(
            prevOptions.length,
            prevOptions.length + 10
        );
        return {
            options: slicedOptions
        };
    };
    let handleCancel = () => {
        history.push("/pbx-device");
    }
    let selectInstallation = async (e) => {
        setState({ ...state, ["device_group_id"]: e.id });
        let groupRecord = await getInstallationById(e.id);
        setOrganizationData(groupRecord[0])
        setGroupName(e);
        setGatewayHubName({ mac_address: null, hub_name: "None" });
    }
    let selectGatewayHub = (e) => {
        setState({ ...state, ["gateway_mac_address"]: e.mac_address });
        setGatewayHubName(e);
    }
    let selectHardwareModel = (e) => {
        setState({ ...state, ["hardware_id"]: e.id });
        setHardwareModel(e);
    }
    // let selectCustomer = (e) => {
    //     setState({ ...state, ["owner_user_id"]: e.id });
    //     setCustomer(e);
    // }
    let handleChange = async (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    }
    let doDelete = async () => {
        await deletePbxDevice({ mac_address })
        history.push("/pbx-device");
    }
    let getDeleteButton = () => {
        const history = useHistory();
        if (getRolePermission('PBXdevices', getUserRole()) > 2) {
            if (typeof (mac_address) !== 'undefined') {
                return (<button onClick={(e) => { e.preventDefault(); doDelete() }} className="cancle-button-style">
                    Delete
                </button>);
            }
        }
    }
    let backButton = () => {
        history.push("/pbx-device")
    }
    let checkEditOrAdd = () => {
        if (typeof (mac_address) !== 'undefined') {
            return (<input
                disabled
                name="mac_address"
                placeholder="Mac address e.g: 0006661A2D47"
                onChange={handleChange}
                value={state.mac_address}
                className="input-style-responsive"
            />
            );
        } else {
            return (<input
                name="mac_address"
                placeholder="Mac address e.g: 0006661A2D47"
                onChange={handleChange}
                value={state.mac_address}
                className="input-style-responsive"
            />
            );
        }
    }
    let checkAddOrEditDevices = () => {
        if (typeof (mac_address) == 'undefined') {
            return (<div className='display-back-button'>
                <div><button className="back" data-toggle="tooltip" title="click to go back" onClick={() => { backButton() }} ><BsArrowLeftShort /></button></div>
                <div className="form-header-container form-header" style={{ marginTop: "05px" }}>
                    <center>{state.FormLabel} Devices</center>
                </div>
            </div>
            );
        } else {
            return (<div></div>
            );
        }
    }
    const [currentOrganizationName, setCurrentOrganizationName] = useState();
    const getCurrentOrganizationName = async () => {
        let record = await me();
        setCurrentOrganizationName(...record)
    }
    let getOrganizationName = () => {
        if (getUserRole() === "super-admin") {
            return (
                <Form.Group as={Row} className="mb-3" >
                    <Col sm="3">
                        <div className="add-responsive">Organization :</div>
                    </Col>
                    <Col sm="9">
                        <input
                            disabled
                            name="owner_organization_id"
                            id={organizationData.organization_id}
                            value={organizationData.organization_name}
                            className="input-style-responsive"
                            style={{ backgroundColor: 'transparent', borderBottom: "0rem" }}
                        />
                    </Col>
                </Form.Group>
            )
        }
        else {
            state.owner_organization_id = currentOrganizationName && currentOrganizationName.organization_id
            return (
                <Form.Group as={Row} className="mb-3" >
                    <Col sm="3">
                        <div className="add-responsive">Organization :</div>
                    </Col>
                    <Col sm="9">
                        <input
                            disabled
                            name="owner_organization_id"
                            id={state.organization_id}
                            value={currentOrganizationName && currentOrganizationName.organization_name}
                            className="input-style-responsive"
                            style={{ backgroundColor: 'transparent', borderBottom: "0rem" }}
                        />
                    </Col>
                </Form.Group>
            )
        }
    }
    let handleSubmit = async (e) => {
        e.preventDefault();
        if (!state.mac_address.length) {
            return;
        }
        const newItem = {
            mac_address: state.mac_address,
            serial: state.serial,
            hardware_revision: state.hardware_revision,
            device_group_id: state.device_group_id,
            hardware_id: state.hardware_id,
            software_revision: state.software_revision,
            owner_organization_id: organizationData.organization_id,
            owner_user_id: null,
            gateway_mac_address: state.gateway_mac_address
        };
        if (typeof (mac_address) !== 'undefined') { //update
            newItem.mac_address = state.mac_address;
            // console.log("newItem>>>>",newItem);
            let response = await updatePbxDevice(newItem);
            if (response.result == "fail") {
                toast.error(ToastLayout("Please enter unique serial!"))
            } else {
                history.push("/pbx-device", newItem);
            }
        } else { //add
            if (newItem.device_group_id) {
                if (newItem.hardware_id) {
                    if (newItem.owner_organization_id) {
                        let response = await addPbxDevice(newItem);
                        if (response.result == "fail") {
                            if (response.msg.errors[0].message == "PRIMARY must be unique") {
                                toast.error(ToastLayout("Please enter unique mac_address!"))
                            } else if (response.msg.errors[0].message == "unique_serial must be unique") {
                                toast.error(ToastLayout("Please enter unique serial!"))
                              
                            } else {
                                toast.error(ToastLayout("Mac address or Serial already exists!"))
                            }
                        }
                        else {
                            history.push("/pbx-device", newItem);
                        }
                    } else { 
                        toast.error(ToastLayout("Please select organization"))
                     }
                } else { 
                    toast.error(ToastLayout("Please select hardware model"))
                 }
            } else { 
                toast.error(ToastLayout("Please select installation group"))
            }
        }
    }
    return (
        <div>
            <Container fluid>
                <Row>
                    <Col md="12">
                        <Card className="striped-table-with-hover">
                            <Card.Header>
                                {checkAddOrEditDevices()}
                            </Card.Header>
                            <Card.Body className=" px-0">
                                <Col lg="9">
                                    <Form >
                                        <Form.Group as={Row} className="mb-3" >
                                            <Col sm="3">
                                                <div className="add-responsive">Mac Address :</div>
                                            </Col>
                                            <Col sm="9">
                                                {checkEditOrAdd()}
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" >
                                            <Col sm="3">
                                                <div className="add-responsive">Serial :</div>
                                            </Col>
                                            <Col sm="9">
                                                <input
                                                    name="serial"
                                                    placeholder="Serial e.g: 1771.3.193"
                                                    onChange={handleChange}
                                                    value={state.serial}
                                                    className="input-style-responsive"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" >
                                            <Col sm="3">
                                                <div className="add-responsive">Hardware Version :</div>
                                            </Col>
                                            <Col sm="9">
                                                <input
                                                    name="hardware_revision"
                                                    placeholder="Hardware version e.g: 4"
                                                    onChange={handleChange}
                                                    value={state.hardware_revision}
                                                    className="input-style-responsive"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" >
                                            <Col sm="3">
                                                <div className="add-responsive">Software Version :</div>
                                            </Col>
                                            <Col sm="9">
                                                <input
                                                    name="software_revision"
                                                    placeholder="Software version e.g: 307"
                                                    onChange={handleChange}
                                                    value={state.software_revision}
                                                    className="input-style-responsive"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" >
                                            <Col sm="3">
                                                <div className="add-responsive">Installation Group:</div>
                                            </Col>
                                            <Col sm="9" style={{ maxWidth: "43.5%" }}>
                                                <AsyncPaginate
                                                    value={groupName}
                                                    loadOptions={loadInstallations}
                                                    placeholder={"Type to select ...."}
                                                    onChange={(e) => selectInstallation(e)}
                                                    options={[]}
                                                    getOptionLabel={e => e.group_name}
                                                    getOptionValue={e => e.id}
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" >
                                            <Col sm="3">
                                                <div className="add-responsive">Hub Name (Gateway):</div>
                                            </Col>
                                            <Col sm="9" style={{ maxWidth: "43.5%" }}>
                                                <AsyncPaginate
                                                    value={gatewayHubName}
                                                    loadOptions={loadGatewayHub}
                                                    placeholder={"Type to select ...."}
                                                    onChange={(e) => selectGatewayHub(e)}
                                                    options={[]}
                                                    getOptionLabel={e => e.hub_name}
                                                    getOptionValue={e => e.mac_address}
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" >
                                            <Col sm="3">
                                                <div className="add-responsive">Hardware Model :</div>
                                            </Col>
                                            <Col sm="9">
                                                <Select
                                                    className="dropDown-style"
                                                    value={hardwareModel}
                                                    options={hardwareData.hardware}
                                                    getOptionLabel={e => e.model}
                                                    getOptionValue={e => e.id}
                                                    onChange={(e) => selectHardwareModel(e)}
                                                />
                                            </Col>
                                        </Form.Group>
                                        {/* <Form.Group as={Row} className="mb-3" >
                                            <Col sm="3">
                                                <div className="add-responsive">Customer :</div>
                                            </Col>
                                            <Col sm="9">
                                                <Select
                                                    className="dropDown-style"
                                                    value={customer}
                                                    options={userData.usrData}
                                                    getOptionLabel={e => e.username}
                                                    getOptionValue={e => e.id}
                                                    onChange={(e) => selectCustomer(e)}
                                                />
                                            </Col>
                                        </Form.Group> */}
                                        {getOrganizationName()}
                                        <Form.Group as={Row} className="mb-3" >
                                            <Col>
                                                <div className="button-container-responsive">
                                                    <ToastContainer />
                                                    <button onClick={handleSubmit} className="add-button-style">
                                                        {state.bLabel}
                                                    </button> &nbsp;&nbsp;
                                                    {getDeleteButton()}
                                                    &nbsp;&nbsp;
                                                    <button onClick={handleCancel} className="cancle-button-style">
                                                        Cancel
                                                    </button>
                                                </div>
                                            </Col>
                                        </Form.Group>
                                    </Form>
                                </Col>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                </Row>
            </Container>
        </div>
    );
}