import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router'
import Select from "react-select";
import { BsArrowLeftShort } from "react-icons/bs";
import {
    Card,
    Container,
    Row,
    Col,
    Form
} from "react-bootstrap";
import { me, getAllCustomerUsers } from '../../services/userService';
import { getGateway, getGatewayByAddress, getGatewayByGroup, searchGatewayByGroup } from '../../services/gatewayService';
import { getHardwareByAssetType } from '../../services/hardwareService';
import { addNode, updateNode, getNodeByMacAddress, deleteNode } from '../../services/nodeService';
import { getInstallation, getInstallationById, searchInstallationGroup, getInstallationInLimit } from '../../services/installationService';
import { getUserId } from '../../services/roleService';
import { getUserType as getUserRole, getRolePermission, getLocalUser } from '../../services/roleService'
import { AsyncPaginate } from "react-select-async-paginate";
import 'react-toastify/dist/ReactToastify.css';
import ToastLayout from '../../views/partials/Toastify';
import { ToastContainer, toast } from 'react-toastify';
export default function NodeAddEdit() {
    const [state, setState] = useState({
        mac_address: '', bLabel: 'Add', FormLabel: 'Add'
    });
    var groupId;
    var node;
    var groupRecord;
    const history = useHistory();
    const { mac_address } = useParams();
    const [nodeGroupId, setNodeGroupId] = useState({});
    const [organizationData, setOrganizationData] = useState({});
    const [groupName, setGroupName] = useState();
    const [gatewayHubName, setGatewayHubName] = useState();
    const [hardwareModel, setHardwareModel] = useState();
    const [customer, setCustomer] = useState();

    useEffect(async () => {
        if (typeof (mac_address) !== 'undefined') {
            await fetchNode(mac_address);
            // reloadUser();
            reloadDeviceGroup();
            reloadInstallationGroup();
            reloadHardware();
            getCurrentOrganizationName();
        } else {
            // reloadUser();
            reloadDeviceGroup();
            reloadInstallationGroup();
            reloadHardware();
            getCurrentOrganizationName();
        }
    }, [])
    let fetchNode = async (mac_address) => {
        node = await getNodeByMacAddress(mac_address);
        console.log("node", node);
        if (typeof (mac_address) !== undefined) {
            setState({ ...node, bLabel: 'Update', FormLabel: 'Edit' });
            groupId = node.device_group_id
            groupRecord = await getInstallationById(node.device_group_id);
            console.log("groupRecord", groupRecord)
            setOrganizationData(groupRecord[0])
        }
    }

    //Display User name
    // const [userData, setUserData] = useState({
    //     usrData: []
    // });
    // let reloadUser = async () => {
    //     let usrRecord = await getAllCustomerUsers();
    //     setUserData({ ...userData, usrData: usrRecord });
    //     if (mac_address !== undefined) {
    //         setCustomer(usrRecord.filter((pre) => {
    //             if (pre.id === node.owner_user_id)
    //                 return true;
    //         }))
    //     }
    // }
    //Display installation group name

    let reloadInstallationGroup = async () => {
        if (mac_address !== undefined) {
            setGroupName(groupRecord[0])
        }
    }
    const loadInstallations = async (search, prevOptions) => {
        let filteredOptions = [];
        if (search) {
            let searchData = {
                searchKey: search
            }
            filteredOptions = await searchInstallationGroup(searchData);
        } else {
            filteredOptions = options;
        }
        const slicedOptions = filteredOptions.slice(
            prevOptions.length,
            prevOptions.length + 10
        );
        return {
            options: slicedOptions
        };
    };

    //Display device group name
    let reloadDeviceGroup = async () => {
        if (mac_address !== undefined) {
            let gatewayName = await getGatewayByAddress(node.gateway_mac_address)
            if (gatewayName.result == 'fail') {
                setGatewayHubName({ mac_address: null, hub_name: "None" });
            } else {
                setGatewayHubName(gatewayName)
            }
        }
    }
    const loadGatewayHub = async (search, prevOptions) => {
        let filteredOptions = [];
        if (search) {
            let searchData = {
                groupId: groupName.id,
                searchKey: search
            }
            filteredOptions = await searchGatewayByGroup(searchData);
            filteredOptions = [{ mac_address: null, hub_name: "None" }, ...filteredOptions]
        } else {
            let options = [{ mac_address: null, hub_name: "None" }]
            filteredOptions = options;
        }
        const slicedOptions = filteredOptions.slice(
            prevOptions.length,
            prevOptions.length + 10
        );
        return {
            options: slicedOptions
        };
    }

    //Display hardware name
    const [hardwareData, setHardwareData] = useState({
        hardware: []
    });
    let reloadHardware = async () => {
        let hardwareRecord = await getHardwareByAssetType("Node");
        setHardwareData({ ...hardwareData, hardware: hardwareRecord });
        if (mac_address !== undefined) {
            setHardwareModel(hardwareRecord.filter((pre) => {
                if (pre.id === node.hardware_id)
                    return true;
            }))
        }
    }

    let handleCancel = () => {
        history.push("/node");
    }
    let selectInstallation = async (e) => {
        setState({ ...state, ["device_group_id"]: e.id });
        let groupRecord = await getInstallationById(e.id);
        setOrganizationData(groupRecord[0])
        setGroupName(e);
        setGatewayHubName({ mac_address: null, hub_name: "None" });
    }
    let selectGatewayHub = (e) => {
        setState({ ...state, ["gateway_mac_address"]: e.mac_address });
        setGatewayHubName(e);
    }
    let selectHardwareModel = (e) => {
        setState({ ...state, ["hardware_id"]: e.id });
        setHardwareModel(e);
    }
    let selectCustomer = (e) => {
        setState({ ...state, ["owner_user_id"]: e.id });
        setCustomer(e);
    }
    let handleChange = async (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    }
    let handleSubmit = async (e) => {
        e.preventDefault();
        if (!state.mac_address.length) {
            return;
        }
        const newItem = {
            mac_address: state.mac_address,
            device_group_id: state.device_group_id,
            hardware_id: state.hardware_id,
            organization_id: organizationData.organization_id,
            owner_user_id: null,
            node_hub_name: state.node_hub_name,
            gateway_mac_address: state.gateway_mac_address,
        };
        if (typeof (mac_address) !== 'undefined') { //update
            console.log("newItem", newItem)
            let response = await updateNode(newItem);
            if (response.result == "fail") {
                toast.error(ToastLayout("Please enter unique mac_address!"))
            } else {
                history.push("/node");
            }
        } else { //add
            if (newItem.device_group_id) {
                if (newItem.hardware_id) {
                    if (newItem.organization_id) {
                        console.log("newItem", newItem)
                        let response = await addNode(newItem);
                        if (response.result == "fail") {
                            toast.error(ToastLayout("Please enter unique mac_address!"))
                        } else {
                            history.push("/node");
                        }
                    } else {
                        toast.error(ToastLayout("Please select organization"))
                    }
                } else {
                    toast.error(ToastLayout("Please select hardware model")) 
                }
            } else {
                toast.error(ToastLayout("Please select installation Group")) 
            }
        }
    }
    let doDelete = async () => {
        // let mac_address = mac_address
        const response = await deleteNode({ mac_address });
        if (response.result == "fail") {
            toast.error(ToastLayout(response.msg))
        } else {
            history.push("/node");
        }
        // history.push("/node");
    }
    let getDeleteButton = () => {
        const history = useHistory();
        if (getRolePermission('node', getUserRole()) > 2) {
            if (typeof (mac_address) !== 'undefined') {
                return (<button onClick={(e) => { e.preventDefault(); doDelete() }} className="cancle-button-style">
                    Delete
                </button>);
            }
        }
    }
    let backButton = () => {
        history.push("/node")
    }
    let checkEditOrAdd = () => {
        if (typeof (mac_address) !== 'undefined') {
            return (<input
                disabled
                name="mac_address"
                placeholder="Mac address e.g: 57DC"
                onChange={handleChange}
                value={state.mac_address}
                className="input-style-responsive"
            />
            );
        } else {
            return (<input
                name="mac_address"
                placeholder="Mac address e.g: 57DC"
                onChange={handleChange}
                value={state.mac_address}
                className="input-style-responsive"
            />
            );
        }
    }
    let checkAddInstallation = () => {
        if (typeof (mac_address) != undefined) {
            return (<div className='display-back-button'>
                <div><button className="back" data-toggle="tooltip" title="click to go back" onClick={() => { backButton() }} ><BsArrowLeftShort /></button></div>
                <div className="form-header-container form-header" style={{ marginTop: "05px" }}>
                    <center>{state.FormLabel} Node</center>
                </div>
            </div>
            );
        } else {
            return (<div></div>
            );
        }
    }
    const [currentOrganizationName, setCurrentOrganizationName] = useState();
    const getCurrentOrganizationName = async () => {
        let record = await me();
        setCurrentOrganizationName(...record)
    }
    let getOrganizationName = () => {
        if (getUserRole() === "super-admin") {
            return (
                <Form.Group as={Row} className="mb-3" >
                    <Col sm="3">
                        <div className="add-responsive">Organization :</div>
                    </Col>
                    <Col sm="9">
                        <input
                            disabled
                            name="organization_id"
                            id={organizationData && organizationData.organization_id}
                            value={organizationData && organizationData.organization_name}
                            className="input-style-responsive"
                            style={{ backgroundColor: 'transparent', borderBottom: "0rem" }}
                        />
                    </Col>
                </Form.Group>
            )
        }
        else {
            state.organization_id = currentOrganizationName && currentOrganizationName.organization_id
            return (
                <Form.Group as={Row} className="mb-3" >
                    <Col sm="3">
                        <div className="add-responsive">Organization :</div>
                    </Col>
                    <Col sm="9">
                        <input
                            disabled
                            name="organization_id"
                            id={state.organization_id}
                            value={currentOrganizationName && currentOrganizationName.organization_name}
                            className="input-style-responsive"
                            style={{ backgroundColor: 'transparent', borderBottom: "0rem" }}
                        />
                    </Col>
                </Form.Group>
            )
        }
    }
    return (
        <div>
            <Container fluid>
                <Row>
                    <Col md="12">
                        <Card className="striped-table-with-hover">
                            <Card.Header>
                                {checkAddInstallation()}
                            </Card.Header>
                            <Card.Body className=" px-0">
                                <Col lg="9">
                                    <Form>
                                        <Form.Group as={Row} className="mb-3" >
                                            <Col sm="3">
                                                <div className="add-responsive">Mac Address :</div>
                                            </Col>
                                            <Col sm="9">
                                                {checkEditOrAdd()}
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" >
                                            <Col sm="3">
                                                <div className="add-responsive">Hub Name (Node) :</div>
                                            </Col>
                                            <Col sm="9">
                                                <input
                                                    name="node_hub_name"
                                                    placeholder="Hub Name (Node)"
                                                    onChange={handleChange}
                                                    value={state.node_hub_name}
                                                    className="input-style-responsive"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" >
                                            <Col sm="3">
                                                <div className="add-responsive">Installation Group :</div>
                                            </Col>
                                            <Col sm="9" style={{ maxWidth: "43.5%" }}>
                                                <AsyncPaginate
                                                    value={groupName}
                                                    loadOptions={loadInstallations}
                                                    placeholder={"Type to select ...."}
                                                    onChange={(e) => selectInstallation(e)}
                                                    options={[]}
                                                    getOptionLabel={e => e.group_name}
                                                    getOptionValue={e => e.id}
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" >
                                            <Col sm="3">
                                                <div className="add-responsive">Hub Name(Gateway) :</div>
                                            </Col>
                                            <Col sm="9" style={{ maxWidth: "43.5%" }}>
                                                <AsyncPaginate
                                                    value={gatewayHubName}
                                                    loadOptions={loadGatewayHub}
                                                    placeholder={"Type to select ...."}
                                                    onChange={(e) => selectGatewayHub(e)}
                                                    options={[]}
                                                    getOptionLabel={e => e.hub_name}
                                                    getOptionValue={e => e.mac_address}
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" >
                                            <Col sm="3">
                                                <div className="add-responsive">Hardware Model :</div>
                                            </Col>
                                            <Col sm="9" >
                                                <Select
                                                    className="dropDown-style"
                                                    value={hardwareModel}
                                                    options={hardwareData.hardware}
                                                    getOptionLabel={e => e.model}
                                                    getOptionValue={e => e.id}
                                                    onChange={(e) => selectHardwareModel(e)}
                                                />
                                            </Col>
                                        </Form.Group>
                                        {/* <Form.Group as={Row} className="mb-3" >
                                            <Col sm="3">
                                                <div className="add-responsive">Customer :</div>
                                            </Col>
                                            <Col sm="9">
                                                <Select
                                                    className="dropDown-style"
                                                    value={customer}
                                                    options={userData.usrData}
                                                    getOptionLabel={e => e.username}
                                                    getOptionValue={e => e.id}
                                                    onChange={(e) => selectCustomer(e)}
                                                />
                                            </Col>
                                        </Form.Group> */}
                                        {getOrganizationName()}
                                        <Form.Group as={Row} className="mb-3" >
                                            <Col>
                                                <div className="button-container-responsive">
                                                    <ToastContainer />
                                                    <button onClick={handleSubmit} className="add-button-style">
                                                        {state.bLabel}
                                                    </button> &nbsp;&nbsp;
                                                    {getDeleteButton()}
                                                    &nbsp;&nbsp;
                                                    <button onClick={handleCancel} className="cancle-button-style">
                                                        Cancel
                                                    </button>
                                                </div>
                                            </Col>
                                        </Form.Group>
                                    </Form>
                                </Col>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                </Row>
            </Container>
        </div>
    );
}