import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { FiEdit } from 'react-icons/fi'
import { BiSort } from 'react-icons/bi';
import { RiLoginBoxLine } from 'react-icons/ri'
import SortingByColumn from 'views/partials/SortingByColumn';
import {
  Card,
  Container,
  Row,
  Col
} from "react-bootstrap";
import '../../assets/css/pages/table-list.css'
import SearchLayout from '../partials/SearchLayout';
import { getInstallation, getInstallationByOrganizationId, deleteInstallation, searchInstallation, getInstallationById, getInstallationPerOrganization } from '../../services/installationService';
import { getUserType as getUserRole, getRolePermission, getOrganizationId } from '../../services/roleService'
import PermissionModel from './PermissionModal';
import { MdLibraryAddCheck, MdMoney } from 'react-icons/md'
import { getUserByRoleOrganization } from '../../services/userService';
import { getInstallationPermission } from '../../services/user_permission_on_installationService';
import { ToastContainer, toast } from 'react-toastify';
import ToastLayout from '../../views/partials/Toastify';
const searchOptions = {
  searchValues: ["group_name", "name", "latitude", "longitude", "organization_name"], //same len as values
  searchNames: ['Name', 'Type', 'Latitude', 'Longitude', 'Organization'],
}
const enableAddButton = () => (getRolePermission('installations', getUserRole()) > 1);
const getEditButton = (id) => {
  if (getRolePermission('installations', getUserRole()) > 1) {
    return (
      <a href={"#/installation-edit/" + id} style={{ color: 'black' }} >
        <FiEdit />
      </a>
    );
  }
}
let selectInstallation = (id, setSelectedInstallations) => {
  // if (getUserRole() == "super-admin") {
  return (
    <a onClick={() => { setSelectedInstallation(id, setSelectedInstallations) }} style={{ color: 'black' }}>
      <RiLoginBoxLine />
    </a>
  )
  // }
}
const setSelectedInstallation = async (installationId, setSelectedInstallations) => {
  let installations = await getInstallationById(installationId)
  setSelectedInstallations({ installations })

}
let getAction = () => {
  const history = useHistory();
  return (
    <div className="attribute" data-name="Actions"><center>Actions</center></div>
  );
}
export default function InstallationList({ globalSelect, globalSelectOrganization, setSelectedInstallations }) {
  var installationScreenSort;
  var installation;
  const searchInput = React.useRef();
  const [flag, setFlag] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [users, setUsers] = useState({});
  const organization = getOrganizationId();
  const [installationUserPermission, setInstallationUserPermission] = useState({});
  const [stateInstallation, setInstallation] = useState({
    items: []
  });
  const [items, setItems] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [clearField, setClearField] = useState(true);
  const history = useHistory();
  const { id } = useParams();

  useEffect(async () => {
    if (typeof (id) !== 'undefined') {
      fetchInstallation(id);
    }
    await reloadInstallation();
    if (localStorage.getItem('installationScreenSort') != null) {
      installationScreenSort = JSON.parse(localStorage.getItem('installationScreenSort'))
    }
    if (installationScreenSort && installationScreenSort.field != null && installationScreenSort.ascFlag != undefined) {
      asecDsec(installationScreenSort.field, installationScreenSort.ascFlag, installation);
    }
  }, [globalSelect, globalSelectOrganization])
  // sorting    
  let asecDsec = (field, flag, items) => {
    let toggle = flag;
    if (flag == undefined) {
      toggle = 1
    }
    localStorage.setItem("installationScreenSort", JSON.stringify({ ascFlag: toggle, field: field }));
    let sorted = SortingByColumn(field, items, toggle, setFlag)
    setItems(sorted);
    setOriginalData(sorted);
  }
  let reloadInstallation = async () => {
    //Global filter Installation: All installations selected as option 
    //Global filter Organization: Any one organization selected as option
    if ((globalSelect.installation_id == 0 || globalSelect.installation_id == undefined) &&
      (globalSelectOrganization.organization_id != 0 && globalSelectOrganization.organization_id != undefined)) {
      installation = await getInstallationByOrganizationId(globalSelectOrganization.organization_id);
      if (installation == "Unauthorized") {
        toast.error(ToastLayout("Your session has expired!"))
        localStorage.clear();
        history.push("/login")
      }
      setItems(installation);
      setOriginalData(installation);
    }
    //Global filter Installation: All installations selected as option 
    //Global filter Organization: All organizations selected as option
    else {
      installation = await getInstallation();
      if (installation == "Unauthorized") {
        toast.error(ToastLayout("Your session has expired!"))
        localStorage.clear();
        history.push("/login")
      }
      setItems(installation);
      setOriginalData(installation);
    }
  }

  let fetchInstallation = async (_id) => {
    let installation = await getInstallationById(_id);
    if (typeof (installation._id) !== 'undefined') {
      setInstallation({ ...stateInstallation.result })
    }
  }
  // get the details in table when onclick on search
  const searchDetails = async (fieldValue, dataValue) => {
    const searchParams = {
      field: fieldValue,
      data: dataValue
    };
    if (getUserRole() == "super-admin") {
      if (globalSelectOrganization.organization_id != 0 && globalSelectOrganization.organization_id != undefined) {
        searchParams.organizationId = globalSelectOrganization.organization_id
      } else {
        searchParams.organizationId = null
      }
    } else {
      searchParams.organizationId = organization
    }
    let searchItems = await searchInstallation(searchParams);
    setItems(searchItems);
  }
  //Clear the details
  const clearDetails = async () => {
    setItems(originalData);
    searchInput.current.value = "";
  }
  return (
    <div>
        <ToastContainer />
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="striped-table-with-hover">
              <Card.Header>
                <SearchLayout
                  searchInput={searchInput}
                  searchOptions={searchOptions}
                  doSearch={searchDetails}
                  clearDetails={clearDetails}
                  addButtonCallback={() => { history.push("/installation-add") }}
                  isAddButtonEnable={enableAddButton()} showHide={true}
                />
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0" style={{ marginTop: "0" }}>
                <SetInstallationList items={items}
                  setItems={setItems}
                  show={show}
                  handleShow={handleShow}
                  handleClose={handleClose}
                  users={users}
                  setUsers={setUsers}
                  installationUserPermission={installationUserPermission}
                  setInstallationUserPermission={setInstallationUserPermission}
                  setSelectedInstallations={setSelectedInstallations}
                  asecDsec={asecDsec}
                  flag={flag}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
        </Row>
      </Container>
    </div>
  );
}
function SetInstallationList({ items, setItems, show, handleShow, handleClose, users, setUsers, installationUserPermission, setInstallationUserPermission, setSelectedInstallations, asecDsec, flag }) {
  const getPermissionButton = (installationRecord) => {
    if (getUserRole() == 'admin'
      || getUserRole() == 'manager'
      || getUserRole() == 'super-admin') {
      return (<a type='button' style={{ fontSize: "16px", color: 'black' }} onClick={() => permissionOpenModel(installationRecord)} >
        <MdLibraryAddCheck />
      </a>)
    } else {
      return null;
    }
  }
  const permissionOpenModel = async (installationRecord) => {
    handleShow();
    let usersData = await getUserByRoleOrganization(installationRecord.organization_id);
    let installationPermission = await getInstallationPermission(installationRecord.id);
    setInstallationUserPermission(installationPermission);
    if (installationPermission.length > 0) { installationPermission.map(preState => { if ((usersData.findIndex(pre => pre.id == preState.user_id) >= 0)) { usersData[usersData.findIndex(pre => pre.id == preState.user_id)]['isPermission'] = (preState.permission == true ? true : false) } }); }
    usersData.map((preUser) => {
      preUser['device_group_id'] = installationRecord.id;
      preUser['group_name'] = installationRecord.group_name;
      return preUser;
    })
    setUsers(usersData);
  }

  return (
    <div>
      <ol className="collection collection-container">
        <li className="item item-container table-header">
          <div className="attribute-container information">
            <div className="attribute-container name-organization">
              <div className="attribute" data-name="Group Name">Name
                <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("group_name", flag, items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
              </div>
              <div className="attribute" data-name="Group Type">Type
                <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("name", flag, items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
              </div>
            </div>
            <div className="attribute-container price-currency">
              <div className="attribute" data-name="Latitude">Latitude
                <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("latitude", flag, items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
              </div>
              <div className="attribute" data-name="Longitude">Longitude
                <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("longitude", flag, items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
              </div>
              {getUserRole() == "super-admin" ?
                <div className="attribute" data-name="Organization">Organization
                  <a style={{ marginLeft: "0px" }} onClick={() => { asecDsec("organization_name", flag, items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
                </div> : <div></div>}
            </div>
          </div>
          <div className="attribute-container amount-edit">
            {/* <div className="attribute" data-name="assets"><center>Assets</center></div> */}
            {getAction()}
          </div>
        </li>
        <PermissionModel
          showPermission={show}
          handlePermissionClose={handleClose}
          userData={users}
          setUsers={setUsers}
          userPermissions={installationUserPermission}
        />
        {(items.length > 0) ?
          <div>
            {items.map((item) => {
              return (<li key={item.id} className="item item-container hover">
                <div className="attribute-container information">
                  <div className="attribute-container name-organization">
                    <div className="attribute" data-name="Group">{item.group_name}</div>
                    <div className="attribute" data-name="Type">{item.name}</div>
                  </div>
                  <div className="attribute-container price-currency">
                    <div className="attribute" data-name="Latitude">{item.latitude}</div>
                    <div className="attribute" data-name="Longitude">{item.longitude}</div>
                    {getUserRole() == "super-admin" ?
                      <div className="attribute" data-name="Organization">{item.organization_name}</div>
                      : <div></div>}
                  </div>
                </div>

                <div className="attribute-container amount-edit">
                  {/* <div className="attribute-container amount-edit">
                    <div className="attribute" data-name="Show Users">
                      <center><a href={"/#/installations/installation-assets/" + item.id} className="show-detail">
                        <font><ImList2 /></font>
                      </a></center>
                    </div>
                  </div> */}
                  <center>
                    {getEditButton(item.id)}&nbsp;&nbsp;&nbsp;
                    {selectInstallation(item.id, setSelectedInstallations)}&nbsp;&nbsp;&nbsp;
                    {getPermissionButton(item)}
                  </center>
                </div>
              </li>)
            })}
          </div>
          :
          <div className="no-data-available-text">No Records Found</div>
        }
      </ol>
    </div>
  );
}

