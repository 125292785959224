import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { BiNotepad, BiEdit } from 'react-icons/bi'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { BsSearch } from 'react-icons/bs'
import { FiEdit } from 'react-icons/fi'
import { MdMoney } from 'react-icons/md'
import { GrHistory } from 'react-icons/gr'
import PaymentModal from '../packages/PaymentModal';
import * as moment from 'moment'
import SearchLayout from '../partials/SearchLayout'
import { getCurrencyNameById } from '../../services/currencyService'
import { ToastContainer, toast } from 'react-toastify';
import ToastLayout from '../../views/partials/Toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  InputGroup,
  FormControl,
  Dropdown
} from "react-bootstrap";
import '../../assets/css/pages/table-list.css'
import '../../assets/css/pages/table-responsive.css'
import { getUserType as getUserRole, getRolePermission } from '../../services/roleService'
import { getWalletUsers, deleteWalletUser, searchWalletUser } from '../../services/walletUserService';
import { getAmountSign } from '../../services/CommonUtils';
import { BiSort } from 'react-icons/bi';
import SortingByColumn from 'views/partials/SortingByColumn';

const searchOptions = {
  searchValues: ["timestamp", "amount", "currency", "balance", "source", "provider", "reference"], //same len as values
  searchNames: ['TXN Date', 'TXN Amount', 'Currency', 'Balance', 'Source', 'Provider', 'Reference'],
}
const enableAddButton = () => (getRolePermission('wallet', getUserRole()) > 1);

export default function WalletUserList() {
  var walletUserScreenSort;
  var walletUsers;
  const searchInput = React.useRef();
  const [flag, setFlag] = useState();
  const [items, setItems] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [clearField, setClearField] = useState(true);
  const history = useHistory();
  useEffect(async () => {
    await reloadWalletUser();
    if (localStorage.getItem('walletUserScreenSort') != null) {
      walletUserScreenSort = JSON.parse(localStorage.getItem('walletUserScreenSort'))
    }
    if (walletUserScreenSort && walletUserScreenSort.field != null && walletUserScreenSort.ascFlag != undefined) {
      asecDsec(walletUserScreenSort.field, walletUserScreenSort.ascFlag, walletUsers);
    }
  }, [])
  // sorting    
  let asecDsec = (field, flag, items) => {
    let toggle = flag;
    if (flag == undefined) {
      toggle = 1
    }
    localStorage.setItem("walletUserScreenSort", JSON.stringify({ ascFlag: toggle, field: field }));
    let sorted = SortingByColumn(field, items, toggle, setFlag)
    setItems(sorted);
    setOriginalData(sorted);
  }
  let reloadWalletUser = async () => {
    walletUsers = await getWalletUsers();
    if (walletUsers == "Unauthorized") {
      toast.error(ToastLayout("Your session has expired!"))
      localStorage.clear();
      history.push("/login")
    } else {
      setItems(walletUsers);
      setOriginalData(walletUsers);
    }
  }
  // get the details in table when onclick on search
  const searchDetails = async (fieldValue, dataValue) => {
    const searchParams = {
      field: fieldValue,
      data: dataValue
    };
    let searchItems = await searchWalletUser(searchParams);
    setItems(searchItems);
  }

  //Clear the details
  let clearDetails = async () => {
    setItems(originalData);
    searchInput.current.value = "";
  }
  return (
    <div>
      <ToastContainer />
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="striped-table-with-hover">
              <Card.Header>
                <Card.Title>
                  <SearchLayout
                    searchInput={searchInput}
                    searchOptions={searchOptions}
                    doSearch={searchDetails}
                    clearDetails={clearDetails}
                    addButtonCallback={() => { history.push("/wallet-user-add") }}
                    isAddButtonEnable={enableAddButton()}
                    showHide={true} />
                </Card.Title>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0" style={{ marginTop: "0" }}>
                <SetWalletUserList items={items}
                  asecDsec={asecDsec}
                  flag={flag}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
        </Row>
      </Container>
    </div>
  );
}
function SetWalletUserList({ items, asecDsec, flag }) {
  const [selectedWallet, setSelectedWallet] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const getActivateButton = (item) => {
    return (
      <div className="attribute" data-name="TXN History"><button style={{ "backgroundColor": "transparent", "border": "none" }} onClick={() => { selectMeterOpenModal(item); }}><GrHistory /></button></div>
    );
  }
  const selectMeterOpenModal = (walletObj) => {
    setSelectedWallet(walletObj);
    handleShow();

  }
  return (
    <div>
      <ol className="collection collection-container">
        <li className="item item-container table-header">
          <div className="attribute-container information">
            <div className="attribute-container name-organization">
              <div className="attribute" data-name="Create Date">TXN Date
                <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("timestamp", flag, items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
              </div>
              <div className="attribute" data-name="Amount">TXN Amount
                <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("signedAmount", flag, items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
              </div>
            </div>
            <div className="attribute-container price-currency">
              <div className="attribute" data-name="Currency">Currency
                <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("currency", flag, items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
              </div>
              <div className="attribute" data-name="Balance">Balance
                <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("balance", flag, items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
              </div>
              <div className="attribute" data-name="Source">Source
                <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("source", flag, items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
              </div>
            </div>
          </div>
          <div className="attribute-container amount-edit">
            <div className="attribute" data-name="Provider">Provider
              <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("provider", flag, items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
            </div>
            <div className="attribute" data-name="Reference">Reference
              <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("reference", flag, items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
            </div>
            {/* {getAction()} */}
          </div>
        </li>
        <PaymentModal
          selectedWallet={selectedWallet}
          show={show}
          handleClose={handleClose} />
        {(items.length != 0) ?
          <div>
            {items.map((item) => {
              return (<li key={item.id} className="item item-container hover">
                {/* <div className="attribute" data-name="No"></div> */}
                <div className="attribute-container information">
                  <div className="attribute-container name-organization">
                    <div className="attribute" data-name="Create Date">{moment(item.timestamp).format('DD-MMM hh:mm:ss A')}</div>
                    <div className="attribute" data-name="Amount">{getAmountSign(item.amount)} {item.amount} </div>
                  </div>
                  <div className="attribute-container price-currency">
                    <div className="attribute" data-name="Currency">{getCurrencyNameById(item.currency_id)}</div>
                    <div className="attribute" data-name="Balance">{item.balance}</div>
                    <div className="attribute" data-name="Source">{item.source}</div>
                  </div>
                </div>
                <div className="attribute-container amount-edit">
                  <div className="attribute" data-name="Provider">{item.provider}</div>
                  <div className="attribute" data-name="Reference">{item.reference}</div>
                </div>
              </li>)
            })}
          </div>
          :
          <div className="no-data-available-text">No Records Found</div>
        }
      </ol>
    </div>

  );
}

