import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { GoSearch } from 'react-icons/go'
import * as moment from 'moment'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function ToastLayout(item) {
    toast.error(item, {
        position: "top-center",
        theme: "colored",
        autoClose: 2000,
        closeOnClick: true,
        pauseOnHover: false,
        hideProgressBar:true,
        draggable: true,
    })
}