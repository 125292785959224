import React, { useState, useContext, useEffect } from 'react';
import Select from 'react-select';
import {
   Modal,
   Row,
   Col,
   Card,
   Container,
   Form
} from "react-bootstrap";
import '../../assets/css/pages/table-list.css'
import AddMeter from './AddMeter';
import AddGateway from './AddGateway';
import AddPBX from './AddPBX';

export default function AddDeviceModal({ itemMeter, setItemMeter,itemGateway,setItemGateway,itemPBX, setItemPBX,showDevice, assetType, selectedNode, handleDeviceClose, organizationData, userData, deviceGroupData, modeData, hardwareData, currentOrganizationName, getUserRole, getUserId, reloadAllTree ,asset,setAsset}) {
  
   const onSelect = async (e) => {
      if(e.value=="PBX Device"){
         setAsset("PBX")
      }else if(e.value=="Energy Meter"){
         setAsset("Meter")
      }else  if(e.value=="Gateway"){
         setAsset("Gateway")
      }
 }
 const checkAsset=()=>{
    if(asset==="PBX"){
       return <AddPBX
      setItemPBX={setItemPBX}
      itemPBX={itemPBX}
      organizationData={organizationData}
      userData={userData}
      deviceGroupData={deviceGroupData}
      modeData={modeData}
      hardwareData={hardwareData}
      currentOrganizationName={currentOrganizationName}
      getUserRole={getUserRole}
      getUserId={getUserId}
      reloadAllTree={reloadAllTree}
      selectedNode={selectedNode}
      />
    }else if(asset==="Meter"){
      return <AddMeter
      itemMeter={itemMeter}
      setItemMeter={setItemMeter}
      organizationData={organizationData}
      userData={userData}
      deviceGroupData={deviceGroupData}
      modeData={modeData}
      hardwareData={hardwareData}
      currentOrganizationName={currentOrganizationName}
      getUserRole={getUserRole}
      getUserId={getUserId}
      reloadAllTree={reloadAllTree}
      selectedNode={selectedNode}
  />
    }else if(asset==="Gateway"){
      return <AddGateway
      setItemGateway={setItemGateway}
      itemGateway={itemGateway}
      organizationData={organizationData}
      userData={userData}
      deviceGroupData={deviceGroupData}
      modeData={modeData}
      hardwareData={hardwareData}
      currentOrganizationName={currentOrganizationName}
      getUserRole={getUserRole}
      getUserId={getUserId}
      reloadAllTree={reloadAllTree}
      selectedNode={selectedNode}
  />
    }
 }
   let subHeading = "Add Asset " + selectedNode.addLabel;
   return (<Modal show={showDevice} onHide={handleDeviceClose}>
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
         <div>
            <Container fluid>
               <Row>
                  <Col md="12">
                     <Col sm="12">
                        <div className="form-header-container form-header" style={{ marginTop: "05px" }}>
                           {subHeading}
                        </div>
                     </Col>
                     <Card className="striped-table-with-hover">
                        <Card.Header>
                           <Col sm="12">
                              <Select
                                 className="installation-asset-dropDown-style"
                                 defaultValue={assetType[0]}
                                 options={assetType}
                                onChange={onSelect}
                              />
                           </Col>
                        </Card.Header>
                        {checkAsset()}
                     </Card>
                  </Col>
               </Row>
               <Row>
               </Row>
            </Container>
         </div>
      </Modal.Body>
      <Modal.Footer>
         <br />
      </Modal.Footer>
   </Modal>
   );

}