import React, { useState, useContext } from 'react';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useHistory } from 'react-router-dom';
import { Input } from 'reactstrap';
import UserContext from './UserContext';
import { apiUrl } from 'services/globalService';
import { setUserToken, setLocalUser } from 'services/roleService';
import { processUserRoutes } from 'services/roleService';
import dashboardRoutes from 'routes';
import FirstComponent from './FirstComponent';
import { Link } from "react-router-dom";
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
// import PhoneInput from 'react-phone-number-input'
// import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { ToastContainer, toast } from 'react-toastify';
import ToastLayout from '../views/partials/Toastify';


function Login() {
   const history = useHistory();
   const [styles, setStyles] = useState({ display: "none" });
   const [inputValue, setInputValue] = useState();
   const [loginWith, setLoginWith] = useState("email");
   const [label, setLabel] = useState("Email/Mobile number :");
   const [eyeShow, setEyeShow] = useState(false);
   const [show, setShow] = useState(false);
   const [PassShow, setPassShow] = useState(false);
   const handleClose = () => setShow(false);
   const passHandleClose = () => setPassShow(false);
   const handleShow = () => setShow(true);
   const passHandleShow = () => setPassShow(true);
   let apiLogin = apiUrl + "login";
   let apiEmailConfirmation = apiUrl + "users-confirmation";
   const userContext = useContext(UserContext);
   let usernameInput = React.createRef(); //document.getById
   let passwordInput = React.createRef();
   let loginButton = React.createRef();
   let emailInput = React.createRef();
   let emailConfirmButton = React.createRef();
   //function call when onclick login button
   const doLogin = () => {
      let data = {
         username: inputValue,
         password: passwordInput.current.value
      };
      console.log()
      //call login API
      fetch(apiLogin, {
         method: 'post',
         headers: {
            'Content-Type': 'application/json;charset=utf-8'
         },
         body: JSON.stringify(data)
      })
         .then(response => response.json())
         .then(response => {
            // setting the login TRUE
            if (response.result == "success") {
               setLocalUser(response.user)
               setUserToken(response.token)
               userContext.doLogin(true);
               history.push("/first-component")
            } else {
               handleShow();
            }
         })
         .catch(function (error) {
            console.log(error);
         });
   }
   const sendConfirmation = async () => {
      passHandleClose();
      let data = {
         email: emailInput.current.value
      };
      await fetch(apiEmailConfirmation, {
         method: 'post',
         headers: {
            'Content-Type': 'application/json;charset=utf-8'
         },
         body: JSON.stringify(data)
      })
         .then(response => response.json())
         .then(response => {
            // setting the login TRUE
            if (response.result == "success") {
               toast.error(ToastLayout("Please check your mail to get reset password link!"))
            } else {
               toast.error(ToastLayout("No such User Found!"))
            }
         })
         .catch(function (error) {
            console.log(error);
         });
   }
   const forgotPassword = () => {
      passHandleShow();
   }
   //login through clicking enter 
   const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
         loginButton.current.focus();
         doLogin();
      }
   }
   const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
         emailConfirmButton.current.focus();
         sendConfirmation();
      }
   }
   const handleChange = (e) => {
      if(e.target.value==0){
         setLabel("Email/Mobile number :");
      }
      if (e.target.value.length > 3) {
         let value = parseInt(e.target.value)
         if (Number.isInteger(value) == true) {
            // console.log('number>>',value)
            setLoginWith("number");
            setLabel("Mobile number :");
            setInputValue(e.target.value);
            setStyles({ display: "block" });
            setTimeout(() => {
               document.querySelector('.phone_temp_login').focus();
            }, 200)
            e.target.value="";
         } else {
            // console.log('STRING>>',value)
            setLoginWith("email");
            setLabel("Email :");
            setInputValue(e.target.value);
            setStyles({ display: "none" });
         }
      }

   }
   const handle = (e) => {
      if(e.length==0){
         setLabel("Email/Mobile number :");
         setLoginWith("email");
         setStyles({ display: "none" });
         setInputValue("")
         setTimeout(() => {
            document.querySelector('.email_temp').focus();
         }, 200)
      }else{
         setInputValue(e)
      }
   }

   return (
      <div className="row" style={{ margin: "3%"}}>
         <div className="col-lg-6 d-md-block" style={{ background: "white", flex: "1", alignItems: "center", marginTop: "10%" }}>
            <center>
               <img className="mobile-login-logo" src="/main-logo.png" /><br /><br />
               <img className="mobile-login-text" src="/main-text.png" />
            </center>
         </div>
         <div style={{ backgroundColor: "White", alignItems: "center", marginTop: "6%" }} className="col-lg-5">
            <center>
               <div className='loginText'>LOGIN</div> </center>
            <br />
            <div style={{ margin: "4%" }}>
               <Form>
                  <Form.Group controlId="formBasicEmail" className="mb-4">
                     <Form.Label style={{ fontSize: "110%" }}>{label}</Form.Label>
                     <Form.Control className='email_temp' hidden={loginWith == "number"} autoFocus name="username" placeholder="Enter Email/Mobile number" onChange={handleChange} style={{ borderBottomColor: "red", borderTop: "none", borderRight: "none", borderLeft: "none", borderRadius: '0rem' }} />
                     <PhoneInput
                        buttonClass='dropdown_login_user'
                        inputClass='phone_temp_login'
                        style={styles}
                        placeholder="Enter phone number"
                        value={inputValue}
                        onChange={handle}
                     />
                  </Form.Group>
                  <Form.Group controlId="formBasicPassword" className="mb-4">
                     <Form.Label style={{ fontSize: "110%" }}>Password :</Form.Label>
                     <div style={{ display: "flex", flexDirection: "column-reverse" }}>
                        <Form.Control name="password" type={eyeShow == true ? 'text' : 'password'} onKeyPress={handleKeyDown} placeholder="Password" ref={passwordInput} style={{ borderBottomColor: "red", borderTop: "none", borderRight: "none", borderLeft: "none", borderRadius: '0rem' }} />
                        <i hidden={eyeShow == false} onClick={() => { setEyeShow(false) }} className="eye-password"><AiFillEye /></i>
                        <i hidden={eyeShow == true} onClick={() => { setEyeShow(true) }}  className="eye-password"><AiFillEyeInvisible /></i>
                     </div>
                  </Form.Group>
                  <ToastContainer />
                  <Button value="Login" onClick={doLogin} ref={loginButton} style={{ width: "100%", background: "red", border: "none", height: "300", color: "white", fontSize: "130%", textAlign: "center" }} >
                     Login
                  </Button>
                  <Link to="#" onClick={forgotPassword} style={{ display: "flex", justifyContent: "center", marginTop: "12px", textDecoration: "none", color: "red", fontSize: "18px" }}>
                     Password Reset?
                  </Link>
                  <Modal show={show} onHide={handleClose}>
                     <Modal.Header closeButton>
                     </Modal.Header>
                     <Modal.Body>
                        Incorrect username or password</Modal.Body>
                     <Modal.Footer>
                        <br />
                        <button onClick={handleClose} style={{ width: "30%", background: "red", border: "none", height: "350", color: "white", fontSize: "130%", textAlign: "center" }}>
                           Try again!
                        </button>
                     </Modal.Footer>
                  </Modal>
                  <Modal show={PassShow} onHide={passHandleClose} contentClassName='modal-custom'>
                     <Modal.Header closeButton>
                     </Modal.Header>
                     <Modal.Body style={{ paddingBottom: "0px" }}>
                        <Form>
                           <Form.Group controlId="formBasicEmail" className="mb-4" style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                              <Form.Label style={{ fontSize: "110%", color: "red", display: "flex", justifyContent: "flex-start", marginLeft: "22%" }}>Enter Your Email :</Form.Label>
                              <Form.Control name="username" placeholder='Enter Email' onKeyPress={handleKeyPress} ref={emailInput} onplaceholder="Enter email" style={{ borderColor: "red", borderRadius: '0.2rem', display: "flex", justifyContent: "center", width: "250px", alignSelf: "center" }} />
                           </Form.Group>
                        </Form>
                     </Modal.Body>
                     <Modal.Footer style={{ display: "flex", justifyContent: "center", }}>
                        <br />
                        <button onClick={sendConfirmation} ref={emailConfirmButton} style={{ width: "28%", background: "red", border: "none", height: "350", color: "white", fontSize: "130%", textAlign: "center", padding: "4px 10px", borderRadius: "0.2rem" }}>
                           Send Email
                        </button>
                     </Modal.Footer>
                  </Modal>
               </Form>
            </div>
         </div>
      </div>);
}

export default Login;
