import { useEffect } from "react";
import { useHistory } from 'react-router-dom';
import dashboardRoutes from "routes";
import { getUserToken } from "services/roleService";
import { processUserRoutes } from "services/roleService";
import routes from "../routes.js";

export default function FirstComponent() {
    const history = useHistory();
    const userRoutes = processUserRoutes(routes());
    const userPath=userRoutes[0].path
    useEffect(() => {
        if (getUserToken != null) {
            history.push(`${userPath}`)
        }
        else {
            history.push("/login")
        }
    }, []);
    return (<div></div>);
}