import React, { useState } from 'react';
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import './assets/css/pages/add-edit-form.css'
// import './assets/css/pages/table-list.css'
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/css/demo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Login from "./views/Login";
import PasswordReset from './views/passwordReset';
import getRoutes from "./routes.js";
import AdminLayout from "layouts/Admin.js";
import UserCtx from './views/UserContext';
import Protected from './views/Protected';
import { getLocalUser } from 'services/roleService';
import { processUserRoutes } from "services/roleService";
import "./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";

function App() {
  const userRoutes = processUserRoutes(getRoutes());
  let loginStatus = () => {
    if (getLocalUser().role_type && typeof getLocalUser().role_type != 'undefined') {
      return { userType: getLocalUser().role_type, session: true };
    } else {
      return { session: false };
    }
  }
  const [isLoggedIn, setIsLoggedIn] = useState(loginStatus().session);
  const [userType, setUserType] = useState(loginStatus().role_type);
  localStorage.setItem('isSession', loginStatus().session);
  // if(loginStatus().session)
  const redirectFirst = () => {
    if (isLoggedIn) {
      if (window.location.hash.length > 3) {
        let newRoute = window.location.hash.substr(1);
        return <Redirect from="/" to={newRoute} />
      } else {
        return <Redirect from="/" to={userRoutes[0].path} />
      }
    }
    else if (window.location.hash.substring(2,16)=='reset-password'){
      let url="/"+window.location.hash.substring(2)
      return <Redirect from="/" to={url} />
    }else {
      return <Redirect from="/" to="/login" />
    }
  }
  return (
    <HashRouter>
      <UserCtx.Provider
        value={{
          isLoggedIn,
          doLogin: (code) => {
            if (code) {
              setIsLoggedIn(true);
              localStorage.setItem('isLoggedIn', "true");
              // in real app this is access_token 
            } else {
              localStorage.clear();
              setIsLoggedIn(false)
            }
          }
        }}
      > 
      {/* <Switch></Switch> */}
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/reset-password/:token" component={PasswordReset} />
          <Protected isLoggedIn={isLoggedIn} userType={userType} path="/">
            <AdminLayout />
          </Protected>
        </Switch>
        {redirectFirst()}
      </UserCtx.Provider>
    </HashRouter>
  );
}
export default App;