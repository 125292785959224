import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import Select from "react-select";
import { getGateway, deleteGateway, getGatewayByAddress, updateGateway, addGateway } from '../../services/gatewayService';
import { BsArrowLeftShort } from "react-icons/bs";
import {
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
    OverlayTrigger,
    Tooltip,
    Form
} from "react-bootstrap";
// import '../../assets/css/pages/add-edit-form.css'
import { getUserId } from '../../services/roleService';
import { getOrganization } from '../../services/organizationService';
import { getUsers, me, getAllCustomerUsers } from '../../services/userService';
import { getDeviceGroup } from '../../services/deviceGroupService';
import { getHardware, getHardwareByAssetType } from '../../services/hardwareService';
import { getUserType as getUserRole, getRolePermission } from '../../services/roleService';
import { getInstallation, getInstallationById, searchInstallationGroup } from '../../services/installationService';
import { AsyncPaginate } from "react-select-async-paginate";
import ToastLayout from '../../views/partials/Toastify';
import { ToastContainer, toast } from 'react-toastify';
export default function GatewayAddEdit() {
    const [state, setState] = useState({
        mac_address: '', bLabel: 'Add', FormLabel: 'Add'
    });
    const [currentOrganizationName, setCurrentOrganizationName] = useState();
    const [organizationData, setOrganizationData] = useState({});
    const [userData, setUserData] = useState({ usrData: [] });
    const [installationGroupData, setInstallationGroupData] = useState({ groupData: [] });
    const [hardwareData, setHardwareData] = useState({ hardware: [] });
    const history = useHistory();
    const { mac_address } = useParams();
    var gateway;
    var groupRecord;
    const [hardwareModel, setHardwareModel] = useState();
    // const [customer, setCustomer] = useState();
    const [groupName, setGroupName] = useState();


    useEffect(async () => {
        if (typeof (mac_address) !== 'undefined') {
            await fetchGateway(mac_address);
        }
        // reloadOrganization();
        // reloadUser();
        reloadDeviceGroup();
        reloadHardware();
        getCurrentOrganizationName();
    }, [])
    let fetchGateway = async (mac_address) => {
        gateway = await getGatewayByAddress(mac_address);
        // console.log("gateway",gateway)
        if (typeof (gateway.mac_address) !== 'undefined') {
            setState({ ...gateway, bLabel: 'Update', FormLabel: 'Edit' })
            groupRecord = await getInstallationById(gateway.device_group_id);
            if (groupRecord.result == 'fail') {
                let currentUserData = await me();
                setOrganizationData(currentUserData[0])
            } else {
                setOrganizationData(groupRecord[0])
            }
        }
    }
    //Display organization name
    // let reloadOrganization = async () => {
    //     let record = await getOrganization();
    //     setOrganizationData({ ...organizationData, data: record });
    // }
    //Display User name
    // let reloadUser = async () => {
    //     let usrRecord = await getAllCustomerUsers();
    //     setUserData({ ...userData, usrData: usrRecord });
    //     // console.log("userData.usrData", usrRecord);
    //     if (typeof (mac_address) !== 'undefined') {
    //         setCustomer(usrRecord.filter((pre) => {
    //             if (pre.id === gateway.owner_user_id)
    //                 return true;
    //         }))
    //     }
    // }
    //Display device group name
    let reloadDeviceGroup = async () => {
        if (typeof (mac_address) == 'undefined') {
            let currentUserData = await me();
            setOrganizationData(currentUserData[0])
        }
        if (typeof (mac_address) !== 'undefined') {
            console.log("groupRecord", groupRecord)
            if (groupRecord.result == 'fail') {
                setGroupName({ id: null, group_name: "None" })
            } else {
                setGroupName(groupRecord[0])
            }
        }
    }
    const loadInstallations = async (search, prevOptions) => {
        let filteredOptions = [];
        if (search) {
            let searchData = {
                searchKey: search
            }
            filteredOptions = await searchInstallationGroup(searchData);
            filteredOptions = [{ id: null, group_name: "None" }, ...filteredOptions]
        } else {
            let options = [{ id: null, group_name: "None" }]
            filteredOptions = options;
        }
        const slicedOptions = filteredOptions.slice(
            prevOptions.length,
            prevOptions.length + 10
        );
        return {
            options: slicedOptions
        };
    };
    //Display hardware name
    let reloadHardware = async () => {
        let hardwareRecord = await getHardwareByAssetType("Gateway");
        setHardwareData({ ...hardwareData, hardware: hardwareRecord });
        if (typeof (mac_address) !== 'undefined') {
            setHardwareModel(hardwareRecord.filter((pre) => {
                if (pre.id === gateway.hardware_id)
                    return true;
            })[0])
        }
    }
    let doDelete = async () => {
        const response = await deleteGateway({ mac_address });
        // console.log("response>>>delete",response)
        history.push("/gateway");
    }
    let getDeleteButton = () => {
        const history = useHistory();
        if (getRolePermission('gateways', getUserRole()) > 2) {
            if (typeof (mac_address) !== 'undefined') {
                return (<button onClick={(e) => { e.preventDefault(); doDelete() }} className="cancle-button-style">
                    Delete
                </button>);
            }
        }
    }
    let checkEditOrAdd = () => {
        if (typeof (mac_address) !== 'undefined') {
            return (<input
                disabled
                name="mac_address"
                placeholder="Mac address e.g: b8:27:eb:e8:00:76"
                onChange={handleChange}
                value={state.mac_address}
                className="input-style-responsive"
            />
            );
        } else {
            return (<input
                name="mac_address"
                placeholder="Mac address e.g: b8:27:eb:e8:00:76"
                onChange={handleChange}
                value={state.mac_address}
                className="input-style-responsive"
            />
            );
        }
    }
    const getCurrentOrganizationName = async () => {
        let record = await me();
        setCurrentOrganizationName(...record)
    }
    let getOrganizationName = () => {
        if (getUserRole() === "super-admin") {
            return (
                <Form.Group as={Row} className="mb-3" >
                    <Col sm="3">
                        <div className="add-responsive">Organization :</div>
                    </Col>
                    <Col sm="9">
                        <input
                            disabled
                            name="owner_organization_id"
                            id={organizationData.organization_id}
                            value={organizationData.organization_name}
                            className="input-style-responsive"
                            style={{ backgroundColor: 'transparent', borderBottom: "0rem" }}
                        />
                    </Col>
                </Form.Group>
            )
        }
        else {
            state.owner_organization_id = currentOrganizationName && currentOrganizationName.organization_id
            return (
                <Form.Group as={Row} className="mb-3" >
                    <Col sm="3">
                        <div className="add-responsive">Organization :</div>
                    </Col>
                    <Col sm="9">
                        <input
                            disabled
                            name="owner_organization_id"
                            id={state.organization_id}
                            value={currentOrganizationName && currentOrganizationName.organization_name}
                            className="input-style-responsive"
                            style={{ backgroundColor: 'transparent', borderBottom: "0rem" }}
                        />
                    </Col>
                </Form.Group>
            )
        }
    }
    let selectHardwareModel = (e) => {
        setState({ ...state, ["hardware_id"]: e.id });
        setHardwareModel(e);
    }
    // let selectCustomer = (e) => {
    //     setState({ ...state, ["owner_user_id"]: e.id });
    //     setCustomer(e);
    // }
    let selectInstallation = async (e) => {
        setState({ ...state, ["device_group_id"]: e.id });
        if (e.id !== null) {
            let groupRecord = await getInstallationById(e.id);
            setOrganizationData(groupRecord[0])
        } else {
            let currentUserData = await me();
            setOrganizationData(currentUserData[0])
        }

        setGroupName(e);
    }
    let handleChange = async (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    }
    let handleSubmit = async (e) => {
        e.preventDefault();
        if (!state.mac_address.length) {
            return;
        }
        const newItem = {
            mac_address: state.mac_address,
            serial: state.serial,
            device_group_id: state.device_group_id,
            hardware_id: state.hardware_id,
            latitude: state.latitude,
            longitude: state.longitude,
            hub_name: state.hub_name,
            owner_organization_id: organizationData.organization_id,
            owner_user_id: null
        };
        if (typeof (mac_address) !== 'undefined') { //update
            newItem.mac_address = state.mac_address;
            let response = await updateGateway(newItem)
            if (response.result == "fail") {
                toast.error(ToastLayout("Serial already exists!"))
            } else {
                history.push("/gateway");
            }
        } else { //add
            // if (newItem.hub_name) {
            if (newItem.hardware_id) {
                if (newItem.owner_organization_id) {
                    let response = await addGateway(newItem)
                    if (response.result == "fail") {
                        if (response.msg.errors[0].message == "PRIMARY must be unique") {
                            toast.error(ToastLayout("Mac address already exists!"))
                        } else if (response.msg.errors[0].message == "unique_serial must be unique") {
                            toast.error(ToastLayout("Serial already exists!"))
                        } else {
                            toast.error(ToastLayout("Mac address or Serial already exists!"))
                        }
                    } else {
                        history.push("/gateway");
                    }
                } else {
                    toast.error(ToastLayout("Please select organization"))
                }
            } else {
                toast.error(ToastLayout("Please select hardware model"))
            }
            // } else { alert("Please select Hub name") }
        }
    }
    let handleCancel = () => {
        history.push("/gateway");
    }
    let backButton = async () => {
        history.push("/gateway")
    }
    return (
        <div>
            <Container fluid>
                <Row>
                    <Col md="12">
                        <Card className="striped-table-with-hover">
                            <Card.Header>
                                <div className='display-back-button'>
                                    <div><button className="back" data-toggle="tooltip" title="click to go back" onClick={() => { backButton() }} ><BsArrowLeftShort /></button></div>
                                    <div className="form-header-container form-header" style={{ marginTop: "05px" }}>
                                        <center>{state.FormLabel} Gateway</center>
                                    </div>
                                </div>
                            </Card.Header>
                            <Card.Body className="px-0" >
                                <Col lg="9">
                                    <Form >
                                        <Form.Group as={Row} className="mb-3" >
                                            <Col sm="3">
                                                <div className="add-responsive">Mac Address :</div>
                                            </Col>
                                            <Col sm="9">
                                                {checkEditOrAdd()}
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" >
                                            <Col sm="3">
                                                <div className="add-responsive">Serial :</div>
                                            </Col>
                                            <Col sm="9">
                                                <input
                                                    name="serial"
                                                    placeholder="Serial e.g: wk1-23"
                                                    onChange={handleChange}
                                                    value={state.serial}
                                                    className="input-style-responsive"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" >
                                            <Col sm="3">
                                                <div className="add-responsive">Installation Group :</div>
                                            </Col>
                                            <Col sm="9" style={{ maxWidth: "43.5%" }}>
                                                <AsyncPaginate
                                                    value={groupName}
                                                    loadOptions={loadInstallations}
                                                    placeholder={"Type to select ...."}
                                                    onChange={(e) => selectInstallation(e)}
                                                    options={[]}
                                                    getOptionLabel={e => e.group_name}
                                                    getOptionValue={e => e.id}
                                                />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} className="mb-3" >
                                            <Col sm="3">
                                                <div className="add-responsive">Hardware Model :</div>
                                            </Col>
                                            <Col sm="9">
                                                <Select
                                                    className="dropDown-style"
                                                    value={hardwareModel}
                                                    options={hardwareData.hardware}
                                                    getOptionLabel={e => e.model}
                                                    getOptionValue={e => e.id}
                                                    onChange={(e) => selectHardwareModel(e)}
                                                />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} className="mb-3" >
                                            <Col sm="3">
                                                <div className="add-responsive">Longitude :</div>
                                            </Col>
                                            <Col sm="9">
                                                <input
                                                    name="longitude"
                                                    placeholder="Longitude e.g: 72.8763"
                                                    onChange={handleChange}
                                                    value={state.longitude}
                                                    className="input-style-responsive"
                                                />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} className="mb-3" >
                                            <Col sm="3">
                                                <div className="add-responsive">Latitude :</div>
                                            </Col>
                                            <Col sm="9">
                                                <input
                                                    name="latitude"
                                                    placeholder="Latitude e.g: 75.8763"
                                                    onChange={handleChange}
                                                    value={state.latitude}
                                                    className="input-style-responsive"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" >
                                            <Col sm="3">
                                                <div className="add-responsive">Hub Name :</div>
                                            </Col>
                                            <Col sm="9">
                                                <input
                                                    name="hub_name"
                                                    placeholder="Hub Name"
                                                    onChange={handleChange}
                                                    value={state.hub_name}
                                                    className="input-style-responsive"
                                                />
                                            </Col>
                                        </Form.Group>
                                        {getOrganizationName()}
                                        {/* <Form.Group as={Row} className="mb-3" >
                                            <Col sm="3">
                                                <div className="add-responsive">Customer :</div>
                                            </Col>
                                            <Col sm="9">
                                                <Select
                                                    className="dropDown-style"
                                                    value={customer}
                                                    options={userData.usrData}
                                                    getOptionLabel={e => e.username}
                                                    getOptionValue={e => e.id}
                                                    onChange={(e) => selectCustomer(e)}
                                                />
                                            </Col>
                                        </Form.Group> */}

                                        <Form.Group as={Row} className="mb-3" >
                                            <Col>
                                                <div className="button-container-responsive">
                                                    <ToastContainer />
                                                    <button onClick={handleSubmit} className="add-button-style">
                                                        {state.bLabel}
                                                    </button> &nbsp;&nbsp;
                                                    {getDeleteButton()}
                                                    &nbsp;&nbsp;
                                                    <button onClick={handleCancel} className="cancle-button-style">
                                                        Cancel
                                                    </button>
                                                </div>
                                            </Col>
                                        </Form.Group>
                                    </Form>
                                </Col>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                </Row>
            </Container>
        </div>
    );
}