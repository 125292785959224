import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router'
import { getInstallation, addInstallation, getInstallationById, updateInstallation, deleteInstallation } from '../../services/installationService';
import { BsArrowLeftShort } from "react-icons/bs";
import Select from "react-select";
import {
    Button,
    Card,
    Table,
    Container,
    Row,
    Col,
    Form
} from "react-bootstrap";
import { getGroupType } from '../../services/groupTypeService';
import { getUserId } from '../../services/roleService';
import { getOrganization, getOrganizationById } from '../../services/organizationService';
import { me } from '../../services/userService';
import { getUserType as getUserRole, getRolePermission, getOrganizationId } from '../../services/roleService'
import ToastLayout from '../../views/partials/Toastify';
import { ToastContainer, toast } from 'react-toastify';

export default function InstalltionAddEdit() {
    const [state, setState] = useState({
        id: 0, bLabel: 'Add', FormLabel: 'Add'
    });
    const [group_name, setGroupName] = useState("")
    const [organization, setOrganization] = useState();
    var installations;
    const history = useHistory();
    const { id } = useParams();
    let fetchInstallation = async (id) => {
        installations = await getInstallationById(id);
        if (typeof (id) !== 'undefined') {
            setState({ ...installations[0], bLabel: 'Update', FormLabel: 'Edit' })
        }
    }
    useEffect(async () => {
        if (typeof (id) !== 'undefined') {
            await fetchInstallation(id);
        }
        reloadOrganization();
        reloadGroupType();
        getCurrentOrganizationName();
    }, [])
    //display organization name
    const [organizationData, setOrganizationData] = useState({
        orgData: []
    });
    let reloadOrganization = async () => {
        let orgRecord = await getOrganization();
        setOrganizationData({ ...organizationData, orgData: orgRecord });
        if (typeof (id) !== 'undefined') {
            setOrganization(orgRecord.filter((pre) => {
                if (pre.id === installations[0].organization_id)
                    return true;
            }))
        }
    }
    //display groupType name
    const [groupTypeData, setGroupTypeData] = useState({
        data: []
    });
    let reloadGroupType = async () => {
        let record = await getGroupType();
        setGroupTypeData({ ...groupTypeData, data: record });
        record.forEach((groupType) => {
            let checkGroupTypeName = groupType.name.toLowerCase();
            if (checkGroupTypeName == "installation") {
                state.group_type_id = groupType.id;
                setGroupName(groupType.name);
            }
        })
    }
    let handleCancel = () => {
        history.push("/installation");
    }
    let selectOrganization = (e) => {
        setState({ ...state, ["organization_id"]: e.id });
        setOrganization(e);
    }
    let handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    }
    let handleSubmit = async (e) => {
        e.preventDefault();
        const newItem = {
            group_name: state.group_name,
            group_type_id: state.group_type_id,
            longitude: state.longitude,
            latitude: state.latitude,
            organization_id: state.organization_id,
            description: state.description
        };
        if (state.id !== 0) { //update
            newItem.id = state.id;
            let response = await updateInstallation(newItem);
            if (response.result == 'fail') {
                "Please select organization"
                toast.error(ToastLayout("Group already exist in this organization!"))               
            }else {
                history.push("/installation");
            }
        } else { //add
            if (newItem.organization_id) {
                console.log("newItem", newItem)
                let response = await addInstallation(newItem);
                if (response.result == 'fail') {
                    toast.error(ToastLayout("Group already exist in this organization!")) 
                } else {
                    history.push("/installation");
                }
            } else {
                toast.error(ToastLayout("Please select organization")) 
            }
        }
    }
    let doDelete = async () => {
        await deleteInstallation({ id })
        history.push("/installation");
    }
    let getDeleteButton = () => {
        const history = useHistory();
        if (getRolePermission('installations', getUserRole()) > 2) {
            if (typeof (id) !== 'undefined') {
                return (<button onClick={(e) => { e.preventDefault(); doDelete() }} className="cancle-button-style">
                    Delete
                </button>);
            }
        }
    }
    let backButton = async () => {
        history.push("/installation")
    }
    const [currentOrganizationName, setCurrentOrganizationName] = useState();
    const getCurrentOrganizationName = async () => {
        let record = await me();
        setCurrentOrganizationName(...record)
    }
    let getOrganizationName = () => {
        if (getUserRole() === "super-admin") {
            return (
                <Form.Group as={Row} className="mb-3" >
                    <Col sm="3">
                        <div className="add-responsive">Organization :</div>
                    </Col>
                    <Col sm="9">
                        <Select
                            className="dropDown-style"
                            value={organization}
                            options={organizationData.orgData}
                            getOptionLabel={e => e.organization_name}
                            getOptionValue={e => e.id}
                            onChange={(e) => selectOrganization(e)}
                        />
                    </Col>
                </Form.Group>
            )
        }
        else {
            state.organization_id = currentOrganizationName && currentOrganizationName.organization_id
            return (
                <Form.Group as={Row} className="mb-3" >
                    <Col sm="3">
                        <div className="add-responsive">Organization :</div>
                    </Col>
                    <Col sm="9">
                        <input
                            disabled
                            name="organization_id"
                            id={state.organization_id}
                            value={currentOrganizationName && currentOrganizationName.organization_name}
                            className="input-style-responsive"
                        />
                    </Col>
                </Form.Group>
            )
        }
    }
    return (
        <div>
            <Container fluid>
                <Row>
                    <Col md="12">
                        <Card className="striped-table-with-hover">
                            <Card.Header>
                                <div className='display-back-button'>
                                    <div><button className="back" data-toggle="tooltip" title="click to go back" onClick={() => { backButton() }} ><BsArrowLeftShort /></button></div>
                                    <div className="form-header-container form-header" style={{ marginTop: "05px" }}>
                                        <center>{state.FormLabel} Installations</center>
                                    </div>
                                </div>
                            </Card.Header>
                            <Card.Body className="px-0" >
                                <Col lg="9">
                                    <Form >
                                        <Form.Group as={Row} className="mb-3" >
                                            <Col sm="3">
                                                <div className="add-responsive">Group Name :</div>
                                            </Col>
                                            <Col sm="9">
                                                <input
                                                    name="group_name"
                                                    placeholder="Group Name"
                                                    onChange={handleChange}
                                                    value={state.group_name}
                                                    className="input-style-responsive"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" >
                                            <Col sm="3">
                                                <div className="add-responsive">Group Type:</div>
                                            </Col>
                                            <Col sm="9">
                                                <input
                                                    disabled
                                                    id={state.group_type_id}
                                                    name="group_type_id"
                                                    className="input-style-responsive"
                                                    value={group_name}
                                                    onChange={handleChange}
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" >
                                            <Col sm="3">
                                                <div className="add-responsive">Longitude :</div>
                                            </Col>
                                            <Col sm="9">
                                                <input
                                                    name="longitude"
                                                    placeholder="longitude  e.g: 75.8763"
                                                    onChange={handleChange}
                                                    value={state.longitude}
                                                    className="input-style-responsive"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" >
                                            <Col sm="3">
                                                <div className="add-responsive">Latitude :</div>
                                            </Col>
                                            <Col sm="9">
                                                <input
                                                    name="latitude"
                                                    placeholder="Latitude  e.g: 72.8763"
                                                    onChange={handleChange}
                                                    value={state.latitude}
                                                    className="input-style-responsive"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" >
                                            <Col sm="3">
                                                <div className="add-responsive">Description :</div>
                                            </Col>
                                            <Col sm="9">
                                                <input
                                                    name="description"
                                                    placeholder="Description"
                                                    onChange={handleChange}
                                                    value={state.description}
                                                    className="input-style-responsive"
                                                />
                                            </Col>
                                        </Form.Group>
                                        {getOrganizationName()}
                                        <Form.Group as={Row} className="mb-3" >
                                            <Col>
                                                <div className="button-container-responsive">
                                                <ToastContainer />
                                                    <button onClick={handleSubmit} className="add-button-style">
                                                        {state.bLabel}
                                                    </button> &nbsp;&nbsp;
                                                    {getDeleteButton()}
                                                    &nbsp;&nbsp;
                                                    <button onClick={handleCancel} className="cancle-button-style">
                                                        Cancel
                                                    </button>
                                                </div>
                                            </Col>
                                        </Form.Group>
                                    </Form>
                                </Col>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                </Row>
            </Container>
        </div>
    );
}