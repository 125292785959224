import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router'
import { getHardware, addHardware, getHardwareById, updateHardware, deleteHardware } from '../../services/hardwareService';
import { BsArrowLeftShort } from "react-icons/bs";
import Select from 'react-select';
import {
  Card,
  Container,
  Row,
  Col,
  Form
} from "react-bootstrap";
import { getVendor } from '../../services/vendorService';
import { getUserId } from '../../services/roleService';
import { getUserType as getUserRole, getRolePermission } from '../../services/roleService'
import { ToastContainer, toast } from 'react-toastify';
import ToastLayout from '../../views/partials/Toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function HardwareAddEdit() {
  var hardware;
  const [company, setCompany] = useState({})
  const history = useHistory();
  const { id } = useParams();
  const [vendor, setVendor] = useState();
  const [type, setType] = useState();
  const [assetType, setAssetType] = useState({});
  const [state, setState] = useState({
    id: 0, bLabel: 'Add', FormLabel: 'Add'
  });
  const [vendorData, setVendorData] = useState({
    data: []
  });
  useEffect(async () => {
    if (typeof (id) !== 'undefined') {
      await fetchHardware(id);
    }
    reloadVendor();
    reloadAssetType();
  }, [])
  let fetchHardware = async (id) => {
    hardware = await getHardwareById(id);
    if (typeof (id) !== 'undefined') {
      setState({ ...hardware, bLabel: 'Update', FormLabel: 'Edit' })
    }
  }
  let reloadAssetType = () => {
    let assets = [{ value: 'Energy Meter', label: 'Energy Meter' },
    { value: 'PBX Device', label: 'PBX Device' },
    { value: 'Gateway', label: 'Gateway' },
    { value: 'Node', label: 'Node' }
    ];
    setAssetType(assets);
    setType(assets.filter((pre) => {
      if (typeof (id) !== 'undefined') {
        if (pre.value === hardware.asset_type)
          return true;
      }
    })[0])
  }
  //display vendor name
  let reloadVendor = async () => {
    let record = await getVendor();
    setVendorData({ ...vendorData, data: record });
    setVendor(record.filter((pre) => {
      if (typeof (id) !== 'undefined') {
        if (pre.id === hardware.vendor_id)
          return true;
      }
    })[0])
  }
  let backButton = async () => {
    history.push('/hardware')
  }
  let handleCancel = () => {
    history.push("/hardware");
  }
  let selectVendor = (e) => {
    setState({ ...state, "vendor_id": e.id });
    setVendor(e);
  }
  let selectType = (e) => {
    setState({ ...state, "asset_type": e.value });
    setType(e);
  }
  let handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  }

  let handleSubmit = async (e) => {
    e.preventDefault();
    const newItem = {
      model: state.model,
      product_no: state.product_no,
      vendor_id: state.vendor_id,
      asset_type: state.asset_type
    };
    if (typeof (id) !== 'undefined') { //update
      newItem.id = id;
      let response = await updateHardware(newItem);
      if (response.result == 'fail') {
        if (response.msg.errors[0].message == "unique_model must be unique") {
          toast.error(ToastLayout("Model name already exists!")) 
        } else if (response.msg.errors[0].message == "unique_product_no must be unique") {
          toast.error(ToastLayout("Product number already exists!"))
        } else {
          toast.error(ToastLayout("Model name or Product number already exists!"))
        }
      } else {
        history.push("/hardware");
      }
    } else { //add
      if (newItem.vendor_id) {
        let response = await addHardware(newItem);
        if (response.result == 'fail') {
          if (response.msg.errors[0].message == "unique_model must be unique") {
            toast.error(ToastLayout("Model name already exists!"))
          } else if (response.msg.errors[0].message == "unique_product_no must be unique") {
            toast.error(ToastLayout("Product number already exists!"))
          } else {
            toast.error(ToastLayout("Model name or Product number already exists!"))
          }
        } else {
          history.push("/hardware");
        }
      } else {
        toast.error(ToastLayout("Please select vendor"))
      }
    }
  }
  let doDelete = async () => {
    let response = await deleteHardware(id)
    history.push("/hardware");
  }
  let getDeleteButton = () => {
    if (getRolePermission('hardware', getUserRole()) > 2) {
      if (typeof (id) !== 'undefined') {
        return (<button onClick={(e) => { e.preventDefault(); doDelete() }} className="cancle-button-style">
          Delete
        </button>);
      }
    }
  }
  return (
    <div>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="striped-table-with-hover">
              <Card.Header>
                <div className='display-back-button'>
                  <div><button className="back" data-toggle="tooltip" title="click to go back" onClick={() => { backButton() }} ><BsArrowLeftShort /></button></div>
                  <div className="form-header-container form-header" style={{ marginTop: "05px" }}>
                    <center>{state.FormLabel} Hardware</center>
                  </div>
                </div>
              </Card.Header>
              <Card.Body className="px-0">
                <Col lg="9">
                  <Form >
                    <Form.Group as={Row} className="mb-3" >
                      <Col sm="3">
                        <div className="add-responsive">Model :</div>
                      </Col>
                      <Col sm="9">
                        <input
                          name="model"
                          placeholder="Model e.g: PBX-200"
                          onChange={handleChange}
                          value={state.model}
                          className="input-style-responsive"
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" >
                      <Col sm="3">
                        <div className="add-responsive">Product No :</div>
                      </Col>
                      <Col sm="9">
                        <input
                          type="number"
                          name="product_no"
                          placeholder="Product no e.g: 01.00.00.0001"
                          onChange={handleChange}
                          value={state.product_no}
                          className="input-style-responsive"
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" >
                      <Col sm="3">
                        <div className="add-responsive">Company name :</div>
                      </Col>
                      <Col sm="9">
                        <Select
                          className="dropDown-style"
                          value={vendor}
                          options={vendorData.data}
                          getOptionLabel={e => e.company_name}
                          getOptionValue={e => e.id}
                          onChange={(e) => selectVendor(e)}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" >
                      <Col sm="3">
                        <div className="add-responsive">Type :</div>
                      </Col>
                      <Col sm="9">
                        <Select
                          className="dropDown-style"
                          value={type}
                          options={assetType}
                          getOptionLabel={e => e.label}
                          getOptionValue={e => e.value}
                          onChange={(e) => selectType(e)}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" >
                      <Col>
                        <div className="button-container-responsive">
                          <ToastContainer />
                          <button onClick={handleSubmit} className="add-button-style">
                            {state.bLabel}
                          </button> &nbsp;&nbsp;
                          {getDeleteButton()}
                          &nbsp;&nbsp;
                          <button onClick={handleCancel} className="cancle-button-style">
                            Cancel
                          </button>
                        </div>
                      </Col>
                    </Form.Group>
                  </Form>
                </Col>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
        </Row>
      </Container>
    </div>
  );
}