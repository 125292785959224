import { apiUrl } from "./globalService";
import { getUserToken } from "./roleService";
let apiDevice = apiUrl + "pbx-device";
let apiDeviceGroupPbx = apiUrl + "device-group-pbx";
let apiGatewayGroupPbx = apiUrl + "gateway-group-device";
let apiPbxInstallationOrg = apiUrl + "pbx-installation-organization";
let apiPbxInOrganization = apiUrl + "pbx-from-organization";
let  apiDeviceByGatewayMacAddress= apiUrl+"pbx-from-gateway-mac-address";

export let getPbxDevices = async () => {
  return fetch(apiDevice, {
    method: 'get',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    }
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
      return "Unauthorized"
    });
}
export let getDeviceByGatewayMacAddress = async (gatewayMacId) => {
  return fetch(apiDeviceByGatewayMacAddress+ "/" + gatewayMacId, {
    method: 'get',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    }
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
      return "Unauthorized"
    });
}
export let getPbxInOrganization = (orgId) => {
  return fetch(apiPbxInOrganization + "/" + orgId, {
    method: 'get',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
      return "Unauthorized"
    });
}
export let getPbxInstallationOrg = (records) => {
  return fetch(apiPbxInstallationOrg, {
    method: 'post',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify(records)
  })
    .then(response => response.json())
    .then(response => {
      console.log("response:::",response.msg)
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}
export let addPbxDevice = async (records) => {
  return fetch(apiDevice, {
    method: 'post',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify(records)
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}
export let deletePbxDevice = async ({ mac_address }) => {
  return fetch(apiDevice + "/" + mac_address, {
    method: 'delete',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify({ mac_address })
  })
    .then(response => response.json())
    .then(response => {
      alert(response.msg)
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}
export let getPbxDeviceByAddress = (mac_address) => {
  return fetch(apiDevice + "/" + mac_address, {
    method: 'get',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    }
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}
export let updatePbxDevice = (records) => {
  return fetch(apiDevice, {
    method: 'put',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(records)
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}
export let searchDevice = (device) => {
  return fetch(apiDevice+"/search", {
    method: 'post',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify(device)
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}
export let getPbxDeviceByGroup = (groupId) => {
  return fetch(apiDeviceGroupPbx + "/" + groupId, {
    method: 'get',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    }
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}
export let getPBXInGateway = (groupId) => {
  return fetch(apiGatewayGroupPbx + "/" + groupId, {
    method: 'get',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    }
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}
