import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { BiNotepad, BiEdit } from 'react-icons/bi'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { BsSearch } from 'react-icons/bs';
import { FiEdit } from 'react-icons/fi';
import { BiSort } from 'react-icons/bi';
import SearchLayout from '../partials/SearchLayout';
import SortingByColumn from 'views/partials/SortingByColumn';
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  InputGroup,
  FormControl
} from "react-bootstrap";
import '../../assets/css/pages/table-list.css'
import { apiUrl } from '../../services/globalService';
import {
  getPbxDevices, deletePbxDevice, searchDevice,
  getPbxInstallationOrg, getPbxInOrganization,
  getPBXInGateway, getPbxDeviceByGroup
} from 'services/deviceService';
import { getGateway } from 'services/gatewayService';
import AddPbxFromGatewayModal from './AddPbxFromGatewayModal'
import { getUserType as getUserRole, getRolePermission ,getOrganizationId} from '../../services/roleService'
import { ToastContainer, toast } from 'react-toastify';
import ToastLayout from '../../views/partials/Toastify';

const searchOptions = {
  searchValues: ["group_name", "hub_name", "pbx_device.serial", "pbx_device.mac_address", "model"], //same len as values
  searchNames: ['Installation', 'Hub Name', 'Serial', 'Mac Address', "Model"],
}
const enableAddButton = () => (getRolePermission('PBXdevices', getUserRole()) > 1);

let getAction = () => {
  const history = useHistory();
  if (getRolePermission('PBXdevices', getUserRole()) > 1) {
    return (
      <div className="attribute" data-name="Edit"><center>Actions</center></div>
    );
  }
}
const getEditButton = (mac_address) => {
  if (getRolePermission('PBXdevices', getUserRole()) > 1) {
    return (
      <center><a href={"#/pbx-device-edit/" + mac_address} style={{ color: 'black' }} >
        <FiEdit />
      </a></center>
    );
  }
}
export default function DeviceList({ globalSelect, globalSelectOrganization }) {
  var PBXDevicesScreenSort;
  var device;
  const searchInput = React.useRef();
  const [flag, setFlag] = useState();
  const [items, setItems] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [gatewayItems, setGatewayItems] = useState([]);
  const [selectedGateway, setSelectedGateway] = useState({})
  const [clearField, setClearField] = useState(true);
  const [showPbxModal, setShowPbxModal] = useState(false);
  const handlePbxClose = () => { setShowPbxModal(false); setSelectedGateway({}) };
  const handlePbxShow = () => setShowPbxModal(true);
  const history = useHistory();
  const organization = getOrganizationId();

  useEffect(async () => {
    await reloadDevice();
    reloadGateway();
    if (localStorage.getItem('PBXDevicesScreenSort') != null) {
      PBXDevicesScreenSort = JSON.parse(localStorage.getItem('PBXDevicesScreenSort'))
    }
    if (PBXDevicesScreenSort && PBXDevicesScreenSort.field != null && PBXDevicesScreenSort.ascFlag != undefined) {
      asecDsec(PBXDevicesScreenSort.field, PBXDevicesScreenSort.ascFlag, device);
    }
  }, [globalSelect, globalSelectOrganization])
  //sorting
  let asecDsec = (field, flag, items) => {
    let toggle = flag;
    if (flag == undefined) {
      toggle = 1
    }
    localStorage.setItem("PBXDevicesScreenSort", JSON.stringify({ ascFlag: toggle, field: field }));
    let sorted = SortingByColumn(field, items, toggle, setFlag)
    setItems(sorted);
    setOriginalData(sorted);
  }
  let reloadGateway = async () => {
    let gateway = await getGateway();
    if (gateway == "Unauthorized") {
      toast.error(ToastLayout("Your session has expired!"))
      localStorage.clear();
      history.push("/login")
    }
    setGatewayItems(gateway)
  }
  let reloadDevice = async () => {
    //Global filter Installation: Any one installation selected as option 
    //Global filter Organization: All organizations selected as option
    if ((globalSelect.installation_id != 0 && globalSelect.installation_id != undefined) &&
      (globalSelectOrganization.organization_id == 0 || globalSelectOrganization.organization_id == undefined)) {
      device = await getPbxDeviceByGroup(globalSelect.installation_id);
      if (device == "Unauthorized") {
        toast.error(ToastLayout("Your session has expired!"))
        localStorage.clear();
        history.push("/login")
      }
      setItems(device);
      setOriginalData(device);
    }
    //Global filter Installation: All installations selected as option 
    //Global filter Organization: Any one organization selected as option
    else if ((globalSelect.installation_id == 0 || globalSelect.installation_id == undefined) &&
      (globalSelectOrganization.organization_id != 0 && globalSelectOrganization.organization_id != undefined)) {
      device = await getPbxInOrganization(globalSelectOrganization.organization_id);
      if (device == "Unauthorized") {
        toast.error(ToastLayout("Your session has expired!"))
        localStorage.clear();
        history.push("/login")
      }
      setItems(device);
      setOriginalData(device);
    }
    //Global filter Installation: Any one installation selected as option 
    //Global filter Organization: Any one organization selected as option
    else if ((globalSelect.installation_id != 0 && globalSelect.installation_id != undefined) &&
      (globalSelectOrganization.organization_id != 0 && globalSelectOrganization.organization_id != undefined)) {
      let record = {
        organizationId: globalSelectOrganization.organization_id,
        deviceGroupId: globalSelect.installation_id
      }
      device = await getPbxInstallationOrg(record);
      if (device == "Unauthorized") {
        toast.error(ToastLayout("Your session has expired!"))
        localStorage.clear();
        history.push("/login")
      }
      setItems(device);
      setOriginalData(device);
    }
    //Global filter Installation: All installations selected as option 
    //Global filter Organization: All organizations selected as option 
    else {
      device = await getPbxDevices();
      if (device == "Unauthorized") {
        toast.error(ToastLayout("Your session has expired!"))
        localStorage.clear();
        history.push("/login");
      }
      setItems(device);
      setOriginalData(device);
    }
  }
  // search details
  const searchDetails = async (fieldValue, dataValue) => {
    const searchParams = {
      field: fieldValue,
      data: dataValue
    };
    if (getUserRole() == "super-admin") {
      if (globalSelectOrganization.organization_id != 0 && globalSelectOrganization.organization_id != undefined) {
          searchParams.organizationId = globalSelectOrganization.organization_id
      } else {
          searchParams.organizationId = null
      }
      if(globalSelect.installation_id != 0 && globalSelect.installation_id != undefined){
          searchParams.installationId = globalSelect.installation_id
      }else{
          searchParams.installationId = null
      }
  } else {
      searchParams.organizationId = organization
  }
    let searchItems = await searchDevice(searchParams);
    setItems(searchItems);
  }
  // Clear the details
  let clearDetails = async () => {
    setItems(originalData);
    searchInput.current.value = "";
  }
  return (
    <div>
       <ToastContainer />
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="striped-table-with-hover">
              <Card.Header>
                <SearchLayout
                  searchInput={searchInput}
                  pbxFlag={true}
                  handlePbxShow={handlePbxShow}
                  searchOptions={searchOptions}
                  doSearch={searchDetails}
                  clearDetails={clearDetails}
                  addButtonCallback={() => { history.push("/pbx-device-add") }}
                  isAddButtonEnable={enableAddButton()} showHide={true}
                />
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0" style={{ marginTop: "0" }}>
                <SetDevicesList items={items}
                  setItems={setItems}
                  asecDsec={asecDsec}
                  flag={flag}
                />
                <AddPbxFromGatewayModal
                  showPbxModal={showPbxModal}
                  handlePbxClose={handlePbxClose}
                  setGatewayItems={setGatewayItems}
                  gatewayItems={gatewayItems}
                  selectedGateway={selectedGateway}
                  setSelectedGateway={setSelectedGateway}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
        </Row>
      </Container>
    </div>
  );
}
function SetDevicesList({ items, setItems,asecDsec,flag }) {
  const history = useHistory();
  return (
    <div>
      <ol className="collection collection-container">
        <li className="item item-container table-header">
          {/* <div className="attribute" data-name="No"></div> */}
          <div className="attribute-container information">
            <div className="attribute-container name-organization">
              <div className="attribute" data-name="Installation">Installation
                <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("group_name",flag,items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
              </div>
              <div className="attribute" data-name="Hub Name">Hub Name
                <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("hub_name",flag,items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
              </div>
            </div>
            <div className="attribute-container price-currency">
              <div className="attribute" data-name="Serial">Serial
                <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("serial",flag,items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
              </div>
              <div className="attribute" data-name="Mac Address">Mac Address
                <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("mac_address",flag,items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
              </div>
            </div>
          </div>
          <div className="attribute-container amount-edit">
            <div className="attribute" data-name="Model">Model
              <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("model",flag,items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
            </div>
            {getAction()}
          </div>
        </li>
        {(items.length > 0) ?
          <div>
            {items.map((item) => {

              return (<li key={item.id} className="item item-container hover">
                {/* <div className="attribute" data-name="No"></div> */}
                <div className="attribute-container information">
                  <div className="attribute-container name-organization">
                    <div className="attribute" data-name="Installation">{item.group_name}</div>
                    <div className="attribute" data-name="Hub Name">{item.hub_name}</div>
                  </div>
                  <div className="attribute-container price-currency">
                    <div className="attribute" data-name="Serial">{item.serial}</div>
                    <div className="attribute" data-name="Mac Address">{item.mac_address}</div>
                  </div>
                </div>
                <div className="attribute-container amount-edit">
                  <div className="attribute" data-name="Model">{item.model}</div>
                  {getEditButton(item.mac_address)}
                </div>
              </li>)
            })}
          </div>
          :
          <div className="no-data-available-text">No Records Found</div>
        }
      </ol>
    </div>

  );
}