import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router';
import { FiEdit } from 'react-icons/fi';
import { BiSort } from 'react-icons/bi';
import { MdMoney } from 'react-icons/md'
import { SiAddthis } from 'react-icons/si';
import * as moment from 'moment';
import Select from "react-select";
import { BsArrowLeftShort } from "react-icons/bs";
import SearchLayout from '../partials/SearchLayout';
import SortingByColumn from 'views/partials/SortingByColumn';
import {
    Card,
    Container,
    Row,
    Col,
    Breadcrumb
} from "react-bootstrap";
import '../../assets/css/pages/table-list.css'
import { getUsers, deleteUser, getUserByOrganization, searchUserByOrganization } from '../../services/userService';
import { getOrganization, getOrganizationById, searchOrganization } from '../../services/organizationService';
import { getCurrencyNameById } from '../../services/currencyService';
import { getUserType as getUserRole, getRolePermission } from '../../services/roleService'
import UserContext from 'views/UserContext';
import { ToastContainer, toast } from 'react-toastify';
import ToastLayout from '../../views/partials/Toastify';

const searchOptions = {
    searchValues: ["first_name || last_name", "email", "mobile_number_1", "role_type"], //same len as values
    searchNames: ['Name', 'Email', 'Mobile Number', "Role Type"]
}
const enableAddButton = () => (getRolePermission('users', getUserRole()) > 1);
const getEditButton = (id) => {
    if (getRolePermission('users', getUserRole()) > 1) {
        return (
            <center><a href={"#/user-organization-edit/" + id} style={{ color: 'black' }} >
                <FiEdit />
            </a></center>
        );
    }
}
const getBalanceCol = (userRecord) => {
    if (userRecord.role_type == 'admin'
        || userRecord.role_type == 'manager'
        || userRecord.role_type == 'super-admin') {
        return null;
    } else {
        return (
            <span>&nbsp;
                {userRecord.wallet_balance} &nbsp;
                {getCurrencyNameById(userRecord.currency_id)}
                &nbsp;&nbsp;&nbsp;
            </span>
        )
    };
}
const getWalletCol = (userRecord) => {
    if (userRecord.role_type == 'admin'
        || userRecord.role_type == 'manager'
        || userRecord.role_type == 'super-admin') {
        return null;
    } else {
        return (
            <span>
                &nbsp;&nbsp;
                <a href={"#/user-wallet-history/" + userRecord.id} style={{ color: 'black' }} >
                    <MdMoney />
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a href={"#/add-money-wallet/" + userRecord.id} style={{ color: 'black' }} >
                    <SiAddthis />
                </a>
            </span>
        )
    };
}
export default function UserOrgList({ globalSelectOrganization, setSelectedOrganizations }) {
    const params = useParams();
    const userContext = useContext(UserContext);

    // console.log("usecontext",hello.key)
    //get full org info
    const [organization, setOrganization] = useState({
        org: []
    });
    const [organizationName, setOrganizationname] = useState({
        organizationDetails: []
    })
    const [organizationid, setOrganizationId] = useState(params.organizationId)
    const [selected, setSelected] = useState([]);
    const [items, setItems] = useState([]);
    const [clearField, setClearField] = useState(true);
    const [inputField, setInputField] = useState({ selectField: "username" });
    const history = useHistory();
    useEffect(() => {
        if (typeof (params.organizationId) !== 'undefined') {
            fetchUserByOrganization(params.organizationId);
        }
        reloadOrganization();
        organizationbyName();
    }, [params.organizationId])

    let fetchUserByOrganization = async (organizationId) => {
        if (globalSelectOrganization.organization_id != 0 && globalSelectOrganization.organization_id != undefined) {
            let user = await getUserByOrganization(globalSelectOrganization.organization_id);
            if (user == "Unauthorized") {
                toast.error(ToastLayout("Your session has expired!"))
                localStorage.clear();
                history.push("/login")
            }
            setItems(user)
        } else {
            let user = await getUserByOrganization(organizationId);
            if (user == "Unauthorized") {
                toast.error(ToastLayout("Your session has expired!"))
                localStorage.clear();
                history.push("/login")
            }
            setItems(user)
        }
    }
    let reloadOrganization = async () => {
        // let url = window.location.hash;
        // console.log("here",params.organizationId)
        // if(url.includes('user-organization') && params.organizationId == 0){
        //     window.location.replace('/#/organization')
        //     setSelectedOrganizations({ organization_name: "All Organizations" })
        // }else{
        let organizations = await getOrganizationById(params.organizationId)
        setSelectedOrganizations({ organizations })
        setOrganization({ ...organization, org: organizations });
        // }
    }
    let organizationbyName = async () => {
        let orgName = await getOrganization()
        setOrganizationname({ ...organizationName, organizationDetails: orgName });
    }
    let backButton = () => {
        history.push("/organization/");
    }
    const searchDetails = async (fieldValue, dataValue) => {
        const searchParams = {
            field: fieldValue,
            data: dataValue
        };
        let searchItems = await searchUserByOrganization(searchParams, organizationid);
        setItems(searchItems);
    }
    //Clear the details
    const clearDetails = async () => {
        if (!clearField) {
            let customers = await getUserByOrganization(params.organizationId);
            setItems(customers);
        }
    }
    //search organizations by user on select
    const onSelect = async (e) => {
        let organizations = await getUserByOrganization(e.id)
        setItems(organizations)
        let orgName = await getOrganizationById(e.id)
        setOrganization({ ...organization, org: orgName });
        setClearField(false);
        setOrganizationId(e.id);
    }
    return (
        <Setorganization
            org={organization.org}
            organizationDetails={organizationName.organizationDetails}
        />
    );
    function Setorganization({ org }) {
        return (
            <div>
                 <ToastContainer />
                <Container fluid>
                    <Row>
                        <Col sm={12}>
                            <div className='display-back-button'>
                                <div><button className="back" data-toggle="tooltip" title="click to go back" onClick={() => { backButton() }} ><BsArrowLeftShort /></button>
                                </div>
                                <div>
                                    <Breadcrumb >
                                        <Breadcrumb.Item style={{ color: "#ed1b24", marginTop: "15px" }}>
                                            {org.organization_name}
                                        </Breadcrumb.Item>
                                        <Breadcrumb.Item style={{ marginTop: "15px" }}>Users</Breadcrumb.Item>
                                    </Breadcrumb>
                                </div></div>
                            <Card style={{ boxShadow: '0 2px 10px -1px rgb(69 90 100 / 30%)' }}>
                                <Card.Header>
                                    <SearchLayout
                                        searchOptions={searchOptions}
                                        doSearch={searchDetails}
                                        clearDetails={clearDetails}
                                        addButtonCallback={() => { history.push("/user-organization-add/" + params.organizationId) }}
                                        isAddButtonEnable={enableAddButton()} showHide={true} />
                                    {/* <Row>
                                        <div className="col-sm-7 col-lg-8 col-md-6">
                                            <div style={{ marginBottom: '10px' }}>
                                                <InputGroup style={{ width: "80%" }}>                                                    
                                                    <Select
                                                        className="dropDown-style"
                                                        defaultValue={organization.org}
                                                        options={organizationName.organizationDetails}
                                                        getOptionLabel={e => e.organization_name}
                                                        getOptionValue={e => e.organization_name}
                                                        onChange={(e) =>onSelect(e)}
                                                    />
                                                </InputGroup>
                                            </div>
                                        </div>
                                    </Row> */}
                                </Card.Header>
                                <Card.Body className="table-full-width table-responsive px-0" style={{ marginTop: "0" }}>
                                    <SetUserList items={items}
                                        setItems={setItems}
                                    />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div >
        );
    }
}
function SetUserList({ items, setItems }) {
    const [flag, setFlag] = useState(1);
    var sort = "asc";
    let asecDsec = (field) => {
        let sorted = SortingByColumn(field, items, flag, setFlag, sort)
        setItems(sorted)
    }
    return (
        <div>
            <ol className="collection collection-container" style={{ marginTop: "0px", paddingTop: "0px" }}>
                <li className="item item-container table-header">
                    <div className="attribute-container information">
                        <div className="attribute-container name-organization">
                            <div className="attribute" data-name="Name">Name
                                <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("first_name") }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
                            </div>
                            <div className="attribute" data-name="Email">Email
                                <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("email") }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
                            </div>
                        </div>
                        <div className="attribute-container price-currency">
                            <div className="attribute" data-name="Mobile Number">Mobile Number
                                <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("mobile_number_1") }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
                            </div>
                            <div className="attribute" data-name="Balance">Balance
                                <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("wallet_balance") }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
                            </div>

                        </div>
                    </div>
                    <div className="attribute-container amount-edit">
                        <div className="attribute" data-name="Role Type">Role Type
                            <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("role_type") }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
                        </div>
                        <div className="attribute" data-name="Wallet">Wallet</div>
                        <div className="attribute" data-name="Edit">Actions</div>
                    </div>
                </li>
                {(items.length > 0) ?
                    <div>
                        {items.map((item) => {
                            return (<li key={item.id} className="item item-container hover">
                                <div className="attribute-container information">
                                    <div className="attribute-container name-organization">
                                        <div className="attribute" data-name="Name">{item.first_name} {item.last_name}</div>
                                        <div className="attribute" data-name="Email">{item.email}</div>
                                    </div>
                                    <div className="attribute-container price-currency">
                                        <div className="attribute" data-name="Mobile Number">{item.mobile_number_1}</div>
                                        <div className="attribute" data-name="Balance">
                                            {getBalanceCol(item)}</div>
                                    </div>
                                </div>
                                <div className="attribute-container amount-edit">
                                    <div className="attribute" data-name="Role Type">{item.role_type}</div>
                                    <div className="attribute" data-name="Wallet">
                                        {getWalletCol(item)}</div>
                                    <div className="attribute" data-name="Actions">
                                        {getEditButton(item.id)}
                                    </div>
                                </div>
                            </li>)
                        })}
                    </div>
                    :
                    <div className="no-data-available-text">No Records Found</div>
                }
            </ol>
        </div>
    );
}