import React, { useState, useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import Select from 'react-select';
import {
    Modal,
    Row,
    Col,
    Card,
    Container,
    Form
} from "react-bootstrap";
import '../../assets/css/pages/table-list.css'
import { updateMeter,addMeter } from '../../services/meterService';
import { addNode } from '../../services/nodeService';
import { ToastContainer, toast } from 'react-toastify';
import ToastLayout from '../../views/partials/Toastify';

export default function AddMeterBatchModal({ showMeter, handleMeterClose, gatewayId, gatewayData, hardwareDataMeter, getUserId, modeData,differentValuesSerial }) {
    const history = useHistory();
    const [selectedGateway, setSelectedGateway] = useState({});;
    const [hardwareModel, setHardwareModel] = useState();
    const [mode, setMode] = useState();
    const [nodeData, setNodeData] = useState([]);
    
    let selectGatewayHub = (e) => {
        setSelectedGateway(e.mac_address)
    }
    let selectHardwareModel = (e) => {
        setNodeData({ ...nodeData, ["hardware_id"]: e.id });
        setHardwareModel(e);
    }
    let selectMode = (e) => {
        setNodeData({ ...nodeData, ["mode_id"]: e.id });
        setMode(e);
    }
    let handleChange = async (e) => {
        setNodeData({ ...nodeData, [e.target.name]: e.target.value });
    }
    let handleSubmit = async (e) => {
        e.preventDefault();
        differentValuesSerial.map(async(data) => {
        const newItem = {
            serial: data.serial_meter,
            modbus_address: data.modbus_address,
            hardware_id: nodeData.hardware_id,
            mode_id: nodeData.mode_id,
            device_group_id: gatewayData.groupData.device_group_id,
            customer_id: null,
            node_id: data.serial_wifi_mac_id,
            gateway_mac_address: gatewayId,
            owner_organization_id: gatewayData.groupData.owner_organization_id,
            created_at: Date.now(),
            created_by: getUserId(),
            modified_at: Date.now(),
            modified_by: getUserId()
        };
        if (newItem.device_group_id) {
            if (newItem.hardware_id) {
                if (newItem.owner_organization_id) {
                    console.log("newItem", newItem)
                    let response = await addMeter(newItem);
                    if (response.result == "fail") {
                        toast.error(ToastLayout("Please enter unique mac_address !"))
                    } else {
                        toast.error(ToastLayout("Meters successfully added!"))
                        window.location.reload();
                    }
                } else {  toast.error(ToastLayout("Please select organization")) }
            } else { toast.error(ToastLayout("Please select hardware model")) }
        } else { toast.error(ToastLayout("Please select installation Group"))}
    })
    }
    const customStyles = {
        menu: styles => ({ ...styles, zIndex: 999 })
    }
    return (<Modal show={showMeter} onHide={handleMeterClose}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
            <div>
            <ToastContainer />
                <Form>
                    <Col md="12">
                        <Form.Group as={Row} className="mb-3" >
                            <Col sm="12">
                                <Form.Group as={Row} className="mb-3" >
                                    <Col sm="4">
                                        <div className="add-responsive">Mode :</div>
                                    </Col>
                                    <Col sm="6">
                                        <Select
                                            className="dropDown-style"
                                            value={mode}
                                            options={modeData.mode}
                                            getOptionLabel={e => e.mode}
                                            getOptionValue={e => e.id}
                                            onChange={(e) => selectMode(e)}
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" >
                            <Col sm="4">
                                <div className="add-responsive">Hardware Model :</div>
                            </Col>
                            <Col sm="6" >
                                <Select
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    maxMenuHeight={200}
                                    menuPortalTarget={document.body}
                                    className="dropDown-style"
                                    value={hardwareModel}
                                    options={hardwareDataMeter.hardware}
                                    getOptionLabel={e => e.model}
                                    getOptionValue={e => e.id}
                                    onChange={(e) => selectHardwareModel(e)}
                                />
                            </Col>
                        </Form.Group>
                    </Col>
                </Form>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <br />
            <button onClick={handleSubmit} className="btn rounded-pill add-btn">Add Meters</button>
        </Modal.Footer>
    </Modal>
    );
}