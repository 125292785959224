import React, { useState, useContext, useEffect } from 'react';
import Select from 'react-select';
import {
    Modal,
    Row,
    Col,
    Card,
    Container,
    Form
} from "react-bootstrap";
import '../../assets/css/pages/table-list.css'

export default function AddPbxFromGatewayModal({ showPbxModal, handlePbxClose, gatewayItems,selectedGateway,setSelectedGateway, setGatewayItems }) {
    let selectGatewayHub = (e) => {
        setSelectedGateway(e.mac_address)
      }
    const customStyles = {
        menu: styles => ({...styles, zIndex: 999})
    }
    return (<Modal show={showPbxModal} onHide={handlePbxClose}  contentClassName='modal-custom'>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
            <div>
                <Form>
                    <Col md="12">
                        <Form.Group as={Row} className="mb-3" >
                            <Col sm="5">
                                <div className="add-responsive-modal">Gateway :</div>
                            </Col>
                            <Col sm="6">
                                <Select
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    maxMenuHeight={200}
                                    menuPortalTarget={document.body} 
                                    options={gatewayItems}
                                    getOptionLabel={e => e.mac_address}
                                    getOptionValue={e => e.mac_address}
                                    onChange={(e) => selectGatewayHub(e)}
                                />
                            </Col>
                        </Form.Group>

                    </Col>
                </Form>
            </div>
        </Modal.Body>
        <Modal.Footer>
        <br />
         {(selectedGateway.length>0)?
         <a href={'#/gateway/'+selectedGateway+'/pbx/add'} className="btn rounded-pill add-btn">Add Device</a>
         :
         <a href="#" className="btn rounded-pill add-btn" style={{pointerEvents:"none"}}>Add Device</a>
         }</Modal.Footer>
    </Modal>
    );
}