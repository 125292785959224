import { apiUrl } from "./globalService";
import { getUserToken } from "./roleService";
let apiDeviceGroup = apiUrl + "device-group";
let apiChildDeviceGroup = apiUrl + "child-device-group";
let deviceGroupArray = [];

export let getDeviceGroup = async () => {
    return fetch(apiDeviceGroup, {
        method: 'get',
        headers: {
            'Authorization' : getUserToken(),
            'Content-Type': 'application/json;charset=utf-8'
        }
    })
        .then(response => response.json())
        .then(response => {
            return response;
        }).catch(function (error) {
            console.log(error);
            return "Unauthorized"
        });
}
export let getDeviceGroupById =  (group_id) => {
  if(deviceGroupArray.length > 0){
    return deviceGroupArray;
}
    return fetch(apiDeviceGroup + "/" + group_id, {
      method: 'get',
      headers: {
        'Authorization' : getUserToken(),
        'Content-Type': 'application/json;charset=utf-8'
      }
    })
      .then(response => response.json())
      .then(response => {
        deviceGroupArray=response;
        return response;
      }).catch(function (error) {
        console.log(error);
      });
  }
export let getChildDeviceGroup = async (parentId) => {
    return fetch(apiChildDeviceGroup + "/" + parentId, {
        method: 'get',
        headers: {
            'Authorization' : getUserToken(),
            'Content-Type': 'application/json;charset=utf-8'
        }
    })
        .then(response => response.json())
        .then(response => {
            return response;
        }).catch(function (error) {
            console.log(error);
            return "Unauthorized"
        });
}
export let addDeviceGroup =async (device) => {
    return fetch(apiDeviceGroup, {
      method: 'post',
      headers: {
        'Authorization' : getUserToken(),
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify(device)
    })
      .then(response => response.json())
      .then(response => {
        return response;
      }).catch(function (error) {
        console.log(error);
      });
  }
