import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router'
import { getWalletUser, addWalletUser, getWalletUserById, updateWalletUser, deleteWalletUser } from '../../services/walletUserService';
import SubItemHeading from '../partials/SubItemHeading';
import {
    Card,
    Container,
    Row,
    Col,
    Form
} from "react-bootstrap";
import { searchNonCustomerUsers,getUsers } from '../../services/userService';
import { getCurrency } from '../../services/currencyService';
import { ToastContainer, toast } from 'react-toastify';
import ToastLayout from '../../views/partials/Toastify';
export default function WalletUserAddEdit() {
    const [state, setState] = useState({
        id: 0, balance:0, bLabel: 'Add'
    });
    const [subHeading, setHaeding] = useState("Add User Wallet");
    const history = useHistory();
    const {walletId} = useParams();
    let fetchWalletUser = async (id) => {
        let WalletUser = await getWalletUserById(id);
        if (typeof (WalletUser.id) !== 'undefined') {
            setState({ ...WalletUser, bLabel: 'Update' })
        }
    }
    useEffect(() => {
        if (typeof (walletId) !== 'undefined') {
            fetchWalletUser(walletId);
        }
       reloadUser();
        reloadCurrency();
    }, [])
    //Display User name
    const [userData, setUserData] = useState([]);
    let reloadUser = async () => {
        let records = await getUsers();
        console.log("searchNonCustomerUsers",records);
        setUserData(records);
    }
    //display currency name
    const [currencyData, setCurrencyData] = useState({
        currency: []
    });
    let reloadCurrency = async () => {
        let currencyRecord = await getCurrency();
        setCurrencyData({ ...currencyData, currency: currencyRecord });
    }
    let handleCancel = () => {
        history.push("/wallet-user");
    }
    let handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    }
    let handleSubmit = async (e) => {
        e.preventDefault();
        const newItem = {
            user_id: state.user_id,
            reference: state.reference,
            amount: state.amount,
            balance: state.balance,
            currency_id: state.currency_id
        };
        if (state.id !== 0) { //update
            newItem.id = state.id;
            let response = await updateWalletUser(newItem);
            history.push("/wallet-user");
        } else { //add
            newItem.balance = newItem.amount;
            let setDefaultCurrency
            if (!newItem.currency_id) {
                for (let i = 0; i < 1; i = i + 1) {
                    setDefaultCurrency = currencyData.currency[i].id
                }
                newItem.currency_id = setDefaultCurrency
            }
            let response = await addWalletUser(newItem);
            if(response.result && response.result =="fail"){
                toast.error(ToastLayout(response.msg))
            }else{
                history.push("/wallet-user");
            }
        }
    }

    return (
        <div>
            <ToastContainer/>
        <Container fluid>
            <Row>
                <Col md="12">
                    <Card className="striped-table-with-hover">
                        <Card.Header>
                            <SubItemHeading heading={subHeading} backButtonCall={() => { history.push("/wallet-user") }} show={true} />
                        </Card.Header>
                        <Card.Body className="px-0">

                            <Col lg="9">
                                <Form >
                                    <Form.Group as={Row} className="mb-3" >
                                        <Col sm="3">
                                            <div className="add-responsive">User :</div>
                                        </Col>
                                        <Col sm="9">
                                            <select id={state.user_id} name="user_id" className="input-style-responsive" value={state.user_id} onChange={handleChange}>
                                                <option key={Date.now()}> Choose user </option>

                                                {userData.map(user => (
                                                    <option key={user.id} value={user.id} >{user.username} ({user.role_type} {user.organization_name}) </option>
                                                ))}
                                            </select>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" >
                                        <Col sm="3">
                                            <div className="add-responsive">Reference :</div>
                                        </Col>
                                        <Col sm="9">
                                            <input
                                                name="reference"
                                                placeholder="Add Note"
                                                onChange={handleChange}
                                                value={state.reference}
                                                className="input-style-responsive"
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" >
                                        <Col sm="3">
                                            <div className="add-responsive">Add Amount :</div>
                                        </Col>
                                        <Col sm="9">
                                            <input
                                                type="number"
                                                name="amount"
                                                placeholder="Amount"
                                                onChange={handleChange}
                                                value={state.amount}
                                                className="input-style-responsive"
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" >
                                        <Col sm="3">
                                            <div className="add-responsive">Current Balance :</div>
                                        </Col>
                                        <Col sm="9">
                                            <input
                                                type="number"
                                                name="balance"
                                                placeholder="Current Balance"
                                                onChange={handleChange}
                                                value={state.balance}
                                                className="input-style-responsive"
                                                readOnly
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" >
                                        <Col sm="3">
                                            <div className="add-responsive">Currency :</div>
                                        </Col>
                                        <Col sm="9">
                                            <select id={state.currency_id} name="currency_id" className="input-style-responsive" value={state.currency_id} onChange={handleChange}>
                                                {currencyData.currency.map(currency => (
                                                    <option key={currency.id} value={currency.id} >{currency.currency} </option>
                                                ))}
                                            </select>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" >
                                        <Col>
                                            <div className="button-container-responsive">
                                                <button onClick={handleSubmit} className="add-button-style">
                                                    {state.bLabel}
                                                </button> &nbsp;&nbsp;
                                                <button onClick={handleCancel} className="cancle-button-style">
                                                    Cancel
                                                </button>
                                            </div>
                                        </Col>
                                    </Form.Group>
                                </Form>
                            </Col>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
            </Row>
        </Container>
    </div>
    );
}