import React, { useState, useContext, useEffect } from 'react';
import Select from 'react-select';
import { useHistory } from 'react-router';
import { uuid } from '../../services/CommonUtils';
import PaymentModal from './PaymentModal';
import MeterStatusModal from './MeterStatusModal';
import { BiSort } from 'react-icons/bi';
import { Input } from 'reactstrap';
import '../../assets/css/pages/table-list.css'
import { getPackagesByOrg } from '../../services/packageService';
import { getUserType as getUserRole, getRolePermission } from '../../services/roleService';
import { BiNotepad, BiEdit } from 'react-icons/bi';
import { FiEdit } from 'react-icons/fi';
import { GrHistory, GrUpdate } from 'react-icons/gr';
import { MdPower, MdPowerOff } from 'react-icons/md'
import { BsSpeedometer2 } from 'react-icons/bs';
import { getUserId } from 'services/roleService';
import { getCurrency } from 'services/currencyService';
import { getWalletUser } from 'services/walletUserService';
import * as moment from 'moment';
import { getPayments, addPayment, getPaymentById, updatePayment, deletePayment } from '../../services/paymentService';
import SortingByColumn from 'views/partials/SortingByColumn';

// packages should be selected based on org , 
// meter owned or OrgX should packages offer by OrgX

const getFirstThreeColumns = (item) => {
   if (getUserRole() == 'super-admin'
      || getUserRole() == 'admin'
      || getUserRole() == 'manager') {
      return (
         <div className="attribute-container amount-edit">
            <div className="attribute" data-name="Installation">{item.group_name}</div>
            {getHubName(item)}
            <div className="attribute" data-name="modbus">{item.modbus_address}</div>
            <div className="attribute" data-name="Name" style={{ width: "115px" }}>{item.first_name} {item.last_name}</div>
         </div>
      )
   } else {
      return
   }
}
const getCustomerHeader = () => {
   if (getUserRole() == 'sales-agent'
      || getUserRole() == 'technician'
      || getUserRole() == 'operator' || getUserRole() == 'customer') {
      return (
         <div className="attribute" data-name="Name" style={{ width: "108px" }}>Customer
            <a style={{ marginLeft: "-1px" }} onClick={() => { asecDsec("first_name", flag, items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
         </div>
      )
   } else {
      return
   }
}
const getCustomerName = (item) => {
   if (getUserRole() == 'sales-agent'
      || getUserRole() == 'technician'
      || getUserRole() == 'operator' || getUserRole() == 'customer') {
      return (
            <div className="attribute" data-name="Name" style={{ width: "115px" }}>{item.first_name} {item.last_name}</div>
      )
   } else {
      return
   }
}
const getHubName = (item) => {
   if (item.node_hub_name) {
      item["hub"] = item.node_hub_name;
      return (
         <div className="attribute item-hub" data-name="Node Hub Name">{item.hub}</div>
      )
   } else {
      item["hub"] = item.hub_name;
      return (
         <div className="attribute item-hub" data-name="Hub Name">{item.hub}</div>
      )
   }
}
export let SelectPackage = ({reloadMeter, items, editMeter, meterUser, customerId, modeData, asecDsec, flag }) => {

   const [show, setShow] = useState(false);
   const [showMeterStatus, setShowMeterStatus] = useState(false);
   const [selectedMeter, setSelectedMeter] = useState(null);
   const [meterStatus, setMeterStatus] = useState(null);
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   const handleMeterShow = () => setShowMeterStatus(true);
   const handleMeterClose = () => setShowMeterStatus(false);
   const getEditButton = (serialNo) => {
      if (getRolePermission('meters', getUserRole()) > 1) {
         return (
            <span type="button" onClick={() => { editMeter(serialNo) }} style={{ backgroundColor: 'transparent' }} ><FiEdit />&nbsp;&nbsp;</span>
         );
      }
   }
   
   const getMeterStatus = (item) => {
      if (item.onoff_status != null) {
         // 1. Mode -> ON && onoff_status -> close (switch ON) :=> ON symbol
         if (item.onoff_status == 'close') { // means ON
            return (<span className='icon-power'><MdPower style={{ fontSize: "22px" }} /></span>);
         }
         // 2. Mode -> OFF && onoff_status -> open (switch OFF) :=> OFF symbol
         else if (item.onoff_status == 'open') {
            return (<span className='icon-power'><MdPowerOff style={{ fontSize: "22px" }} /></span>);
         }
         /*
         // 3. Mode -> AUTOMATIC && onoff_status -> close (switch ON) && meter_balance > 0(balance -> positive) :=> ON symbol
         else if (item.onoff_status == "close") {
            return (<span className='icon-power'><MdPower style={{ fontSize: "22px" }} /></span>);
         }
         // 4. Mode -> AUTOMATIC && onoff_status -> open (switch OFF) && meter_balance <= 0(balance -> negative) :=> OFF symbol
         else if (item.meter_balance <= 0 && item.onoff_status == "open" && item.mode_id == "1") {
            return (<span className='icon-power'><MdPowerOff style={{ fontSize: "22px" }} /></span>);
         }*/
         // Rest all the combinations will have update symbol
         // 1. Mode -> ON && onoff_status == 'open'
         // 2. Mode -> OFF && onoff_status == 'close'
         // 3. Mode -> AUTOMATIC && onoff_status -> close (switch ON) && meter_balance <= 0(balance -> negative)
         // 4. Mode -> AUTOMATIC && onoff_status -> open (switch OFF) && meter_balance > 0(balance -> positive)
         else {
            return (<span className='icon-power' type="button" onClick={() => { reloadMeter() }} ><GrUpdate style={{ fontSize: "18px" }} /></span>);
         }
      } else {
         return (<span></span>);
      }
   }
   const getMeterReading = (item) => {
      if (item.meter_balance != null) {
         return (
            <div className="attribute balance-item" data-name="Balance">{item.meter_balance} WH</div>
         )
      } else {
         return (
            <span></span>
         )
      }
   }
   const getMeterBalance = (item) => {
      if (item.meter_balance != null) {
         return (
            <span>{item.meter_balance}</span>
         )
      } else {
         return (
            <span></span>
         )
      }
   }
   const getActivateButton = (item) => {
      if (item.role_type === 'customer') {
         if (getRolePermission('customers', getUserRole()) > 1) {
            return (
               <span type="button" style={{ marginRight: "0%", display: "flex", alignContent: "center", justifyContent: "center", alignItems: "center" }}
                  onClick={() => { selectMeterOpenModal(item); }}>
                  <i style={{ fontSize: "18px", color: "black" }} className='nc-icon nc-money-coins'></i>
               </span>);
         }
      } else {
         return (
            <div ><span type="button" style={{ width: "18px", marginRight: "0%", display: "flex", alignContent: "center", justifyContent: "center", alignItems: "center" }}>
               <i style={{ fontSize: "18px", color: "black" }} ></i>
            </span></div>);
      }
   }
   const selectMeterOpenModal = (meterObj) => {
      setSelectedMeter(meterObj);
      handleShow();
   }
   const meterStatusOpenModal = (meterObj) => {
      setMeterStatus(meterObj);
      handleMeterShow();
   }
   const getAction = () => {
      if (getRolePermission('meters', getUserRole()) > 0) {
         return (
            <div className="attribute" data-name="Edit"><center>Actions</center></div>
         );
      }
   }
   const getMeterWallet = (serialNo, meterObj) => {
      return (
         <div className="attribute item-txn" data-name="TXN History" >
            <div className="icons-action" style={{ display: "flex", alignItems: "center" }}>
               {getEditButton(serialNo)}
               <button style={{ "backgroundColor": "transparent", "border": "none" }}><a href={"#/meter-wallet/" + serialNo}><GrHistory /></a>&nbsp;&nbsp;</button>
               {getActivateButton(meterObj)}

               {/* <span type="button" style={{fontSize:"22px"}} onClick={() => { meterStatusOpenModal(meterObj); }}><BsSpeedometer2 /></span> */}
            </div></div>
      );
   }
   const getPackageAction = () => {
      if (getRolePermission('customers', getUserRole()) > 1) {
         return (
            <div className="attribute"><center>Package</center></div>
         );
      }
   }
   const [roleData, setRoleData] = useState({
      role: []
   });
   let reloadRoleType = async () => {
      let roleRecord = await getRole();
      var role_id;
      let chooseOption = roleRecord.filter((item) => {
         if (item.role_type === getUserRole()) {
            role_id = item.id;
         }
         return item.id >= role_id
      })
      setRoleData({ ...roleData, role: chooseOption });
   }
   const getLastUpdateDuration = (item) => {
      if (item.reading_time !== null) {
         var startTime = moment(item.reading_time).format("YYYY-MM-DD HH:mm:ss");
         var endTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
         var duration = moment.duration(moment(endTime).diff(moment(startTime)));
         var days = parseInt(duration.asDays());
         var hours = parseInt(duration.asHours()) - days * 24;
         var minutes = parseInt(duration.asMinutes()) - days * 24 * 60 - hours * 60;
         if (days == 0 && hours == 0) {
            return (
               <div className="attribute item-hub" data-name="Last Update">{minutes + 'min'}</div>
            )
         } else if (days == 0 && hours != 0) {
            return (
               <div className="attribute item-hub" data-name="Last Update">{hours + 'h ' + minutes + 'min'}</div>
            )
         } else {
            return (
               <div className="attribute item-hub" data-name="Last Update">{days + 'd ' + hours + 'h ' + minutes + 'min'}</div>
            )
         }
      } else {
         return (
            <div style={{ width: "24.5px" }}></div>
         )
      }
   }
   const getFirstThreeColumnsHeader = () => {
      if (getUserRole() == 'super-admin'
         || getUserRole() == 'admin'
         || getUserRole() == 'manager') {
         return (
            <div className="attribute-container amount-edit">
               <div className="attribute" data-name="Installation" style={{ marginRight: "-27px" }}>Installation
                  <a style={{ marginLeft: "0px" }} onClick={() => { asecDsec("group_name", flag, items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
               </div>
               <div className="attribute hub-name" data-name="Hub Name">Hub
                  <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("hub", flag, items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
               </div>
               <div className="attribute" data-name="modbus">Address
                  <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("modbus_address", flag, items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
               </div>
               <div className="attribute" data-name="Name" style={{ width: "108px" }}>Customer
                  <a style={{ marginLeft: "-1px" }} onClick={() => { asecDsec("first_name", flag, items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
               </div>
            </div>
         )
      } else {
         return
      }
   }
   return (
      <div>
         <ol className="collection collection-container">
            <li className="item item-container table-header">
               <div className="attribute-container information">
                     {getFirstThreeColumnsHeader()}
                  <div className="attribute-container price-currency">
                     {getCustomerHeader()}
                     <div className="attribute balance-item" data-name="Balance">Balance
                        <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("meter_balance", flag, items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
                     </div>
                     <div className="attribute" data-name="ON-OFF"><center>Power
                        <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("onoff_status", flag, items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
                     </center></div>
                     <div className="attribute" data-name="Mode">Mode
                        <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("mode", flag, items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
                     </div>
                  </div>
               </div>
               <div className="attribute-container price-currency">
                  {/* {getPackageAction()} */}
                  <div className="attribute" data-name="Last Update">Last Update
                     <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("reading_time", flag, items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
                  </div>
                  {getAction()}
                  {/* <div className="attribute" data-name="TXN History"><center>ACTIONS</center></div> */}
               </div>
            </li>
            <PaymentModal
               selectedMeter={selectedMeter}
               reloadMeter={reloadMeter}
               setSelectedMeter={setSelectedMeter}
               meterUser={meterUser}
               show={show}
               handleClose={handleClose}
               customerId={customerId} />
            <MeterStatusModal
               meterStatus={meterStatus}
               meterUser={meterUser}
               showMeterStatus={showMeterStatus}
               handleMeterClose={handleMeterClose}
               modeData={modeData}
            />
            {(items.length != 0) ?
               <div>
                  {items.map((item) => {
                     return (<li key={item.serial} className="item item-container hover">
                        <div className="attribute-container information">
                              {getFirstThreeColumns(item)}
                           <div className="attribute-container price-currency">
                              {getCustomerName(item)}
                              {getMeterReading(item, item.serial)}
                              <div className="attribute icon-power" data-name="ON-OFF">{getMeterStatus(item, item.serial)}</div>
                              <div className="attribute" data-name="Mode">{item.mode}</div>
                           </div>
                        </div>
                        <div className="attribute-container name-organization">
                           {getLastUpdateDuration(item)}
                           {getMeterWallet(item.serial, item)}
                        </div>
                     </li>)
                  })}
               </div>
               :
               <div className="no-data-available-text">No Records Found</div>
            }
         </ol>
      </div>
   );
}