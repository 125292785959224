import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router'
import { getPayments, addPayment, getPaymentById, updatePayment, deletePayment } from '../../services/paymentService';
import { BsArrowLeftShort } from "react-icons/bs";

import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
// import '../../assets/css/pages/add-edit-form.css'
import { getOrganization } from 'services/organizationService';
import { getCurrency } from 'services/currencyService';
import { getUsers } from 'services/userService';
import { getMeter } from 'services/meterService';
import { getPackages } from 'services/packageService';
import { getWalletUsers } from 'services/walletUserService';
import { getUserId } from 'services/roleService';
import { me } from '../../services/userService';
import { getUserType as getUserRole, getRolePermission } from '../../services/roleService'


export default function PaymentAddEdit() {
  const [state, setState] = useState({
    id: '', bLabel: 'Add', FormLabel: 'Add'
  });
  const history = useHistory();
  const params = useParams();
  let fetchPayment = async (id) => {
    let payment = await getPaymentById(id);
    if (typeof (payment.id) !== 'undefined') {
      setState({ ...payment, bLabel: 'Update', FormLabel: 'Edit' })
    }
  }
  useEffect(() => {
    if (typeof (params.id) !== 'undefined') {
      fetchPayment(params.id);
    }
    reloadPackage();
    reloadMeter();
    reloadUser();
    reloadCurrency();
    reloadOrganization();
    reloadUserWallet();
    getCurrentOrganizationName();
  }, [])
  //display organization name
  const [organizationData, setOrganizationData] = useState({
    orgData: []
  });
  let reloadOrganization = async () => {
    let orgRecord = await getOrganization();
    setOrganizationData({ ...organizationData, orgData: orgRecord });
  }
  //display currency name
  const [currencyData, setCurrencyData] = useState({
    currency: []
  });
  let reloadCurrency = async () => {
    let currencyRecord = await getCurrency();
    setCurrencyData({ ...currencyData, currency: currencyRecord });
  }
  //Display User name
  const [userData, setUserData] = useState({
    data: []
  });
  let reloadUser = async () => {
    let record = await getUsers();
    setUserData({ ...userData, data: record });
  }
  //Display Meter name
  const [meterData, setMeterData] = useState({
    meter: []
  });
  let reloadMeter = async () => {
    let meterRecord = await getMeter();
    setMeterData({ ...meterData, meter: meterRecord });
  }
  //Display Package name
  const [packageData, setPackageData] = useState({
    package: []
  });
  let reloadPackage = async () => {
    let packageRecord = await getPackages();
    setPackageData({ ...packageData, package: packageRecord });
  }
  //Display Package name
  const [userWalletData, setUserWalletData] = useState({
    walletData: []
  });
  let reloadUserWallet = async () => {
    let walletRecord = await getWalletUsers();
    setUserWalletData({ ...userWalletData, walletData: walletRecord });
  }
  //Generate unique UUId()
  function uuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
  let handleCancel = () => {
    history.push("/payment");
  }
  let handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  }
  let handleSubmit =async (e) => {
    e.preventDefault();
    const newItem = {
      timestamp: Date.now(),
      source: state.source,
      info: state.info,
      amount: state.amount,
      currency_id: state.currency_id,
      user_id: state.user_id,
      reference: state.reference,
      type: state.type,
      energy_meter_id: state.energy_meter_id,
      package_id: state.package_id,
      user_wallet_id: state.user_wallet_id,
      organization_id: state.organization_id,
    };
    if (state.id !== '') { //update
      newItem.id = state.id;
      await updatePayment(newItem);
    } else { //add
      await addPayment(newItem);
    }
    history.push("/payment");
  }
  let backButton = () => {
    history.push("/payment");
  }
  let doDelete = async () => {
    var id = params.id
    await deletePayment({ id })
  }
  let getDeleteButton = (doDelete) => {
    const history = useHistory();
    if (getRolePermission('payments', getUserRole()) > 2) {
      if (typeof (params.id) !== 'undefined') {
      return (<button onClick={doDelete} className="cancle-button-style">
        Delete
      </button>
      );
    }
  }
}
  const [currentOrganizationName, setCurrentOrganizationName] = useState();
  const getCurrentOrganizationName = async () => {
    let record = await me();
    console.log("record>>>>>>", record);
    setCurrentOrganizationName(...record)
  }
  let getOrganizationName = () => {
    if (getUserRole() === "super-admin") {
      return (<tr>
        <td className="add">Organization :</td>
        <td>
          <select id={state.organization_id} name="organization_id" className="input-style" value={state.organization_id} onChange={handleChange}>
            <option> Choose organization </option>
            {organizationData.orgData.map(organization => (
              <option value={organization.id} >{organization.organization_name} </option>
            ))}
          </select>
        </td>
      </tr>)
    }
    else {
      return (
        <tr>
          <td className="add">Organization :</td>
          <td>
            <input
              disabled
              name="organization_id"
              id={state.organization_id}
              value={currentOrganizationName && currentOrganizationName.organization_name}
              className="input-style"
            />
          </td>
        </tr>)
    }
  }
  return (
    <div>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="striped-table-with-hover">
              <Card.Header>
                <div className='display-back-button'>
                  <div><button className="back" data-toggle="tooltip" title="click to go back" onClick={() => { backButton() }} ><BsArrowLeftShort /></button></div>
                  <div className="form-header-container form-header" style={{ marginTop: "05px" }}>
                    <center>{state.FormLabel} Payment</center>
                  </div>
                </div>
              </Card.Header>
              <Card.Body className="px-0" style={{ margin: "2%", marginTop: "0" }}>
                <form className="login-form">
                  <table>
                    <tr>
                      <td className="add">Source :</td>
                      <td>
                        <input
                          name="source"
                          placeholder="Source"
                          onChange={handleChange}
                          value={state.source}
                          className="input-style"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="add">Information :</td>
                      <td>
                        <input
                          name="info"
                          placeholder="Information"
                          onChange={handleChange}
                          value={state.info}
                          className="input-style"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="add">Amount :</td>
                      <td>
                        <input
                          type="number"
                          name="amount"
                          placeholder="Amount"
                          onChange={handleChange}
                          value={state.amount}
                          className="input-style"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="add">Currency :</td>
                      <td>
                        <select id={state.currency_id} name="currency_id" className="input-style" value={state.currency_id} onChange={handleChange}>
                          <option>Choose Currency</option>
                          {currencyData.currency.map(currency => (
                            <option value={currency.id} >{currency.currency} </option>
                          ))}
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td className="add">Customer :</td>
                      <td>
                        <select id={state.user_id} name="user_id" className="input-style" value={state.user_id} onChange={handleChange}>
                          <option>Choose Customer</option>
                          {userData.data.map(user => (
                            <option value={user.id} >{user.username} </option>
                          ))}
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td className="add">Reference :</td>
                      <td>
                        <input
                          name="reference"
                          placeholder="reference"
                          onChange={handleChange}
                          value={state.reference}
                          className="input-style"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="add">Type :</td>
                      <td>
                        <input
                          name="type"
                          placeholder="Transaction Type"
                          onChange={handleChange}
                          value={state.type}
                          className="input-style"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="add">Energy Meter Serial:</td>
                      <td>
                        <select id={state.energy_meter_id} name="energy_meter_id" className="input-style" value={state.energy_meter_id} onChange={handleChange}>
                          <option>Choose Serial</option>
                          {meterData.meter.map(meter => (
                            <option value={meter.serial} >{meter.serial} </option>
                          ))}
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td className="add">Package :</td>
                      <td>
                        <select id={state.package_id} name="package_id" className="input-style" value={state.package_id} onChange={handleChange}>
                          <option>Choose Package</option>
                          {packageData.package.map(packages => (
                            <option value={packages.id} >{packages.product_name} </option>
                          ))}
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td className="add">User Wallet :</td>
                      <td>
                        <select id={state.user_wallet_id} name="user_wallet_id" className="input-style" value={state.user_wallet_id} onChange={handleChange}>
                          <option>Choose user-wallet refrence</option>
                          {userWalletData.walletData.map(userWallet =>
                            <option value={userWallet.id} >{userWallet.reference} </option>
                          )}
                        </select>
                      </td>
                    </tr>
                    {getOrganizationName()}
                    <br />
                    <tr>
                      <td colSpan="2">
                        <div className="button-container">
                          <button onClick={handleSubmit} className="add-button-style">
                            {state.bLabel}
                          </button>
                          &nbsp;&nbsp;
                          {getDeleteButton(doDelete)}
                          &nbsp;&nbsp;
                          <button onClick={handleCancel} className="cancle-button-style">
                            Cancel
                          </button>
                        </div>
                      </td>
                    </tr>
                  </table>
                </form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
        </Row>
      </Container>
    </div>
  );
}
