import React, { useState, useContext } from 'react';
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useParams } from 'react-router';
import { useHistory } from "react-router"
import { apiUrl } from 'services/globalService';
import { AiFillEye } from 'react-icons/ai'
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import ToastLayout from '../views/partials/Toastify';

function PasswordReset() {
    const { token } = useParams();
    const history = useHistory();
    let apiVerifySetPassword = apiUrl + "verify-set-password"
    let newPasswordInput = React.createRef();
    let confirmPasswordInput = React.createRef();
    let passwordResetButton = React.createRef();
    const resetPassword = async () => {
        if (newPasswordInput.current.value && confirmPasswordInput.current.value) {
            if (newPasswordInput.current.value == confirmPasswordInput.current.value) {
                let data = {
                    password: newPasswordInput.current.value,
                    token: token
                }
                await fetch(apiVerifySetPassword, {
                    method: 'put',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(data)
                })
                    .then(response => response.json())
                    .then(response => {
                        if (response.result == "success") {
                            toast.error(ToastLayout("password set successfully!"))
                            history.push("/login")
                        } else {
                            toast.error(ToastLayout("Link expired! Please try again"))
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            } else {
                toast.error(ToastLayout("Not match!"))
            }
        } else {
            toast.error(ToastLayout("Field cannot be empty"))
        }
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            passwordResetButton.current.focus();
            resetPassword();
        }
    }
    return (
        <div className="row" style={{ margin: "3%", flexWrap: "unset" }}>
            <div className="col-lg-6 d-none d-md-block" style={{ background: "white", flex: "1", alignItems: "center", marginTop: "10%" }}>
                <center>
                    <img style={{ width: '230px' }} src="/main-logo.png" /><br /><br /><br />
                    <img style={{ width: '450px' }} src="/main-text.png" />
                </center>
            </div>
            <div style={{ backgroundColor: "White", alignItems: "center", marginTop: "6%" }} className="col-lg-5">
                <center>
                    <h2 style={{ fontWeight: "bold", marginTop: "8%" }}>PASSWORD RESET</h2> </center>
                <br />
                <div style={{ margin: "4%" }}>
                    <Form>
                        <Form.Group controlId="formBasicEmail" className="mb-4">
                            <Form.Label style={{ fontSize: "110%" }}>New Password:</Form.Label>
                            <Form.Control name="newPassword" type="password" onKeyPress={handleKeyDown} ref={newPasswordInput} placeholder="Enter New Password" style={{ borderBottomColor: "red", borderTop: "none", borderRight: "none", borderLeft: "none", borderRadius: '0rem' }} />
                        </Form.Group>
                        <Form.Group controlId="formBasicPassword" className="mb-4">
                            <Form.Label style={{ fontSize: "110%" }}>Confirm Password :</Form.Label>
                            <Form.Control name="confirmPassword" type="password" onKeyPress={handleKeyDown} ref={confirmPasswordInput} placeholder="Confirm New Password" style={{ borderBottomColor: "red", borderTop: "none", borderRight: "none", borderLeft: "none", borderRadius: '0rem' }} />
                        </Form.Group>
                        <ToastContainer />
                        <Button value="ResetPassword" onClick={resetPassword} ref={passwordResetButton} style={{ width: "100%", background: "red", border: "none", height: "300", color: "white", fontSize: "130%", textAlign: "center" }} >
                            Reset Password
                        </Button>
                        <Link to="/login"  style={{ display: "flex", justifyContent: "center", marginTop: "12px", textDecoration: "none", color: "red", fontSize: "18px" }}>
                            Back to Login
                        </Link>
                    </Form>
                </div>
            </div>
        </div>);
}
export default PasswordReset;
