import React, { useState, Component, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import routes from "../../routes.js";
import { useContext } from 'react';
import UserContext from "views/UserContext";
import { GrMenu } from "react-icons/gr";
import { FaUserCircle } from 'react-icons/fa';
import { getUserType } from "services/roleService";
import { getOrganizationById } from 'services/organizationService';
import { me ,clearMe} from 'services/userService';
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Toast,
  DropdownButton,
  Dropdown,
  Popover,
  OverlayTrigger
} from "react-bootstrap";

function Header() {
  const userContext = useContext(UserContext);
  const location = useLocation();
  const history = useHistory();
  const [meData, setMeData] = useState();
  useEffect(() => {
    reloadMeData();
  }, []);
  let reloadMeData = async () => {
    let record = await me();
    setMeData(...record);
  }
  const mobileSidebarToggle = (e) => {
    e.preventDefault();
    document.documentElement.classList.toggle("nav-open");
    let node = document.createElement("div");
    node.id = "bodyClick";
    node.onclick = function () {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle("nav-open");
    };
    document.body.appendChild(node);
  };
  const getBrandText = () => {
    let cRoutes = routes();
    for (let i = 0; i < cRoutes.length; i++) {
      if (location.pathname.indexOf(cRoutes[i].path) !== -1) {
        return cRoutes[i].name;
      }
      if(cRoutes[i].subitem && cRoutes[i].subitem.length > 0 ){
        for (let j = 0; j < cRoutes[i].subitem.length; j++) {
          if (location.pathname.indexOf(cRoutes[i].subitem[j].path) !== -1) {
            return cRoutes[i].subitem[j].name;
          }
        }
      }
    }
    return "";
  };
  
  return (
    <Navbar style={{ flexGrow: 0, flexWrap: "nowrap" }}>
      <Container fluid>
        <div
          className="d-flex justify-content-center align-items-center"
        >
          <Button
            letiant="dark"
            className="d-lg-none "
            onClick={mobileSidebarToggle}
            style={{ border: "none", fontSize: "25px", backgroundColor: "transparent" }}
          >
            <GrMenu />
          </Button>
          <Navbar.Brand style={{ fontWeight: "normal", color: "black", fontSize: "28px", marginLeft: "2%" }}>
            {getBrandText()}
          </Navbar.Brand>
        </div>
        <Navbar
          className="d-lg-none" style={{ backgroundColor: "transparent", border: "none" }} >
          <Nav.Link
            style={{ lineHeight: "0px" }}
          >
          </Nav.Link>
        </Navbar>
        <Navbar.Collapse id="basic-navbar-nav" style={{ flexGrow: '0' }}>
          <Nav className="ml-auto" navbar >
            <Nav.Item >
              <div style={{ float: "right" }}>
                <Nav.Link
                  style={{ lineHeight: "0px" }}
                >
                  <div>
                    <div>
                      <OverlayTrigger
                        trigger="click"
                        rootClose
                        key='left'
                        placement='left'
                        overlay={
                          <Popover id={`popover-positioned-left`}>
                            <Popover.Header as="h3">
                            </Popover.Header>
                            <Popover.Body>
                              <div style={{display:"flex" }}>
                              <div>
                              <div>{meData && meData.username}</div>
                              <div><strong>{meData && meData.organization_name}</strong><br /></div>
                              <div>[ Role: <strong>{meData && meData.role_type} </strong>]<br /></div>
                              </div>
                              <div>
                              <button
                                  style={{ border: "none", background: "none", width: "auto",marginLeft:"5px",marginTop:"10px"}}
                                  onClick={(e) => {
                                    clearMe()
                                    e.preventDefault();
                                    userContext.doLogin(false);
                                    history.push("/login");
                                  }}
                                >&nbsp;
                                  <img style={{ width: '33px' }} src="/logout.png" />
                                </button>
                              </div>
                              </div>
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <button className="edit" style={{ color: "#ed1b24", fontSize: "40px"}}><FaUserCircle /></button>
                      </OverlayTrigger>
                    </div>
                  </div>
                </Nav.Link>
              </div>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;