import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import Select from "react-select";
import { getMeter, getMeterBySerial, updateMeter, addMeter, deleteMeter } from '../../services/meterService';
import { BsArrowLeftShort } from "react-icons/bs";
import {
  Card,
  Container,
  Row,
  Col,
  Form
} from "react-bootstrap";
import '../../assets/css/pages/add-edit-form.css'
import { getOrganization } from '../../services/organizationService';
import { getUsers, getUserById, me, getAllCustomerUsers, searchCustomerForSelect } from '../../services/userService';
import { getGateway, getGatewayByAddress, getGatewayByGroup, searchGatewayByGroup } from '../../services/gatewayService';
import { getMode } from '../../services/modeService';
import { getNodeInGateway, getNode, searchNodeByGroup, getNodeByMacAddress } from '../../services/nodeService';
import { getHardware, getHardwareByAssetType } from '../../services/hardwareService';
import { getUserId } from '../../services/roleService';
import { getInstallation, getInstallationById, searchInstallationGroup } from '../../services/installationService';
import { getUserType as getUserRole, getRolePermission } from '../../services/roleService';
import { AsyncPaginate } from "react-select-async-paginate";
import ToastLayout from '../../views/partials/Toastify';
import { ToastContainer, toast } from 'react-toastify';
export default function MeterAddEdit() {
  const [state, setState] = useState({
    serial: '', bLabel: 'Add', FormLabel: 'Add'
  });
  const history = useHistory();
  const { serial } = useParams();
  var groupId;
  var meter;
  var groupRecord;
  // var initialModeId;
  const [deviceGroup, setDeviceGroup] = useState([]);
  const [nodeGroup, setNodeGroup] = useState([]);
  const [hardwareModel, setHardwareModel] = useState();
  const [mode, setMode] = useState();
  const [initialModeId, setInitialModeId] = useState();
  const [modeChange, setModeChange] = useState('false');
  const [groupName, setGroupName] = useState();
  const [gatewayHubName, setGatewayHubName] = useState();
  const [nodeHubName, setNodeHubName] = useState();
  const [customer, setCustomer] = useState({ id: null, first_name: "", last_name: "" });

  let fetchMeter = async (serial) => {
    meter = await getMeterBySerial(serial);
    console.log("meter", meter)
    if (typeof (serial) !== undefined) {
      setState({ ...meter, bLabel: 'Update', FormLabel: 'Edit' })
      groupId = meter.device_group_id
      setInitialModeId(meter.mode_id);
      groupRecord = await getInstallationById(meter.device_group_id);
      setOrganizationData(groupRecord[0])
    }
  }
  useEffect(async () => {
    if (typeof (serial) !== 'undefined') {
      await fetchMeter(serial);
    }
    reloadOrganization();
    reloadUser();
    reloadDeviceGroup();
    reloadNodeGroup();
    reloadInstallation();
    reloadMode();
    reloadHardware();
    getCurrentOrganizationName();
  }, [])
  //Display organization name
  const [organizationData, setOrganizationData] = useState({
    orgData: []
  });
  let reloadOrganization = async () => {
    let orgRecord = await getOrganization();
    setOrganizationData({ ...organizationData, orgData: orgRecord });
  }

  let reloadUser = async () => {
    if (serial !== undefined) {
      let userName = await getUserById(meter.customer_id);
      if (userName.result == 'fail') {
        setCustomer({ id: null, first_name: "None", last_name: "" });
      } else {
        setCustomer(userName);
      }
    }
  }
  const loadCustomer = async (search, prevOptions) => {
    let filteredOptions = [];
    if (search) {
      let searchData = {
        searchKey: search
      }
      filteredOptions = await searchCustomerForSelect(searchData);
      filteredOptions = [{ id: null, first_name: "None", last_name: "" }, ...filteredOptions]
    } else {
      let options = [{ id: null, first_name: "None", last_name: "" }]
      filteredOptions = options;
    }
    const slicedOptions = filteredOptions.slice(
      prevOptions.length,
      prevOptions.length + 10
    );
    return {
      options: slicedOptions
    };
  };
  let reloadDeviceGroup = async () => {
    if (serial !== undefined) {
      let gatewayName = await getGatewayByAddress(meter.gateway_mac_address)
      if (gatewayName.result == 'fail') {
        setGatewayHubName({ mac_address: null, hub_name: "None" });
      } else {
        setGatewayHubName(gatewayName)
      }
    }
  }
  const loadGatewayHub = async (search, prevOptions) => {
    let filteredOptions = [];
    if (search) {
      let searchData = {
        groupId: groupName.id,
        searchKey: search
      }
      filteredOptions = await searchGatewayByGroup(searchData);
      filteredOptions = [{ mac_address: null, hub_name: "None" }, ...filteredOptions]
    } else {
      let options = [{ mac_address: null, hub_name: "None" }]
      filteredOptions = options;
    }
    const slicedOptions = filteredOptions.slice(
      prevOptions.length,
      prevOptions.length + 10
    );
    return {
      options: slicedOptions
    };
  }
  let reloadNodeGroup = async () => {
    if (serial !== undefined) {
      let nodeName = await getNodeByMacAddress(meter.node_id)
      if (nodeName == undefined) {
        setNodeHubName({ mac_address: null, node_hub_name: "None" });
      } else {
        setNodeHubName(nodeName)
      }
    }
  }
  const loadNodeHub = async (search, prevOptions) => {
    let filteredOptions = [];
    if (search) {
      let searchData = {
        groupId: groupName.id,
        searchKey: search
      }
      filteredOptions = await searchNodeByGroup(searchData);
      filteredOptions = [{ mac_address: null, node_hub_name: "None" }, ...filteredOptions]
    } else {
      let options = [{ mac_address: null, node_hub_name: "None" }]
      filteredOptions = options;
    }
    const slicedOptions = filteredOptions.slice(
      prevOptions.length,
      prevOptions.length + 10
    );
    return {
      options: slicedOptions
    };
  }
  let reloadInstallation = async () => {
    if (serial !== undefined) {
      setGroupName(groupRecord[0])
    }
  }
  const loadInstallations = async (search, prevOptions) => {
    let filteredOptions = [];
    if (search) {
      let searchData = {
        searchKey: search
      }
      filteredOptions = await searchInstallationGroup(searchData);
    } else {
      filteredOptions = options;
    }
    const slicedOptions = filteredOptions.slice(
      prevOptions.length,
      prevOptions.length + 10
    );
    return {
      options: slicedOptions
    };
  };
  //Display mode name
  const [modeData, setModeData] = useState({
    mode: []
  });
  let reloadMode = async () => {
    let modeRecord = await getMode();
    setModeData({ ...modeData, mode: modeRecord });
    if (serial !== undefined) {
      setMode(modeRecord.filter((pre) => {
        if (pre.id === meter.mode_id)
          return true;
      }))
    }
  }
  //Display hardware name
  const [hardwareData, setHardwareData] = useState({
    hardware: []
  });
  let reloadHardware = async () => {
    let hardwareRecord = await getHardwareByAssetType("Energy Meter");
    setHardwareData({ ...hardwareData, hardware: hardwareRecord });
    if (serial !== undefined) {

      setHardwareModel(hardwareRecord.filter((pre) => {
        if (pre.id === meter.hardware_id)
          return true;
      }))
    }
  }
  //Add & edit
  let handleCancel = () => {
    history.push("/meters");
  }
  let selectHardwareModel = (e) => {
    setState({ ...state, ["hardware_id"]: e.id });
    setHardwareModel(e);
  }
  let selectMode = (e) => {
    setModeChange(true);
    setState({ ...state, ["mode_id"]: e.id });
    setMode(e);
  }
  let selectInstallation = async (e) => {
    setState({ ...state, ["device_group_id"]: e.id });
    let groupRecord = await getInstallationById(e.id);
    setOrganizationData(groupRecord[0])
    setGroupName(e);
    setGatewayHubName({ mac_address: null, hub_name: "None" });
    setNodeHubName({ mac_address: null, node_hub_name: "None" });
  }
  let selectGatewayHub = (e) => {
    setState({ ...state, ["gateway_mac_address"]: e.mac_address });
    setGatewayHubName(e);
  }
  let selectNodeHub = (e) => {
    setState({ ...state, ["node_id"]: e.mac_address });
    setNodeHubName(e);
  }
  let selectCustomer = (e) => {
    setState({ ...state, ["customer_id"]: e.id });
    setCustomer(e);
  }
  let handleChange = async (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  }
  let handleSubmit = async (e) => {
    e.preventDefault();
    if (!state.serial.length) {
      return;
    }
    const newItem = {
      serial: state.serial,
      hardware_id: state.hardware_id,
      mode_id: state.mode_id,
      device_group_id: state.device_group_id,
      customer_id: state.customer_id,
      node_id: state.node_id,
      gateway_mac_address: state.gateway_mac_address,
      owner_organization_id: organizationData.organization_id
    };
    if (typeof (serial) !== 'undefined') { //update
      newItem.serial = state.serial;
      if (modeChange) {
        if (newItem.mode_id != initialModeId) {
          newItem.onoff_status = "update";
        }
      }
      let response = await updateMeter(newItem);
      if (response.result == "fail") {
        toast.error(ToastLayout("Please enter unique serial!"))
      } else {
        history.push("/meters");
      }
    } else { //add
      if (newItem.hardware_id) {
        if (newItem.mode_id) {
          if (newItem.device_group_id) {
            // if (newItem.customer_id) {
            if (newItem.owner_organization_id) {
              let response = await addMeter(newItem);
              if (response.result == "fail") {
                toast.error(ToastLayout("Please enter unique serial!"))
              } else {
                history.push("/meters");
              }
            } else {
              toast.error(ToastLayout("Please select organnization"))
            }
            // } else { alert("Please select coustomer") }
          } else {
            toast.error(ToastLayout("Please select device group"))
          }
        } else {
          toast.error(ToastLayout("Please select mode")) 
        }
      } else {
        toast.error(ToastLayout("Please select hardware model")) 
      }
    }
  }
  let doDelete = async () => {
    await deleteMeter({ serial })
    history.push('/meters')
  }
  let getDeleteButton = () => {
    const history = useHistory();
    if (getRolePermission('meters', getUserRole()) > 2) {
      if (typeof (serial) !== 'undefined') {
        return (<button onClick={(e) => { e.preventDefault(); doDelete() }} className="cancle-button-style">
          Delete
        </button>);
      }
    }
  }
  let backButton = () => {
    history.push("/meters");
  }
  let checkEditOrAdd = () => {
    if (typeof (serial) !== 'undefined') {
      return (<input
        disabled
        name="serial"
        placeholder="Serial e.g: 90869876"
        onChange={handleChange}
        value={state.serial}
        type="number"
        className="input-style-responsive"
      />
      );
    } else {
      return (<input
        name="serial"
        placeholder="Serial e.g: 90869876"
        onChange={handleChange}
        type="number"
        value={state.serial}
        className="input-style-responsive"
      />
      );
    }
  }
  let checkAddInstallation = () => {
    if (typeof (serial) !== undefined) {
      return (<div className='display-back-button'>
        <div><button className="back" data-toggle="tooltip" title="click to go back" onClick={() => { backButton() }} ><BsArrowLeftShort /></button></div>
        <div className="form-header-container form-header" style={{ marginTop: "05px" }}>
          <center>{state.FormLabel} Meter</center>
        </div>
      </div>
      );
    } else {
      return (<div></div>
      );
    }
  }
  const [currentOrganizationName, setCurrentOrganizationName] = useState();
  const getCurrentOrganizationName = async () => {
    let record = await me();
    setCurrentOrganizationName(...record)
  }
  let getOrganizationName = () => {
    if (getUserRole() === "super-admin") {
      return (
        <Form.Group as={Row} className="mb-3" >
          <Col sm="3">
            <div className="add-responsive">Organization :</div>
          </Col>
          <Col sm="9">
            <input
              disabled
              name="owner_organization_id"
              id={organizationData.organization_id}
              value={organizationData.organization_name}
              className="input-style-responsive"
              style={{ backgroundColor: 'transparent', borderBottom: "0rem" }}
            />
          </Col>
        </Form.Group>
      )
    }
    else {
      state.owner_organization_id = currentOrganizationName && currentOrganizationName.organization_id
      return (
        <Form.Group as={Row} className="mb-3" >
          <Col sm="3">
            <div className="add-responsive">Organization :</div>
          </Col>
          <Col sm="9">
            <input
              disabled
              name="organization_id"
              id={state.owner_organization_id}
              value={currentOrganizationName && currentOrganizationName.organization_name}
              className="input-style-responsive"
              style={{ backgroundColor: 'transparent', borderBottom: "0rem" }}
            />
          </Col>
        </Form.Group>
      )
    }
  }
  return (
    <div>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="striped-table-with-hover">
              <Card.Header>
                {checkAddInstallation()}
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Col lg="9">
                  <Form >
                    <Form.Group as={Row} className="mb-3" >
                      <Col sm="3">
                        <div className="add-responsive">Serial :</div>
                      </Col>
                      <Col sm="9">
                        {checkEditOrAdd()}
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                      <Col sm="3">
                        <div className="add-responsive">Hardware Model :</div>
                      </Col>
                      <Col sm="9">
                        <Select
                          className="dropDown-style"
                          value={hardwareModel}
                          options={hardwareData.hardware}
                          getOptionLabel={e => e.model}
                          getOptionValue={e => e.id}
                          onChange={(e) => selectHardwareModel(e)}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                      <Col sm="3">
                        <div className="add-responsive">Mode :</div>
                      </Col>
                      <Col sm="9">
                        <Select
                          className="dropDown-style"
                          value={mode}
                          options={modeData.mode}
                          getOptionLabel={e => e.mode}
                          getOptionValue={e => e.id}
                          onChange={(e) => selectMode(e)}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                      <Col sm="3">
                        <div className="add-responsive">Installation Group :</div>
                      </Col>
                      <Col sm="9" style={{ maxWidth: "43.5%" }}>
                        <AsyncPaginate
                          value={groupName}
                          loadOptions={loadInstallations}
                          placeholder={"Type to select ...."}
                          onChange={(e) => selectInstallation(e)}
                          options={[]}
                          getOptionLabel={e => e.group_name}
                          getOptionValue={e => e.id}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                      <Col sm="3">
                        <div className="add-responsive">Hub Name (Gateway):</div>
                      </Col>
                      <Col sm="9" style={{ maxWidth: "43.5%" }}>
                        <AsyncPaginate
                          value={gatewayHubName}
                          loadOptions={loadGatewayHub}
                          placeholder={"Type to select ...."}
                          onChange={(e) => selectGatewayHub(e)}
                          options={[]}
                          getOptionLabel={e => e.hub_name}
                          getOptionValue={e => e.mac_address}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                      <Col sm="3">
                        <div className="add-responsive">Hub Name (Node):</div>
                      </Col>
                      {/* <Col sm="9">
                        <Select
                          className="dropDown-style"
                          value={nodeHubName}
                          options={nodeGroup}
                          getOptionLabel={e => e.node_hub_name}
                          getOptionValue={e => e.mac_address}
                          onChange={(e) => selectNodeHub(e)}
                        />
                      </Col> */}
                      <Col sm="9" style={{ maxWidth: "43.5%" }}>
                        <AsyncPaginate
                          value={nodeHubName}
                          loadOptions={loadNodeHub}
                          placeholder={"Type to select ...."}
                          onChange={(e) => selectNodeHub(e)}
                          options={[]}
                          getOptionLabel={e => e.node_hub_name}
                          getOptionValue={e => e.mac_address}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                      <Col sm="3">
                        <div className="add-responsive">Customer :</div>
                      </Col>
                      {/* <Col sm="9">
                        <Select
                          className="dropDown-style"
                          value={customer}
                          options={userData.data}
                          getOptionLabel={e => e.username}
                          getOptionValue={e => e.id}
                          onChange={(e) => selectCustomer(e)}
                        />
                      </Col> */}
                      <Col sm="9" style={{ maxWidth: "43.5%" }}>
                        <AsyncPaginate
                          value={customer}
                          loadOptions={loadCustomer}
                          placeholder={"Type to select ...."}
                          onChange={(e) => selectCustomer(e)}
                          options={[]}
                          getOptionLabel={e => e.first_name + " " + e.last_name}
                          getOptionValue={e => e.id}
                        />
                      </Col>
                    </Form.Group>
                    {getOrganizationName()}
                    <Form.Group as={Row} className="mb-3" >
                      <Col>
                        <div className="button-container-responsive">
                          <ToastContainer  
                          />
                          <button onClick={handleSubmit} className="add-button-style">
                            {state.bLabel}
                          </button> &nbsp;&nbsp;
                          {getDeleteButton()}
                          &nbsp;&nbsp;
                          <button onClick={handleCancel} className="cancle-button-style">
                            Cancel
                          </button>
                        </div>
                      </Col>
                    </Form.Group>
                  </Form>
                </Col>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
        </Row>
      </Container>
    </div>
  );
}