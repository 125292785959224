import { apiUrl } from "./globalService";
import { getUserToken } from "./roleService";
let apiHardware = apiUrl + "hardware";
let apiHardwareAsset=apiUrl + "hardware-by-asset-type"
export let getHardware = () => {
    return fetch(apiHardware, {
        method: 'get',
        headers: {
          'Authorization' : getUserToken(),
            'Content-Type': 'application/json;charset=utf-8'
        }
    })
        .then(response => response.json())
        .then(response => {
            return response;
        }).catch(function (error) {
            console.log(error);
            return "Unauthorized"
        });
}
export let getHardwareByAssetType = (asset) => {
  return fetch(apiHardwareAsset+"/"+asset, {
      method: 'get',
      headers: {
        'Authorization' : getUserToken(),
          'Content-Type': 'application/json;charset=utf-8'
      }
  })
      .then(response => response.json())
      .then(response => {
          return response;
      }).catch(function (error) {
          console.log(error);
          return "Unauthorized"
      });
}
export let getHardwareById = (id) => {
    return fetch(apiHardware + "/" + id, {
      method: 'get',
      headers: {
        'Authorization' : getUserToken(),
        'Content-Type': 'application/json;charset=utf-8'
      }
    })
      .then(response => response.json())
      .then(response => {
        return response;
      }).catch(function (error) {
        console.log(error);
      });
  }
  export let addHardware = (hardware) => {
    return fetch(apiHardware, {
      method: 'post',
      headers: {
        'Authorization' : getUserToken(),
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify(hardware)
    })
      .then(response => response.json())
      .then(response => {
        return response;
      }).catch(function (error) {
        console.log(error);
      });
  }
  export let updateHardware = (hardware) => {
    return fetch(apiHardware, {
      method: 'put',
      headers: {
        'Authorization' : getUserToken(),
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify(hardware)
    })
      .then(response => response.json())
      .then(response => {
        return response;
      }).catch(function (error) {
        console.log(error);
      });
  }
  export const deleteHardware =  ( id ) => {
    return  fetch(apiHardware + "/" + id, {
      method: 'delete',
      headers: {
        'Authorization' : getUserToken(),
        'Content-Type': 'application/json;charset=utf-8'
      }
    })
      .then(response => response.json())
      .then(response => {
        console.log("response",response);

        // alert(response.msg)
        return response;
      }).catch(function (error) {
        console.log(error);
      });
  }  
  export let searchHardware = (hardware) => {
    return fetch(apiHardware+"/search", {
      method: 'post',
      headers: {
        'Authorization' : getUserToken(),
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify(hardware)
    })
      .then(response => response.json())
      .then(response => {
        return response;
      }).catch(function (error) {
        console.log(error);
      });
  }