import { apiUrl } from "./globalService";
import { getUserToken } from "./roleService";

let currenyArray = [];
const apiCurrency = apiUrl + "currency";

export const getCurrency = async() => {
    if(currenyArray.length > 0){
        return currenyArray;
    }
    return fetch(apiCurrency, {
        method: 'get',
        headers: {
            'Authorization' : getUserToken(),
            'Content-Type': 'application/json;charset=utf-8'
        }
    })
        .then(response => response.json())
        .then(response => {
            currenyArray = response;
            return response;
        }).catch(function (error) {
            console.log(error);
            return "Unauthorized"
        });
}

export const getCurrencyNameById = (currencyId) => {
    if(currenyArray.length == 0){
        getCurrency(); //can't hold this for JSX
        return "";
    }
    let tempArray = currenyArray.filter((item)=>(currencyId == item.id));
    if(tempArray.length > 0){
        return tempArray[0].currency;
    }else{
        return "";
    }
}

//load currency on load
// getCurrency();