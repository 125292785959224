import { apiUrl } from "./globalService";
import { getUserToken } from "./roleService";
let apiCountry = apiUrl + "country";
export let getCountry = () => {
    return fetch(apiCountry, {
        method: 'get',
        headers: {
            'Authorization' : getUserToken(),
            'Content-Type': 'application/json;charset=utf-8'
        }
    })
        .then(response => response.json())
        .then(response => {
            return response;
        }).catch(function (error) {
            console.log(error);
            return "Unauthorized"
        });
}
