import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { FiEdit } from 'react-icons/fi';
import { BiSort } from 'react-icons/bi';
import { getUserType as getUserRole, getRolePermission } from '../../services/roleService'
import * as moment from 'moment'
import SearchLayout from '../partials/SearchLayout';
import SortingByColumn from 'views/partials/SortingByColumn';
import {
    Card,
    Container,
    Row,
    Col,
} from "react-bootstrap";
import '../../assets/css/pages/table-list.css'
import { sortNode, searchNode } from '../../services/nodeService';
import { getNode, getNodeInGateway, getNodeInOrganization, getNodeInstallationOrg } from '../../services/nodeService';
import { ToastContainer, toast } from 'react-toastify';
import ToastLayout from '../../views/partials/Toastify';

const searchOptions = {
    searchValues: ["group_name", "serial", "node_hub_name", "node.mac_address", "model"], //same len as values
    searchNames: ['Installation', 'Gateway', "Hub Name", 'Mac Address', "Model"],
}
const enableAddButton = () => (getRolePermission('gateways', getUserRole()) > 1);
const getEditButton = (mac_address) => {
    return (
        <center><a href={"#/node-edit/" + mac_address} style={{ color: 'black' }} >
            <FiEdit />
        </a></center>
    );
}

export default function NodeList({ globalSelect, globalSelectOrganization }) {
    var nodeScreenSort;
    var gateway;
    const [state, setState] = useState({
        items: []
    });
    const searchInput = React.useRef();
    const [flag, setFlag] = useState();
    const [items, setItems] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    const [installation, setInstallation] = useState([]);
    const [clearField, setClearField] = useState(true);
    const [inputField, setInputField] = useState({ selectField: "mac_address" });
    const history = useHistory();
    useEffect(async () => {
        await reloadNode();
        if (localStorage.getItem('nodeScreenSort') != null) {
            nodeScreenSort = JSON.parse(localStorage.getItem('nodeScreenSort'))
        }
        if (nodeScreenSort && nodeScreenSort.field != null && nodeScreenSort.ascFlag != undefined) {
            asecDsec(nodeScreenSort.field, nodeScreenSort.ascFlag, gateway);
        }
    }, [globalSelect, globalSelectOrganization])
    let asecDsec = (field, flag, items) => {
        let toggle = flag;
        if (flag == undefined) {
            toggle = 1
        }
        localStorage.setItem("nodeScreenSort", JSON.stringify({ ascFlag: toggle, field: field }));
        let sorted = SortingByColumn(field, items, toggle, setFlag)
        setItems(sorted);
        setOriginalData(sorted);
    }
    let reloadNode = async () => {
        //Global filter Installation: Any one installation selected as option 
        //Global filter Organization: All organizations selected as option
        if ((globalSelect.installation_id != 0 && globalSelect.installation_id != undefined) &&
            (globalSelectOrganization.organization_id == 0 || globalSelectOrganization.organization_id == undefined)) {
             gateway = await getNodeInGateway(globalSelect.installation_id);
            if (gateway == "Unauthorized") {
                toast.error(ToastLayout("Your session has expired!"))
                localStorage.clear();
                history.push("/login")
            }
            setItems(gateway);
            setOriginalData(gateway);
        }
        //Global filter Installation: All installations selected as option 
        //Global filter Organization: Any one organization selected as option
        else if ((globalSelect.installation_id == 0 || globalSelect.installation_id == undefined) &&
            (globalSelectOrganization.organization_id != 0 && globalSelectOrganization.organization_id != undefined)) {
             gateway = await getNodeInOrganization(globalSelectOrganization.organization_id);
            if (gateway == "Unauthorized") {
                toast.error(ToastLayout("Your session has expired!"))
                localStorage.clear();
                history.push("/login")
            }
            setItems(gateway);
            setOriginalData(gateway);
        }
        //Global filter Installation: Any one installation selected as option 
        //Global filter Organization: Any one organization selected as option
        else if ((globalSelect.installation_id != 0 && globalSelect.installation_id != undefined) &&
            (globalSelectOrganization.organization_id != 0 && globalSelectOrganization.organization_id != undefined)) {
            let record = {
                organizationId: globalSelectOrganization.organization_id,
                deviceGroupId: globalSelect.installation_id
            }
             gateway = await getNodeInstallationOrg(record);
            if (gateway == "Unauthorized") {
                toast.error(ToastLayout("Your session has expired!"))
                localStorage.clear();
                history.push("/login")
            }
            setItems(gateway);
            setOriginalData(gateway);
        }
        //Global filter Installation: All installations selected as option 
        //Global filter Organization: All organizations selected as option
        else {
             gateway = await getNode();
            if (gateway == "Unauthorized") {
                toast.error(ToastLayout("Your session has expired!"))
                localStorage.clear();
                history.push("/login")
            }
            setItems(gateway);
            setOriginalData(gateway);
        }
    }
    // search details
    const searchDetails = async (fieldValue, dataValue) => {
        const searchParams = {
            field: fieldValue,
            data: dataValue,
        };
        if (getUserRole() == "super-admin") {
            if (globalSelectOrganization.organization_id != 0 && globalSelectOrganization.organization_id != undefined) {
                searchParams.organizationId = globalSelectOrganization.organization_id
            } else {
                searchParams.organizationId = null
            }
            if(globalSelect.installation_id != 0 && globalSelect.installation_id != undefined){
                searchParams.installationId = globalSelect.installation_id
            }else{
                searchParams.installationId = null
            }
        } else {
            searchParams.organizationId = organization
        }
        let searchItems = await searchNode(searchParams);
        setItems(searchItems);
    }
    // Clear the details
    let clearDetails = async () => {
        setItems(originalData);
        searchInput.current.value = "";
    }
    return (
        <div>
            <Container fluid>
                <Row>
                    <Col md="12">
                        <Card className="striped-table-with-hover">
                            <Card.Header>
                                <SearchLayout
                                    searchInput={searchInput}
                                    searchOptions={searchOptions}
                                    doSearch={searchDetails}
                                    clearDetails={clearDetails}
                                    addButtonCallback={() => { history.push("/node-add") }}
                                    isAddButtonEnable={enableAddButton()} showHide={true}
                                />
                            </Card.Header>
                            <Card.Body className="table-full-width table-responsive px-0" style={{ marginTop: "0" }}>
                                <SetGatewayList items={items}
                                setItems={setItems}
                                asecDsec={asecDsec}
                                flag={flag}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                </Row>
            </Container>
        </div>
    );
}
function SetGatewayList({ items,setItems,asecDsec,flag}) {
    return (
        <div>
             <ToastContainer />
            <ol className="collection collection-container">
                <li className="item item-container table-header">
                    <div className="attribute-container information">
                        <div className="attribute-container name-organization">
                            <div className="attribute" data-name="Installation">Installation
                                <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("group_name",flag,items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
                            </div>
                            <div className="attribute" data-name="Gateway">Gateway
                                <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("serial",flag,items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
                            </div>
                        </div>
                        <div className="attribute-container price-currency">
                            <div className="attribute" data-name="Hub Name">Hub Name
                                <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("node_hub_name",flag,items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
                            </div>
                            <div className="attribute" data-name="Mac Address">Mac Address
                                <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("mac_address",flag,items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
                            </div>
                        </div>
                    </div>
                    <div className="attribute-container amount-edit">
                        <div className="attribute" data-name="Model">Model
                            <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("model",flag,items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
                        </div>
                        <div className="attribute" data-name="Edit"><center>Actions</center>
                        </div>
                    </div>
                </li>
                {(items.length > 0) ?
                    <div>
                        {items.map((item) => {
                            return (<li key={item.id} className="item item-container hover">
                                <div className="attribute-container information">
                                    <div className="attribute-container name-organization">
                                        <div className="attribute" data-name="Installation">{item.group_name}</div>
                                        <div className="attribute" data-name="Gateway">{item.serial}</div>
                                    </div>
                                    <div className="attribute-container price-currency">
                                        <div className="attribute" data-name="Hub Name">{item.node_hub_name}</div>
                                        <div className="attribute" data-name="Mac Address">{item.mac_address}</div>
                                    </div>
                                </div>
                                <div className="attribute-container amount-edit">
                                    <div className="attribute" data-name="Model">{item.model}</div>
                                    {getEditButton(item.mac_address)}
                                </div>
                            </li>)
                        })}
                    </div>
                    :
                    <div className="no-data-available-text">No Records Found</div>
                }
            </ol>
        </div>
    );
}