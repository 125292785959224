import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import {
    Card,
    Container,
    Row,
    Col,
} from "react-bootstrap";
import '../../assets/css/pages/table-list.css'
import {
    getMeter, deleteMeter, searchMeter,
    getMeterByCustomer, getMeterInGroup, getMeterByGroup,
    getMeterInOrganization, getMeterInstallationOrg
} from '../../services/meterService';
import { getGateway } from 'services/gatewayService';
import { getCustomerById } from '../../services/userService';
import { SelectPackage } from '../packages/SelectMeterPackage';
import { getUserType as getUserRole, getRolePermission ,getOrganizationId} from '../../services/roleService';
import { getMode } from '../../services/modeService';
import SubItemHeading from '../partials/SubItemHeading';
import SearchLayout from '../partials/SearchLayout';
import AddMeterFromGatewayModal from './AddMeterFromGatewayModal'
import SortingByColumn from '../partials/SortingByColumn';
import { ToastContainer, toast } from 'react-toastify';
import ToastLayout from '../../views/partials/Toastify';

const getAddbutton = () => {
    const history = useHistory();
    if (getRolePermission('meters', getUserRole()) > 1) {
        return (
            <button className="btn rounded-pill add-btn" onClick={() => { history.push("/meter-add") }}>
                + Add Manually
            </button>
        );
    }
}
const enableAddButton = () => (getRolePermission('meters', getUserRole()) > 1);

const searchOptions = {
    searchValues: ["device_group.group_name", "gateway.hub_name || node.node_hub_name","modbus_address", "first_name || last_name", "meter_balance", "onoff_status","mode","reading_time"], //same len as values
    searchNames: ["Installation", 'Hub', 'Address', 'Customer', 'Balance','Power','Mode','Last Update'],
}
export default function MeterList({ globalSelect, globalSelectOrganization }) {
    var meterScreenSort;
    var meters;
    const searchInput = React.useRef();
    const [state, setState] = useState({
        items: [], customerId: ''
    });
    const [originalData, setOriginalData] = useState({items: []});
    const [flag, setFlag] = useState();
    const [gatewayItems, setGatewayItems] = useState([]);
    const [selectedGateway, setSelectedGateway] = useState({});
    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false); setSelectedGateway({}) };
    const handleShow = () => setShow(true);
    const [meterUser, setMeteruser] = useState({ first_name: '' })
    const [isCustomerMeters, setCustomerMetersFlag] = useState(false)
    const [myInterval, setMyInterval] = useState(0)
    const { customerId } = useParams();
    const [clearField, setClearField] = useState(true);
    const [sortingField, setSortingField] = useState("");
    const [sortingToggle, setSortingToggle] = useState("");
    const history = useHistory();
    const organization = getOrganizationId();

    /*let updateProgressMeters = () =>{
        setCount((count)=>(count+1));
        console.log(">> setInterval");
        console.log(">> setInterval"+itemsChange.length);
        let inProgressMeters = itemsChange.filter((record)=>(record.onoff_status == "update"));
        if(inProgressMeters.length > 0){
            inProgressMeters.map((record)=>{
                console.log('record:',record);
            })
        }
    }*/
    useEffect(async () => {
        if (typeof (customerId) !== 'undefined') {
            await reloadMeterByCustomer(customerId);
            setCustomerMetersFlag(true);
            getMeterUser(customerId);
        }
        else {
            await reloadMeter();
            reloadGateway();
        }
        if (localStorage.getItem('meterScreenSort') != null) {
            meterScreenSort = JSON.parse(localStorage.getItem('meterScreenSort'))
        }
        if (meterScreenSort && meterScreenSort.field != null && meterScreenSort.ascFlag != undefined) {
            asecDsec(meterScreenSort.field, meterScreenSort.ascFlag, meters);
        }
        reloadMode();
        /*
        let mInterval = setInterval(updateProgressMeters, 5000);
         setMyInterval(mInterval); 
        return ()=>{
         clearInterval(myInterval)
        }*/
    }, [globalSelect, globalSelectOrganization])
    // sorting    
    let asecDsec = (field, flag, items) => {
        let toggle = flag;
        if (flag == undefined) {
            toggle = 1
        }
        localStorage.setItem("meterScreenSort", JSON.stringify({ ascFlag: toggle, field: field }));
         setSortingField(field);
         setSortingToggle(toggle);
        let sorted = SortingByColumn(field, items, toggle, setFlag)
        setState({ ...state, items: sorted });
        setOriginalData({ ...originalData, items: sorted });
    }

    let subHeading = " Select Meter (" + meterUser.first_name + ")";
    let reloadGateway = async () => {
        let gateway = await getGateway();
        if (gateway == "Unauthorized") {
            toast.error(ToastLayout("Your session has expired!"))
            localStorage.clear();
            history.push("/login")
        }
        setGatewayItems(gateway)
    }
    let getMeterUser = async (customerId) => {
        let userMeter = await getCustomerById(customerId);
        setMeteruser(userMeter);
    }
    let reloadMeter = async () => {
        //Global filter Installation: Any one installation selected as option 
        //Global filter Organization: All organizations selected as option
        if ((globalSelect.installation_id != 0 && globalSelect.installation_id != undefined) &&
            (globalSelectOrganization.organization_id == 0 || globalSelectOrganization.organization_id == undefined)) {
            meters = await getMeterByGroup(globalSelect.installation_id);
            if (meters == "Unauthorized") {
                toast.error(ToastLayout("Your session has expired!"))
                localStorage.clear();
                history.push("/login")
            }
            setState({ ...state, items: meters });
            setOriginalData({ ...originalData, items: meters });
        }
        //Global filter Installation: All installations selected as option 
        //Global filter Organization: Any one organization selected as option
        else if ((globalSelect.installation_id == 0 || globalSelect.installation_id == undefined) &&
            (globalSelectOrganization.organization_id != 0 && globalSelectOrganization.organization_id != undefined)) {
            meters = await getMeterInOrganization(globalSelectOrganization.organization_id);
            if (meters == "Unauthorized") {
                toast.error(ToastLayout("Your session has expired!"))
                localStorage.clear();
                history.push("/login")
            }
            setState({ ...state, items: meters });
            setOriginalData({ ...originalData, items: meters });
        }
        //Global filter Installation: Any one installation selected as option 
        //Global filter Organization: Any one organization selected as option
        else if ((globalSelect.installation_id != 0 && globalSelect.installation_id != undefined) &&
            (globalSelectOrganization.organization_id != 0 && globalSelectOrganization.organization_id != undefined)) {
            let record = {
                organizationId: globalSelectOrganization.organization_id,
                deviceGroupId: globalSelect.installation_id
            }
            meters = await getMeterInstallationOrg(record);
            if (meters == "Unauthorized") {
                toast.error(ToastLayout("Your session has expired!"))
                localStorage.clear();
                history.push("/login")
            }
            setState({ ...state, items: meters });
            setOriginalData({ ...originalData, items: meters });
        }
        //Global filter Installation: All installations selected as option 
        //Global filter Organization: All organizations selected as option
        else {
            meters = await getMeter();
            if (meters == "Unauthorized") {
                toast.error(ToastLayout("Your session has expired!"))
                localStorage.clear();
                history.push("/login")
            }
            let sorted = SortingByColumn(sortingField, meters, sortingToggle, setFlag)
            setState({ ...state, items: sorted });
            // setState({ ...state, items: meters });
            setOriginalData({ ...originalData, items: meters });
        }
    }
    const [modeData, setModeData] = useState([]);
    let reloadMode = async () => {
        let records = await getMode();
        setModeData(records);
    }
    let reloadMeterByCustomer = async (id) => {
        meters = await getMeterByCustomer(id);
        if (meters == "Unauthorized") {
            toast.error(ToastLayout("Your session has expired!"))
            localStorage.clear();
            history.push("/login")
        }
        setState({ ...state, items: meters });
    }
    let doEdit = (serial) => {
        history.push("/meters-edit/" + serial);
    }

    // get the details in table when onclick on search
    const searchDetailsCall = async (fieldValue, dataValue) => {
        const searchParams = {
            field: fieldValue,
            data: dataValue
        };
        if (getUserRole() == "super-admin") {
            if (globalSelectOrganization.organization_id != 0 && globalSelectOrganization.organization_id != undefined) {
                searchParams.organizationId = globalSelectOrganization.organization_id
            } else {
                searchParams.organizationId = null
            }
            if(globalSelect.installation_id != 0 && globalSelect.installation_id != undefined){
                searchParams.installationId = globalSelect.installation_id
            }else{
                searchParams.installationId = null
            }
        } else {
            searchParams.organizationId = organization
        }
        let searchItems = await searchMeter(searchParams);
        setState({ ...state, items: searchItems });
    }
    const backButton = async () => {
        history.push('/customer')
    }
    const noMetersFound = () => {
        if (typeof (customerId) !== 'undefined') {
        if (state.items.length == 0) {
            return (
                <div style={{ marginLeft: "5%" }}>No meters found for this customer </div>
            );
        } else {
            return null;
        }
    }
    }
    //Clear the details
    const clearDetails = async () => {
        setState({ ...state, items: originalData.items});
        searchInput.current.value = "";
    }

    return (
        <div>
             <ToastContainer />
            <Container fluid>
                <Row>
                    <Col md="12">
                        <Card >
                            <SubItemHeading heading={subHeading} backButtonCall={backButton} show={isCustomerMeters} />
                            {noMetersFound()}
                            <Card.Header>
                                <SearchLayout
                                    searchInput={searchInput}
                                    searchOptions={searchOptions}
                                    doSearch={searchDetailsCall}
                                    clearDetails={clearDetails}
                                    addButtonCallback={() => { history.push("/meters-add") }}
                                    isAddButtonEnable={enableAddButton()}
                                    flag={true}
                                    showHide={!isCustomerMeters}
                                    handleShow={handleShow}
                                />
                            </Card.Header>
                            <Card.Body className="table-full-width table-responsive px-0" style={{ marginTop: "0px", paddingTop: "0px" }}>
                                <SelectPackage
                                    meterUser={meterUser}
                                    items={state.items}
                                    enableAction={!isCustomerMeters}
                                    editMeter={doEdit}
                                    customerId={customerId}
                                    modeData={modeData}
                                    setState={setState}
                                    state={state}
                                    reloadMeter={reloadMeter}
                                    asecDsec={asecDsec}
                                    flag={flag}
                                />
                                <AddMeterFromGatewayModal
                                    show={show}
                                    handleClose={handleClose}
                                    setGatewayItems={setGatewayItems}
                                    gatewayItems={gatewayItems}
                                    selectedGateway={selectedGateway}
                                    setSelectedGateway={setSelectedGateway}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                </Row>
            </Container>
        </div>
    );
}