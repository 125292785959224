import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router'
import { getPackages, addPackage, getPackageById, updatePackage, deletePackage } from '../../services/packageService';
import { BsArrowLeftShort } from "react-icons/bs";
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
export default function SubHeading({heading, backButtonCall, show }) {
    if(show){
        return(
            <div className='display-back-button'>
                <div><button className="back" data-toggle="tooltip" title="click to go back" onClick={() => { backButtonCall() }} ><BsArrowLeftShort /></button></div>
                <div className="form-header-container form-header" style={{ marginTop: "05px" }}>
                <center>{heading}</center>
            </div>
            </div>
         );
    }
    return null;
}
