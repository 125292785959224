import React, { useState, useContext, useEffect } from 'react';
import Select from 'react-select';
import {
    Modal,
    Row,
    Col,
    Card,
    Container,
    Form
} from "react-bootstrap";
import '../../assets/css/pages/table-list.css'
import { updateMeter } from '../../services/meterService';

export default function EditMeterInstallationModal({itemMeter,setItemMeter, showEditMeterModal, handleEditMeterModalClose,organizationData,userData,deviceGroupData,modeData,hardwareData,currentOrganizationName,getUserRole,getUserId,reloadAllTree,checkAddOrEdit}) {
    let handleChange = (e) => {
        let name=e.target.name
        setItemMeter((preData) => {
            preData[e.target.name]= e.target.value
            return {...preData};
        })
    }
    let checkAddInstallation = () => {
          return (<div className='display-back-button'>
          <div className="form-header-container form-header" style={{ marginTop: "05px" }}>
            <center>Edit Meter</center>
          </div>
        </div>
          );
      }
      let handleSubmit = (e) => {
            e.preventDefault();
            const newItem = {
              serial: itemMeter.serial,
              hardware_id: itemMeter.hardware_id,
              mode_id: itemMeter.mode_id,
              device_group_id: itemMeter.device_group_id,
              customer_id: itemMeter.customer_id,
              owner_organization_id: itemMeter.owner_organization_id,
              created_at: Date.now(),
              created_by: getUserId(),
              modified_at: Date.now(),
              modified_by: getUserId()
            };
             //update
              newItem.serial = itemMeter.serial;
              updateMeter(newItem);
              reloadAllTree();
              handleEditMeterModalClose();
              window.location.reload();
          }
      let getOrganizationName = () => {
        if (getUserRole() === "super-admin") {
          return (
            <Form.Group as={Row} className="mb-3" >
              <Col sm="4">
                <div className="add-responsive">Organization :</div>
              </Col>
              <Col sm="6">
                <select id={itemMeter.owner_organization_id} name="owner_organization_id" className="input-style-responsive" value={itemMeter.owner_organization_id} onChange={handleChange}>
                  <option disabled selected hidden> Choose organization </option>
                  {organizationData.orgData && organizationData.orgData.map(organization => (
                    <option value={organization.id} >{organization.organization_name} </option>
                  ))}
                </select>
              </Col>
            </Form.Group>
          )
        }
        else {
            itemMeter.owner_organization_id = currentOrganizationName && currentOrganizationName.organization_id
          return (
            <Form.Group as={Row} className="mb-3" >
              <Col sm="4">
                <div className="add-responsive">Organization :</div>
              </Col>
              <Col sm="4">
                <input
                  disabled
                  name="organization_id"
                  id={itemMeter.owner_organization_id}
                  value={currentOrganizationName && currentOrganizationName.organization_name}
                  className="input-style-responsive"
                />
              </Col>
            </Form.Group>
          )
        }
      }
    if (checkAddOrEdit=="EditMeter") {
        return (<Modal show={showEditMeterModal} onHide={handleEditMeterModalClose}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Container fluid>
                        <Row>
                            <Col md="12">
                                <Card className="striped-table-with-hover">
                                    <Card.Header>
                                    {checkAddInstallation()}
                                    </Card.Header>
                                    <Card.Body className="table-full-width table-responsive px-0">
                                        <Col lg="9">
                                            <Form>
                                                <Form.Group as={Row} className="mb-3" >
                                                    <Col sm="4">
                                                        <div className="add-responsive">Serial :</div>
                                                    </Col>
                                                    <Col sm="6">
                                                        <input
                                                            disabled
                                                            name="serial"
                                                            placeholder="Serial"
                                                            // onChange={handleChange}
                                                            value={itemMeter.serial}
                                                            className="input-style-responsive"
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-3" >
                                                    <Col sm="4">
                                                        <div className="add-responsive">Hardware Model :</div>
                                                    </Col>
                                                    <Col sm="6">
                                                        <select id={itemMeter.hardware_id} name="hardware_id" className="input-style-responsive" value={itemMeter.hardware_id} onChange={handleChange}>
                                                            <option disabled selected hidden> Choose hardware model</option>
                                                            {hardwareData.hardware.map(hardware => (
                                                                <option value={hardware.id} >{hardware.model} </option>
                                                            ))}
                                                        </select>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-3" >
                                                    <Col sm="4">
                                                        <div className="add-responsive">Mode :</div>
                                                    </Col>
                                                    <Col sm="6">
                                                        <select id={itemMeter.mode_id} name="mode_id" className="input-style-responsive" value={itemMeter.mode_id} onChange={handleChange}>
                                                            <option disabled selected hidden> Choose mode</option>
                                                            {modeData.mode.map(mode => (
                                                                <option value={mode.id} >{mode.mode} </option>
                                                            ))}
                                                        </select>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-3" >
                                                    <Col sm="4">
                                                        <div className="add-responsive">Device Group :</div>
                                                    </Col>
                                                    <Col sm="6">
                                                        <select name="device_group_id" className="input-style-responsive" value={itemMeter.device_group_id} onChange={handleChange}>
                                                            <option disabled selected hidden> Choose device group</option>
                                                            {deviceGroupData.groupData.map(deviceGroup => (
                                                                <option value={deviceGroup.id} >{deviceGroup.group_name} </option>
                                                            ))}
                                                        </select>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-3" >
                                                    <Col sm="4">
                                                        <div className="add-responsive">Customer :</div>
                                                    </Col>
                                                    <Col sm="6">
                                                        <select id={itemMeter.customer_id} name="customer_id" className="input-style-responsive" value={itemMeter.customer_id} onChange={handleChange}>
                                                            <option disabled selected hidden> Choose customer </option>
                                                            {userData.data.map(user => (
                                                                <option value={user.id} >{user.username} </option>
                                                            ))}
                                                        </select>
                                                    </Col>
                                                </Form.Group> 
                                                {getOrganizationName()}
                                                 <Form.Group as={Row} className="mb-3" >
                                                    <Col>
                                                        <div className="button-container-responsive">
                                                            <button onClick={handleSubmit} className="add-button-style">Update</button> &nbsp;&nbsp;
                                                            {/* {getDeleteButton(doDelete)}
                                                            &nbsp;&nbsp;
                                                            <button onClick={handleCancel} className="cancle-button-style">
                                                                Cancel
                                                            </button> */}
                                                        </div>
                                                    </Col>
                                                </Form.Group>
                                            </Form>
                                        </Col>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                        </Row>
                    </Container>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <br />
            </Modal.Footer>
        </Modal>
        );
    }else{
        return null;
    }
}