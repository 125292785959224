import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router'
import { getPackages, addPackage, getPackageById, updatePackage, deletePackage } from '../../services/packageService';
import { BsArrowLeftShort } from "react-icons/bs";
import Select from "react-select";
import {
  Card,
  Container,
  Row,
  Col,
  Form
} from "react-bootstrap";
import { getOrganization } from 'services/organizationService';
import { getCurrency } from 'services/currencyService';
import { getUserId } from 'services/roleService';
import { getUserType as getUserRole, getRolePermission } from '../../services/roleService'
import { me } from '../../services/userService';
import SubItemHeading from '../partials/SubItemHeading';
import ToastLayout from '../../views/partials/Toastify';
import { ToastContainer, toast } from 'react-toastify';

export default function PackageAddEdit() {
  var packages;
  const [organization, setOrganization] = useState();
  const [currency, setCurrency] = useState();
  const [state, setState] = useState({
    id: 0, bLabel: 'Add', FormLabel: 'Add'
  });
  //active radio button
  const [activeState, setActiveState] = useState({
    active:1
  });
  const history = useHistory();
  const { id } = useParams();
  let fetchPackage = async (id) => {
    packages = await getPackageById(id);
    if (typeof (id) !== 'undefined') {
      setState({ ...packages, bLabel: 'Update', FormLabel: 'Update' })
      console.log(packages.active)
      setActiveState({...activeState,active:packages.active})
    }
  }
  useEffect(async () => {
    if (typeof (id) !== 'undefined') {
      await fetchPackage(id);
    }
    reloadCurrency();
    reloadOrganization();
    getCurrentOrganizationName();
  }, [])
  //display organization name
  const [organizationData, setOrganizationData] = useState({
    orgData: []
  });
  let backButton = () => {
    history.push("/package");
  }
  let reloadOrganization = async () => {
    let orgRecord = await getOrganization();
    setOrganizationData({ ...organizationData, orgData: orgRecord });
    // console.log("organization", orgRecord)
    if (typeof (id) !== 'undefined') {
      setOrganization(orgRecord.filter((pre) => {
        if (pre.id === packages.organization_id)
          return true;
      }))
    }
  }
  //display currency name
  const [currencyData, setCurrencyData] = useState({
    data: []
  });
  let reloadCurrency = async () => {
    let record = await getCurrency();
    setCurrencyData({ ...currencyData, data: record });
    // console.log("currency",record)
    if (typeof (id) !== 'undefined') {
      setCurrency(record.filter((pre) => {
        if (pre.id === packages.currency_id)
          return true;
      }))
    }
  }
  //active button radio change
  let activeRadioChange = (e) => {
    setActiveState({
      active: e.target.value,
    });
  }
  let handleCancel = () => {
    history.push("/package");
  }
  let selectOrganization = (e) => {
    setState({ ...state, ["organization_id"]: e.id });
    setOrganization(e);
  }
  let selectCurrency = (e) => {
    setState({ ...state, ["currency_id"]: e.id });
    setCurrency(e);
  }
  let handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  }
  let handleSubmit = async (e) => {
    e.preventDefault();
    const newItem = {
      organization_id: state.organization_id,
      product_name: state.product_name,
      price: state.price,
      currency_id: state.currency_id,
      amount: state.amount,
      unit: "Wh",
      active: activeState.active
    };
    if (state.id !== 0) { //update
      newItem.id = state.id;
      await updatePackage(newItem);
      history.push("/package");
    } else { //add
      if (state.currency_id) {
        if (getUserRole() === "super-admin") {
          if (state.organization_id) {
            await addPackage(newItem);
            history.push("/package");
          } else {
            toast.error(ToastLayout("Please select organization",))
          }
        } else {
          await addPackage(newItem);
          history.push("/package");
        }
      } else {
        toast.error(ToastLayout("Please select currency",))
      }
    }
  } 
  let doDelete = async () => {
    await deletePackage({ id })
    history.push("/package");
  }
  let getDeleteButton = () => {
    const history = useHistory();
    if (getRolePermission('packages', getUserRole()) > 2) {
      if (typeof (id) !== 'undefined') {
        return (<button onClick={(e)=>{e.preventDefault();doDelete()}} className="cancle-button-style">
          Delete
        </button>);
      }
    }
  }
  const [currentOrganizationName, setCurrentOrganizationName] = useState();
  const getCurrentOrganizationName = async () => {
    let record = await me();
    setCurrentOrganizationName(...record)
  }
  let subHeading = state.FormLabel + ' Packages';
  let getOrganizationName = () => {
    if (getUserRole() === "super-admin") {
      return (
        <Form.Group as={Row} className="mb-3" >
          <Col sm="3">
            <div className="add-responsive">Organization :</div>
          </Col>
          <Col sm="9">
            <Select
              className="dropDown-style"
              value={organization}
              options={organizationData.orgData}
              getOptionLabel={e => e.organization_name}
              getOptionValue={e => e.id}
              onChange={(e) => selectOrganization(e)}
            />
          </Col>
        </Form.Group>
      )
    }
    else {
      state.organization_id = currentOrganizationName && currentOrganizationName.organization_id
      return (
        // <Form.Group as={Row} className="mb-3" >
        //   <Col sm="3">
        //     <div className="add-responsive">Organization :</div>
        //   </Col>
        //   <Col sm="9">
        //     <input
        //       disabled
        //       name="organization_id"
        //       id={state.organization_id}
        //       value={currentOrganizationName && currentOrganizationName.organization_name}
        //       className="input-style-responsive"
        //     />
        //   </Col>
        // </Form.Group>
        <div></div>
      )
    }
  }
  return (
    <div>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="striped-table-with-hover">
              <Card.Header>
                <SubItemHeading heading={subHeading} backButtonCall={backButton} show={true} />
              </Card.Header>
              <Card.Body className="px-0" >

                <Col lg="9">
                  <Form >
                    <Form.Group as={Row} className="mb-3" >
                      <Col sm="3">
                        <div className="add-responsive">Name :</div>
                      </Col>
                      <Col sm="9">
                        <input
                          name="product_name"
                          placeholder="Product name e.g:Pacote Frio 1"
                          onChange={handleChange}
                          value={state.product_name}
                          className="input-style-responsive"
                        />
                      </Col>
                    </Form.Group>
                    {getOrganizationName()}
                    <Form.Group as={Row} className="mb-3" >
                      <Col sm="3">
                        <div className="add-responsive">Price :</div>
                      </Col>
                      <Col sm="9">
                        <input
                          type="number"
                          name="price"
                          placeholder="Price"
                          onChange={handleChange}
                          value={state.price}
                          className="input-style-responsive"
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                      <Col sm="3">
                        <div className="add-responsive">Currency :</div>
                      </Col>
                      <Col sm="9">
                        <Select
                          className="dropDown-style"
                          value={currency}
                          options={currencyData.data}
                          getOptionLabel={e => e.currency}
                          getOptionValue={e => e.id}
                          onChange={(e) => selectCurrency(e)}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                      <Col sm="3">
                        <div className="add-responsive">Amount :</div>
                      </Col>
                      <Col sm="9">
                        <input
                          type="number"
                          name="amount"
                          placeholder="Amount"
                          onChange={handleChange}
                          value={state.amount}
                          className="input-style-responsive"
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" >
                      <Col sm="3">
                        <div className="add-responsive">Unit :</div>
                      </Col>
                      <Col sm="9">
                        <input
                          disabled
                          name="unit"
                          placeholder="Unit"
                          onChange={handleChange}
                          value="Wh"
                          className="input-style-responsive"
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" >
                      <Col sm="3">
                        <div className="add-responsive">Active :</div>
                      </Col>
                      <Col sm="9">
                        <div className="input-style-responsive ">
                          <input
                            type="radio"
                            name="active"
                            onChange={activeRadioChange}
                            value="1"
                            className="radiobutton"
                            checked={activeState.active == 1}
                          />
                          &nbsp;&nbsp;Yes&nbsp;&nbsp;&nbsp;&nbsp;
                          <input
                            type="radio"
                            name="active"
                            onChange={activeRadioChange}
                            value="0"
                            className="radiobutton"
                            checked={activeState.active == 0}
                          />&nbsp;&nbsp;No
                        </div>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" >
                      <Col>
                        <div className="button-container-responsive">
                        <ToastContainer />
                          <button onClick={handleSubmit} className="add-button-style">
                            {state.bLabel}
                          </button> &nbsp;&nbsp;
                          {getDeleteButton()}
                          &nbsp;&nbsp;
                          <button onClick={handleCancel} className="cancle-button-style">
                            Cancel
                          </button>
                        </div>
                      </Col>
                    </Form.Group>
                  </Form>
                </Col>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
        </Row>
      </Container>
    </div>
  );
}