import React, { useState, useContext, useEffect } from 'react';
import Select from 'react-select';
import {
    Modal,
    Row,
    Col,
    Card,
    Container,
    Form
} from "react-bootstrap";
import '../../assets/css/pages/table-list.css'
import { addPbxDevice } from '../../services/deviceService';
import { ToastContainer, toast } from 'react-toastify';
import ToastLayout from '../../views/partials/Toastify';
export default function AddPBX({ itemPBX, setItemPBX, assetType, selectedNode, handleDeviceClose, organizationData, userData, deviceGroupData, modeData, hardwareData, currentOrganizationName, getUserRole, getUserId, reloadAllTree, selectedGroupId }) {
    let handleChange = (e) => {
        let name = e.target.name
        setItemPBX((preData) => {
            preData[e.target.name] = e.target.value
            return { ...preData };
        })
    }
    let handleSubmit = (e) => {
        e.preventDefault();
        const newItem = {
            mac_address: itemPBX.mac_address,
            serial: itemPBX.serial,
            hardware_revision: itemPBX.hardware_revision,
            device_group_id: selectedNode.item.id,
            hardware_id: itemPBX.hardware_id,
            software_revision: itemPBX.software_revision,
            owner_organization_id: itemPBX.owner_organization_id,
            owner_user_id: itemPBX.owner_user_id,
            created_at: Date.now(),
            created_by: getUserId(),
            modified_at: Date.now(),
            modified_by: getUserId(),
            id: Date.now()
        };
        if (newItem.device_group_id) {
            if (newItem.hardware_id) {
                if (newItem.owner_organization_id) {
                    if (newItem.owner_user_id) {
                        addPbxDevice(newItem);
                        window.location.reload();
                    } else {
                        toast.error(ToastLayout("Please select customer"))
                    }
                } else {
                    toast.error(ToastLayout("Please select organization"))
                }
            } else {
                toast.error(ToastLayout("Please select hardware model"))
            }
        } else {
            toast.error(ToastLayout("Please select device group"))
        }
    }
    let getOrganizationName = () => {
        if (getUserRole() === "super-admin") {
            return (
                <Form.Group as={Row} className="mb-3" >
                    <Col sm="4">
                        <div className="add-responsive">Organization :</div>
                    </Col>
                    <Col sm="6">
                        <select id={itemPBX.owner_organization_id} name="owner_organization_id" className="input-style-responsive" value={itemPBX.owner_organization_id} onChange={handleChange}>
                            <option disabled selected hidden> Choose organization </option>
                            {organizationData.orgData && organizationData.orgData.map(organization => (
                                <option value={organization.id} >{organization.organization_name} </option>
                            ))}
                        </select>
                    </Col>
                </Form.Group>
            )
        }
        else {
            itemPBX.owner_organization_id = currentOrganizationName && currentOrganizationName.organization_id
            return (
                <Form.Group as={Row} className="mb-3" >
                    <Col sm="4">
                        <div className="add-responsive">Organization :</div>
                    </Col>
                    <Col sm="4">
                        <input
                            disabled
                            name="organization_id"
                            id={itemPBX.owner_organization_id}
                            value={currentOrganizationName && currentOrganizationName.organization_name}
                            className="input-style-responsive"
                        />
                    </Col>
                </Form.Group>
            )
        }
    }
    return (<Card.Body className=" px-0">
        <Col lg="9">
            <Form >
                <Form.Group as={Row} className="mb-3" >
                    <Col sm="4">
                        <div className="add-responsive">Mac Address :</div>
                    </Col>
                    <Col sm="6">
                        <input
                            name="mac_address"
                            placeholder="Mac address"
                            onChange={handleChange}
                            value={itemPBX.mac_address}
                            className="input-style-responsive"
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" >
                    <Col sm="4">
                        <div className="add-responsive">Serial :</div>
                    </Col>
                    <Col sm="6">
                        <input
                            name="serial"
                            placeholder="Serial"
                            onChange={handleChange}
                            value={itemPBX.serial}
                            className="input-style-responsive"
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" >
                    <Col sm="4">
                        <div className="add-responsive">Hardware Version :</div>
                    </Col>
                    <Col sm="6">
                        <input
                            name="hardware_revision"
                            placeholder="Hardware version"
                            onChange={handleChange}
                            value={itemPBX.hardware_revision}
                            className="input-style-responsive"
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" >
                    <Col sm="4">
                        <div className="add-responsive">Software Version :</div>
                    </Col>
                    <Col sm="6">
                        <input
                            name="software_revision"
                            placeholder="Software version"
                            onChange={handleChange}
                            value={itemPBX.software_revision}
                            className="input-style-responsive"
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" >
                    <Col sm="4">
                        <div className="add-responsive">Device Group :</div>
                    </Col>
                    <Col sm="6">
                        <input
                            disabled
                            name="device_group_id"
                            // onChange={handleChange}
                            defaultValue={selectedNode && selectedNode.item.group_name}
                            // value={itemMeter.serial}
                            className="input-style-responsive"
                        />
                        {/* <select id={itemPBX.device_group_id} name="device_group_id" className="input-style-responsive" value={selectedNode && selectedNode.item.group_name} onChange={handleChange}>
                       <option disabled selected hidden> Choose device group</option>
                       {deviceGroupData.groupData.map(deviceGroup => (
                           <option value={deviceGroup.id} >{deviceGroup.group_name} </option>
                       ))}
                   </select> */}
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" >
                    <Col sm="4">
                        <div className="add-responsive">Hardware Model :</div>
                    </Col>
                    <Col sm="6">
                        <select id={itemPBX.hardware_id} name="hardware_id" className="input-style-responsive" value={itemPBX.hardware_id} onChange={handleChange}>
                            <option disabled selected hidden> Choose hardware model</option>
                            {hardwareData.hardware.map(hardware => (
                                <option value={hardware.id} >{hardware.model} </option>
                            ))}
                        </select>
                    </Col>
                </Form.Group>
                {getOrganizationName()}
                <Form.Group as={Row} className="mb-3" >
                    <Col sm="4">
                        <div className="add-responsive">Customer :</div>
                    </Col>
                    <Col sm="6">
                        <select id={itemPBX.owner_user_id} name="owner_user_id" className="input-style-responsive" value={itemPBX.owner_user_id} onChange={handleChange}>
                            <option disabled selected hidden> Choose customer </option>
                            {userData.data.map(user => (
                                <option value={user.id} >{user.username} </option>
                            ))}
                        </select>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" >
                    <Col>
                        <div className="button-container-responsive">
                            <ToastContainer />
                            <button onClick={handleSubmit} className="add-button-style">
                                Add
                            </button> &nbsp;&nbsp;
                            {/* {getDeleteButton(doDelete)}
                       &nbsp;&nbsp;
                       <button onClick={handleCancel} className="cancle-button-style">
                           Cancel
                       </button> */}
                        </div>
                    </Col>
                </Form.Group>
            </Form>
        </Col>
    </Card.Body>
    );
}