import React, { useState, useContext, useEffect } from 'react';
import {
    Modal,
    Card,
    Container
} from "react-bootstrap";
import '../../assets/css/pages/table-list.css'
import Switch from "react-switch";
import { setPermissions } from 'services/user_permission_on_installationService';

export default function PermissionModel({ showPermission, handlePermissionClose, userData, userPermissions, setUsers }) {
    const handleOnChecked = (ichecked, event, Id) => {
        setUsers(preState => {
            let temp = [...preState];
            temp[temp.findIndex(pre => pre.id == Id)]["isPermission"] = ichecked;
            setPermissions(temp[temp.findIndex(pre => pre.id == Id)]);
            preState = temp;
            return preState;
        });
    }
    return (
        <Modal show={showPermission} onHide={handlePermissionClose}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Header>
                <h5 className="form-header-container form-header">Installation Permissions: {(userData.length > 0) ? userData[0].group_name : null} </h5>
            </Modal.Header>
            <Modal.Body >
                <Container>
                    <Card >
                        <ol className="collection collection-container" style={{ marginTop: "0px", paddingTop: "0px" }}>
                            <li className="item item-container table-header">
                                <div className="attribute-container information">
                                    <div className="attribute-container amount-edit">
                                        <div className="attribute" data-name="User">User</div>
                                        <div className="attribute" data-name="User">Role</div>
                                    </div>
                                </div>
                                <div className="attribute-container amount-edit">
                                    <div className="attribute" data-name="Permission">Permission</div>
                                </div>
                            </li>
                            {(userData.length > 0) ?
                                <div>
                                    {userData && userData.map((item, index) => {
                                        return (
                                            <li className="item item-container hover" key={index}>
                                                <div className="attribute-container information">
                                                    <div className="attribute-container amount-edit">
                                                        <div className="attribute" data-name="User">{item.first_name} {item.last_name}</div>
                                                        <div className="attribute" data-name="Installation">{item.role_type}</div>
                                                    </div>
                                                </div>
                                                <div className="attribute-container amount-edit">
                                                    <div className="attribute" data-name="Permission">
                                                        <Switch key={item.id} id={item.id} onChange={handleOnChecked} checked={item.isPermission == true ? true : false} />
                                                    </div>
                                                </div>
                                            </li>)
                                    })}
                                </div>
                                :
                                <div className='no-data-available-text'>No Record Found</div>
                            }
                        </ol>
                    </Card>
                </Container>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    );
}