import React, { useState, useContext, useEffect } from 'react';
import Select from 'react-select';
import { useHistory } from 'react-router';
import { uuid } from '../../services/CommonUtils';
import {
   Modal,
   Row,
   Col,
   Card,
   Container,
   Form
} from "react-bootstrap";
import '../../assets/css/pages/table-list.css'
import {updateMeter} from '../../services/meterService';
//Vivek: packages should be selected based on org , 
//Vivek: meter owned or OrgX should packages offer by OrgX
export default function MeterStatusModal({ meterStatus, showMeterStatus, handleMeterClose, modeData }) {
   const onSelect = async (e) => {
      let meter = await updateMeter({"serial":meterStatus.serial,"mode_id":e.id})
  }
   return (<Modal show={showMeterStatus} onHide={handleMeterClose}>
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
         <div>
            <Form>
               <Col md="12">
                  <Form.Group as={Row} className="mb-3" >
                     <Col sm="5">
                        <div className="add-responsive-modal">Meter Id :</div>
                     </Col>
                     <Col sm="6">
                        <div className="input-style-modal">{meterStatus && meterStatus.serial}</div>
                     </Col>
                  </Form.Group>
               </Col>
               <Col md="12">
                  <Form.Group as={Row} className="mb-3" >
                     <Col sm="5">
                        <div className="add-responsive-modal">Organization :</div>
                     </Col>
                     <Col sm="6">
                        <div className="input-style-modal">{meterStatus && meterStatus.organization_name}</div>
                     </Col>
                  </Form.Group>
               </Col>
               <Col md="12">
                  <Form.Group as={Row} className="mb-3" >
                     <Col sm="5">
                        <div className="add-responsive-modal">Mode :</div>
                     </Col>
                     <Col sm="6">
                        {/* <div className="input-style-modal">{meterStatus && meterStatus.mode}</div> */}
                        {/* <select id={meterStatus && meterStatus.mode_id} name="organization_id" value={meterStatus && meterStatus.mode_id}>
                           {modeData.map(meter => (
                              <option value={meter.id} >{meter.mode}</option>
                           ))}
                        </select> */}
                        <Select
                           defaultValue={meterStatus && meterStatus}
                           options={modeData}
                           getOptionLabel={e => e.mode}
                           getOptionValue={e => e.id}
                           // onChange={(e) => onSelect(e)}
                        />
                     </Col>
                  </Form.Group>
               </Col>
            </Form>
         </div>
      </Modal.Body>
      <Modal.Footer>
         <br />
      </Modal.Footer>
   </Modal>
   );
}