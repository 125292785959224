import Dashboard from "views/Dashboard.js";
import Icons from "views/Icons.js";
import UserList from "views/users/User";
import MeterList from "views/meters/Meter";
import PaymentList from "views/payments/Payment";
import BussinessAcntList from "views/vendor/Vendor";
import PackageList from "views/packages/Package";
import DeviceList from "views/device/Devices";
import OrganizationList from "views/organization/organization";
import GatewayList from "views/gateway/Gateway";
import NodeList from "views/node/Node";
import WalletUserList from "views/wallet-user/WalletUser";
import { roleTypes as allRoles } from "services/roleService";
import { GiOrganigram, GiWifiRouter } from 'react-icons/gi';
import { FiUsers,FiUser,FiShoppingCart } from 'react-icons/fi';
import { BsSpeedometer2 } from 'react-icons/bs';
import { VscTypeHierarchy } from 'react-icons/vsc';
import { BsGear} from 'react-icons/bs';
import { BsWallet2} from 'react-icons/bs';
import { MdOutlineRouter } from 'react-icons/md';
import { RiInstallLine } from 'react-icons/ri';
import { IoSpeedometerOutline } from 'react-icons/io5';
import { BiChip } from "react-icons/bi";
import { SiSocketdotio } from "react-icons/si";
import CustomerList from './views/users/Customer'
import HardwareList from "views/hardware/Hardware";
import InstallationList from "views/installation/Installation";

const getDashBoardRoutes = () =>( [
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   icon: "nc-icon nc-chart-pie-35",
  //   component: Dashboard,
  //   roles: allRoles
  // },
  {
    path: "/organization",
    name: "Organizations",
    themeicon: <GiOrganigram />,
    component: OrganizationList,
    roles: ['super-admin']
  },
  {
    path: "/user",
    name: "Users",
    themeicon: <FiUsers />,
    component: UserList,
    roles: ['super-admin','admin', 'manager']
  },
  {
    path: "/customer",
    name: "Customers",
    themeicon: <FiUser />,
    component: CustomerList,
    roles: ['super-admin', 'admin', 'manager', 'sales-agent', 'technician', 'operator']
  },
  {
    path: "/meter-wallet",
    name: "Meter Wallet",
    themeicon: <FiUsers />,
    component: ()=>(null),
    roles: []
  },
  {
    path: "/user-wallet-history",
    name: "User Wallet History",
    themeicon: <FiUsers />,
    component: ()=>(null),
    roles: []
  },
  
  {
    path: "/add-money-wallet",
    name: "User Wallet",
    themeicon: <FiUsers />,
    component: ()=>(null),
    roles: []
  },
  {
    path: "/wallet-user",
    name: "User Wallet",
    themeicon: <BsWallet2 />,
    component: WalletUserList,
    roles: ['sales-agent', 'operator']
  },
  {
    path: "/payment",
    name: "Transactions",
    icon: "nc-icon nc-money-coins",
    component: PaymentList,
    roles: ['super-admin', 'admin', 'manager', 'operator', 'customer']
  },  
  {
    path: "/package",
    name: "Packages",
    themeicon: <FiShoppingCart />,
    component: PackageList,
    roles: ['super-admin', 'admin', 'manager', 'sales-agent', 'operator', 'customer']
  },
  {
    path: "#",
    name: "Assets",
    icon: "nc-icon nc-layers-3",
    roles: allRoles,
    subitem: [
      {
        path: "/gateway",
        name: "Gateways",
        themeicon: <MdOutlineRouter />,
        component: GatewayList,
        roles: ['super-admin', 'admin', 'manager','technician','operator']
      },
      {
        path: "/node",
        name: "Node",
        themeicon: <VscTypeHierarchy />,
        component: NodeList,
        roles: ['super-admin', 'admin', 'manager','technician','operator']
      },
      {
        path: "/meters",
        name: "Meters",
        themeicon: <BsSpeedometer2 />,
        component: MeterList,
        roles: allRoles

      },
      {
        path: "/pbx-device",
        name: "PBX Devices",
        icon: "nc-icon nc-app",
        component: DeviceList,
        roles: ['super-admin', 'admin', 'manager', 'sales-agent', 'technician', 'operator']
      },
    ]
  },

  {
    path: "/installation",
    name: "Installations",
    icon: "nc-icon nc-square-pin",
    component: InstallationList,
    roles: ['super-admin', 'admin', 'manager', 'sales-agent', 'technician', 'operator']
  },
  
  {
    path: "/vendor",
    name: "Vendors",
    icon: "nc-icon nc-delivery-fast",
    component: BussinessAcntList,
    roles: ['super-admin']
  },
  {
    path: "/hardware",
    name: "Hardware",
    themeicon: <BsGear />,
    component: HardwareList,
    roles: ['super-admin', 'admin', 'manager', 'technician', 'operator']
  },
]);

//const dashboardRoutes = defaultRoutes;
export default getDashBoardRoutes;