import React, { useState, useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router'
import Select from 'react-select';
import {
  Modal,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  InputGroup,
  FormControl,
  Container,
  Card
} from "react-bootstrap";
import { Input } from 'reactstrap';
import '../../assets/css/pages/table-list.css'
import { getWalletByUser } from '../../services/walletUserService';
import * as moment from 'moment'
import { getCurrencyNameById,getAllCurrency } from 'services/currencyService';
import { getUserById } from '../../services/userService';
import SubItemHeading from '../partials/SubItemHeading';
import { BiSort } from 'react-icons/bi';
import SortingByColumn from 'views/partials/SortingByColumn';
export default function MeterWallet() {
  var meterWalletScreenSort;
  var meterWallet;
  let history = useHistory();
  const [flag, setFlag] = useState();
  const { userId } = useParams();
  const [walltetRecords, setWalltetRecords] = useState([])
  const [user, setUser] = useState({})
  useEffect(async () => {
    await getWalletRecordById(userId);
    if (localStorage.getItem('meterWalletScreenSort') != null) {
      meterWalletScreenSort = JSON.parse(localStorage.getItem('meterWalletScreenSort'))
    }
    if (meterWalletScreenSort && meterWalletScreenSort.field != null && meterWalletScreenSort.ascFlag != undefined) {
      asecDsec(meterWalletScreenSort.field, meterWalletScreenSort.ascFlag, meterWallet);
    }
  }, [])
  // sorting    
  let asecDsec = (field, flag, items) => {
    let toggle = flag;
    if (flag == undefined) {
      toggle = 1
    }
    localStorage.setItem("meterWalletScreenSort", JSON.stringify({ ascFlag: toggle, field: field }));
    let sorted = SortingByColumn(field, items, toggle, setFlag)
    setWalltetRecords(sorted)
  }
  let getWalletRecordById = async (userId) => {
    meterWallet = await getWalletByUser(userId);
    let user = await getUserById(userId);
    setWalltetRecords(meterWallet);
    setUser(user);
  }
  
  return (
    <div>
      <Container fluid>
        <Row>
          <Col sm={12}>
            <Card style={{ boxShadow: '0 2px 10px -1px rgb(69 90 100 / 30%)' }}>
              <Card.Header>
                <SubItemHeading heading={"User : " + user.first_name} backButtonCall={() => { history.goBack() }} show={true} />
                {/* <SearchLayout
                    searchOptions={searchOptions}
                    doSearch={searchDetails}
                    clearDetails={clearDetails}
                    addButtonCallback={() => { history.push("/organization-add") }}
                    // isAddButtonEnable={enableAddButton()} 
                    showHide={true}
                  /> */}
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0" style={{ marginTop: "0" }}>
                <DisplayMeterWalletList walltetRecords={walltetRecords}
                asecDsec={asecDsec}
                flag={flag}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
        </Row>
      </Container>
    </div>
  );
}
function DisplayMeterWalletList({ walltetRecords,asecDsec,flag }) {
  return (
    <div>
      <ol className="collection collection-container">
        <li className="item item-container table-header">
          <div className="attribute-container information">
            <div className="attribute-container name-organization">
              <div className="attribute" data-name="Create Date">TXN Date
                <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("timestamp", flag, walltetRecords) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
              </div>
              <div className="attribute" data-name="Amount">TXN Amount
                <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("amount", flag, walltetRecords) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
              </div>
            </div>
            <div className="attribute-container price-currency">
              {/* <div className="attribute" data-name="Currency">Currency
                <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("currency", flag, walltetRecords) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
              </div> */}
              <div className="attribute" data-name="Balance">Balance
                <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("balance", flag, walltetRecords) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
              </div>
              <div className="attribute" data-name="Source">Source
                <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("source", flag, walltetRecords) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
              </div>
            </div>
          </div>
          <div className="attribute-container amount-edit">
            <div className="attribute" data-name="Provider">Provider
              <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("provider", flag, walltetRecords) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
            </div>
            <div className="attribute" data-name="Reference">Reference
              <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("reference", flag, walltetRecords) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
            </div>
            {/* {getAction()} */}
          </div>
        </li>
        {(walltetRecords.length > 0) ?
          <div>
            {walltetRecords.map((item) => {
              return (<li key={item.id} className="item item-container hover">
                <div className="attribute-container information">
                  <div className="attribute-container price-currency">
                    <div className="attribute" data-name="Date">{moment(item.timestamp).format('DD-MMM hh:mm:ss A')}</div>
                    <div className="attribute" data-name="Amount">{item.amount}</div>
                  </div>
                  <div className="attribute-container name-organization">
                    {/* <div className="attribute" data-name="Currency"> {getCurrencyNameById(item.currency_id)}</div> */}
                    <div className="attribute" data-name="Balance">{item.balance}</div>
                    <div className="attribute" data-name="Source">{item.source}</div>
                  </div>
                </div>
                <div className="attribute-container amount-edit">
                  <div className="attribute" data-name="Provider">{item.provider}</div>
                  <div className="attribute" data-name="Reference">{item.reference}</div>
                </div>
              </li>)
            })}
          </div>
          :
          <div className="no-data-available-text">No Records Found</div>
        }
      </ol>
    </div>
  );
}

