import React, { useState, useContext, useEffect } from 'react';
import {useHistory, useParams } from 'react-router'
import Select from 'react-select';
import SearchLayout from '../partials/SearchLayout';
import SubItemHeading from '../partials/SubItemHeading';
import {
    Modal,
    Row,
    Col,
    OverlayTrigger,
    Tooltip,
    InputGroup,
    FormControl,
    Container,
    Card
} from "react-bootstrap";
import { Input } from 'reactstrap';
import '../../assets/css/pages/table-list.css'
import { getMeterWalletBySerial,searchMeterwallet,getMeterWallet} from '../../services/meterService';
import * as moment from 'moment'
import { getUserType as getUserRole, getRolePermission } from '../../services/roleService';

const enableAddButton = () => (getRolePermission('meters', getUserRole()) > 1);
const searchOptions = {
  searchValues:["modified_at","energy_meter_id","amount","balance","reference"], //same len as values
  searchNames:['TXN Date','Meter Id','TXN Amount','Balance','Reference'], 
}
export default function MeterWallet() {
    const history = useHistory();
    const {serial} = useParams();
    const [meterUser, setMeteruser] = useState([])
    const [clearField, setClearField] = useState(true);
    const subHeading = "Meter: "+serial;
    useEffect(() => {
        getMeterWalletById(serial);
    }, [])

    let getMeterWalletById = async (serial) => {
        let meterWallet = await getMeterWalletBySerial(serial);
        setMeteruser(meterWallet);
    }
     // get the details in table when onclick on search
     const searchDetails = async (fieldValue, dataValue) => {
      const searchParams = {
          field: fieldValue,
          data: dataValue
      };
      let meterWallet = await searchMeterwallet(searchParams,serial);
      console.log("search",meterWallet)
      setMeteruser(meterWallet);
      
  }

//Clear the details
const clearDetails = async (serial) => {
  if (!clearField) {
      let meterWallet = await getMeterWalletBySerial(serial);
      setMeteruser(meterWallet);
  } 
}
  
    return (  
        <div>
        <Container fluid>
          <Row>
            <Col sm={12}>
              <Card style={{ boxShadow: '0 2px 10px -1px rgb(69 90 100 / 30%)' }}>
                <Card.Header>
                    <SubItemHeading heading={subHeading} backButtonCall={()=>{history.goBack()}} show={true}/>
                    
                  {/* No need search here 
                  <br/> <SearchLayout
                    searchOptions={searchOptions}
                    doSearch={searchDetails}
                    clearDetails={clearDetails}
                    showHide={true}
                  />*/ }
                </Card.Header>
                <Card.Body className="table-full-width table-responsive px-0" style={{ marginTop: "0" }}>
                  <DisplayMeterWalletList meterUser={meterUser}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
          </Row>
        </Container>
      </div>
    );
}
function DisplayMeterWalletList({ meterUser }) {
    return (
      <div>
        <ol className="collection collection-container">
          <li className="item item-container table-header">
            <div className="attribute-container information">
              <div className="attribute-container name-organization">
                <div className="attribute" data-name="Create Date">TXN Date</div>
                <div className="attribute" data-name="Meter Id">Meter Id</div>
              </div>
              <div className="attribute-container price-currency">
                <div className="attribute" data-name="Amount">Amount</div>
                <div className="attribute" data-name="Balance">Balance </div>
                <div className="attribute" data-name="Balance">Total Energy</div>
              </div>
            </div>
            <div className="attribute-container amount-edit">
            <div className="attribute" data-name="Reference">Reference</div>
              {/* {getAction()} */}
            </div>
          </li>
          {(meterUser.length > 0) ?
            <div>
              {meterUser.map((item) => {
                return (<li key={item.id} className="item item-container hover">
                  <div className="attribute-container information">
                    <div className="attribute-container name-organization">
                      <div className="attribute" data-name="Date">{moment(item.timestamp).format('DD-MMM hh:mm:ss A')}</div>
                      <div className="attribute" data-name="Meter Id">{item.energy_meter_id}</div>
                    </div>
                    <div className="attribute-container price-currency">
                      <div className="attribute" data-name="Amount">{item.amount} WH</div>
                      <div className="attribute" data-name="Balance">{item.balance} WH</div>
                      <div className="attribute" data-name="TotalEnergy">{item.total_energy} WH</div>
                    </div>
                  </div>
                  <div className="attribute-container amount-edit">
                    <div className="attribute" data-name="Actions">
                    <div className="attribute" data-name="Reference">{item.reference}</div>
                      {/* {getEditButton(item.id)} */}
                    </div></div>
                </li>)
              })}
            </div>
            :
            <div className="no-data-available-text">No Records Found</div>
          }
        </ol>
      </div>
    );
  }
  
  