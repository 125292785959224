import { apiUrl } from "./globalService";
import { getUserToken } from "./roleService";
let apiPackage = apiUrl + "package";

export const getPackagesByOrg = async(orgId) => {
  ///api/package/org/:id
  return fetch(apiPackage+"/org/"+orgId, {
    method: 'get',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    }
  })
    .then(response => response.json())
    .then(response => {
      if(response=='unauthorized'){
        alert("anauthorized")
      }
      return response;
    }).catch(function (error) {
      console.log(error);
      return "Unauthorized"
    });
}

export const getPackages = async() => {
  return fetch(apiPackage, {
    method: 'get',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    }
  })
    .then(response => response.json())
    .then(response => {
      if(response=='unauthorized'){
        alert("anauthorized")
      }
      return response;
    }).catch(function (error) {
      console.log(error);
      return "Unauthorized"
    });
}
export const addPackage = async (packages) => {
  return fetch(apiPackage, {
    method: 'post',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify(packages)
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}
export const deletePackage = async ({ id }) => {
  return fetch(apiPackage + "/" + id, {
    method: 'delete',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify({ id })
  })
    .then(response => response.json())
    .then(response => {
      alert(response.msg)
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}
export const getPackageById = async (id) => {
  return fetch(apiPackage + "/" + id, {
    method: 'get',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    }
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}
export const updatePackage = async (packages) => {
  return fetch(apiPackage, {
    method: 'put',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify(packages)
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}
export const searchPackage = async(packages) => {
  return fetch(apiPackage+"/search", {
    method: 'post',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify(packages)
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}