export default function SortingByColumn(field, items,flag,setFlag) {
        var sort;
        if (items.length > 0) {
            if (flag == 1) {
                sort = "asc"
                setFlag(0)
                items.sort(dynamicSort(field, sort));
            } else{
                sort = "desc"
                setFlag(1)
                items.sort(dynamicSort(field, sort));
            }
        }
        return items;
    }
    
    const dynamicSort = (property, order) => {
        var sortOrder = 1
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            let result;
            if (order == "asc") {
                if (isNumber(a[property]) && isNumber(b[property])) {
                    result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
                } else if (a[property] != null && b[property] != null) {
                    result = (a[property].toLowerCase() < b[property].toLowerCase()) ? -1 : (a[property].toLowerCase() > b[property].toLowerCase()) ? 1 : 0;
                } else if (a[property] != null && b[property] == null) {
                    result = -1;
                } else if (a[property] == null && b[property] != null) {
                    result = 1;
                } else {
                    result = 0;
                }
            } else {
                if (isNumber(a[property]) && isNumber(b[property])) {
                    result = (a[property] < b[property]) ? 1 : (a[property] > b[property]) ? -1 : 0;
                } else if (a[property] != null && b[property] != null) {
                    result = (a[property].toLowerCase() < b[property].toLowerCase()) ? 1 : (a[property].toLowerCase() > b[property].toLowerCase()) ? -1 : 0;
                } else if (a[property] != null && b[property] == null) {
                    result = 1;
                } else if (a[property] == null && b[property] != null) {
                    result = -1;
                } else {
                    result = 0;
                }
            }
            return result * sortOrder;
        }
    }
    const isNumber = (value) => {
        let tempValue = parseInt(value);
        if (Number.isInteger(tempValue)) {
            return true;
        } else {
            return false;
        }
    }
  