import { apiUrl } from "./globalService";
import { getUserToken } from "./roleService";
let apiNode= apiUrl + "node";
let apiNodeInGateway= apiUrl + "gateway-group-node";
let apiNodeInOrganization = apiUrl + "node-from-organization";
let apiNodeInstallationOrg = apiUrl + "node-installation-organization";
let apiNodeMacAddress = apiUrl + "get-node-mac-address";

export let getNode= () => {
    return fetch(apiNode, {
        method: 'get',
        headers: {
            'Authorization' : getUserToken(),
            'Content-Type': 'application/json;charset=utf-8'
        }
    })
        .then(response => response.json())
        .then(response => {
            return response;
        }).catch(function (error) {
            console.log(error);
            return "Unauthorized"
        });
} 
export let getNodeInOrganization = (orgId) => {
  return fetch(apiNodeInOrganization + "/" + orgId, {
    method: 'get',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
      return "Unauthorized"
    });
}
export let getNodeMacAddress= () => {
  return fetch(apiNodeMacAddress, {
      method: 'get',
      headers: {
          'Authorization' : getUserToken(),
          'Content-Type': 'application/json;charset=utf-8'
      }
  })
      .then(response => response.json())
      .then(response => {
          return response;
      }).catch(function (error) {
          console.log(error);
          return "Unauthorized"
      });
} 
export let getNodeInstallationOrg = (records) => {
  return fetch(apiNodeInstallationOrg, {
    method: 'post',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify(records)
  })
    .then(response => response.json())
    .then(response => {
      console.log("response:::",response.msg)
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}
export let getNodeInGateway = (groupId) => {
    return fetch(apiNodeInGateway + "/" + groupId, {
      method: 'get',
      headers: {
        'Authorization' : getUserToken(),
        'Content-Type': 'application/json;charset=utf-8'
      }
    })
      .then(response => response.json())
      .then(response => {
        return response;
      }).catch(function (error) {
        console.log(error);
      });
  }
  export let getNodeByMacAddress = (mac_address) => {
    return fetch(apiNode + "/" + mac_address, {
      method: 'get',
      headers: {
        'Authorization' : getUserToken(),
        'Content-Type': 'application/json;charset=utf-8'
      }
    })
      .then(response => response.json())
      .then(response => {
        return response;
      }).catch(function (error) {
        console.log(error);
      });
  }
  export let addNode = (records) => {
    return fetch(apiNode, {
      method: 'post',
      headers: {
        'Authorization' : getUserToken(),
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify(records)
    })
      .then(response => response.json())
      .then(response => {
        console.log("response>>",response);
        return response;
      }).catch(function (error) {
        console.log(error);
      });
  }
  export let deleteNode= async ({ mac_address }) => {
    return fetch(apiNode + "/" + mac_address, {
      method: 'delete',
      headers: {
        'Authorization' : getUserToken(),
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify({ mac_address })
    })
      .then(response => response.json())
      .then(response => {
        return response;
      }).catch(function (error) {
        console.log(error);
      });
  }
  export let updateNode = (records) => {
    return fetch(apiNode, {
      method: 'put',
      headers: {
        'Authorization' : getUserToken(),
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify(records)
    })
      .then(response => response.json())
      .then(response => {
        return response;
      }).catch(function (error) {
      });
  }
  
  export const searchNode = async(nodes) => {
    return fetch(apiNode+"/search", {
      method: 'post',
      headers: {
        'Authorization' : getUserToken(),
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify(nodes)
    })
      .then(response => response.json())
      .then(response => {
        return response;
      }).catch(function (error) {
        console.log(error);
      });
  }
  //search node for select
  export let searchNodeByGroup = (searchData) => {
    return fetch(apiNode+"/searchNodeByGroup", {
      method: 'post',
      headers: {
        'Authorization' : getUserToken(),
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify(searchData)
    })
      .then(response => response.json())
      .then(response => {
        return response;
      }).catch(function (error) {
        console.log(error);
      });
  }
  export const sortNode = async(nodes) => {
    return fetch(apiNode+"/sort", {
      method: 'post',
      headers: {
        'Authorization' : getUserToken(),
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify(nodes)
    })
      .then(response => response.json())
      .then(response => {
        return response;
      }).catch(function (error) {
        console.log(error);
      });
  }