import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Select from "react-select";
import {
  Card,
  Container,
  Row,
  Col,
  Form
} from "react-bootstrap";
import { getUserById, updateUser, me, addUser, getUsers, deleteUser } from '../../services/userService';
import { getCountry } from '../../services/countryService';
import { getLanguage } from '../../services/languageService';
import { getOrganization } from '../../services/organizationService';
import { getUserType as getUserRole, getRolePermission } from '../../services/roleService'
import { getRole } from '../../services/roleTypeService';
import { BsArrowLeftShort } from "react-icons/bs";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import 'react-toastify/dist/ReactToastify.css';
import ToastLayout from '../../views/partials/Toastify';
import { ToastContainer, toast } from 'react-toastify';

export default function UserAddEdit() {
  const [showModal, setShowModal] = React.useState(false);
  var user;
  const [currentOrganizationName, setCurrentOrganizationName] = useState();
  const [language, setLanguage] = useState();
  const [organization, setOrganization] = useState();
  const [country, setCountry] = useState();
  const [roleType, setRoleType] = useState();

  const regExp = /^[a-z0-9.]+@\w+([\.-]?\w+)*(\.\w{2,3})+$/
  const [state, setState] = useState({
    id: '', bLabel: 'Add', FormLabel: 'Add'
  });
  const [activeState, setActiveState] = useState({
    active: "1"
  });
  const [privateState, setPrivateState] = useState({
    private: "1"
  });
  const history = useHistory();
  const { id } = useParams();
  let fetchUser = async (id) => {
    let privateValue;
    let activeValue;
    user = await getUserById(id);
    if (typeof (id) !== 'undefined') {
      setState({ ...user, bLabel: 'Update', FormLabel: 'Edit' })
      if (user.private == true) {
        privateValue = "1"
      } else {
        privateValue = "0"
      }
      //Active value  
      if (user.active == false) {
        activeValue = "1"
      } else {  
        activeValue = "0"
      }
      setPrivateState({
        private: privateValue
      });
      setActiveState({
        active: activeValue
      });
    }
  }
  let backButton = () => {
    history.push("/user");
  }
  useEffect(async () => {
    if (typeof (id) !== 'undefined') {
      await fetchUser(id);
    }
    reloadRoleType();
    reloadCountry();
    reloadLanguage();
    getCurrentOrganizationName();
    reloadOrganization();
  }, [])
  let handleCancel = () => {
    history.push("/user")
  }
  //display country name
  const [countryData, setCountryData] = useState({
    data: []
  });
  let reloadCountry = async () => {
    let record = await getCountry();
    setCountryData({ ...countryData, data: record });
    if (typeof (id) !== 'undefined') {
      setCountry(record.filter((pre) => {
        if (pre.id === user.country_id)
          return true;
      }))
    }
  }
  //display organization name
  const [allOrganization, setAllOrganization] = useState();
  let reloadOrganization = async () => {
    let organizations = await getOrganization();
    setAllOrganization(organizations);
    if (typeof (id) !== 'undefined') {
      setOrganization(organizations.filter((pre) => {
        if (pre.id === user.organization_id)
          return true;
      }))
    }
  }
  //display language name
  const [languageData, setLanguageData] = useState({
    language: []
  });
  let reloadLanguage = async () => {
    let languageRecord = await getLanguage();
    setLanguageData({ ...languageData, language: languageRecord });
    if (typeof (id) !== 'undefined') {
      setLanguage(languageRecord.filter((pre) => {
        if (pre.id === user.language_id)
          return true;
      }))
    }
  }
  //display Roletype
  const [roleData, setRoleData] = useState({
    role: []
  });
  let reloadRoleType = async () => {
    let roleRecord = await getRole();
    let filteredItems = roleRecord.filter(item => {
      if (item.role_type != "customer") {
        return item.id;
      }
    })
    var role_id;
    let chooseOption = filteredItems.filter((item) => {
      if (item.role_type === getUserRole()) {
        role_id = item.id;
      }
      return item.id >= role_id
    })
    setRoleData({ ...roleData, role: chooseOption });
    if (typeof (id) !== 'undefined') {
      setRoleType(roleRecord.filter((pre) => {
        if (pre.role_type === user.role_type)
          return true;
      })[0])
    }
  }
  //generate uuid
  function uuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
  const selectLanguage = (e) => {
    setState({ ...state, ["language_id"]: e.id });
    setLanguage(e);
  }
  let selectCountry = (e) => {
    setState({ ...state, ["country_id"]: e.id });
    setCountry(e);
  }
  let selectRoleType = (e) => {
    setState({ ...state, ["role_type"]: e.role_type });
    console.log("role_type", state)
    setRoleType(e);
  }
  let selectOrganization = (e) => {
    setState({ ...state, ["organization_id"]: e.id });
    setOrganization(e);
  }
  let handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  }
  let handleMobile1 = (e) => {
    setState({ ...state, ["mobile_number_1"]: e });
  }
  let handleMobile2 = (e) => {
    setState({ ...state, ["mobile_number_2"]: e });
  }
  //Radio button
  let activeRadioChange = (e) => {
    setActiveState({
      active: e.target.value,
    });
  }
  let privateRadioChange = (e) => {
    setPrivateState({
      private: e.target.value
    });
  }
  let handleSubmit = async (e) => {
    e.preventDefault();
    // if (!state.username.length) {
    //   return;
    // }
    const newItem = {
      username: null,
      // password: state.password,
      first_name: state.first_name,
      last_name: state.last_name,
      email: state.email,
      mobile_number_1: state.mobile_number_1,
      mobile_number_2: state.mobile_number_2,
      address: state.address,
      city: state.city,
      zip: state.zip,
      country_id: state.country_id,
      latitude: state.latitude,
      longitude: state.longitude,
      language_id: state.language_id,
      private: false,
      organization_id: state.organization_id,
      active: activeState.active,
      role_type: state.role_type,
    };
    if (regExp.test(newItem.email)) {
      if (state.id !== '') { //update
        newItem.id = state.id;
        let updateId = newItem.id
        let response = await updateUser(newItem);
        if (response.result == 'fail') {
          toast.error(ToastLayout("Email or mobile number already exist!"))
        } else {
          history.push("/user");
        }
      } else { //add
        if ((!newItem.email && newItem.mobile_number_1) || (newItem.email && !newItem.mobile_number_1) || (newItem.email && newItem.mobile_number_1)) {
          if (newItem.country_id) {
            if (newItem.language_id) {
              if (newItem.role_type) {
                let response = await addUser(newItem);
                if (response.result == 'fail') {
                  toast.error(ToastLayout("Email or mobile number already exist!"))
                } else {
                  history.push("/user");
                }
              } else {
                toast.error(ToastLayout("Please select role type"))
              }
            } else {
              toast.error(ToastLayout("Please select language"))
            }
          } else {
            toast.error(ToastLayout("Please select country"))
          }
        } else {
          toast.error(ToastLayout("Please enter email or mobile number!"))
        }
      }
    }
    else {
      toast.error(ToastLayout("Add valid Email"))
    }
  }
  const getCurrentOrganizationName = async () => {
    let record = await me();
    setCurrentOrganizationName(...record)
  }
  let getOrganizationName = () => {
    if (getUserRole() === "super-admin") {
      return (
        <Form.Group as={Row} className="mb-3" >
          <Col sm="3">
            <div className="add-responsive">Organization :</div>
          </Col>
          <Col sm="9">
            {/* <input
                        name="owner_organization_id"
                        id={organizationData.organization_id}
                        value={organizationData.organization_name}
                        className="input-style-responsive"
                        style={{ backgroundColor:'transparent',borderBottom:"0rem" }}
                    /> */}
            <Select
              className="dropDown-style"
              value={organization}
              options={allOrganization}
              getOptionLabel={e => e.organization_name}
              getOptionValue={e => e.id}
              onChange={(e) => selectOrganization(e)}
            />
          </Col>
        </Form.Group>
      )
    }
    else {
      state.organization_id = currentOrganizationName && currentOrganizationName.organization_id
      return (
        <Form.Group as={Row} className="mb-3" >
          <Col sm="3">
            <div className="add-responsive">Organization :</div>
          </Col>
          <Col sm="9">
            <input
              disabled
              name="owner_organization_id"
              id={state.organization_id}
              value={currentOrganizationName && currentOrganizationName.organization_name}
              className="input-style-responsive"
              style={{ backgroundColor: 'transparent', borderBottom: "0rem" }}
            />
          </Col>
        </Form.Group>
      )
    }
  }
  let doDelete = async () => {
    await deleteUser(id)
    console.log("delete user")
    history.push("/user");
  }
  let getDeleteButton = () => {
    const history = useHistory();
    if (getRolePermission('users', getUserRole()) > 2) {
      if (typeof (id) !== 'undefined') {
        return (<button onClick={(e) => { e.preventDefault(); doDelete() }} className="cancle-button-style">
          Delete
        </button>
        )
      };
    }
  }

  return (
    <div >
      <Container fluid>
        <Row>
          <Col md="13">
            <Card className="striped-table-with-hover">
              <Card.Header>
                <div className='display-back-button'>
                  <div><button className="back" data-toggle="tooltip" title="click to go back" onClick={() => { backButton() }} ><BsArrowLeftShort /></button></div>
                  <div className="form-header-container form-header" style={{ marginTop: "05px" }}>
                    <center>{state.FormLabel} User</center>
                  </div>
                </div>
              </Card.Header>

              <Card.Body className="px-0">

                <Col lg="10">
                  <Form >
                    {/* <Form.Group as={Row} className="mb-3" >
                      <Col sm="3">
                        <div className="add-responsive">Username :</div>
                      </Col>
                      <Col sm="9">
                        <input
                          name="username"
                          placeholder="User Name"
                          onChange={handleChange}
                          value={state.username}
                          className="input-style-responsive"
                        />
                      </Col>
                    </Form.Group> */}

                    {/* <Form.Group as={Row} className="mb-3" >
                      <Col sm="3">
                        <div className="add-responsive">Password :</div>
                      </Col>
                      <Col sm="9">
                        <input
                          type="password"
                          name="password"
                          placeholder="Password"
                          onChange={handleChange}
                          value={state.password}
                          className="input-style-responsive"
                        />
                      </Col>
                    </Form.Group> */}

                    <Form.Group as={Row} className="mb-3" >
                      <Col sm="3">
                        <div className="add-responsive">First Name :</div>
                      </Col>
                      <Col sm="9">
                        <input
                          name="first_name"
                          placeholder="First Name"
                          onChange={handleChange}
                          value={state.first_name}
                          className="input-style-responsive"
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" >
                      <Col sm="3">
                        <div className="add-responsive">Last Name :</div>
                      </Col>
                      <Col sm="9">
                        <input
                          name="last_name"
                          placeholder="Last Name"
                          onChange={handleChange}
                          value={state.last_name}
                          className="input-style-responsive"
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" >
                      <Col sm="3">
                        <div className="add-responsive">Email :</div>
                      </Col>
                      <Col sm="9">
                        <input
                          name="email"
                          placeholder="Email"
                          onChange={handleChange}
                          value={state.email}
                          className="input-style-responsive"
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" >
                      <Col sm="3">
                        <div className="add-responsive">Primary Mobile No :</div>
                      </Col>
                      <Col sm="9">
                        <PhoneInput
                          buttonClass='dropdown_add_user'
                          inputClass='phone_temp_user'
                          placeholder="Primary Mobile Number e.g: +82738183652"
                          value={state.mobile_number_1}
                          onChange={handleMobile1}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" >
                      <Col sm="3">
                        <div className="add-responsive">Secondary Mobile No :</div>
                      </Col>
                      <Col sm="9">
                        <PhoneInput
                          buttonClass='dropdown_add_user'
                          inputClass='phone_temp_user'
                          placeholder="Secondary Mobile Number e.g: +82738183652"
                          value={state.mobile_number_2}
                          onChange={handleMobile2}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" >
                      <Col sm="3">
                        <div className="add-responsive">Address :</div>
                      </Col>
                      <Col sm="9">
                        <input
                          name="address"
                          placeholder="Address"
                          onChange={handleChange}
                          value={state.address}
                          className="input-style-responsive"
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" >
                      <Col sm="3">
                        <div className="add-responsive">City :</div>
                      </Col>
                      <Col sm="9">
                        <input
                          name="city"
                          placeholder="City"
                          onChange={handleChange}
                          value={state.city}
                          className="input-style-responsive"
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" >
                      <Col sm="3">
                        <div className="add-responsive">Zip Code :</div>
                      </Col>
                      <Col sm="9">
                        <input
                          name="zip"
                          placeholder="Zip code"
                          onChange={handleChange}
                          value={state.zip}
                          className="input-style-responsive"
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" >
                      <Col sm="3">
                        <div className="add-responsive">Country :</div>
                      </Col>
                      <Col sm="9">
                        <Select
                          className="dropDown-style"
                          value={country}
                          options={countryData.data}
                          getOptionLabel={e => e.country}
                          getOptionValue={e => e.id}
                          onChange={(e) => selectCountry(e)}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" >
                      <Col sm="3">
                        <div className="add-responsive">Latitude :</div>
                      </Col>
                      <Col sm="9">
                        <input
                          name="latitude"
                          placeholder="Latitude"
                          onChange={handleChange}
                          value={state.latitude}
                          className="input-style-responsive"
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" >
                      <Col sm="3">
                        <div className="add-responsive">Longitude :</div>
                      </Col>
                      <Col sm="9">
                        <input
                          name="longitude"
                          placeholder="Longitude"
                          onChange={handleChange}
                          value={state.longitude}
                          className="input-style-responsive"
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" >
                      <Col sm="3">
                        <div className="add-responsive">Language :</div>
                      </Col>
                      <Col sm="9">

                        <Select
                          className="dropDown-style"
                          value={language}
                          options={languageData.language}
                          getOptionLabel={e => e.language_name}
                          getOptionValue={e => e.id}
                          onChange={(e) => selectLanguage(e)}
                        />
                      </Col>
                    </Form.Group>

                    {/* <Form.Group as={Row} className="mb-3" >
                      <Col sm="3">
                        <div className="add-responsive">Private :</div>
                      </Col>
                      <Col sm="9">
                        <div className="input-style-responsive">
                          <input
                            type="radio"
                            name="private"
                            onChange={privateRadioChange}
                            value="1"
                            className="radiobutton"
                            checked={privateState.private === "1"}
                          />
                          &nbsp;&nbsp;Yes&nbsp;&nbsp;&nbsp;&nbsp;
                          <input
                            type="radio"
                            name="private"
                            onChange={privateRadioChange}
                            value="0"
                            className="radiobutton"
                            checked={privateState.private === "0"}
                          />&nbsp;&nbsp;No
                        </div>
                      </Col>
                    </Form.Group> */}

                    <Form.Group as={Row} className="mb-3" >
                      <Col sm="3">
                        <div className="add-responsive">Active :</div>
                      </Col>
                      <Col sm="9">
                        <div className="input-style-responsive" >
                          <input
                            type="radio"
                            name="active"
                            onChange={activeRadioChange}
                            value="1"
                            className="radiobutton"
                            checked={activeState.active === "1"}
                          />
                          &nbsp;&nbsp;Yes&nbsp;&nbsp;&nbsp;&nbsp;
                          <input
                            type="radio"
                            name="active"
                            onChange={activeRadioChange}
                            value="0"
                            className="radiobutton"
                            checked={activeState.active === "0"}
                          />&nbsp;&nbsp;No
                        </div>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" >
                      <Col sm="3">
                        <div className="add-responsive">Role Type :</div>
                      </Col>
                      <Col sm="9">
                        {/* <select id={state.role_type} name="role_type" value={state.role_type} className="input-style-responsive" onChange={handleChange}>
                          <option selected disabled hidden>Choose Role</option>
                          {roleData.role.map(role => (
                            <option value={role.role_type} >{role.role_type} </option>
                          ))}
                        </select> */}
                        <Select
                          className="dropDown-style"
                          value={roleType}
                          options={roleData.role}
                          getOptionLabel={e => e.role_type}
                          getOptionValue={e => e.role_type}
                          onChange={(e) => selectRoleType(e)}
                        />
                      </Col>
                    </Form.Group>
                    {getOrganizationName()}
                    <Form.Group as={Row} className="mb-3" >
                      <Col>
                        <div className="button-container-responsive">
                          <ToastContainer />
                          <button onClick={handleSubmit} className="add-button-style">
                            {state.bLabel}
                          </button> &nbsp;&nbsp;
                          {getDeleteButton()}
                          &nbsp;&nbsp;
                          <button onClick={handleCancel} className="cancle-button-style">
                            Cancel
                          </button>
                        </div>
                      </Col>

                    </Form.Group>
                  </Form>
                </Col>

              </Card.Body>

            </Card>
          </Col>
        </Row>
        <Row>
        </Row>
      </Container>
    </div>
  );
}