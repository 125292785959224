import React, { Component } from "react";
import { useHistory, useParams } from 'react-router';
import { useLocation, Route, Switch } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import AdminNavbar from "../components/Navbars/AdminNavbar";
import Sidebar from "components/Sidebar/Sidebar";
import PaymentAddEdit from "../views/payments/AddEditPayment";
import VendorAddEdit from "views/vendor/AddEditVendor";
import UserAddEdit from "views/users/AddEditUser";
import MeterAddEdit from "../views/meters/AddEditMeter";
import Meter from '../views/meters/Meter';
import AddMeterToGateway from '../views/meters/AddMeterToGateway';
import AddPbxToGateway from '../views/device/AddPbxToGateway';
import MeterWallet from "../views/packages/MeterWallet";
import PackageAddEdit from "../views/packages/AddEditPackage";
import DeviceAddEdit from "../views/device/AddEditDevices";
import getAppRoutes from "../routes";
import Select from "react-select";
import sidebarImage from "assets/img/sidebar-3.jpg";
import UserList from "views/users/UserOrg";
import { processUserRoutes, getUserType as getUserRole } from "../services/roleService";
import FirstComponent from "views/FirstComponent";
import OrganizationListDetail from "views/organization/AddEditOrganization";
import GatewayAddEdit from "views/gateway/AddEditGateway";
import UserOrgAddEdit from "views/users/AddEditUserOrg";
import DeviceList from "views/device/Devices";
import GatewayList from "views/gateway/Gateway";
import NodeList from "views/node/Node";
import NodeAddEdit from "views/node/AddEditNode"
import WalletUserAddEdit from "views/wallet-user/AddEditWalletUser";
import WalletAdd from "../views/wallet-user/AddWallet";
import WalletHistory from "views/users/UserWalletHistoryScreen";
import HardwareAddEdit from "views/hardware/AddEditHardware";
import InstalltionAddEdit from "views/installation/AddEditInstallation";
// import AssetsList from "views/installation/Assets";
import CustomerAddEdit from "views/users/AddEditCustomer";
import AssetsInstallation from "views/installation/AssetsInstallation";
import { getInstallation, searchInstallationGroup } from '../services/installationService';
import { getOrganization } from 'services/organizationService';

function Admin() {
  const params = useParams();
  const userRoutes = processUserRoutes(getAppRoutes());
  const [image, setImage] = React.useState(sidebarImage);
  const [color, setColor] = React.useState("black");
  const [hasImage, setHasImage] = React.useState(true);
  const [installations, setInstallations] = React.useState([]);
  const [organizationData, setOrganizationData] = React.useState([]);
  const [selectedInstallation, setSelectedInstallation] = React.useState({})
  const [selectedOrganization, setSelectedOrganization] = React.useState({})
  const location = useLocation();
  const mainPanel = React.useRef(null);
  const [groupName, setGroupName] = React.useState({ group_name: "All Installations (Type to select any installation)" });
  const [organizationName, setOrganizationName] = React.useState({ organization_name: "All Organizations" });
  const [updateOrganizationInGlobal, setUpdateOrganizationInGlobal] = React.useState(1);
  const searchOptions = {
    searchValues: ["username", "first_name", "last_name", "email"], //same len as values
    searchNames: ['Username', 'First Name', 'Last Name', 'Email'],
  }
  const getRoutes = (routesApp) => {
    return routesApp.map((prop, key) => {
      return (
        <Route
          path={prop.path}
          render={(props) => <prop.component {...props} 
          globalSelect={selectedInstallation} 
          globalSelectOrganization={selectedOrganization} 
          setSelectedOrganizations={setSelectedOrganizations}
          setSelectedInstallations={setSelectedInstallations}
          />}
          key={key}
        />
      );
    });
  };
  React.useEffect(() => {
    // reloadInstallation();
    reloadOrganization();
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      let element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, [selectedOrganization, updateOrganizationInGlobal,selectedInstallation]);

  const loadInstallations = async (search, prevOptions) => {
    let filteredOptions = [];
    if (search) {
      let searchData = {
        searchKey: search
      }
      filteredOptions = await searchInstallationGroup(searchData);
      filteredOptions = [{ id: 0, group_name: "All Installations" }, ...filteredOptions]
    } else {
      let options = [{ id: 0, group_name: "All Installations" }]
      filteredOptions = options;
    }
    const slicedOptions = filteredOptions.slice(
      prevOptions.length,
      prevOptions.length + 10
    );
    return {
      options: slicedOptions
    };
  };
  let reloadOrganization = async () => {
    let record = await getOrganization();
    if (record == "Unauthorized") {
      alert("Your session has expired!");
      localStorage.clear();
      history.push("/login")
    }
    setOrganizationData([{ id: 0, organization_name: "All Organizations" }, ...record]);
  }
  let selectInstallation = (e) => {
    setSelectedInstallation({ ...selectedInstallation, ["installation_id"]: e.id });
    setGroupName(e);
  }
  let setSelectedInstallations = (data) => {
    setGroupName(data.installations[0])
    setSelectedInstallation({ ...selectedInstallation, ["installation_id"]: data.installations[0].id });
  }
  let selectOrganization = (e) => {
    setSelectedOrganization({ ...selectedOrganization, ["organization_id"]: e.id });
    let url = window.location.hash;
    if (url.includes('user-organization')) {
      window.location.replace('/#/user-organization/' + e.id)
    }
    if (url.includes('user-organization') && e.id == 0) {
      setOrganizationName({ organization_name: "All Organizations" })
      window.location.replace('/#/organization')
    }
    setOrganizationName(e);
  }
  let setSelectedOrganizations = (data) => {
    setOrganizationName(data.organizations)
    setSelectedOrganization({ ...selectedOrganization, ["organization_id"]: data.organizations.id });
  }
  let setUpdatedOrganizationList = () => {
    if (updateOrganizationInGlobal == 1) {
      setUpdateOrganizationInGlobal(0);
    } else {
      setUpdateOrganizationInGlobal(1);
    }
  }
  let handleChange = (e) => {

  }
  return (
    <>
      <div className="wrapper">
        <Sidebar color={color} image={hasImage ? image : ""} routes={userRoutes} />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar />
          <div className="select-header">
            <div className="select-installation">
              <AsyncPaginate
                value={groupName}
                loadOptions={loadInstallations}
                placeholder={"Type to select ...."}
                onChange={(e) => selectInstallation(e)}
                options={[]}
                getOptionLabel={e => e.group_name}
                getOptionValue={e => e.id}
              />
            </div>
            {(getUserRole() === "super-admin") ?
              <div className="select-installation">
                <Select
                  // className="dropDown-style"
                  value={organizationName}
                  options={organizationData}
                  getOptionLabel={e => e.organization_name}
                  getOptionValue={e => e.id}
                  onChange={(e) => selectOrganization(e)}
                />
              </div>
              :
              <div></div>
            }
          </div>
          <div className="content" style={{ paddingTop: "7px" }}>

            <Switch>{getRoutes(getAppRoutes())}
            </Switch>
            <Switch><Route
              path={"/payment-add"}
              render={props => <PaymentAddEdit {...props} />}
            /></Switch>
            <Switch><Route
              path={"/payment-edit/:id"}
              render={props => <PaymentAddEdit {...props} />}
            /></Switch>
            <Switch><Route
              path={"/vendor-add"}
              render={props => <VendorAddEdit {...props} />}
            /></Switch>
            <Switch><Route
              path={"/vendor-edit/:id"}
              render={props => <VendorAddEdit {...props} />}
            /></Switch>
            <Switch><Route
              path={"/user-organization-add/:organizationId"}
              render={props => <UserOrgAddEdit {...props} />}
            /></Switch>
            <Switch><Route
              exact path={"/gateway/:gatewayId/meter/add"}
              render={props => <AddMeterToGateway {...props} />}
            /></Switch>
            <Switch><Route
              exact path={"/gateway/:gatewayId/pbx/add"}
              render={props => <AddPbxToGateway {...props} />}
            /></Switch>
            <Switch>
              <Route
                path={"/user-edit/:id"}
                render={props => <UserAddEdit {...props} />}
              /></Switch>
            <Switch><Route
              path={"/user-add"}
              render={props => <UserAddEdit {...props} />}
            /></Switch>
            <Switch><Route
              path={"/customer-edit/:id"}
              render={props => <CustomerAddEdit {...props} />}
            /></Switch>
            <Switch><Route
              path={"/customer-add"}
              render={props => <CustomerAddEdit {...props} />}
            /></Switch>
            <Switch><Route
              path={"/user-organization-edit/:id"}
              render={props => <UserOrgAddEdit {...props} />}
            /></Switch>
            <Switch><Route
              exact path={"/gateway"}
              render={props => <GatewayList {...props} globalSelect={selectedInstallation} globalSelectOrganization={selectedOrganization} />}
            /></Switch>
            <Switch><Route
              path={"/node"}
              render={props => <NodeList {...props} globalSelect={selectedInstallation} globalSelectOrganization={selectedOrganization} />}
            /></Switch>
            <Switch><Route
              path={"/node-add"}
              render={props => <NodeAddEdit {...props} />}
            /></Switch>
            <Switch><Route
              path={"/node-edit/:mac_address"}
              render={props => <NodeAddEdit {...props} />}
            /></Switch>
            <Switch><Route
              path={"/pbx-device"}
              render={props => <DeviceList {...props} globalSelect={selectedInstallation} globalSelectOrganization={selectedOrganization} />}
            /></Switch>
            <Switch><Route
              path={"/meters"}
              render={props => <Meter {...props} globalSelect={selectedInstallation} globalSelectOrganization={selectedOrganization} />}
            /></Switch>
            <Switch><Route
              path={"/installations/installation-assets/:deviceGroupId"}
              render={props => <AssetsInstallation {...props} />}
            /></Switch>
            <Switch><Route
              path={"/meter/customer/:customerId"}
              render={props => <Meter {...props} />}
            /></Switch>
            <Switch><Route
              path={"/meters-add"}
              render={props => <MeterAddEdit {...props} />}
            /></Switch>
            <Switch><Route
              path={"/meter-wallet/:serial"}
              render={props => <MeterWallet {...props} />}
            /></Switch>
            <Switch><Route
              path={"/meters-edit/:serial"}
              render={props => <MeterAddEdit {...props} />}
            /></Switch>
            <Switch><Route
              path={"/package-add"}
              render={props => <PackageAddEdit {...props} />}
            /></Switch>
            <Switch><Route
              path={"/package-edit/:id"}
              render={props => <PackageAddEdit {...props} />}
            /></Switch>
            <Switch><Route
              path={"/pbx-device-add"}
              render={props => <DeviceAddEdit {...props} />}
            /></Switch>
            <Switch><Route
              path={"/pbx-device-edit/:mac_address"}
              render={props => <DeviceAddEdit {...props} />}
            /></Switch>
            <Switch><Route
              path={"/user-organization/:organizationId"}
              render={props => <UserList {...props} globalSelectOrganization={selectedOrganization} setSelectedOrganizations={setSelectedOrganizations} />}
            /></Switch>
            <Switch><Route
              path={"/first-component"}
              render={props => <FirstComponent {...props} />}
            /></Switch>
            <Switch><Route
              path={"/organization-detail/:id"}
              render={props => <OrganizationListDetail {...props} setUpdatedOrganizationList={setUpdatedOrganizationList} />}
            /></Switch>
            <Switch><Route
              path={"/organization-add"}
              render={props => <OrganizationListDetail {...props} setUpdatedOrganizationList={setUpdatedOrganizationList} />}
            /></Switch>
            <Switch><Route
              path={"/gateway-edit/:mac_address"}
              render={props => <GatewayAddEdit {...props} />}
            /></Switch>
            <Switch><Route
              path={"/gateway-add"}
              render={props => <GatewayAddEdit {...props} />}
            /></Switch>
            <Switch><Route
              path={"/wallet-user-add"}
              name="User Wallet"
              render={props => <WalletUserAddEdit {...props} />}
            /></Switch>
            <Switch><Route
              path={"/wallet-user-edit/:walletId"}
              render={props => <WalletUserAddEdit {...props} />}
            /></Switch>
            <Switch><Route
              path={"/user-wallet-history/:userId"}
              render={props => <WalletHistory {...props} />}
            /></Switch>
            <Switch><Route
              path={"/add-money-wallet/:walletId"}
              render={props => <WalletAdd {...props} />}
            /></Switch>
            <Switch><Route
              path={"/hardware-add"}
              render={props => <HardwareAddEdit {...props} />}
            /></Switch>
            <Switch><Route
              path={"/hardware-edit/:id"}
              render={props => <HardwareAddEdit {...props} />}
            /></Switch>
            <Switch><Route
              path={"/installation-add"}
              render={props => <InstalltionAddEdit {...props} />}
            /></Switch>
            <Switch><Route
              path={"/installation-edit/:id"}
              render={props => <InstalltionAddEdit {...props} />}
            /></Switch>
          </div>
        </div>
      </div>
    </>
  );
}

export default Admin;
