import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { BsArrowLeftShort } from "react-icons/bs";
import { uuid } from '../../services/CommonUtils'
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  InputGroup,
  Checkbox,
  FormControl,
  Form
} from "react-bootstrap";
// import '../../assets/css/pages/add-edit-form.css'
import { getUserById, updateUser, addUser, getUsers, deleteUser, getCustomerById, me } from '../../services/userService';
import { getCountry } from '../../services/countryService';
import { getLanguage } from '../../services/languageService';
import { getOrganization } from '../../services/organizationService';
import { getUserId } from "../../services/roleService";
import Select from "react-select";
import { getUserType as getUserRole, getRolePermission } from '../../services/roleService'
import { getOrganizationId } from '../../services/roleService';
import { Alert } from 'reactstrap';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import ToastLayout from '../../views/partials/Toastify';
import { ToastContainer, toast } from 'react-toastify';


export default function CustomerAddEdit() {
  const history = useHistory();
  const { id } = useParams();
  const [currentOrganizationName, setCurrentOrganizationName] = useState();
  const [language, setLanguage] = useState();
  const [organization, setOrganization] = useState();
  var user;
  const [state, setState] = useState({
    id: '', bLabel: 'Add', FormLabel: 'Add'
  });
  const [activeState, setActiveState] = useState({
    active: "1"
  });
  const [privateState, setPrivateState] = useState({
    private: "1"
  });
  const [countryData, setCountryData] = useState({
    data: []
  });
  const [languageData, setLanguageData] = useState({
    data: []
  });
  useEffect(async () => {
    if (typeof (id) !== 'undefined') {
      await fetchUser(id);
    }
    reloadLanguage();
    reloadOrganization();
    getCurrentOrganizationName();
  }, [])
  let fetchUser = async (recordId) => {
    console.log("recordId>>>", recordId);
    user = await getCustomerById(recordId);
    if (typeof (id) !== 'undefined') {
      setState({ ...user, bLabel: 'Update', FormLabel: 'Edit' })
    }
  }
  let handleCancel = () => {
    history.push("/customer")
  }
  //display organization
  const [allOrganization, setAllOrganization] = useState();
  let reloadOrganization = async () => {
    let organizations = await getOrganization();
    setAllOrganization(organizations);
    if (typeof (id) !== 'undefined') {
      setOrganization(organizations.filter((pre) => {
        if (pre.id === user.organization_id)
          return true;
      }))
    }
  }
  //display language name
  let reloadLanguage = async () => {
    let record = await getLanguage();
    setLanguageData({ ...languageData, data: record });
    console.log("langauges>>>", record)
    if (typeof (id) !== 'undefined') {
      setLanguage(record.filter((pre) => {
        if (pre.id === user.language_id)
          return true;
      }))
    }
  }
  let selectOrganization = (e) => {
    setState({ ...state, ["organization_id"]: e.id });
    setOrganization(e);
  }
  const selectLanguage = (e) => {
    setState({ ...state, ["language_id"]: e.id });
    setLanguage(e);
  }
  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  }
  let handleMobile1 = (e) => {
    setState({ ...state, ["mobile_number_1"]: e });
  }
  let handleMobile2 = (e) => {
    setState({ ...state, ["mobile_number_2"]: e });
  }
  //Radio button
  const activeRadioChange = (e) => {
    setActiveState({
      active: e.target.value,
    });
  }
  const backButton = () => {
    history.push("/customer");
  }
  const privateRadioChange = (e) => {
    setPrivateState({
      private: e.target.value
    });
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (!state.username.length) {
    //   return;
    // }
    const newItem = {
      first_name: state.first_name,
      last_name: state.last_name,
      mobile_number_1: state.mobile_number_1,
      mobile_number_2: state.mobile_number_2,
      language_id: state.language_id,
      organization_id: state.organization_id,
      role_type: "customer"
    };
    if (state.id !== '') { //update
      newItem.id = state.id;
      newItem.organization_id = state.organization_id; //use old org Id
      let updateId = newItem.id
      let serverRes = await updateUser(newItem);
      if (serverRes.result == "fail") {
        toast.error(ToastLayout(serverRes.msg,))
      } else {
        history.push("/customer");
      }
    } else { //add
      if (state.language_id) {
        // console.log("newItem",newItem)
        let serverRes = await addUser(newItem);
        if (serverRes.result === "fail") {
          toast.error(ToastLayout(serverRes.msg,))
        } else {
          history.push("/customer");
        }
      } else {
        toast.error(ToastLayout("Please select language",))
      }
    }
  }
  const getCurrentOrganizationName = async () => {
    let record = await me();
    setCurrentOrganizationName(...record)
  }
  let getOrganizationName = () => {
    if (getUserRole() === "super-admin") {
      return (
        <Form.Group as={Row} className="mb-3" >
          <Col sm="3">
            <div className="add-responsive">Organization :</div>
          </Col>
          <Col sm="9">
            {/* <input
                        name="owner_organization_id"
                        id={organizationData.organization_id}
                        value={organizationData.organization_name}
                        className="input-style-responsive"
                        style={{ backgroundColor:'transparent',borderBottom:"0rem" }}
                    /> */}
            <Select
              className="dropDown-style"
              value={organization}
              options={allOrganization}
              getOptionLabel={e => e.organization_name}
              getOptionValue={e => e.id}
              onChange={(e) => selectOrganization(e)}
            />
          </Col>
        </Form.Group>
      )
    }
    else {
      state.organization_id = currentOrganizationName && currentOrganizationName.organization_id
      return (
        <Form.Group as={Row} className="mb-3" >
          <Col sm="3">
            <div className="add-responsive">Organization :</div>
          </Col>
          <Col sm="9">
            <input
              disabled
              name="owner_organization_id"
              id={state.organization_id}
              value={currentOrganizationName && currentOrganizationName.organization_name}
              className="input-style-responsive"
              style={{ backgroundColor: 'transparent', borderBottom: "0rem" }}
            />
          </Col>
        </Form.Group>
      )
    }
  }
  let doDelete = async () => {
    await deleteUser(id)
    history.push("/customer");
  }
  let getDeleteButton = () => {
    if (getRolePermission('users', getUserRole()) > 2) {
      if (typeof (id) !== 'undefined') {
        return (<input type="button" onClick={(e) => { e.preventDefault(); doDelete() }} className="cancle-button-style"
          value='Delete' />
        )
      };
    }
  }

  return (
    <div>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="striped-table-with-hover">
              <Card.Header>
                <Col >
                  <div className='display-back-button'>
                    <div><button className="back" data-toggle="tooltip" title="click to go back" onClick={() => { backButton() }} ><BsArrowLeftShort /></button></div>
                    <div className=" form-header" style={{ lineHeight: '50px' }}>
                      {state.FormLabel} Customer
                    </div>
                  </div>
                </Col>
              </Card.Header>

              <Card.Body className="px-0">

                <Col lg="12">
                  <Form>
                    <Form.Group as={Row} className="mb-3" >
                      <Col sm="3">
                        <div className="add-responsive">First Name :</div>
                      </Col>
                      <Col sm="9">
                        <input
                          name="first_name"
                          placeholder="First Name"
                          onChange={handleChange}
                          value={state.first_name}
                          className="input-style-responsive"
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" >
                      <Col sm="3">
                        <div className="add-responsive">Last Name :</div>
                      </Col>
                      <Col sm="9">
                        <input
                          name="last_name"
                          placeholder="Last Name"
                          onChange={handleChange}
                          value={state.last_name}
                          className="input-style-responsive"
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" >
                      <Col sm="3">
                        <div className="add-responsive">Primary Mobile No :</div>
                      </Col>
                      <Col sm="9">
                        <PhoneInput
                          buttonClass='dropdown_add_user'
                          inputClass='phone_temp_cus_m1'
                          placeholder="Primary Mobile Number e.g: +82738183652"
                          value={state.mobile_number_1}
                          onChange={handleMobile1}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" >
                      <Col sm="3">
                        <div className="add-responsive">Secondary Mobile No :</div>
                      </Col>
                      <Col sm="8">
                        <PhoneInput
                          buttonClass='dropdown_add_user'
                          inputClass='phone_temp_cus_m2'
                          placeholder="Secondary Mobile Number e.g: +82738183652"
                          value={state.mobile_number_2}
                          onChange={handleMobile2}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" >
                      <Col sm="3">
                        <div className="add-responsive">Language :</div>
                      </Col>
                      <Col sm="9">
                        <Select
                          className="dropDown-style"
                          value={language}
                          options={languageData.data}
                          getOptionLabel={e => e.language_name}
                          getOptionValue={e => e.id}
                          onChange={(e) => selectLanguage(e)}
                        />
                      </Col>
                    </Form.Group>
                    {getOrganizationName()}
                    <Form.Group as={Row} className="mb-3" >
                      <Col>
                        <div className="button-container-responsive">
                          <ToastContainer/>
                          <input type="button" className="add-button-style" onClick={handleSubmit} value={state.bLabel} />&nbsp;&nbsp;
                          {getDeleteButton()}&nbsp;&nbsp;
                          <input type="button" onClick={handleCancel} className="cancle-button-style"
                            value='Cancel' />
                        </div>
                      </Col>
                    </Form.Group>
                  </Form>
                </Col>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
        </Row>
      </Container>
    </div>
  );
}