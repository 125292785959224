import { apiUrl } from "./globalService";
import { getUserToken } from "./roleService";
const apiLanguage = apiUrl + "language";
export const getLanguage = async () => {
    return fetch(apiLanguage, {
        method: 'get',
        headers: {
            'Authorization' : getUserToken(),
            'Content-Type': 'application/json;charset=utf-8'
        }
    })
        .then(response => response.json())
        .then(response => {
            return response;
        }).catch(function (error) {
            console.log(error);
            return "Unauthorized"
        });
}
export let getLanguageById = (language_id) => {
    return fetch(apiLanguage + "/" + language_id, {
      method: 'get',
      headers: {
        'Authorization' : getUserToken(),
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(response => {
        return response;
      }).catch(function (error) {
        console.log(error);
      });
  }