import React, { useState, useContext, useEffect } from 'react';
import Select from 'react-select';
import {
    Modal,
    Row,
    Col,
    Card,
    Container,
    Form
} from "react-bootstrap";
import '../../assets/css/pages/table-list.css'
import { addGateway } from '../../services/gatewayService';
import { ToastContainer, toast } from 'react-toastify';
import ToastLayout from '../../views/partials/Toastify';
export default function AddGateway({ setItemGateway, itemGateway, organizationData, userData, deviceGroupData, modeData, hardwareData, currentOrganizationName, getUserRole, getUserId, reloadAllTree, selectedNode }) {

    let handleChange = (e) => {
        let name = e.target.name
        setItemGateway((preData) => {
            preData[e.target.name] = e.target.value
            return { ...preData };
        })
    }
    let handleSubmit = (e) => {
        e.preventDefault();
        const newItem = {
            mac_address: itemGateway.mac_address,
            serial: itemGateway.serial,
            device_group_id: selectedNode.item.id,
            hardware_id: itemGateway.hardware_id,
            latitude: itemGateway.latitude,
            longitude: itemGateway.longitude,
            owner_organization_id: itemGateway.owner_organization_id,
            owner_user_id: itemGateway.owner_user_id,
            created_at: Date.now(),
            created_by: getUserId(),
            modified_at: Date.now(),
            modified_by: getUserId(),
            id: Date.now()
        };
        if (newItem.device_group_id) {
            if (newItem.hardware_id) {
                if (newItem.owner_organization_id) {
                    if (newItem.owner_user_id) {
                        addGateway(newItem)
                        window.location.reload();
                    } else {
                        toast.error(ToastLayout("Please select customer"))
                    }
                } else {
                    toast.error(ToastLayout("Please select organization"))
                }
            } else {
                toast.error(ToastLayout("Please select hardware model"))
            }
        } else {
            toast.error(ToastLayout("Please select device group"))
        }
    }
    let getOrganizationName = () => {
        if (getUserRole() === "super-admin") {
            return (
                <Form.Group as={Row} className="mb-3" >
                    <Col sm="4">
                        <div className="add-responsive">Organization :</div>
                    </Col>
                    <Col sm="6">
                        <select id={itemGateway.owner_organization_id} name="owner_organization_id" className="input-style-responsive" value={itemGateway.owner_organization_id} onChange={handleChange}>
                            <option disabled selected hidden> Choose organization </option>
                            {organizationData.orgData && organizationData.orgData.map(organization => (
                                <option value={organization.id} >{organization.organization_name} </option>
                            ))}
                        </select>
                    </Col>
                </Form.Group>
            )
        }
        else {
            itemGateway.owner_organization_id = currentOrganizationName && currentOrganizationName.organization_id
            return (
                <Form.Group as={Row} className="mb-3" >
                    <Col sm="4">
                        <div className="add-responsive">Organization :</div>
                    </Col>
                    <Col sm="4">
                        <input
                            disabled
                            name="organization_id"
                            id={itemGateway.owner_organization_id}
                            value={currentOrganizationName && currentOrganizationName.organization_name}
                            className="input-style-responsive"
                        />
                    </Col>
                </Form.Group>
            )
        }
    }
    return (

        <Card.Body className="px-0" >
            <ToastContainer />
            <Col lg="9">
                <Form >
                    <Form.Group as={Row} className="mb-3" >
                        <Col sm="4">
                            <div className="add-responsive">Mac Address :</div>
                        </Col>
                        <Col sm="6">
                            <input
                                name="mac_address"
                                placeholder="Mac address"
                                onChange={handleChange}
                                value={itemGateway.mac_address}
                                className="input-style-responsive"
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                        <Col sm="4">
                            <div className="add-responsive">Serial :</div>
                        </Col>
                        <Col sm="6">
                            <input
                                name="serial"
                                placeholder="Serial"
                                onChange={handleChange}
                                value={itemGateway.serial}
                                className="input-style-responsive"
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                        <Col sm="4">
                            <div className="add-responsive">Device Group :</div>
                        </Col>
                        <Col sm="6">
                            {/* <select id={itemGateway.device_group_id} name="device_group_id" className="input-style-responsive" value={itemGateway.device_group_id} onChange={handleChange}>
                            <option disabled hidden selected> Choose device group</option>
                            {deviceGroupData.groupData.map(deviceGroup => (
                                <option value={deviceGroup.id} >{deviceGroup.group_name} </option>
                            ))}
                        </select> */}
                            <input
                                disabled
                                name="device_group_id"
                                // onChange={handleChange}
                                defaultValue={selectedNode && selectedNode.item.group_name}
                                // value={itemMeter.serial}
                                className="input-style-responsive"
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" >
                        <Col sm="4">
                            <div className="add-responsive">Hardware Model :</div>
                        </Col>
                        <Col sm="6">
                            <select id={itemGateway.hardware_id} name="hardware_id" className="input-style-responsive" value={itemGateway.hardware_id} onChange={handleChange}>
                                <option disabled hidden selected> Choose hardware model</option>
                                {hardwareData.hardware.map(hardware => (
                                    <option value={hardware.id} >{hardware.model} </option>
                                ))}
                            </select>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" >
                        <Col sm="4">
                            <div className="add-responsive">Longitude :</div>
                        </Col>
                        <Col sm="6">
                            <input
                                name="longitude"
                                placeholder="Longitude"
                                onChange={handleChange}
                                value={itemGateway.longitude}
                                className="input-style-responsive"
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" >
                        <Col sm="4">
                            <div className="add-responsive">Latitude :</div>
                        </Col>
                        <Col sm="6">
                            <input
                                name="latitude"
                                placeholder="Latitude"
                                onChange={handleChange}
                                value={itemGateway.latitude}
                                className="input-style-responsive"
                            />
                        </Col>
                    </Form.Group>
                    {getOrganizationName()}
                    <Form.Group as={Row} className="mb-3" >
                        <Col sm="4">
                            <div className="add-responsive">Customer :</div>
                        </Col>
                        <Col sm="6">
                            <select id={itemGateway.owner_user_id} name="owner_user_id" className="input-style-responsive" value={itemGateway.owner_user_id} onChange={handleChange}>
                                <option disabled hidden selected> Choose customer </option>
                                {userData.data.map(user => (
                                    <option value={user.id} >{user.username} </option>
                                ))}
                            </select>
                        </Col>
                    </Form.Group>


                    <Form.Group as={Row} className="mb-3" >
                        <Col>
                            <div className="button-container-responsive">
                                <button onClick={handleSubmit} className="add-button-style">
                                    Add
                                </button> &nbsp;&nbsp;
                                {/* {getDeleteButton(doDelete)}
                                &nbsp;&nbsp;
                                <button onClick={handleCancel} className="cancle-button-style">
                                    Cancel
                                </button> */}
                            </div>
                        </Col>
                    </Form.Group>
                </Form>
            </Col>

        </Card.Body>
    );

}