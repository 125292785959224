import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router'
import { addMoneyToWallet } from '../../services/walletUserService';
import Select from 'react-select';
import { getUserType as getUserRole, getRolePermission } from '../../services/roleService'
import SubItemHeading from '../partials/SubItemHeading';
import {
    Card,
    Container,
    Row,
    Col,
    Form
} from "react-bootstrap";
// import '../../assets/css/pages/add-edit-form.css'
import { getUserById } from '../../services/userService';
import { getCurrency } from 'services/currencyService';
import { getCurrencyNameById } from 'services/currencyService';
import { ToastContainer, toast } from 'react-toastify';
import ToastLayout from '../../views/partials/Toastify';
const getCurrencyColumn = (state, handleChange, currencyData) => {
    const { currency_id, wallet_balance } = state;
    if (wallet_balance == 0) {
        return (<Form.Group as={Row} className="mb-3" >
            <Col sm="3">
                <div className="add-responsive">Currency:</div>
            </Col>
            <Col sm="9">
                <select id={state.currency_id} name="currency_id" className="input-style-responsive" value={state.currency_id} onChange={handleChange}>
                    <option selected disabled hidden> Choose currency </option>
                    {currencyData.map(currency => (
                        <option key={currency.id} value={currency.id} >{currency.currency} </option>
                    ))}
                </select>
            </Col>
        </Form.Group>)
    } else {
        return (
            <Form.Group as={Row} className="mb-3" >
                <Col sm="3">
                    <div className="add-responsive">Currency:</div>
                </Col>
                <Col sm="9">
                    <div style={{ paddingLeft: '6px', marginTop: '10px' }}>{getCurrencyNameById(state.currency_id)}</div>
                </Col>
            </Form.Group>
        )
    }
}

export default function WalletAdd() {
    const [state, setState] = useState({
        id: 0, balance: 0, bLabel: 'Add'
    });
    const [subHeading, setHeading] = useState("Add Money to Wallet");
    const history = useHistory();
    const { walletId } = useParams(); 
    const sourceOptions = [{ value: 'Cash', label: 'Cash' },
        { value: 'Mobile Money', label: 'Mobile Money' },
        { value: 'Wire Transfer', label: 'Wire Transfer' },
        { value: 'Credit Card', label: 'Credit Card' },
        { value: 'Debit Card', label: 'Debit Card' }];
   let defaultSource=sourceOptions[0].value;
   const [sourceType, setSource] = useState(defaultSource)

    let fetchWalletUser = async (id) => {
        let walletUser = await getUserById(id);
        if (typeof (walletUser.id) !== 'undefined') {
            setState({ ...walletUser, bLabel: 'Add' })
        }
    }
    useEffect(() => {
        if (typeof (walletId) !== 'undefined') {
            fetchWalletUser(walletId);
        }
        reloadCurrency();
    }, [])
    //display currency name
    const [currencyData, setCurrencyData] = useState([]);

    const reloadCurrency = async () => {
        let currencyRecord = await getCurrency();
        setCurrencyData(currencyRecord);
    }
    const handleCancel = () => {
        history.goBack()
    }
    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    }
    const onSelect = sourceOptions => {
      setSource(sourceOptions.value);
   }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const newItem = {
            user_id: state.id,
            reference: state.reference,
            amount: state.amount,
            provider:state.provider,
            source:sourceType,
            currency_id: state.currency_id
        };
        if (!newItem.currency_id) {
            toast.error(ToastLayout("Please select currency."))
            return;
        }
        if (!newItem.amount) {
            toast.error(ToastLayout("Please enter the amount!"))
            return;
        } else {
            let response = await addMoneyToWallet(newItem);
            if (response.result && response.result == "fail") {
                toast.error(ToastLayout(response.msg))
            } else {
                history.goBack();
            }
        }
    }
    return (
        <div>
            <ToastContainer/>
            <Container fluid>
                <Row>
                    <Col md="12">
                        <Card className="striped-table-with-hover">
                            <Card.Header>
                                <SubItemHeading heading={subHeading} backButtonCall={() => { history.goBack() }} show={true} />
                            </Card.Header>
                            <Card.Body className="px-0" >

                                <Col lg="9">
                                    <Form>
                                        <Form.Group as={Row} className="mb-3" >
                                            <Col sm="3">
                                                <div className="add-responsive">Name:</div>
                                            </Col>
                                            <Col sm="9">
                                                <div style={{ paddingLeft: '6px', marginTop: '10px' }}> {state.first_name} {state.last_name} ({state.role_type}) </div>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" >
                                            <Col sm="3">
                                                <div className="add-responsive">Current Balance:</div>
                                            </Col>
                                            <Col sm="9">
                                                <div style={{ paddingLeft: '6px', marginTop: '10px' }}>{state.wallet_balance}</div>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" >
                                            <Col sm="3">
                                                <div className="add-responsive">Add Amount:</div>
                                            </Col>
                                            <Col sm="9">
                                                <input
                                                    type="number"
                                                    name="amount"
                                                    placeholder="Amount"
                                                    onChange={handleChange}
                                                    value={state.amount}
                                                    className="input-style-responsive"
                                                />
                                            </Col>
                                        </Form.Group>
                                        {getCurrencyColumn(state, handleChange, currencyData)}
                                        <Form.Group as={Row} className="mb-3" >
                                            <Col sm="3">
                                                <div className="add-responsive">Source :</div>
                                            </Col>
                                            <Col sm="6">
                                                <Select
                                                    className="dropDown-style"
                                                    defaultValue={sourceOptions[0]}
                                                    options={sourceOptions}
                                                    onChange={onSelect}
                                                    // style={{width:"50px"}}
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" >
                                            <Col sm="3">
                                                <div className="add-responsive">Reference:</div>
                                            </Col>
                                            <Col sm="9">
                                                <input
                                                    name="reference"
                                                    placeholder="Reference (if applicable)"
                                                    onChange={handleChange}
                                                    value={state.reference}
                                                    className="input-style-responsive"
                                                />
                                            </Col>
                                        </Form.Group>
                                        
                                        <Form.Group as={Row} className="mb-3" >
                                            <Col sm="3">
                                                <div className="add-responsive">Provider:</div>
                                            </Col>
                                            <Col sm="9">    
                                                <input
                                                    name="provider"
                                                    placeholder="Provider (if applicable)"
                                                    onChange={handleChange}
                                                    value={state.provider}
                                                    className="input-style-responsive"
                                                />
                                            </Col>
                                        </Form.Group>
                                        
                                        <Form.Group as={Row} className="mb-3" >
                                            <Col>
                                                <div className="button-container-responsive">
                                                    <input type="button" className="add-button-style" onClick={handleSubmit} value={state.bLabel} />
                                                    &nbsp;&nbsp;
                                                    <input type="button" onClick={handleCancel} className="cancle-button-style"
                                                        value='Cancel' />
                                                </div>
                                            </Col>
                                        </Form.Group>
                                    </Form>
                                </Col>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                </Row>
            </Container>
        </div>
    );
}