import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { BiSort } from 'react-icons/bi';
import * as moment from 'moment'
import SearchLayout from '../partials/SearchLayout';
import { getCurrencyNameById } from '../../services/currencyService';
import { getAmountSign } from '../../services/CommonUtils';
import SortingByColumn from '../partials/SortingByColumn';
import {
  Card,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import '../../assets/css/pages/table-list.css'
import { getPayments, searchPayment, getPaymentsByOrg } from '../../services/paymentService';
import { getUserType as getUserRole, getRolePermission,getOrganizationId } from '../../services/roleService';
import { ToastContainer, toast } from 'react-toastify';
import ToastLayout from '../../views/partials/Toastify';

const searchOptions = {
  searchValues: ["user.first_name", "modified_at", "energy_meter_id", "amount", "type", "reference"], //same len as values
  searchNames: ['Name', 'TXN Date', 'Meter Id', 'TXN Amount', 'Type', 'Reference'],
}
const enableAddButton = () => (getRolePermission('payments', getUserRole()) > 1);

let getAction = () => {
  const history = useHistory();
  if (getRolePermission('payments', getUserRole()) > 1) {
    return (
      <div className="attribute" data-name="Edit">Actions</div>
    );
  }
}
export default function PaymentList({ globalSelectOrganization }) {
  var paymentScreenSort;
  var payment;
  const searchInput = React.useRef();
  const [flag, setFlag] = useState();
  const [items, setItems] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [clearField, setClearField] = useState(true);
  const [inputField, setInputField] = useState({ selectField: "source" });
  const history = useHistory();
  const organization = getOrganizationId();

  useEffect(async () => {
    await reloadPayment();
    if (localStorage.getItem('paymentScreenSort') != null) {
      paymentScreenSort = JSON.parse(localStorage.getItem('paymentScreenSort'))
    }
    if (paymentScreenSort && paymentScreenSort.field != null && paymentScreenSort.ascFlag != undefined) {
      asecDsec(paymentScreenSort.field, paymentScreenSort.ascFlag, payment);
    }
  }, [globalSelectOrganization])
  // sorting    
  let asecDsec = (field, flag, items) => {
    let toggle = flag;
    if (flag == undefined) {
        toggle = 1
    }
    localStorage.setItem("paymentScreenSort", JSON.stringify({ ascFlag: toggle, field: field }));
    let sorted = SortingByColumn(field, items, toggle, setFlag)
    setItems(sorted);
    setOriginalData(sorted);
}
  let reloadPayment = async () => {
    if (globalSelectOrganization.organization_id != 0 && globalSelectOrganization.organization_id != undefined) {
       payment = await getPaymentsByOrg(globalSelectOrganization.organization_id);
      if (payment == "Unauthorized") {
        toast.error(ToastLayout("Your session has expired!"))
        localStorage.clear();
        history.push("/login")
      }
      setItems(payment);
      setOriginalData(payment);
    } else {
       payment = await getPayments();
      if (payment == "Unauthorized") {
        toast.error(ToastLayout("Your session is expired!"))
        localStorage.clear()
        history.push("/login")
      }
      setItems(payment);
      setOriginalData(payment)
    }
  }
  // get the details in table when onclick on search
  const searchDetails = async (fieldValue, dataValue) => {
    const searchParams = {
      field: fieldValue,
      data: dataValue
    };
    if (getUserRole() == "super-admin") {
      if (globalSelectOrganization.organization_id != 0 && globalSelectOrganization.organization_id != undefined) {
          searchParams.organizationId = globalSelectOrganization.organization_id
      } else {
          searchParams.organizationId = null
      }
  } else {
      searchParams.organizationId = organization
  }
    let searchItems = await searchPayment(searchParams);
    setItems(searchItems);
  }
  //Clear the details
  const clearDetails = async () => {
    setItems(originalData);
    searchInput.current.value = "";
  }
  return (
    <div>
      <Container fluid>
      <ToastContainer />
        <Row>
          <Col sm={12}>
            <Card style={{ boxShadow: '0 2px 10px -1px rgb(69 90 100 / 30%)' }}>
              <Card.Header>
                <SearchLayout
                  searchInput={searchInput}
                  searchOptions={searchOptions}
                  doSearch={searchDetails}
                  clearDetails={clearDetails}
                  addButtonCallback={() => { history.push("/organization-add") }}
                  // isAddButtonEnable={enableAddButton()} 
                  showHide={true}
                />
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0" style={{ marginTop: "0" }}>
                <DisplayPaymentList items={items}
                setItems={setItems}
                asecDsec={asecDsec}
                flag={flag}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
        </Row>
      </Container>
    </div>
  );
}
function DisplayPaymentList({ items,setItems,asecDsec,flag }) {
  return (
    <div>
      <ol className="collection collection-container">
        <li className="item item-container table-header">
          <div className="attribute-container information">
            <div className="attribute-container name-organization">
              <div className="attribute" data-name="Name">Name
                <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("first_name",flag,items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
              </div>
              <div className="attribute" data-name="Create Date">TXN Date
                <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("modified_at",flag,items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
              </div>

            </div>
            <div className="attribute-container price-currency">
              <div className="attribute" data-name="Meter Id">Meter Id
                <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("energy_meter_id",flag,items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
              </div>
              <div className="attribute" data-name="Amount">TXN Amount
                <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("amount",flag,items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
              </div>
              <div className="attribute" data-name="Type">Type
                <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("type",flag,items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
              </div>
            </div>
          </div>
          <div className="attribute-container amount-edit">
            <div className="attribute" data-name="Reference">Reference
              <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("reference",flag,items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
            </div>
            {/* {getAction()} */}
          </div>
        </li>
        {(items.length > 0) ?
          <div>
            {items.map((item) => {
              return (<li key={item.id} className="item item-container hover">
                {/* <div className="attribute" data-name="No"></div> */}
                <div className="attribute-container information">
                  <div className="attribute-container name-organization">
                    <div className="attribute" data-name="Name">{item.first_name} {item.last_name}</div>
                    <div className="attribute" data-name="Date">{moment(item.modified_at).format('DD-MMM hh:mm:ss A')}</div>

                  </div>
                  <div className="attribute-container price-currency">
                    <div className="attribute" data-name="Meter Id">{item.energy_meter_id}</div>
                    <div className="attribute" data-name="Amount">{getAmountSign(item.amount)} {item.amount} {getCurrencyNameById(item.currency_id)}</div>
                    <div className="attribute" data-name="Reference">{item.type}</div>
                  </div>
                </div>
                <div className="attribute-container amount-edit">
                  <div className="attribute" data-name="Actions">
                    <div className="attribute" data-name="Reference">{item.reference}</div>
                    {/* {getEditButton(item.id)} */}
                  </div></div>
              </li>)
            })}
          </div>
          :
          <div className="no-data-available-text">No Records Found</div>
        }
      </ol>
    </div>
  );
}

