import { apiUrl } from "./globalService";
import { getUserToken } from "./roleService";
let apiGateway = apiUrl + "gateway";
let apiDeviceGroupGateway = apiUrl + "device-group-gateway";
let apiGroupGateway = apiUrl + "add-device-group-gateway";
let apiGatewayInOrganization = apiUrl + "gateway-from-organization";
let apiGatewayInstallationOrg = apiUrl + "gateway-installation-organization";
export let getGateway = () => {
  return fetch(apiGateway, {
    method: 'get',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
      return "Unauthorized"
    });
}
export let getGatewayInOrganization = (orgId) => {
  return fetch(apiGatewayInOrganization + "/" + orgId, {
    method: 'get',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
      return "Unauthorized"
    });
}
export let getGatewayInstallationOrg = (records) => {
  return fetch(apiGatewayInstallationOrg, {
    method: 'post',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify(records)
  })
    .then(response => response.json())
    .then(response => {
      console.log("response:::",response.msg)
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}
export let addGateway = (records) => {
  return fetch(apiGateway, {
    method: 'post',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify(records)
  })
    .then(response => response.json())
    .then(response => {
      console.log("response:::",response.msg)
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}
export let deleteGateway = ({ mac_address }) => {
  return fetch(apiGateway + "/" + mac_address, {
    method: 'delete',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify({ mac_address })
  })
    .then(response => response.json())
    .then(response => {
      alert(response.msg)
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}
export let getGatewayByAddress = (mac_address) => {
  return fetch(apiGateway + "/" + mac_address, {
    method: 'get',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    }
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}
export let updateGateway = (records) => {
  return fetch(apiGateway, {
    method: 'put',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify(records)
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}
export let searchGateway = (gateway) => {
  return fetch(apiGateway+"/search", {
    method: 'post',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify(gateway)
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}
export let searchGatewayByGroup = (searchData) => {
  return fetch(apiGateway+"/searchGatewayByGroup", {
    method: 'post',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify(searchData)
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}
export let getGatewayByGroup = (groupId) => {
  return fetch(apiDeviceGroupGateway + "/" + groupId, {
    method: 'get',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    }
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}
export let addGroupGateway =async (device) => {
  return fetch(apiGroupGateway, {
    method: 'post',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify(device)
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}