export let apiUrl = 'https://api-powerblox.mobiapps.in/api/';

if (window.location.hostname == 'localhost') {
    apiUrl = 'http://localhost:8085/api/'
} else if (window.location.hostname == '127.0.0.1') {
    apiUrl = 'http://127.0.0.1:8081/api/'
} else if (window.location.hostname.endsWith('mobiapps.in')) {
    apiUrl = "https://api-powerblox.mobiapps.in/api/";
} else{
    apiUrl = "https://business-api.power-blox.com/api/";
}