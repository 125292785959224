const userKey = "userDetail"
const token = "userToken"

const [superAdmin, admin,manager, salesAgent,technician,operator, customer] = [ 
    'super-admin', //1
    'admin', //Org //2
    'manager', //Org
    'sales-agent', //Org
    'technician', //Org
    'operator',
    'customer'
];
export const roleTypes=[ // All types of roles
    superAdmin,
    admin,
    manager, //Org
    salesAgent,
    technician, //Org
    operator,
    customer
]
/* permissions 
0	No access
1	Read only
2	Read and write
3	Read, write, delete
*/ 
const allPermissionObject = {
    dashboard:{[superAdmin]:1,[admin]:1,[manager]:1, [salesAgent]:1,[technician]:1,[operator]:1,[customer]:1},
    organizations:{[superAdmin]:3,[admin]:0,[manager]:0, [salesAgent]:0,[technician]:0,[operator]:0,[customer]:0},
    users:{[superAdmin]:3,[admin]:3,[manager]:2,[salesAgent]:0,[technician]:0,[operator]:0,[customer]:0},
    rbac:{[superAdmin]:3,[admin]:3,[manager]:0,[salesAgent]:0,[technician]:0,[operator]:0,[customer]:0},
    wallet:{[superAdmin]:3,[admin]:2,[manager]:0,[salesAgent]:1,[technician]:0,[operator]:1,[customer]:0},
    payments:{[superAdmin]:3,[admin]:2,[manager]:2,[salesAgent]:1,[technician]:0,[operator]:2,[customer]:1},
    packages:{[superAdmin]:3,[admin]:3,[manager]:1,[salesAgent]:1,[technician]:0,[operator]:1,[customer]:1},
    installations:{[superAdmin]:3,[admin]:3,[manager]:2,[salesAgent]:1,[technician]:2,[operator]:1,[customer]:0},
    customers:{[superAdmin]:3,[admin]:3,[manager]:2,[salesAgent]:2,[technician]:1,[operator]:2,[customer]:0},
    gateways:{[superAdmin]:3,[admin]:3,[manager]:2, [salesAgent]:0,[technician]:2,[operator]:2,[customer]:0},
    PBXdevices:{[superAdmin]:3,[admin]:3,[manager]:2, [salesAgent]:1,[technician]:2,[operator]:1,[customer]:0},
    meters:{[superAdmin]:3,[admin]:3,[manager]:2, [salesAgent]:1,[technician]:2,[operator]:1,[customer]:1},
    node:{[superAdmin]:3,[admin]:3,[manager]:2, [salesAgent]:0,[technician]:2,[operator]:2,[customer]:0},
    vendor:{[superAdmin]:3,[admin]:0,[manager]:0, [salesAgent]:0,[technician]:0,[operator]:0,[customer]:0},
    hardware:{[superAdmin]:3,[admin]:1,[manager]:1, [salesAgent]:0,[technician]:1,[operator]:1,[customer]:0}
};
export const getRolePermission = (moduleName,role)=> {
    // console.log("moduleName:"+moduleName);
    // console.log("role:"+role);
    // valid moduleName name = dashboard
    if(!allPermissionObject[moduleName]){
        return 0;
    }
    if(!allPermissionObject[moduleName][role]){
        return 0;
    }
    return allPermissionObject[moduleName][role];
}
export const setLocalUser = (user)=> {
    //delete unwanted details
    // delete(email);
    // delete(created_by);
    localStorage.setItem(userKey, window.btoa(JSON.stringify(user)));
}

export const getLocalUser = function () {
    if(localStorage.getItem(userKey) != null){
        try{
           let user = JSON.parse(window.atob(localStorage.getItem(userKey)));
           return user;
        }catch (e){
            //localStorage.removeItem(userKey);
           return {};
        }
    }else{
        return {};
    }
}

export let processUserRoutes = (dashboardRoutes)=> {
    //let cRole = "manager";
    let cRole = getUserType();
   // console.log("login user role:::::"+cRole);
    let dRoutes = dashboardRoutes.filter((item)=>{
        let allowRoles = item.roles;
        
        if(allowRoles.indexOf(cRole) < 0){
          return false;
        } 
        else if(typeof(item.subitem) === 'undefined'){
            return true;
        }else{
            let subRoutes = item.subitem.filter((subMenu)=>{
              let subMenuRoles = subMenu.roles;
              if(subMenuRoles.indexOf(cRole) >= 0){
                return true;
              }
            })
            if(subRoutes.length > 0){
              item.subitem = subRoutes;
              return true;
            }
        }
    })
    return dRoutes;
}

export const getUserType = ()=> {
    //return "admin";
    return getLocalUser().role_type;
}
export const setUserToken = (userToken) => {
    localStorage.setItem(token,userToken);
}

export const getUserToken = () => {
   let token =  localStorage.getItem("userToken");
   return token
}
export const getOrganizationId = () => {
    return getLocalUser().organization_id;
 }

 export const getUserId = ()=> {
    return getLocalUser().id;
}
