import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { GoSearch } from 'react-icons/go'
import * as moment from 'moment'
import {
    Form,
    Card,
    Row,
    Col,
    FormControl,
} from "react-bootstrap";
import '../../assets/css/pages/table-list.css'

export default function SearchLayout({searchInput, searchOptions, doSearch, clearDetails, isAddButtonEnable, showHide, addButtonCallback, flag, handleShow,pbxFlag,handlePbxShow }) {
    /* for example
     const searchOptions = {
       searchValues:["username","first_name","last_name","email"], //same len as values
       searchNames:['Username','First Name','Last Name','Email'], 
   }*/
    const getAddbutton = () => {
        const history = useHistory();
        if (isAddButtonEnable) {
            if (!flag && !pbxFlag) {
                return (
                    <button className="btn rounded-pill add-btn" onClick={addButtonCallback}>
                        + Add
                    </button>
                );
            } else {
                if(pbxFlag){
                    return (
                        <div style={{ paddingTop: "20px", display: "inline" }}>
                            <button className="btn rounded-pill add-btn" onClick={addButtonCallback}>
                                + Add Manually
                            </button>
                            <div style={{ paddingTop: "10px" }}>
                                <button className="btn rounded-pill add-btn" onClick={() => { selectDeviceOpenModal(); }}>
                                    + Add from Gateway
                                </button>
                            </div>
                        </div>
                    );
                }else{
                    return (
                        <div style={{ paddingTop: "20px", display: "inline" }}>
                            <button className="btn rounded-pill add-btn" onClick={addButtonCallback}>
                                + Add Manually
                            </button>
                            <div style={{ paddingTop: "10px" }}>
                                <button className="btn rounded-pill add-btn" onClick={() => { selectMeterOpenModal(); }}>
                                    + Add from Gateway
                                </button>
                            </div>
                        </div>
                    );
                }
            }
        }
    }
    const selectMeterOpenModal = () => {
        handleShow();
    }
    const selectDeviceOpenModal = () => {
        handlePbxShow();
    }
    const [inputField, setInputField] = useState({ selectField: searchOptions.searchValues[0] });
    useEffect(() => {

    }, [])
    // const searchInput = React.useRef();
    const searchButton = React.createRef();
    //select column name
    const handleSelectChange = (event) => {
        setInputField(preState => {
            preState["selectField"] = event.target.value;
            preState["dataField"] = null;
            searchInput.current.value = "";
            return preState;
        });
    }
    const clearDetailsLocal = async () => {
        await clearDetails();
        setInputField({ selectField: "username", dataField: null });
        searchInput.current.value = "";
    }

    // Search field
    const handleChange = (event) => {
        setInputField(preState => {
            let dataValue = event.target.value;
            dataValue = dataValue.replace(/ /g, '');
            dataValue = dataValue.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')
            preState["dataField"] = dataValue;
            return preState;
        });
    }
    // get the details in table when onclick on search
    const searchDetails = async () => {
        if (searchInput.current.value != "") {
            let field = inputField["selectField"];
            let data = inputField["dataField"];
            doSearch(field, data);
        }
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            searchButton.current.focus();
            searchDetails();
        }
    }
    if (showHide) {
        return (
            <Row className="g-3">
                <Col md>
                    <FormControl placeholder="Search by"
                        onKeyPress={handleKeyDown} onChange={handleChange}
                        ref={searchInput} style={{ borderColor: "#ed1b24", backgroundColor: "white" }} />
                </Col>
                <Col md>
                    <Form.Select onChange={handleSelectChange} aria-label="Floating label select example" name="field" style={{ borderColor: 'red' }} >
                        {searchOptions.searchNames.map((item, index) => (
                            <option key={item} value={searchOptions.searchValues[index]}>{item}</option>
                        ))}
                    </Form.Select>
                </Col>
                <Col lg>
                    <button type="button" className="search-btn" onClick={searchDetails} ref={searchButton}>&nbsp;&nbsp;<GoSearch />&nbsp;&nbsp;&nbsp;</button>
                    <button type="button" className="search-btn" style={{ marginLeft: "10px" }} onClick={clearDetails}>&nbsp;&nbsp;&nbsp;<strong>Clear</strong>&nbsp;&nbsp;&nbsp;</button>
                    {getAddbutton()}
                </Col>
            </Row>
        );
    } else {
        return null;
    }
}