import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { FiEdit } from 'react-icons/fi';
import { BiSort } from 'react-icons/bi';
import * as moment from 'moment';
import SortingByColumn from 'views/partials/SortingByColumn';
import {
    Card,
    Container,
    Row,
    Col
} from "react-bootstrap";
import '../../assets/css/pages/table-list.css'
import { getVendor, deleteVendor, searchVendor } from '../../services/vendorService';
import SearchLayout from '../partials/SearchLayout';
import { ToastContainer, toast } from 'react-toastify';
import ToastLayout from '../../views/partials/Toastify';
import { getUserType as getUserRole, getRolePermission } from '../../services/roleService'
const searchOptions = {
    searchValues: ["company_name", "url", "email"], //same len as values
    searchNames: ['Company Name', 'Url', 'Email'],
}
const enableAddButton = () => (getRolePermission('vendor', getUserRole()) > 1);
const getEditButton = (id) => {
    return (
        <center><a href={"#/vendor-edit/" + id} style={{ color: 'black' }} >
            <FiEdit />
        </a></center>
    );
}
export default function VendorList() {
    // const [state, setState] = useState({
    //     items: []
    // });
    var vendorScreensort;
    var vendor;
    const searchInput = React.useRef();
    const [flag, setFlag] = useState();
    const [clearField, setClearField] = useState(true);
    const history = useHistory();
    const [items, setItems] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    useEffect(async () => {
        await reloadVendor();
        if (localStorage.getItem('vendorScreensort') != null) {
            vendorScreensort = JSON.parse(localStorage.getItem('vendorScreensort'))
        }
        if (vendorScreensort && vendorScreensort.field != null && vendorScreensort.ascFlag != undefined) {
            asecDsec(vendorScreensort.field, vendorScreensort.ascFlag, vendor);
        }
    }, [])
    // sorting    
    let asecDsec = (field, flag, items) => {
        let toggle = flag;
        if (flag == undefined) {
            toggle = 1
        }
        localStorage.setItem("vendorScreensort", JSON.stringify({ ascFlag: toggle, field: field }));
        let sorted = SortingByColumn(field, items, toggle, setFlag)
        setItems(sorted);
        setOriginalData(sorted);
    }
    let reloadVendor = async () => {
         vendor = await getVendor();
        if (vendor == "Unauthorized") {
            toast.error(ToastLayout("Your session has expired!"))
            localStorage.clear();
            history.push("/login")
        }
        setItems(vendor);
        setOriginalData(vendor);
    }
    // get the details in table when onclick on search
    const searchDetails = async (fieldValue, dataValue) => {
        const searchParams = {
            field: fieldValue,
            data: dataValue
        };
        let searchItems = await searchVendor(searchParams);
        setItems(searchItems);
    }
    //Clear the details
    const clearDetails = async () => {
        setItems(originalData);
        searchInput.current.value = "";
    }
    return (
        <div>
             <ToastContainer />
            <Container fluid>
                <Row>
                    <Col md="12">
                        <Card  >
                            <Card.Header>
                                <SearchLayout
                                    searchInput={searchInput}
                                    searchOptions={searchOptions}
                                    doSearch={searchDetails}
                                    clearDetails={clearDetails}
                                    addButtonCallback={() => { history.push("/vendor-add") }}
                                    isAddButtonEnable={enableAddButton()} showHide={true}
                                />
                            </Card.Header>
                            <Card.Body className="table-full-width table-responsive px-0" style={{ marginTop: "0" }}>
                                <SetVendorList items={items}
                                setItems={setItems}
                                asecDsec={asecDsec}
                                flag={flag}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                </Row>
            </Container>
        </div>
    );
}
function SetVendorList({ items,setItems,asecDsec,flag }) {
    return (
        <div>
            <ol className="collection collection-container">
                <li className="item item-container table-header">
                    {/* <div className="attribute" data-name="No"></div> */}
                    <div className="attribute-container information">
                        <div className="attribute-container name-organization">
                            <div className="attribute" data-name="Name">Company
                                <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("company_name",flag,items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
                            </div>
                        </div>
                        <div className="attribute-container price-currency">
                            <div className="attribute" data-name="URL">URL
                                <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("url",flag,items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
                            </div>
                        </div>
                        <div className="attribute-container price-currency">
                            <div className="attribute" data-name="Email">Email
                                <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("email",flag,items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
                            </div>
                        </div>
                    </div>
                    <div className="attribute-container amount-edit">
                        <div className="attribute" data-name="Edit"><center>Actions</center></div>
                    </div>
                </li>
                {(items.length > 0) ?
                    <div>
                        {items.map((item) => {
                            return (<li key={item.id} className="item item-container hover">
                                {/* <div className="attribute" data-name="No"></div> */}
                                <div className="attribute-container information">
                                    <div className="attribute-container name-organization">
                                        <div className="attribute" data-name="Name">{item.company_name}</div>
                                    </div>
                                    <div className="attribute-container price-currency">
                                        <div className="attribute" data-name="URL">{item.url}</div>
                                    </div>
                                    <div className="attribute-container price-currency">
                                        <div className="attribute" data-name="Email">{item.email}</div>
                                    </div>
                                </div>
                                <div className="attribute-container amount-edit">
                                    {getEditButton(item.id)}
                                </div>
                            </li>)
                        })}
                    </div>
                    :
                    <div className="no-data-available-text">No Records Found</div>
                }
            </ol>
        </div>

    );
}