import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router'
import { getVendor, addVendor, getVendorById, updateVendor, deleteVendor } from '../../services/vendorService';
import { BsArrowLeftShort } from "react-icons/bs";
import ToastLayout from '../../views/partials/Toastify';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip, Form
} from "react-bootstrap";
// import '../../assets/css/pages/add-edit-form.css'
import { getUserId } from 'services/roleService';
import { getUserType as getUserRole, getRolePermission } from '../../services/roleService'
export default function VendorAddEdit() {
  const [state, setState] = useState({
    id: 0, bLabel: 'Add', FormLabel: 'Add'
  });
  const history = useHistory();
  const regExp = /^[a-z0-9.]+@\w+([\.-]?\w+)*(\.\w{2,3})+$/

  const { id } = useParams();
  let fetchVendor = async (id) => {
    let vendor = await getVendorById(id);
    if (typeof (id) !== 'undefined') {
      setState({ ...vendor, bLabel: 'Update', FormLabel: 'Edit' })
    }
  }
  useEffect(() => {
    if (typeof (id) !== 'undefined') {
      fetchVendor(id);
    }
  }, [])
  let handleCancel = () => {
    history.push("/vendor");
  }
  let handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  }
  let backButton = async () => {
    history.push('/vendor')
  }
  let handleSubmit = async (e) => {
    e.preventDefault();
    const newItem = {
      company_name: state.company_name,
      url: state.url,
      telephone_number: state.telephone_number,
      email: state.email
    };
    if (regExp.test(newItem.email)) {
      if (state.id !== 0) { //update
        newItem.id = state.id;
        let response = await updateVendor(newItem);
        if (response.result == 'fail') {
          if (response.msg.errors[0].message == "unique_url must be unique") {
            toast.error(ToastLayout("Url already exists!")) 
          } else if (response.msg.errors[0].message == "unique_telephone_number must be unique") {
            toast.error(ToastLayout("Telephone Number already exists!")) 
          } else if (response.msg.errors[0].message == "unique_email must be unique") {
            toast.error(ToastLayout("email already exists!")) 
          } else {
            toast.error(ToastLayout("Url or Telephone number or Email already exists!")) 
          }
        } else {
          history.push("/vendor");
        }
      } else { //add
        let response = await addVendor(newItem);
        if (response.result == 'fail') {
          if (response.msg.errors[0].message == "unique_url must be unique") {
            toast.error(ToastLayout("Url already exists!")) 
          } else if (response.msg.errors[0].message == "unique_telephone_number must be unique") {
            toast.error(ToastLayout("Telephone Number already exists!")) 
          } else if (response.msg.errors[0].message == "unique_email must be unique") {
            toast.error(ToastLayout("email already exists!")) 
          } else {
            toast.error(ToastLayout("Url or Telephone number or Email already exists!")) 
          }
        } else {
          history.push("/vendor");
        }
      }
    }
    else {
      toast.error(ToastLayout("Add valid Email"))
    }
  }
  let doDelete = async () => {
    await deleteVendor({ id })
    history.push("/vendor");
  }
  let getDeleteButton = () => {
    const history = useHistory();
    if (getRolePermission('vendor', getUserRole()) > 2) {
      if (typeof (id) !== 'undefined')
        return (<button onClick={(e) => { e.preventDefault(); doDelete() }} className="cancle-button-style">
          Delete
        </button>);
    }
  }


  return (
    <div>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="striped-table-with-hover">
              <Card.Header>
                <div className='display-back-button'>
                  <div><button className="back" data-toggle="tooltip" title="click to go back" onClick={() => { backButton() }} ><BsArrowLeftShort /></button></div>
                  <div className="form-header-container form-header" style={{ marginTop: "5px" }}>
                    <center>{state.FormLabel} Vendors</center>
                  </div>
                </div>
              </Card.Header>
              <Card.Body className="px-0" style={{ marginTop: "0" }}>
                <Col lg="9">
                  <Form >
                    <Form.Group as={Row} className="mb-3" >
                      <Col sm="3">
                        <div className="add-responsive">Name :</div>
                      </Col>
                      <Col sm="9">
                        <input
                          name="company_name"
                          placeholder="Name"
                          onChange={handleChange}
                          value={state.company_name}
                          className="input-style-responsive"
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                      <Col sm="3">
                        <div className="add-responsive">URL :</div>
                      </Col>
                      <Col sm="9">
                        <input
                          name="url"
                          placeholder="URL"
                          onChange={handleChange}
                          value={state.url}
                          className="input-style-responsive"
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" >
                      <Col sm="3">
                        <div className="add-responsive">Telephone No :</div>
                      </Col>
                      <Col sm="9">
                        <input
                          name="telephone_number"
                          placeholder="Telephone number"
                          onChange={handleChange}
                          value={state.telephone_number}
                          className="input-style-responsive"
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                      <Col sm="3">
                        <div className="add-responsive">Email :</div>
                      </Col>
                      <Col sm="9">
                        <input
                          name="email"
                          placeholder="Email"
                          onChange={handleChange}
                          value={state.email}
                          className="input-style-responsive"
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" >
                      <Col>
                        <div className="button-container-responsive">
                          <ToastContainer />
                          <button onClick={handleSubmit} className="add-button-style">
                            {state.bLabel}
                          </button> &nbsp;&nbsp;
                          {getDeleteButton()}
                          &nbsp;&nbsp;
                          <button onClick={handleCancel} className="cancle-button-style">
                            Cancel
                          </button>
                        </div>
                      </Col>

                    </Form.Group>
                  </Form>
                </Col>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
        </Row>
      </Container>
    </div>
  );
}