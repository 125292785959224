import { apiUrl } from "./globalService";
import { getUserToken } from "./roleService";
let apiPayment = apiUrl + "transactions";
let apiPaymentPerOrganization = apiUrl + "transactions-per-organization";
//transactions is Payment Module
export const getPayments = async() => {
  return fetch(apiPayment, {
    method: 'get',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
      return "Unauthorized"
    });
}
export const getPaymentsByOrg = async(orgId) => {
  return fetch(apiPaymentPerOrganization + "/"+ orgId, {
    method: 'get',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
      return "Unauthorized"
    });
}
export let addPayment = (records) => {
  return fetch(apiPayment, {
    method: 'post',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify(records)
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}
export let deletePayment = ({ id }) => {
  return fetch(apiPayment + "/" + id, {
    method: 'delete',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify({ id })
  })
    .then(response => response.json())
    .then(response => {
      alert(response.msg)
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}
export let getPaymentById = (id) => {
  return fetch(apiPayment + "/" + id, {
    method: 'get',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    }
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}
export let updatePayment = (records) => {
  return fetch(apiPayment, {
    method: 'put',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify(records)
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}
export let searchPayment = (payment) => {
  return fetch(apiPayment+"/search", {
    method: 'post',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify(payment)
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}