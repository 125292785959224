import { apiUrl } from "./globalService";
import { getUserToken } from "./roleService";
let apiInstallation = apiUrl + "device-group";
let apiInstallationByOrg= apiUrl+"installation";
let apiInstallationPerOrg= apiUrl+"get-installation-id-per-organization";
// let installationArray = [];
export let getInstallation = () => {
    return fetch(apiInstallation + '/installation' , {
        method: 'get',
        headers: {
            'Authorization' : getUserToken(),
            'Content-Type': 'application/json;charset=utf-8'
        }
    })
        .then(response => response.json())
        .then(response => {
            
            return response;
        }).catch(function (error) {
            console.log(error);
            return "Unauthorized"
        });
}
export let getInstallationInLimit = () => {
  return fetch(apiInstallation + '/installation-in-limit' , {
      method: 'get',
      headers: {
          'Authorization' : getUserToken(),
          'Content-Type': 'application/json;charset=utf-8'
      }
  })
      .then(response => response.json())
      .then(response => {
          
          return response;
      }).catch(function (error) {
          console.log(error);
          return "Unauthorized"
      });
}
export let getInstallationById = (id) => {
//   if(installationArray.length > 0){
//     return installationArray;
// }
    return fetch(apiInstallation + "/" + id, {
      method: 'get',
      headers: {
        'Authorization' : getUserToken(),
        'Content-Type': 'application/json;charset=utf-8'
      }
    })
      .then(response => response.json())
      .then(response => {
        // installationArray = response;
        return response;
      }).catch(function (error) {
        console.log(error);
      });
  }
  export let getInstallationByOrganizationId = (organizationId) => {
    //   if(installationArray.length > 0){
    //     return installationArray;
    // }
        return fetch(apiInstallationByOrg + "/" + organizationId, {
          method: 'get',
          headers: {
            'Authorization' : getUserToken(),
            'Content-Type': 'application/json;charset=utf-8'
          }
        })
          .then(response => response.json())
          .then(response => {
            // installationArray = response;
            return response;
          }).catch(function (error) {
            console.log(error);
          });
      }
  export let addInstallation = (device) => {
    return fetch(apiInstallation, {
      method: 'post',
      headers: {
        'Authorization' : getUserToken(),
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify(device)
    })
      .then(response => response.json())
      .then(response => {
        return response;
      }).catch(function (error) {
        console.log(error);
      });
  }
  export let getInstallationPerOrganization = (device) => {
    return fetch(apiInstallationPerOrg, {
      method: 'post',
      headers: {
        'Authorization' : getUserToken(),
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify(device)
    })
      .then(response => response.json())
      .then(response => {
        return response;
      }).catch(function (error) {
        console.log(error);
      });
  }
  export let updateInstallation = (device) => {
    return fetch(apiInstallation, {
      method: 'put',
      headers: {
        'Authorization' : getUserToken(),
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify(device)
    })
      .then(response => response.json())
      .then(response => {
        return response;
      }).catch(function (error) {
        console.log(error);
      });
  }
  export let deleteInstallation = ({ id }) => {
    return fetch(apiInstallation + "/" + id, {
      method: 'delete',
      headers: {
        'Authorization' : (getUserToken()),
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify({ id })
    })
      .then(response => response.json())
      .then(response => {
        alert(response.msg)
        return response;
      }).catch(function (error) {
        console.log(error);
      });
  }  
  export let searchInstallation = (installation) => {
    return fetch(apiInstallation+"/installation/search", {
      method: 'post',
      headers: {
        'Authorization' : getUserToken(),
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify(installation)
    })
      .then(response => response.json())
      .then(response => {
        console.log("response>>>>",response);
        return response;
      }).catch(function (error) {
        console.log(error);
      });
  }
  export let searchInstallationGroup = (searchData) => {
    return fetch(apiInstallation+"/installation/searchByGroup", {
      method: 'post',
      headers: {
        'Authorization' : getUserToken(),
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify(searchData)
    })
      .then(response => response.json())
      .then(response => {
        console.log("response>>>>",response);
        return response;
      }).catch(function (error) {
        console.log(error);
      });
  }