import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { BiNotepad, BiEdit } from 'react-icons/bi'
import { BsArrowLeftShort } from "react-icons/bs";
import {
    Card,
    Container,
    Row,
    Col
} from "react-bootstrap";
import '../../assets/css/pages/table-list.css'
import Select from 'react-select';
import { getInstallation, deleteInstallation, searchInstallation } from '../../services/installationService';
import { getUserType as getUserRole, getRolePermission, getUserId } from '../../services/roleService'
import { getChildDeviceGroup, getDeviceGroupById, getDeviceGroup } from '../../services/deviceGroupService'
import { getPbxDevices, getPbxDeviceByGroup } from '../../services/deviceService'
import { useParams } from "react-router-dom";
import { getMeter, getMeterByGroup } from "../../services/meterService"
import { getGateway, getGatewayByGroup } from "../../services/gatewayService"
import { getOrganization } from 'services/organizationService';
import { getMode } from '../../services/modeService';
import { getHardware } from '../../services/hardwareService';
import { getUsers, me, getAllCustomerUsers } from 'services/userService';
import AddDeviceModal from './AddDeviceModal';
import EditMeterInstallationModal from './EditMeterInstallationModal';
import EditGatewayInstallationModal from './EditGatewayInstallationModal';
import EditPBXInstallationModal from './EditPBXInstallationModal';
import AddSubGroupModal from './AddSubGroupModal';
import TreeView from 'deni-react-treeview';
import SubItemHeading from '../partials/SubItemHeading';
import { FiEdit } from 'react-icons/fi';

const ColoredLine = ({ color }) => (
    <hr style={{ color: color, backgroundColor: color, height: 2 }} />
);

const addChildren = (initialTreeArray, parentId, childrenArray) => {
    if (initialTreeArray != null) {
        for (let i = 0; i < initialTreeArray.length; i++) {
            if (initialTreeArray[i].id == parentId) {
                initialTreeArray[i].children = [...initialTreeArray[i].children, ...childrenArray]
            } else {
                addChildren(initialTreeArray[i].children, parentId, childrenArray);
            }
        }
    }
}

export default function AssetsInstallation() {
    let subHeading = 'Installation Assests';
    const [treeItems, setTreeItems] = useState([]);
    const [selectedNode, setSelectedNode] = useState({ type: 'root', id: '', text: '', addLabel: '' });
    const [selectedNodeData, setNodeData] = useState({ assetType: '', data: [] });
    const [itemMeter, setItemMeter] = useState({})
    const [itemGateway, setItemGateway] = useState({})
    const [itemPBX, setItemPBX] = useState({})
    const [groupData, setGroupData] = useState({})
    const [asset, setAsset] = useState("Meter");
    const [SubGroup,setSubGroup]=useState("Gateway")
    //Add subgroup 
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    //Add device
    const [showDevice, setShowDevice] = useState(false);
    const handleDeviceClose = () => setShowDevice(false);
    const handleDeviceShow = () => setShowDevice(true);

    const [showEditMeterModal, setShowEditMeterModal] = useState(false);
    const handleEditMeterModalClose = () => setShowEditMeterModal(false);
    const handleEditMeterModalShow = () => setShowEditMeterModal(true);

    const [showGatewayEditModal, setShowGatewayEditModal] = useState(false);
    const handleEditGatewayModalClose = () => setShowGatewayEditModal(false);
    const handleEditGatewayModalShow = () => setShowGatewayEditModal(true);

    const [showEditPBXModal, setShowPBXEditModal] = useState(false);
    const handleEditPBXModalClose = () => setShowPBXEditModal(false);
    const handleEditPBXModalShow = () => setShowPBXEditModal(true);

    const { deviceGroupId } = useParams();
    const [checkAddOrEdit, setCheckAddOrEdit] = useState("AddMeter");
    const [selectedGroupId, setSelectedGroupId] = useState(deviceGroupId);
    const assetType = [
        { value: 'Energy Meter', label: 'Energy Meter' },
        { value: 'PBX Device', label: 'PBX Device' },
        // { value: 'Gateway', label: 'Gateway' }
    ];
    const subGroupType=[{value:'Gateway', label: 'Gateway'},
        {value:'Node', label: 'Node'},
    ]
    //Display organization name
    const [organizationData, setOrganizationData] = useState({
        orgData: []
    });
    let reloadOrganization = async () => {
        let orgRecord = await getOrganization();
        setOrganizationData({ ...organizationData, orgData: orgRecord });
    }
    //Display User name
    const [userData, setUserData] = useState({
        data: []
    });
    let reloadUser = async () => {
        let record = await getAllCustomerUsers();
        setUserData({ ...userData, data: record });
    }
    //Display device group name
    const [deviceGroupData, setDeviceGroupData] = useState({
        groupData: []
    });
    let reloadDeviceDataGroup = async () => {
        let groupRecord = await getDeviceGroup();
        setDeviceGroupData({ ...deviceGroupData, groupData: groupRecord });
    }
    //Display mode name
    const [modeData, setModeData] = useState({
        mode: []
    });
    let reloadMode = async () => {
        let modeRecord = await getMode();
        setModeData({ ...modeData, mode: modeRecord });
    }
    //Display hardware name
    const [hardwareData, setHardwareData] = useState({
        hardware: []
    });
    let reloadHardware = async () => {
        let hardwareRecord = await getHardware();
        setHardwareData({ ...hardwareData, hardware: hardwareRecord });
    }
    const [currentOrganizationName, setCurrentOrganizationName] = useState();
    const getCurrentOrganizationName = async () => {
        let record = await me();
        setCurrentOrganizationName(...record)
    }
    const getSubGroupDevices = async (groupId, mainTreeArray) => {
        let devices = [];
        let pbxDevices = await getPbxDeviceByGroup(groupId);
        let pxbDeviceArr = pbxDevices.map((item) => {
            let device = {
                key: 'device' + item.id,
                id: 'device' + item.id,
                oriId: item.id,
                text: 'PBX Device ' + item.serial,
                isLeaf: true,
                type: 'PBX Device',
                item: item
            }
            return device;
        })
        let meterDevices = await getMeterByGroup(groupId);
        let meterDeviceArr = meterDevices.map((item) => {
            let device = {
                key: 'm' + item.serial,
                id: 'meter' + item.serial,
                oriId: item.serial,
                type: 'meter',
                text: 'Meter ' + item.serial,
                isLeaf: true,
                key: 'meter' + item.serial,
                item: item
            }
            return device;
        })
        let gatewayDevices = await getGatewayByGroup(groupId);
        let gatewayDeviceArr = gatewayDevices.map((item) => {
            let device = {
                key: 'gateway' + item.id,
                id: item.id,
                oriId: item.id,
                type: 'gateway',
                text: 'Gateway ' + 'Mac Address ' + item.mac_address,
                isLeaf: true,
                key: 'gateway' + item.serial,
                item: item
            }
            return device;
        })
        devices = [...pxbDeviceArr, ...meterDeviceArr, ...gatewayDeviceArr];
        addChildren(mainTreeArray, groupId, devices);
    }
    const processBySubGroups = async (groupId, mainTreeArray) => {
        let result = await getChildDeviceGroup(groupId);
        let treeChildren = result.map((item) => {
            let subGroup = {
                key: 'group' + item.id,
                id: item.id,
                type: 'group',
                text: item.group_name +' ['+item.name+']',
                isLeaf: false,
                key: item.id,
                item: item,
                children: [
                ]
            }
            processBySubGroups(item.id, mainTreeArray);
            getSubGroupDevices(item.id, mainTreeArray);
            return subGroup;
        })
        addChildren(mainTreeArray, groupId, treeChildren);
        setTreeItems(mainTreeArray);
    }
    const updateGroupTree = async (object, type) => {
        if (type == 'root') {
            let data = [{
                key: type + object.id,
                id: object.id,
                type: 'group',
                text: object.group_name,
                isLeaf: false,
                list: object,
                item:object,
                children: [
                ]
            }];
            await processBySubGroups(object.id, data);
            await getSubGroupDevices(object.id, data);
            // setTimeout(() => { setCounter(v => v + 1) }, 700);
        }
    }
    const [gateways, setGateways] = useState([]);
    const [tempCounter, setCounter] = useState(0);
    const [meters, setMeters] = useState([]);
    const [pbxDevices, setPBXDecvices] = useState([]);
    const history = useHistory();
    let reloadgetGroupType = async () => {
        let gateways = await getGatewayByGroup(deviceGroupId);
        setGateways(gateways);
    }
    let reloadPbxdevice = async () => {
        let pbxDevices = await getPbxDeviceByGroup(deviceGroupId);
        setPBXDecvices(pbxDevices)
    }
    let reloadEnergyMeter = async () => {
        let meters = await getMeterByGroup(deviceGroupId);
        setMeters(meters);
    }
    // let reloadDeviceGroup = async () => {
    //     let group = await getDeviceGroupById(selectedGroupId);
    //     updateGroupTree(group[0], 'root');
    // }
    let reloadAllTree = () => {
        reloadgetGroupType();
        reloadPbxdevice();
        reloadEnergyMeter();
        // reloadDeviceGroup();

    }
    useEffect(() => {
        reloadAllTree();
    }, [])
    let backButton = async () => {
        history.push('/installation')
    }
    const onSelectItemHandler = async ({ id, oriId, type, text, item }) => {
        setNodeData({ assetType: type, data: [item] });
        let addLabel = '';

        if (type == "group" || type == "root") {
            addLabel = "To Group " + text;
        }
        //{type:'',id:'',text:'', addLabel:''}
        setSelectedNode({ id: id, addLabel, type, item })
        setSelectedGroupId(id)
        //Get PBX devices of selected group
        let pbxDevices = await getPbxDeviceByGroup(id);
        setPBXDecvices(pbxDevices)
        //Get Energy Meters of selected group
        let meters = await getMeterByGroup(id);
        setMeters(meters)
        //Get Gateways of selected group
        let gateways = await getGatewayByGroup(id);
        setGateways(gateways);
    }
    const getAddDeviceButton = () => {
        const history = useHistory();
        let visibility = 'hidden';
        let visibilityAddDevice='hidden';
        if(selectedNode.item && selectedNode.item.name!="installation"){
            visibilityAddDevice='visible'
        }
        if (selectedNode.type == 'group') {
            visibility = 'visible';
            return (
                <div style={{ padding: '15px 15px 15px 15px', visibility: visibility }}>
                    <div style={{ marginLeft: "15px" }}>
                        <button className="device-button-style" onClick={() => { subGroupOpenModal(); }}>+ Add Sub Group</button>
                        &nbsp;&nbsp;&nbsp;
                        <button style={{visibility: visibilityAddDevice}} className="device-button-style" onClick={() => { deviceOpenModal(); }}>+ Add Device</button>
                    </div>
                </div>
            );
        }
    }
    const subGroupOpenModal = () => {
        reloadHardware();
        reloadOrganization();
        reloadUser();
        getCurrentOrganizationName();
        handleShow();
    }
    const deviceOpenModal = () => {
        reloadOrganization();
        reloadUser();
        reloadDeviceDataGroup();
        reloadMode();
        reloadHardware();
        getCurrentOrganizationName();
        handleEditMeterModalShow();
        handleDeviceShow();

    }
    const getAddButton = () => {
        //if (selectedNode.addLabel.length > 1) {
        if (false) {
            return (
                // <button className="device-button-style" style={{ marginLeft: "15px", marginTop: "20px", marginBottom: "20px" }}>
                //     + {selectedNode.addLabel}
                // </button>
                <div style={{ display: "inline-grid", marginBottom: "20px" }}>
                    <div style={{ display: "inline-flex" }}>
                        <div style={{ marginLeft: "15px" }}><button className="device-button-style">Add</button></div>
                        <div style={{ marginLeft: "15px" }}>
                            <Select
                                className="installation-asset-dropDown-style"
                                defaultValue={assetType[0]}
                                options={assetType}
                            //   onChange={onSelect}
                            />

                        </div>
                        <div style={{ marginLeft: "15px" }}>{selectedNode.addLabel}</div>
                    </div>
                    <div style={{ display: "inline-flex" }}>
                        <div style={{ marginLeft: "15px" }}><button className="device-button-style">Add SubGroup</button></div>
                        <div style={{ marginLeft: "15px" }}>{selectedNode.addLabel}</div>
                    </div>
                </div>
            )
        } else {
            return (<></>)
        }
    }
    // Table Gateway
    function setGateway(items) {
        // if (items.length > 0 && showLabel && showLabel.length > 1) {
            let getEditButton = (item) => {
                return (
                    <center><div onClick={() => { editGatewayOpenModal(item); }} style={{ color: 'black' }} >
                        <FiEdit />
                    </div></center>
                );
            }
            let editGatewayOpenModal = (item) => {
                setItemGateway(item)
                handleEditGatewayModalShow();
                reloadOrganization();
                reloadUser();
                reloadDeviceDataGroup();
                reloadMode();
                reloadHardware();
                getCurrentOrganizationName();
            }
            return (
                <div>
                    <ol className="collection collection-container">
                        <div className='installation-label'>Gateways </div><br />
                        <li className="item item-container table-header">
                            <div className="attribute-container information">
                                <div className="attribute-container name-organization">
                                    <div className="attribute" data-name="Mac Address">Mac Address</div>
                                    <div className="attribute" data-name="Serial">Serial</div>
                                </div>
                                <div className="attribute-container price-currency">
                                <div className="attribute" data-name="Group Name">Installation</div>
                                    <div className="attribute" data-name="Hub Name">Hub Name</div>
                                </div>
                            </div>
                            <div className="attribute-container amount-edit">
                                <div className="attribute" data-name="Model">Model</div>
                                {/* <div className="attribute" data-name="Edit"><center>Actions</center></div> */}
                            </div>
                        </li>
                        {(items.length > 0) ?
                            <div>
                                {items.map((item) => {
                                    return (<li key={item.id} className="item item-container hover">
                                        <div className="attribute-container information">
                                            <div className="attribute-container name-organization">
                                                <div className="attribute" data-name="Mac Address">{item.mac_address}</div>
                                                <div className="attribute" data-name="Serial">{item.serial}</div>
                                            </div>
                                            <div className="attribute-container price-currency">
                                            <div className="attribute" data-name="Group Name">{item.group_name}</div>
                                                <div className="attribute" data-name="Hub Name">{item.hub_name}</div>
                                            </div>
                                        </div>
                                        <div className="attribute-container amount-edit">
                                            <div className="attribute" data-name="Model">{item.model}</div>
                                            {/* {getEditButton(item)} */}
                                        </div>
                                    </li>)
                                })
                                }
                            </div>
                            :
                            <div className="no-data-available-text">No Records Found</div>
                        }
                        <ColoredLine color="#ed1b24" />
                    </ol>
                </div>
            );
        // }
    }
    // Table PBX
    function setPbxDevice(pbxDevices) {
        // if (pbxDevices.length > 0 && showLabel && showLabel.length > 1) {
            const getEditButton = (item) => {
                if (getRolePermission('PBXdevices', getUserRole()) > 1) {
                    return (
                        <center><div onClick={() => { editPBXOpenModal(item); }} style={{ color: 'black' }} >
                            <FiEdit />
                        </div></center>
                    );
                }
            }
            let getAction = () => {
                const history = useHistory();
                if (getRolePermission('PBXdevices', getUserRole()) > 1) {
                    return (
                        <div className="attribute" data-name="Edit"><center>Actions</center></div>
                    );
                }
            }
            let editPBXOpenModal = (item) => {
                setItemPBX(item)
                handleEditPBXModalShow();
                reloadOrganization();
                reloadUser();
                reloadDeviceDataGroup();
                reloadMode();
                reloadHardware();
                getCurrentOrganizationName();
            }
            return (
                <div>
                    <ol className="collection collection-container">
                        <div className='installation-label' style={{ marginTop: '3%' }}>PBX Devices</div><br />
                        <li className="item item-container table-header">
                            <div className="attribute-container information">
                                <div className="attribute-container name-organization">
                                    <div className="attribute" data-name="Mac Address">Mac Address</div>
                                    <div className="attribute" data-name="Serial">Serial</div>
                                </div>
                                <div className="attribute-container price-currency">
                                <div className="attribute" data-name="Device Group">Installation</div>
                                    <div className="attribute" data-name="Hub Name">Hub Name</div>
                                </div>
                            </div>
                            <div className="attribute-container amount-edit">
                                <div className="attribute" data-name="User">Model</div>
                                {/* {getAction()} */}
                            </div>
                        </li>
                        {(pbxDevices.length > 0) ?
                            <div>
                                {pbxDevices.map((item) => {
                                    return (<li key={item.id} className="item item-container hover">
                                        <div className="attribute-container information">
                                            <div className="attribute-container name-organization">
                                                <div className="attribute" data-name="Mac Address">{item.mac_address}</div>
                                                <div className="attribute" data-name="Serial">{item.serial}</div>
                                            </div>
                                            <div className="attribute-container price-currency">
                                            <div className="attribute" data-name="Device Group">{item.group_name}</div>
                                                <div className="attribute" data-name="Hub Name">{item.hub_name}</div>
                                            </div>
                                        </div>
                                        <div className="attribute-container amount-edit">
                                            <div className="attribute" data-name="User">{item.model}</div>
                                            {/* {getEditButton(item)} */}
                                        </div>
                                    </li>)
                                })}
                            </div>
                            :
                            <div className="no-data-available-text">No Records Found</div>
                        }
                        <ColoredLine color="#ed1b24" />
                    </ol>
                </div>
            );
        // }
    }

    // Table Energy Meter
    function setEnergyMeter(meters) {
        // if (meters.length > 0 && showLabel && showLabel.length > 1) {
            let getEditButton = (item) => {
                //setSerialId(item.serial)
                if (getRolePermission('meters', getUserRole()) > 1) {
                    return (
                        <center><div onClick={() => { editMeterOpenModal(item); }} type="button" style={{ backgroundColor: 'transparent' }} ><FiEdit /></div></center>
                    );
                }
            }
            let editMeterOpenModal = (item) => {
                setItemMeter(item)
                handleEditMeterModalShow();
                setCheckAddOrEdit("EditMeter")
                reloadOrganization();
                reloadUser();
                reloadDeviceDataGroup();
                reloadMode();
                reloadHardware();
                getCurrentOrganizationName();
            }
            const getAction = () => {
                if (getRolePermission('meters', getUserRole()) > 1) {
                    return (
                        <div className="attribute" data-name="Edit"><center>Actions</center></div>
                    );
                }
            }
            return (
                <div>
                    <ol className="collection collection-container">
                        <div className='installation-label' style={{ marginTop: '3%' }}>Energy Meters</div><br />
                        <li className="item item-container table-header">
                            <div className="attribute-container information">
                                <div className="attribute-container name-organization">
                                    <div className="attribute" data-name="serial">serial</div>
                                <div className="attribute" data-name="Device Group">Installation</div>
                                </div>
                                <div className="attribute-container price-currency">
                                    <div className="attribute" data-name="Gateway Hub">Hub Name(Gateway)</div>
                                    <div className="attribute" data-name="Node Hub">Hub Name(Node)</div>
                                </div>
                            </div>
                            <div className="attribute-container amount-edit">
                            <div className="attribute" data-name="hardware model">Model</div>
                                {/* <div className="attribute" data-name="Device Group">owner</div> */}
                                {/* {getAction()} */}
                            </div>
                        </li>
                        {(meters.length > 0) ?
                            <div>
                                {meters.map((item) => {
                                    return (<li key={item.serial} className="item item-container hover">
                                        <div className="attribute-container information">
                                            <div className="attribute-container name-organization">
                                                <div className="attribute" data-name="serial">{item.serial}</div>
                                            <div className="attribute" data-name="Device Group">{item.group_name}</div>
                                            </div>
                                            <div className="attribute-container price-currency">
                                                <div className="attribute" data-name="Gateway Hub">{item.hub_name}</div>
                                                <div className="attribute" data-name="Node Hub">{item.node_hub_name}</div>
                                            </div>
                                        </div>
                                        <div className="attribute-container amount-edit">
                                        <div className="attribute" data-name="hardware model">{item.model}</div>
                                            {/* <div className="attribute" data-name="Device Group">{item.username}</div> */}
                                            {/* {getEditButton(item)} */}
                                            {/* { EditMeterInstallationModal(itemMeter,setSerialId,showEditMeterModal, handleEditMeterModalClose,organizationData,userData,deviceGroupData,modeData,hardwareData,currentOrganizationName,getUserRole) } */}
                                        </div>
                                    </li>)
                                })}
                            </div>
                            :
                            <div className="no-data-available-text">No Records Found</div>
                        }
                        <ColoredLine color="#ed1b24" />
                    </ol>
                </div>
            );
        // }
    }
    function showNodeList() {
        //useState({ assetType: '', data:[] });
        if (selectedNodeData.assetType == 'gateway') {
            return setGateway(selectedNodeData.data, selectedNodeData.assetType)
        } else if (selectedNodeData.assetType == 'PBX Device') {
            return setPbxDevice(selectedNodeData.data, selectedNodeData.assetType)
        } else if (selectedNodeData.assetType == 'meter') {
            return setEnergyMeter(selectedNodeData.data, selectedNodeData.assetType);
        }
    }

    return (
        <div>
            <Container fluid>
                <Row>
                    <Col md="12">
                        <Card className="striped-table-with-hover">
                            <Card.Header>
                                <SubItemHeading heading={subHeading} backButtonCall={backButton} show={true} />
                            </Card.Header>
                            <div>
                                {getAddDeviceButton()}
                                {getAddButton()}
                            </div>

                            {/* {AddDeviceModal(itemMeter,setItemMeter,showDevice, assetType, selectedNode, handleDeviceClose,organizationData,userData,deviceGroupData,modeData,hardwareData,currentOrganizationName,getUserRole,getUserId,reloadAllTree)} */}
                            <div className="installation-label" style={{ marginLeft: '2%' }}  >
                                {/* <TreeView
                                    className="customizable-tree"
                                    theme="classic"
                                    showCheckbox={false}
                                    items={treeItems}
                                    expanded={true}
                                    onSelectItem={onSelectItemHandler}
                                /> */}
                            </div>
                            <Card.Body className="table-full-width table-responsive px-0" style={{ marginTop: "0" }}>
                                {setGateway(gateways)}
                                {setPbxDevice(pbxDevices)}
                                {setEnergyMeter(meters)}
                                {/* {showNodeList()} */}

                                <EditMeterInstallationModal itemMeter={itemMeter}
                                    setItemMeter={setItemMeter}
                                    showEditMeterModal={showEditMeterModal}
                                    handleEditMeterModalClose={handleEditMeterModalClose}
                                    organizationData={organizationData}
                                    userData={userData}
                                    deviceGroupData={deviceGroupData}
                                    modeData={modeData}
                                    hardwareData={hardwareData}
                                    currentOrganizationName={currentOrganizationName}
                                    getUserRole={getUserRole}
                                    getUserId={getUserId}
                                    reloadAllTree={reloadAllTree}
                                    checkAddOrEdit={checkAddOrEdit}
                                />
                                <AddSubGroupModal
                                    show={show}
                                    selectedNode={selectedNode}
                                    handleClose={handleClose}
                                    groupData={groupData}
                                    setGroupData={setGroupData}
                                    organizationData={organizationData}
                                    getUserId={getUserId}
                                    subGroupType={subGroupType}
                                    SubGroup={SubGroup}
                                    setSubGroup={setSubGroup}
                                    userData={userData}
                                    hardwareData={hardwareData}
                                    getUserRole={getUserRole}
                                    currentOrganizationName={currentOrganizationName}
                                />
                                <EditGatewayInstallationModal
                                    setItemGateway={setItemGateway}
                                    itemGateway={itemGateway}
                                    showGatewayEditModal={showGatewayEditModal}
                                    handleEditGatewayModalClose={handleEditGatewayModalClose}
                                    organizationData={organizationData}
                                    userData={userData}
                                    deviceGroupData={deviceGroupData}
                                    modeData={modeData}
                                    hardwareData={hardwareData}
                                    currentOrganizationName={currentOrganizationName}
                                    getUserRole={getUserRole}
                                    getUserId={getUserId}
                                    reloadAllTree={reloadAllTree}
                                />
                                <EditPBXInstallationModal
                                    setItemPBX={setItemPBX}
                                    itemPBX={itemPBX}
                                    showEditPBXModal={showEditPBXModal}
                                    handleEditPBXModalClose={handleEditPBXModalClose}
                                    organizationData={organizationData}
                                    userData={userData}
                                    deviceGroupData={deviceGroupData}
                                    modeData={modeData}
                                    hardwareData={hardwareData}
                                    currentOrganizationName={currentOrganizationName}
                                    getUserRole={getUserRole}
                                    getUserId={getUserId}
                                    reloadAllTree={reloadAllTree}
                                />
                                <AddDeviceModal
                                    itemMeter={itemMeter}
                                    setItemMeter={setItemMeter}
                                    itemGateway={itemGateway}
                                    setItemGateway={setItemGateway}
                                    itemPBX={itemPBX}
                                    setItemPBX={setItemPBX}
                                    showDevice={showDevice}
                                    assetType={assetType}
                                    selectedNode={selectedNode}
                                    handleDeviceClose={handleDeviceClose}
                                    organizationData={organizationData}
                                    userData={userData}
                                    deviceGroupData={deviceGroupData}
                                    modeData={modeData}
                                    hardwareData={hardwareData}
                                    currentOrganizationName={currentOrganizationName}
                                    getUserRole={getUserRole}
                                    getUserId={getUserId}
                                    reloadAllTree={reloadAllTree}
                                    asset={asset}
                                    setAsset={setAsset}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                </Row>
            </Container>
        </div>
    );
}
