import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import Select from "react-select";
import { BsArrowLeftShort } from "react-icons/bs";
import {
    Card,
    Container,
    Row,
    Col,
    Form,
    Accordion
} from "react-bootstrap";
import '../../assets/css/pages/add-edit-form.css'
import { getOrganization } from '../../services/organizationService';
import { getUsers, me, getAllCustomerUsers } from '../../services/userService';
import { getGateway, getGatewayByAddress, getGatewayByGroup } from '../../services/gatewayService';
import { getMode } from '../../services/modeService';
import { getNodeInGateway, getNode, getNodeMacAddress } from '../../services/nodeService';
import { getMeterByGatewayMacAddress,updateMeterAddFromGateway, updateMeter, getMeterStateBySerial, getMeter, getMeterSerial, getSerialWifiMacIdFromGatewayMacAddress } from '../../services/meterService';
import { getHardware, getHardwareByAssetType } from '../../services/hardwareService';
import { getUserId } from '../../services/roleService';
import { getInstallation, getInstallationById } from '../../services/installationService';
import { getUserType as getUserRole, getRolePermission } from '../../services/roleService'
import { useAccordionButton } from 'react-bootstrap/AccordionButton'
import AddNodeToGatewayModal from './AddNodeToGatewayModal'
import AddMeterBatchModal from './AddMeterBatchModal'
import { ToastContainer, toast } from 'react-toastify';
import ToastLayout from '../../views/partials/Toastify';

export default function AddMeterToGateway() {
    const { gatewayId } = useParams();
    var groupId;
    var meter;
    let similarValues = [];
    let distinctValues = [];
    let similarValuesSerialMeter = [];
    let distinctValuesSerialMeter = [];
    let similarValuesDatabaseData = [];
    let distinctValuesDatabaseData = [];
    const [meterData, setMeterData] = useState([]);
    const history = useHistory();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [flagValue, setValue] = useState();
    const [showMeter, setShowMeter] = useState(false);
    const handleMeterClose = () => setShowMeter(false);
    const handleMeterShow = () => setShowMeter(true);
    const [deviceGroup, setDeviceGroup] = useState([]);
    const [nodeGroup, setNodeGroup] = useState([]);
    const [hardwareModel, setHardwareModel] = useState();
    const [selectedMeter, setSelectedMeter] = useState();
    const [selectedModbus, setSelectedModbus] = useState();
    const [mode, setMode] = useState();
    const [groupName, setGroupName] = useState();
    const [nodeHubName, setNodeHubName] = useState();
    const [customer, setCustomer] = useState();
    const [serialData, setSerialData] = useState([]);
    const [serialDataDatabase, setSerialDataDatabase] = useState([]);
    const [modbusData, setModbusData] = useState([]);
    const [similarValuesMac, setSimilarValuesMac] = useState([]);
    const [differentValuesMac, setDifferentValuesMac] = useState([]);
    const [similarValuesSerial, setSimilarValuesSerial] = useState([]);
    const [differentValuesSerial, setDifferentValuesSerial] = useState([]);
    const [numberOfNewNode, setNumberOfNewNode] = useState();
    const [numberOfNewMeter, setNumberOfNewMeter] = useState();
    const [nodeSerialWifiMacId, setNodeSerialWifiMacId] = useState();

    let fetchMeter = async (macAdress) => {
        let meter = await getMeterByGatewayMacAddress(macAdress);
        let meterSerialDatabase = await getMeter();
        let allNodeMacAddress = await getNodeMacAddress();
        let serialWifiServer = await getSerialWifiMacIdFromGatewayMacAddress(macAdress);
        meter.map(pre => {
            if (meterSerialDatabase.findIndex(preData => preData.serial == String(pre.serial_meter)) > -1) {
                similarValuesSerialMeter.push(pre)
            } else {
                distinctValuesSerialMeter.push(pre)
            }
        })
        meterSerialDatabase.map(pre => {
            if (meter.findIndex(preData => preData.serial_meter == String(pre.serial)) > -1) {
                similarValuesDatabaseData.push(pre)
            } else {
                distinctValuesDatabaseData.push(pre)
            }
        })
        setSerialDataDatabase(similarValuesDatabaseData)
        setSimilarValuesSerial(similarValuesSerialMeter)
        setDifferentValuesSerial(distinctValuesSerialMeter)
        if (distinctValuesSerialMeter.length < 2 && similarValuesSerialMeter.length < 2) {
            setNumberOfNewMeter(distinctValuesSerialMeter.length + " new meter and " + similarValuesSerialMeter.length + " known meter have been discovered.")
        } else if (distinctValuesSerialMeter.length > 1 && similarValuesSerialMeter.length < 2) {
            setNumberOfNewMeter(distinctValuesSerialMeter.length + " new meters and " + similarValuesSerialMeter.length + " known meter have been discovered.")
        } else if (distinctValuesSerialMeter.length < 2 && similarValuesSerialMeter.length > 1) {
            setNumberOfNewMeter(distinctValuesSerialMeter.length + " new meter and " + similarValuesSerialMeter.length + " known meters have been discovered.")
        } else if (distinctValuesSerialMeter.length > 1 && similarValuesSerialMeter.length > 1) {
            setNumberOfNewMeter(distinctValuesSerialMeter.length + " new meters and " + similarValuesSerialMeter.length + " known meters have been discovered.")
        }

        serialWifiServer.map(pre => {
            if (allNodeMacAddress.findIndex(preData => preData.mac_address == pre.serial_wifi_mac_id) > -1) {
                similarValues.push(pre)
            } else {
                distinctValues.push(pre)
            }
        })
        setSimilarValuesMac(similarValues)
        setDifferentValuesMac(distinctValues)
        if (distinctValues.length < 2) {
            setNumberOfNewNode(distinctValues.length + " new node has been discovered.")
        } else {
            setNumberOfNewNode(distinctValues.length + " new nodes have been discovered.")
        }
        setSerialData(meter);
        setModbusData(meter);
    }
    useEffect(async () => {
        await fetchMeter(gatewayId);
        reloadOrganization();
        reloadUser();
        reloadDeviceGroup();
        reloadNodeGroup();
        reloadInstallation();
        reloadMode();
        reloadHardware();
        reloadHardwareMeter();
        getCurrentOrganizationName();
    }, [])
    const selectMeterOpenModal = (newNode) => {
        setNodeSerialWifiMacId(newNode.serial_wifi_mac_id)
        handleShow();
    }
    const addMeterOpenModal = () => {
        handleMeterShow();
    }
    //Display organization name
    const [organizationData, setOrganizationData] = useState({
        orgData: []
    });
    let reloadOrganization = async () => {
        let orgRecord = await getOrganization();
        setOrganizationData({ ...organizationData, orgData: orgRecord });
    }
    //Display User name
    const [userData, setUserData] = useState({
        data: []
    });
    let reloadUser = async () => {
        let record = await getAllCustomerUsers();
        setUserData({ ...userData, data: record });
    }
    let reloadDeviceGroup = async () => {
        if (groupId) {
            const deviceGroupGateway = await getGatewayByGroup(groupId);
            setDeviceGroup(deviceGroupGateway);
        } else {
            let groupRecord = await getGateway();
            setDeviceGroup(groupRecord);
        }
    }
    let reloadNodeGroup = async () => {
        if (groupId) {
            const deviceGroupGateway = await getNodeInGateway(groupId);
            if (Array.isArray(deviceGroupGateway)) {
                setNodeGroup(deviceGroupGateway);
            }
        } else {
            let groupRecord = await getNode();
            if (Array.isArray(groupRecord)) {
                setNodeGroup(groupRecord);
            }
        }
    }
    const [gatewayData, setGatewayData] = useState({
        groupData: []
    });
    let reloadInstallation = async () => {
        let groupRecord = await getGatewayByAddress(gatewayId);
        setGatewayData({ ...gatewayData, groupData: groupRecord });
    }
    //Display mode name
    const [modeData, setModeData] = useState({
        mode: []
    });
    let reloadMode = async () => {
        let modeRecord = await getMode();
        setModeData({ ...modeData, mode: modeRecord });
    }
    //Display hardware name
    const [hardwareData, setHardwareData] = useState({
        hardware: []
    });
    let reloadHardware = async () => {
        let hardwareRecord = await getHardwareByAssetType("Node");
        hardwareRecord=[{id:null,model:"None"},...hardwareRecord]
        setHardwareData({ ...hardwareData, hardware: hardwareRecord });
    }
    const [hardwareDataMeter, setHardwareDataMeter] = useState({
        hardware: []
    });
    let reloadHardwareMeter = async () => {
        let hardwareRecord = await getHardwareByAssetType("Energy Meter");
        hardwareRecord=[{id:null,model:"None"},...hardwareRecord]
        setHardwareDataMeter({ ...hardwareDataMeter, hardware: hardwareRecord });
    }
    let handleCancel = () => {
        history.push("/meters");
    }
    let selectHardwareModel = (e) => {
        setMeterData({ ...meterData, ["hardware_id"]: e.id });
        setHardwareModel(e);
    }
    let selectMode = (e) => {
        setMeterData({ ...meterData, ["mode_id"]: e.id });
        setMode(e);
    }
    let selectInstallation = async (e) => {
        setMeterData({ ...meterData, ["device_group_id"]: e.id });
        let deviceGroupGateway = await getGatewayByGroup(e.id);
        setDeviceGroup(deviceGroupGateway);
        let nodeGroupGateway = await getNodeInGateway(e.id);
        setNodeGroup(nodeGroupGateway);
        let groupRecord = await getInstallationById(e.id);
        setOrganizationData(groupRecord[0])
        setGroupName(e);
    }
    // let selectGatewayHub = (e) => {
    //     setMeterData({ ...meterData, ["gateway_mac_address"]: e.mac_address });
    //     setGatewayHubName(e);
    // }
    let selectNodeHub = (e) => {
        setMeterData({ ...meterData, ["node_id "]: e.mac_address });
        setNodeHubName(e);
    }
    let selectCustomer = (e) => {
        setMeterData({ ...meterData, ["customer_id"]: e.id });
        setCustomer(e);
    }
    let handleChange = async (e) => {
        setMeterData({ ...meterData, [e.target.name]: e.target.value });
    }
    let selectMeter = async (e) => {
        let deviceGroupGateway = await getMeterStateBySerial(e.serial_meter);
        setMeterData({ ...meterData, ["serial_meter"]: e.serial_meter, ["modbus_address"]: deviceGroupGateway[0].modbus_address });
        setSelectedMeter(e);
    }
    let selectModbus = (e) => {
        setMeterData({ ...meterData, ["modbus_address"]: e.modbus_address });
        setSelectedModbus(e);
    }
    let handleSubmit = async (e) => {
        e.preventDefault();
        similarValuesSerial.map(async (data) => {
            const newItem = {
                serial: data.serial_meter,
                modbus_address: data.modbus_address,
                device_group_id: gatewayData.groupData.device_group_id,
                node_id: data.serial_wifi_mac_id,
                gateway_mac_address: gatewayId,
                created_at: Date.now(),
                created_by: getUserId(),
                modified_at: Date.now(),
                modified_by: getUserId()
            };
            let response = await updateMeterAddFromGateway(newItem);
            if (response.result == "fail") {
                toast.error(ToastLayout("Please enter unique serial!"))
               
            } else {
                let flag;
                flag = flag + 1;
                setValue(flag)
                window.location.reload();
            }
        })
        if (flagValue > 0) {
            alert(flag + " Meters updated successfully!")
        }
    }
    let backButton = () => {
        history.push("/meters");
    }
    const getButtons = () => {
        // When new meters > 0 and known meters > 0
        if (differentValuesSerial.length > 0 && similarValuesSerial.length > 0) {
            return (<div>
                <div style={{ marginTop: "20px",paddingLeft:"2%" }}>
                    <button onClick={() => { addMeterOpenModal(); }} className="btn rounded-pill add-node">
                        Add new meters
                    </button>
                    <button onClick={handleSubmit} className="btn rounded-pill add-node">
                        Update known meters
                    </button>
                </div>
            </div>);
            // When new meters > 0 and known meters = 0
        } else if (differentValuesSerial.length > 0 && similarValuesSerial.length < 1) {
            return (<div>
                <div style={{ marginTop: "20px",paddingLeft:"2%" }}>
                    <button onClick={() => { addMeterOpenModal(); }} className="btn rounded-pill add-node">
                        Add new meters
                    </button>
                </div>
            </div>);
            // When new meters = 0 and known meters > 0
        } else if (differentValuesSerial.length < 1 && similarValuesSerial.length > 0) {
            return (<div>
                <div style={{ marginTop: "20px",paddingLeft:"2%" }}>
                    <button onClick={handleSubmit} className="btn rounded-pill add-node">
                        Update known meters
                    </button>
                </div>
            </div>);
            // When new meters = 0 and known meters = 0
        } else if (differentValuesSerial.length < 1 && similarValuesSerial.length < 1) {
            return
        }
    }
    let checkEditOrAdd = () => {
        return (<input
            name="serial"
            placeholder="Serial e.g: 90869876"
            onChange={handleChange}
            type="number"
            value={meterData.serial}
            className="input-style-responsive"
        />
        );
        // }
    }
    const [currentOrganizationName, setCurrentOrganizationName] = useState();
    const getCurrentOrganizationName = async () => {
        let record = await me();
        setCurrentOrganizationName(...record)
    }
    let getOrganizationName = () => {
        if (getUserRole() === "super-admin") {
            return (
                <Form.Group as={Row} className="mb-3" >
                    <Col sm="3">
                        <div className="add-responsive">Organization :</div>
                    </Col>
                    <Col sm="9">
                        <input
                            disabled
                            name="owner_organization_id"
                            id={organizationData.organization_id}
                            value={organizationData.organization_name}
                            className="input-style-responsive"
                        />
                    </Col>
                </Form.Group>
            )
        }
        else {
            meterData.owner_organization_id = currentOrganizationName && currentOrganizationName.organization_id
            return (
                <Form.Group as={Row} className="mb-3" >
                    <Col sm="3">
                        <div className="add-responsive">Organization :</div>
                    </Col>
                    <Col sm="9">
                        <input
                            disabled
                            name="organization_id"
                            id={meterData.owner_organization_id}
                            value={currentOrganizationName && currentOrganizationName.organization_name}
                            className="input-style-responsive"
                        />
                    </Col>
                </Form.Group>
            )
        }
    }
    function CustomToggle({ children, eventKey }) {
        const decoratedOnClick = useAccordionButton(eventKey);
        return (
            <button
                type="button"
                className="btn rounded-pill add-node"
                onClick={decoratedOnClick}
            >
                {children}
            </button>
        );
    }
    const getHubName = (item) => {
        if (item.hub_name != null) {
            return (
                <div className="attribute" data-name="Hub Name" style={{ marginLeft: "10px" }}>{item.hub_name}</div>
            )
        } else {
            return (
                <div className="attribute" data-name="Node Hub Name">{item.node_hub_name}</div>
            )
        }
    }
    return (
        <div>
             <ToastContainer />
            <Container fluid>
                <Row>
                    <Col md="12">
                        <Accordion defaultActiveKey="1">
                            <Card className="striped-table-with-hover">
                                <Card.Header>
                                    <div className='display-back-button'>
                                        <div><button className="back" data-toggle="tooltip" title="click to go back" onClick={() => { backButton() }} ><BsArrowLeftShort /></button></div>
                                        <div className="form-header-container form-header" style={{ marginTop: "05px" }}>
                                        </div>
                                    </div>
                                    {(differentValuesMac.length > 0) ?
                                        <div>
                                            <div style={{paddingLeft:"2%"}}>{numberOfNewNode} All nodes must be registered before meters can be added!</div>
                                            <div style={{ marginTop: "10px" ,paddingLeft:"2%" }}>
                                                <CustomToggle className="btn rounded-pill add-node" eventKey="0">
                                                    Add Nodes
                                                </CustomToggle> &nbsp;&nbsp;
                                                &nbsp;&nbsp;
                                                <CustomToggle className="btn rounded-pill add-node" eventKey="1" >
                                                    Cancel
                                                </CustomToggle>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <div style={{paddingLeft:"2%"}}>{numberOfNewNode}</div>
                                            <div style={{paddingLeft:"2%"}}>{numberOfNewMeter}</div>
                                            {getButtons()}
                                            <div>
                                                <ol className="collection collection-container">
                                                    <li className="item item-container table-header">
                                                        <div className="attribute-container information">
                                                            <div className="attribute-container amount-edit">
                                                                <div className="attribute" data-name="Installation" style={{ marginRight: "-15px" }}>Installation</div>
                                                            </div>
                                                            <div className="attribute-container price-currency">
                                                                <div className="attribute" data-name="Hub Name">Hub Name</div>
                                                            </div>
                                                            <div className="attribute-container price-currency">
                                                                <div className="attribute" data-name="modbus">Serial</div>
                                                            </div>
                                                            <div className="attribute-container price-currency">
                                                                <div className="attribute" data-name="modbus">Address</div>
                                                            </div>

                                                        </div>
                                                        <div className="attribute-container price-currency">
                                                            <div className="attribute" data-name="Username">Customer Name</div>
                                                            <div className="attribute" data-name="Mode">Mode</div>
                                                        </div>
                                                        {/* <div className="attribute-container price-currency">
                                                        </div> */}
                                                    </li>
                                                    {(serialDataDatabase.length != 0) ?
                                                        <div>
                                                            {serialDataDatabase.map((item) => {
                                                                return (<li key={item.serial} className="item item-container hover">
                                                                    <div className="attribute-container information">
                                                                        <div className="attribute-container amount-edit">
                                                                            <div className="attribute" data-name="Installation">{item.group_name}</div>
                                                                        </div>
                                                                        <div className="attribute-container price-currency">
                                                                            {getHubName(item)}
                                                                        </div>
                                                                        <div className="attribute-container price-currency">
                                                                            <div className="attribute" data-name="modbus">{item.serial}</div>
                                                                        </div>
                                                                        <div className="attribute-container price-currency">
                                                                            <div className="attribute" data-name="modbus">{item.modbus_address}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="attribute-container price-currency">
                                                                        <div className="attribute" data-name="Username">{item.first_name} {item.last_name}</div>
                                                                        <div className="attribute" data-name="Mode">{item.mode}</div>
                                                                    </div>
                                                                </li>)
                                                            })}
                                                        </div>
                                                        :
                                                        <div className="no-data-available-text">No Records Found</div>
                                                    }
                                                </ol>
                                            </div>
                                        </div>
                                    }
                                </Card.Header>
                                <Card.Body className="table-full-width table-responsive px-0">
                                    <AddNodeToGatewayModal
                                        show={show}
                                        handleClose={handleClose}
                                        gatewayId={gatewayId}
                                        gatewayData={gatewayData}
                                        hardwareData={hardwareData}
                                        nodeSerialWifiMacId={nodeSerialWifiMacId}
                                        getUserId={getUserId}
                                    />
                                    <AddMeterBatchModal
                                        showMeter={showMeter}
                                        handleMeterClose={handleMeterClose}
                                        gatewayId={gatewayId}
                                        gatewayData={gatewayData}
                                        hardwareDataMeter={hardwareDataMeter}
                                        getUserId={getUserId}
                                        modeData={modeData}
                                        differentValuesSerial={differentValuesSerial}
                                    />
                                    {/* <Container> */}
                                    <Accordion.Collapse eventKey="0" style={{paddingLeft:"2%"}}>
                                        <Card style={{ width: "500px", marginLeft: "10px"}}>
                                            <ol className="collection collection-container" style={{ marginLeft: "0px", marginRight: "0px", marginTop: "0px", paddingTop: "0px" }}>
                                                <li className="item item-container table-header">
                                                    <div className="attribute-container information">
                                                        <div className="attribute-container amount-edit">
                                                            <div className="attribute" data-name="Node Mac Address">Node Mac Address</div>
                                                        </div>
                                                    </div>
                                                    <div className="attribute-container amount-edit">
                                                        <div className="attribute" data-name="Actions" style={{ textAlign: "center" }}>Actions</div>
                                                    </div>
                                                </li>
                                                {(differentValuesMac.length > 0) ?
                                                    <div>
                                                        {differentValuesMac && differentValuesMac.map((item, index) => {
                                                            return (
                                                                <li className="item item-container hover" key={index}>
                                                                    <div className="attribute-container information">
                                                                        <div className="attribute-container amount-edit">
                                                                            <div className="attribute" data-name="User">{item.serial_wifi_mac_id}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="attribute-container amount-edit">
                                                                        <div className="attribute" data-name="Actions" style={{ textAlign: "center" }}>
                                                                            <button className="btn rounded-pill add-node"
                                                                                onClick={() => { selectMeterOpenModal(item); }}>
                                                                                + Add Node
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </li>)
                                                        })}
                                                    </div>
                                                    :
                                                    <div className='no-data-available-text' style={{ marginTop: "10px" }}>No Record Found</div>
                                                }
                                            </ol>
                                        </Card>
                                    </Accordion.Collapse>
                                </Card.Body>
                            </Card>
                        </Accordion>
                    </Col>
                </Row>
                <Row>
                </Row>
            </Container>
        </div>
    );
}