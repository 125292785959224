import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { FiEdit } from 'react-icons/fi';
import { BiSort } from 'react-icons/bi';
import 'bootstrap/dist/css/bootstrap.css';
import SearchLayout from '../partials/SearchLayout';
import SortingByColumn from 'views/partials/SortingByColumn';
import {
    Card,
    Container,
    Row,
    Col,
} from "react-bootstrap";
import '../../assets/css/pages/table-list.css'
import '../../assets/css/pages/table-responsive.css'
import { getPackages, deletePackage, searchPackage, getPackagesByOrg } from '../../services/packageService';
import { getUserType as getUserRole, getRolePermission ,getOrganizationId} from '../../services/roleService';

const searchOptions = {
    searchValues: ["product_name", "organization_name", "price", "currency", "amount", "unit"], //same len as values
    searchNames: ['Product', 'Organization', 'Price', 'Currency', 'Amount', 'Unit'],
}
const enableAddButton = () => (getRolePermission('packages', getUserRole()) > 1);
let getAction = () => {
    const history = useHistory();
    if (getRolePermission('packages', getUserRole()) > 1) {
        return (
            <div className="attribute" data-name="Edit"><center>Actions</center></div>
        );
    }
}
const getEditButton = (packageId) => {
    if (getRolePermission('packages', getUserRole()) > 1) {
        return (
            <center><a href={"#/package-edit/" + packageId} style={{ color: 'black' }} >
                <FiEdit />
            </a></center>
        );
    }
}
export default function PackageList({ globalSelectOrganization }) {
    var packageScreenSort;
    var packages;
    const searchInput = React.useRef();
    const [state, setState] = useState({
        items: []
    });
    const [flag, setFlag] = useState();
    const [items, setItems] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    const [clearField, setClearField] = useState(true);
    const history = useHistory();
    const organization = getOrganizationId();
    useEffect(async() => {
        await reloadPackage();
        if (localStorage.getItem('packageScreenSort') != null) {
            packageScreenSort = JSON.parse(localStorage.getItem('packageScreenSort'))
        }
        if (packageScreenSort && packageScreenSort.field != null && packageScreenSort.ascFlag != undefined) {
            asecDsec(packageScreenSort.field, packageScreenSort.ascFlag, packages);
        }
    }, [globalSelectOrganization])
     // sorting    
     let asecDsec = (field, flag, items) => {
        let toggle = flag;
        if (flag == undefined) {
            toggle = 1
        }
        localStorage.setItem("packageScreenSort", JSON.stringify({ ascFlag: toggle, field: field }));
        let sorted = SortingByColumn(field, items, toggle, setFlag)
        setItems(sorted);
        setOriginalData(sorted);
    }
    let reloadPackage = async () => {
        if (globalSelectOrganization.organization_id != 0 && globalSelectOrganization.organization_id != undefined) {
             packages = await getPackagesByOrg(globalSelectOrganization.organization_id);
            if (packages == "Unauthorized") {
                alert("Your session has expired!");
                localStorage.clear();
                history.push("/login")
            }
            setItems(packages);
            setOriginalData(packages);
        } else {
             packages = await getPackages();
            if (packages == "Unauthorized") {
                alert("Your session has expired!");
                localStorage.clear();
                history.push("/login")
            }
            setItems(packages)
            setOriginalData(packages);
        }
    }
    // get the details in table when onclick on search
    const searchDetails = async (fieldValue, dataValue) => {
        const searchParams = {
            field: fieldValue,
            data: dataValue
        };
        if (getUserRole() == "super-admin") {
            if (globalSelectOrganization.organization_id != 0 && globalSelectOrganization.organization_id != undefined) {
                searchParams.organizationId = globalSelectOrganization.organization_id
            } else {
                searchParams.organizationId = null
            }
        } else {
            searchParams.organizationId = organization
        }
        let searchItems = await searchPackage(searchParams);
        setItems(searchItems);
    }
    //Clear the details
    const clearDetails = async () => {
        setItems(originalData);
        searchInput.current.value = "";
    }
    return (
        <div>
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Card style={{ boxShadow: '0 2px 10px -1px rgb(69 90 100 / 30%)' }}>
                            <Card.Header>
                                <SearchLayout
                                    searchInput={searchInput}
                                    searchOptions={searchOptions}
                                    doSearch={searchDetails}
                                    clearDetails={clearDetails}
                                    addButtonCallback={() => { history.push("/package-add") }}
                                    isAddButtonEnable={enableAddButton()} showHide={true}
                                />
                            </Card.Header>
                            <Card.Body className="table-full-width table-responsive px-0" style={{ marginTop: "0" }}>
                                <SetPackageList items={items}
                                setItems={setItems}
                                asecDsec={asecDsec}
                                flag={flag}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                </Row>
            </Container>
        </div>
    );
}
function SetPackageList({ items,setItems,asecDsec,flag }) {
    return (
        <div className="">
            <ol className="collection collection-container">
                <li className="item item-container table-header">
                    {/* <div className="attribute" data-name="No"></div> */}
                    <div className="attribute-container information">
                        <div className="attribute-container name-organization">
                            <div className="attribute" data-name="Product">Product
                                <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("product_name",flag,items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
                            </div>
                            <div className="attribute" data-name="Organization">Organization
                                <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("organization_name",flag,items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
                            </div>
                        </div>
                        <div className="attribute-container price-currency">
                            <div className="attribute" data-name="Price">Price
                                <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("price",flag,items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
                            </div>
                            <div className="attribute" data-name="Currency">Currency
                                <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("currency",flag,items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
                            </div>
                            <div className="attribute" data-name="Amount">Amount
                                <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("amount",flag,items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
                            </div>
                        </div>
                    </div>
                    <div className="attribute-container amount-edit">
                        <div className="attribute" data-name="Unit">Unit
                            <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("unit",flag,items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
                        </div>
                        {getAction()}
                    </div>
                </li>
                {(items.length > 0) ?
                    <div>
                        {items.map((item) => {
                            return (<li key={item.id} className="item item-container hover">
                                {/* <div className="attribute" data-name="No"></div> */}
                                <div className="attribute-container information">
                                    <div className="attribute-container name-organization">
                                        <div className="attribute" data-name="Product">{item.product_name}</div>
                                        <div className="attribute" data-name="Organization">{item.organization_name}</div>
                                    </div>
                                    <div className="attribute-container price-currency">
                                        <div className="attribute" data-name="Price">{item.price}</div>
                                        <div className="attribute" data-name="Currency">{item.currency}</div>
                                        <div className="attribute" data-name="Amount">{item.amount}</div>
                                    </div>
                                </div>
                                <div className="attribute-container amount-edit">
                                    <div className="attribute" data-name="Unit">{item.unit}</div>
                                    {getEditButton(item.id)}
                                </div>
                            </li>)
                        })}
                    </div>
                    :
                    <div className="no-data-available-text">No Records Found</div>
                }
            </ol>
        </div>
    );
}
