import { apiUrl } from "./globalService";
import { getUserToken } from "./roleService";
const apiWalletUser = apiUrl + "user-wallet";
const apiAddWallet= apiUrl + "user-money-wallet";
const apiWalletHistory=apiUrl + "user-wallet-transaction-history";
const apiUserWalletHistory=apiUrl + "user-wallet-history";

export const getWalletUsers = async() => {
  return fetch(apiWalletUser, {
    method: 'get',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    }
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
      return "Unauthorized"

    });
}
export const getWalletByUser = async( id ) => {
  return fetch(apiUserWalletHistory + "/" + id, {
    method: 'get',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    }
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
      return "Unauthorized"

    });
}
export const addWalletUser = async (walletUser) => {
  return fetch(apiWalletUser, {
    method: 'post',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify(walletUser)
  })
    .then(response => response.json())
    .then(response => {
      console.log(response)
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}
export const deleteWalletUser = async({ id }) => {
  return fetch(apiWalletUser + "/" + id, {
    method: 'delete',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify({ id })
  })
    .then(response => response.json())
    .then(response => {
      alert(response.msg)
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}
export const getWalletUserById = async(id) => {
  return fetch(apiWalletUser + "/" + id, {
    method: 'get',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}

export const getWalletUserTransaction = async(id) => {
  return fetch(apiWalletHistory + "/" + id, {
    method: 'get',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}

export const processPackageTxn = async(txnDataObj) => {
  return fetch(apiWalletUser + "/package-txn", {
    method: 'post',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json'
    },
    body:JSON.stringify(txnDataObj)
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}
export const getMyWallet = async() => {
  return fetch(apiWalletUser + "/me", {
    method: 'get',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}

export const updateWalletUser = async(walletUser) => {
  return fetch(apiWalletUser, {
    method: 'put',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify(walletUser)
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);

    });
}
export const addMoneyToWallet = async(walletUser) => {
  //const apiAddWallet= apiUrl + "user-money-wallet";
  return fetch(apiAddWallet, {
    method: 'post',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify(walletUser)
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);

    });
}
export const searchWalletUser = async (walletUser) => {
  return fetch(apiWalletUser+"/search", {
    method: 'post',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify(walletUser)
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}