import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { FiEdit } from 'react-icons/fi'
import { BiSort } from 'react-icons/bi';
import { SiAddthis } from 'react-icons/si'
import SearchLayout from '../partials/SearchLayout';
import { MdMoney } from 'react-icons/md'
import { getAllUsers, isRoleSuperAdmin, isRoleAdminManager } from '../../services/userService'
import SortingByColumn from 'views/partials/SortingByColumn';
import * as moment from 'moment'
import {
    Card,
    Container,
    Row,
    Col,
} from "react-bootstrap";
import '../../assets/css/pages/table-list.css'
import { getUserByOrganization, searchNonCustomerUser, me } from '../../services/userService';
import { getCurrencyNameById } from '../../services/currencyService';
import { getOrganizationId, getRolePermission, getUserType as getUserRole } from 'services/roleService';
import { ToastContainer, toast } from 'react-toastify';
import ToastLayout from '../../views/partials/Toastify';

const searchOptions = {
    // "CONCAT(first_name,' ',last_name)"
    searchValues: ["first_name", "email", "mobile_number_1", "role_type"], //same len as values
    searchNames: ['Name', 'Email', 'Mobile Number', "Role Type"],
}
const getEditButton = (userId) => {
    return (
        <span>
            &nbsp;&nbsp;&nbsp;
            <a href={"#/user-edit/" + userId} style={{ color: 'black' }} >
                <FiEdit />
            </a>
        </span>
    );
}
const getBalanceCol = (userRecord) => {
    if (userRecord.role_type == 'admin'
        || userRecord.role_type == 'manager'
        || userRecord.role_type == 'super-admin') {
        return null;
    } else {
        return (
            <span>&nbsp;
                {userRecord.wallet_balance} &nbsp;
                {getCurrencyNameById(userRecord.currency_id)}
                &nbsp;&nbsp;&nbsp;
            </span>
        )
    };
}

const getWalletCol = (userRecord) => {
    if (userRecord.role_type == 'admin'
        || userRecord.role_type == 'manager'
        || userRecord.role_type == 'super-admin') {
        return null;
    } else {
        return (
            <span>
                &nbsp;&nbsp;
                <a href={"#/user-wallet-history/" + userRecord.id} style={{ color: 'black' }} >
                    <MdMoney />
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a href={"#/add-money-wallet/" + userRecord.id} style={{ color: 'black' }} >
                    <SiAddthis />
                </a>
            </span>
        )
    };
}
const enableAddButton = () => (getRolePermission('users', getUserRole()) > 1);

export default function UserList({ globalSelectOrganization }) {
    var userScreenSort;
    var users;
    const searchInput = React.useRef();
    const [state, setState] = useState({
        organization_id: ''
    });
    const [flag, setFlag] = useState();
    const [items, setItems] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    const [clearField, setClearField] = useState(true);
    const [inputField, setInputField] = useState({ selectField: "username" });
    const params = useParams();
    const organization = getOrganizationId();
    const history = useHistory();
    const [currentUser, setCurrentUser] = useState({});

    useEffect(async () => {
        if (typeof (getOrganizationId()) !== 'undefined') {
            await fetchUserByOrganization(organization);
        }
        getCurrentOrganizationName();
        if (localStorage.getItem('userScreenSort') != null) {
            userScreenSort = JSON.parse(localStorage.getItem('userScreenSort'))
        }
        if (userScreenSort && userScreenSort.field != null && userScreenSort.ascFlag != undefined) {
            asecDsec(userScreenSort.field, userScreenSort.ascFlag, users);
        }
    }, [globalSelectOrganization])
    // sorting    
    let asecDsec = (field, flag, items) => {
        let toggle = flag;
        if (flag == undefined) {
            toggle = 1
        }
        localStorage.setItem("userScreenSort", JSON.stringify({ ascFlag: toggle, field: field }));
        let sorted = SortingByColumn(field, items, toggle, setFlag)
        setItems(sorted)
        setOriginalData(sorted)
    }
    const fetchUserByOrganization = async (organization) => {
        if (getUserRole() == "super-admin") {
            if (globalSelectOrganization.organization_id != 0 && globalSelectOrganization.organization_id != undefined) {
                users = await getUserByOrganization(globalSelectOrganization.organization_id); //get Org / Role  from token 
                if (users == "Unauthorized") {
                    toast.error(ToastLayout("Your session has expired!"))
                    localStorage.clear();
                    history.push("/login")
                } else {
                    setItems(users);
                    setOriginalData(users)
                }
            } else {
                users = await getAllUsers();
                if (users == "Unauthorized") {
                    toast.error(ToastLayout("Your session has expired!"))
                    localStorage.clear();
                    history.push("/login")
                }
                setItems(users)
                setOriginalData(users)
            }
        } else {
            users = await getUserByOrganization(organization); //get user / Role  from token 
            if (users == "Unauthorized") {
                toast.error(ToastLayout("Your session has expired!"))
                localStorage.clear();
                history.push("/login")
            } else {
                setItems(users);
                setOriginalData(users)
            }
        }
    }
    //select column name
    function handleSelectChange(event) {
        setInputField(preState => {
            preState["selectField"] = event.target.value;
            preState["dataField"] = null;
            searchInput.current.value = "";
            return preState;
        });
    }
    // search details
    const searchDetails = async (fieldValue, dataValue) => {
        let searchParams = {
            field: fieldValue,
            data: dataValue
        };
        if (getUserRole() == "super-admin") {
            if (globalSelectOrganization.organization_id != 0 && globalSelectOrganization.organization_id != undefined) {
                searchParams.organizationId = globalSelectOrganization.organization_id
            } else {
                searchParams.organizationId = null
            }
        } else {
            searchParams.organizationId = organization
        }
        let searchItems = await searchNonCustomerUser(searchParams);
        setItems(searchItems);
    }
    // Clear the details
    let clearDetails = async () => {
        setItems(originalData);
        searchInput.current.value = "";
    }
    const getCurrentOrganizationName = async () => {
        let record = await me();
        setCurrentUser(...record)
    }
    return (
        <div>
             <ToastContainer />
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Card style={{ boxShadow: '0 2px 10px -1px rgb(69 90 100 / 30%)' }}>
                            <Card.Header>
                                <SearchLayout
                                    searchInput={searchInput}
                                    searchOptions={searchOptions}
                                    doSearch={searchDetails}
                                    clearDetails={clearDetails}
                                    addButtonCallback={() => { history.push("/user-add") }}
                                    isAddButtonEnable={enableAddButton()} showHide={true}
                                />
                                {/* {getUserRole() != "super-admin" ?
                                    <div className="form-header-container form-header" style={{ color: "black" }}>
                                        {currentUser.organization_name}
                                    </div>
                                    :
                                    <div></div>
                                } */}
                            </Card.Header>
                            <Card.Body className="table-full-width table-responsive px-0" style={{ marginTop: "0px" }}>
                                <DisplayUserList items={items}
                                    setItems={setItems}
                                    asecDsec={asecDsec}
                                    flag={flag}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
function DisplayUserList({ items, setItems, asecDsec, flag }) {
    const history = useHistory();
    return (
        <ol className="collection collection-container" style={{ marginTop: "0px", paddingTop: "0px" }}>
            <li className="item item-container table-header">
                <div className="attribute-container information">
                    <div className="attribute-container name-organization">
                        <div className="attribute" data-name="Name">Name
                            <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("first_name", flag, items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
                        </div>
                        <div className="attribute" data-name="Email">Email
                            <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("email", flag, items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
                        </div>
                    </div>
                    <div className="attribute-container price-currency">
                        <div className="attribute" data-name="Mobile Number">Mobile Number
                            <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("mobile_number_1", flag, items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
                        </div>
                        <div className="attribute" data-name="Balance">Balance
                            <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("wallet_balance", flag, items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
                        </div>
                    </div>
                </div>
                <div className="attribute-container amount-edit">
                    <div className="attribute" data-name="Role Type">Role Type
                        <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("role_type", flag, items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
                    </div>
                    <div className="attribute" data-name="Wallet">Wallet</div>
                    <div className="attribute" data-name="Edit">Actions</div>
                </div>
            </li>
            {(items.length > 0) ?
                <div>
                    {items.map((item) => {
                        return (<li key={item.id} className="item item-container hover">
                            <div className="attribute-container information">
                                <div className="attribute-container name-organization">
                                    <div className="attribute" data-name="Name">{item.first_name} {item.last_name}</div>
                                    <div className="attribute" data-name="Email">{item.email}</div>
                                </div>
                                <div className="attribute-container price-currency">
                                    <div className="attribute" data-name="Mobile Number">{item.mobile_number_1}</div>
                                    <div className="attribute" data-name="Balance">
                                        {getBalanceCol(item)}</div>
                                </div>
                            </div>
                            <div className="attribute-container amount-edit">
                                <div className="attribute" data-name="Role Type">{item.role_type}</div>
                                <div className="attribute" data-name="Wallet">
                                    {getWalletCol(item)}</div>
                                <div className="attribute" data-name="Actions">
                                    {getEditButton(item.id)}
                                </div>
                            </div>
                        </li>)
                    })}
                </div>
                :
                <div className="no-data-available-text">No Records Found</div>
            }
        </ol>
    );
}

