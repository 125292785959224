import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router'
import { getPbxDevices, deletePbxDevice, getPbxDeviceByAddress, getDeviceByGatewayMacAddress, updatePbxDevice, addPbxDevice } from '../../services/deviceService';
import { BsArrowLeftShort } from "react-icons/bs";
import Select from "react-select";
import {
    Badge,
    Button,
    Card,
    Table,
    Container,
    Row,
    Col,
    Form
} from "react-bootstrap";
import { getOrganization } from '../../services/organizationService';
import { getUsers, me, getAllCustomerUsers } from '../../services/userService';
import { getGateway, getGatewayByAddress, getGatewayByGroup } from '../../services/gatewayService';
import { getHardware, getHardwareByAssetType } from '../../services/hardwareService';
import { getInstallation, getInstallationById } from '../../services/installationService';
import { getUserType as getUserRole, getRolePermission, getUserId } from '../../services/roleService'
import AddDeviceModal from './AddDeviceModal'
import { ToastContainer, toast } from 'react-toastify';
import ToastLayout from '../../views/partials/Toastify';

export default function DeviceAddEdit() {
    const [state, setState] = useState({
        mac_address: '', bLabel: 'Add', FormLabel: 'Add'
    });
    let similarValuesDevice = [];
    let distinctValuesDevice = [];
    let similarValuesDatabaseData = [];
    let distinctValuesDatabaseData = [];
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [organizationData, setOrganizationData] = useState({});
    const [groupName, setGroupName] = useState();
    const [gatewayHubName, setGatewayHubName] = useState();
    const [hardwareModel, setHardwareModel] = useState();
    const [customer, setCustomer] = useState();
    const [deviceMacId, setDeviceMacId] = useState();
    const [numberOfNewDevice, setNumberOfNewDevice] = useState();
    const [deviceDataDatabase, setDeviceDataDatabase] = useState([]);
    const [similarValuesDeviceServer, setSimilarValuesDeviceServer] = useState([]);
    const [differentValuesDeviceServer, setDifferentValuesDeviceServer] = useState([]);
    const history = useHistory();
    const { gatewayId } = useParams();
    var groupId;
    var device;
    let fetchDevice = async (device_mac_address) => {
        device = await getDeviceByGatewayMacAddress(device_mac_address);
        let deviceDatabaseRecord = await getPbxDevices();
        device.map(pre => {
            if (deviceDatabaseRecord.findIndex(preData => preData.mac_address == String(pre.device_mac_id)) > -1) {
                similarValuesDevice.push(pre)
            } else {
                distinctValuesDevice.push(pre)
            }
        })
        deviceDatabaseRecord.map(pre => {
            if (device.findIndex(preData => preData.device_mac_id == String(pre.mac_address)) > -1) {
                similarValuesDatabaseData.push(pre)
            } else {
                distinctValuesDatabaseData.push(pre)
            }
        })
        setDeviceDataDatabase(similarValuesDatabaseData)
        setSimilarValuesDeviceServer(similarValuesDevice)
        setDifferentValuesDeviceServer(distinctValuesDevice)
        if (distinctValuesDevice.length < 2 && similarValuesDevice.length < 2) {
            setNumberOfNewDevice(distinctValuesDevice.length + " new device and " + similarValuesDevice.length + " known device have been discovered.")
        } else if (distinctValuesDevice.length > 1 && similarValuesDevice.length < 2) {
            setNumberOfNewDevice(distinctValuesDevice.length + " new devices and " + similarValuesDevice.length + " known device have been discovered.")
        } else if (distinctValuesDevice.length < 2 && similarValuesDevice.length > 1) {
            setNumberOfNewDevice(distinctValuesDevice.length + " new device and " + similarValuesDevice.length + " known devices have been discovered.")
        } else if (distinctValuesDevice.length > 1 && similarValuesDevice.length > 1) {
            setNumberOfNewDevice(distinctValuesDevice.length + " new devices and " + similarValuesDevice.length + " known devices have been discovered.")
        }
    }
    useEffect(async () => {
        if (gatewayId !== undefined) {
            await fetchDevice(gatewayId);
        }
        reloadUser();
        reloadInstallation();
        // reloadDeviceGroup();
        reloadHardware();
        getCurrentOrganizationName();
    }, [])
    const [userData, setUserData] = useState({
        usrData: []
    });
    let reloadUser = async () => {
        let usrRecord = await getAllCustomerUsers();
        setUserData({ ...userData, usrData: usrRecord });
    }
    const getButtons = () => {
        // known devices > 0
        if (similarValuesDeviceServer.length > 0) {
            return (<div>
                <div style={{ marginTop: "20px", paddingLeft: "2%" }}>
                    <button onClick={handleSubmit} className="btn rounded-pill add-node">
                        Update known Devices
                    </button>
                </div>
            </div>);
        } else {
            return
        }
    }
    //Display hardware name
    const [hardwareData, setHardwareData] = useState({
        hardware: []
    });
    let reloadHardware = async () => {
        let hardwareRecord = await getHardwareByAssetType("PBX Device");
        hardwareRecord=[{id:null,model:"None"},...hardwareRecord];
        setHardwareData({ ...hardwareData, hardware: hardwareRecord });
        // if (mac_address !== undefined) {
        //     setHardwareModel(hardwareRecord.filter((pre) => {
        //         if (pre.id === device.hardware_id)
        //             return true;
        //     })[0])
        // }
    }
    const [gatewayData, setGatewayData] = useState({
        groupData: []
    });
    let reloadInstallation = async () => {
        let groupRecord = await getGatewayByAddress(gatewayId);
        setGatewayData({ ...gatewayData, groupData: groupRecord });
    }
    let handleCancel = () => {
        history.push("/pbx-device");
    }
    let selectInstallation = async (e) => {
        setState({ ...state, ["device_group_id"]: e.id });
        let deviceGroupGateway = await getGatewayByGroup(e.id);
        deviceGroupGateway = [{ mac_address: null, hub_name: "None" }, ...deviceGroupGateway]
        setDeviceGroup(deviceGroupGateway);
        let groupRecord = await getInstallationById(e.id);
        setOrganizationData(groupRecord[0])
        setGroupName(e);
    }
    let selectGatewayHub = (e) => {
        setState({ ...state, ["gateway_mac_address"]: e.mac_address });
        setGatewayHubName(e);
    }
    let selectHardwareModel = (e) => {
        setState({ ...state, ["hardware_id"]: e.id });
        setHardwareModel(e);
    }
    let selectCustomer = (e) => {
        setState({ ...state, ["owner_user_id"]: e.id });
        setCustomer(e);
    }
    let handleChange = async (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    }
    let backButton = () => {
        history.push("/pbx-device")
    }
   
    const [currentOrganizationName, setCurrentOrganizationName] = useState();
    const getCurrentOrganizationName = async () => {
        let record = await me();
        setCurrentOrganizationName(...record)
    }
    let getOrganizationName = () => {
        if (getUserRole() === "super-admin") {
            return (
                <Form.Group as={Row} className="mb-3" >
                    <Col sm="3">
                        <div className="add-responsive">Organization :</div>
                    </Col>
                    <Col sm="9">
                        <input
                            disabled
                            name="owner_organization_id"
                            id={organizationData.organization_id}
                            value={organizationData.organization_name}
                            className="input-style-responsive"
                        />
                    </Col>
                </Form.Group>
            )
        }
        else {
            state.owner_organization_id = currentOrganizationName && currentOrganizationName.organization_id
            return (
                <Form.Group as={Row} className="mb-3" >
                    <Col sm="3">
                        <div className="add-responsive">Organization :</div>
                    </Col>
                    <Col sm="9">
                        <input
                            disabled
                            name="owner_organization_id"
                            id={state.organization_id}
                            value={currentOrganizationName && currentOrganizationName.organization_name}
                            className="input-style-responsive"
                        />
                    </Col>
                </Form.Group>
            )
        }
    }
    const selectMeterOpenModal = (newDevice) => {
        setDeviceMacId(newDevice.device_mac_id)
        handleShow();
    }
    let handleSubmit = async (e) => {
        e.preventDefault();
        similarValuesDeviceServer.map(async (data) => {
        const newItem = {
            mac_address: data.device_mac_id,
            device_group_id: gatewayData.groupData.device_group_id,
            owner_organization_id: gatewayData.groupData.owner_organization_id,
            gateway_mac_address: gatewayId,
            created_at: Date.now(),
            created_by: getUserId(),
            modified_at: Date.now(),
            modified_by: getUserId(),
        };
            let response = await updatePbxDevice(newItem);
            if (response.result == "fail") {
                toast.error(ToastLayout("Please enter unique serial!"))
            } else {
                window.location.reload();
            }
    })
    }
    return (
        <div>
             <ToastContainer />
            <Container fluid>
                <Row>
                    <Col md="12">
                        <Card className="striped-table-with-hover">
                            <Card.Header>
                                <div className='display-back-button'>
                                    <div><button className="back" data-toggle="tooltip" title="click to go back" onClick={() => { backButton() }} ><BsArrowLeftShort /></button></div>
                                    <div className="form-header-container form-header" style={{ marginTop: "05px" }}>
                                        <center>Add Devices</center>
                                    </div>
                                </div>
                                <div style={{ paddingLeft: "2%" }}>{numberOfNewDevice}</div>
                            </Card.Header>
                            <Card.Body className=" px-0">
                            <div style={{paddingLeft:"2%"}}><strong>New Devices</strong></div>
                                <div>
                                    <ol className="collection collection-container">
                                        <li className="item item-container table-header">
                                            <div className="attribute-container information">
                                                <div className="attribute-container amount-edit">
                                                    <div className="attribute" data-name="Node Mac Address">Device Mac Address</div>
                                                </div>
                                            </div>
                                            <div className="attribute-container amount-edit">
                                                <div className="attribute" data-name="Actions" style={{ textAlign: "center" }}>Actions</div>
                                            </div>
                                        </li>
                                        {(differentValuesDeviceServer.length > 0) ?
                                            <div>
                                                {differentValuesDeviceServer && differentValuesDeviceServer.map((item, index) => {
                                                    return (
                                                        <li className="item item-container hover" key={index}>
                                                            <div className="attribute-container information">
                                                                <div className="attribute-container amount-edit">
                                                                    <div className="attribute" data-name="User">{item.device_mac_id}</div>
                                                                </div>
                                                            </div>
                                                            <div className="attribute-container amount-edit">
                                                                <div className="attribute" data-name="Actions" style={{ textAlign: "center" }}>
                                                                    <button className="btn rounded-pill add-node"
                                                                        onClick={() => { selectMeterOpenModal(item); }}>
                                                                        + Add Device
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </li>)
                                                })}
                                            </div>
                                            :
                                            <div className='no-data-available-text' style={{ marginTop: "10px" }}>No Records Found</div>
                                        }
                                    </ol>
                                </div>
                                <div style={{paddingLeft:"2%"}}><strong>Known Devices</strong></div>
                                {getButtons()}
                                <div style={{paddingTop:"10px"}}>
                                    <ol className="collection collection-container">
                                        <li className="item item-container table-header">
                                            {/* <div className="attribute" data-name="No"></div> */}
                                            <div className="attribute-container information">
                                                <div className="attribute-container name-organization">
                                                    <div className="attribute" data-name="Installation">Installation
                                                    </div>
                                                    <div className="attribute" data-name="Hub Name">Hub Name
                                                    </div>
                                                </div>
                                                <div className="attribute-container price-currency">
                                                    <div className="attribute" data-name="Serial">Serial
                                                    </div>
                                                    <div className="attribute" data-name="Mac Address">Mac Address
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="attribute-container amount-edit">
                                                <div className="attribute" data-name="Model">Model
                                                </div>
                                            </div>
                                        </li>
                                        {(deviceDataDatabase.length != 0) ?
                                            <div>
                                                {deviceDataDatabase.map((item) => {
                                                    return (<li key={item.id} className="item item-container hover">
                                                        {/* <div className="attribute" data-name="No"></div> */}
                                                        <div className="attribute-container information">
                                                            <div className="attribute-container name-organization">
                                                                <div className="attribute" data-name="Mac Address">{item.group_name}</div>
                                                                <div className="attribute" data-name="Serial">{item.hub_name}</div>
                                                            </div>
                                                            <div className="attribute-container price-currency">
                                                                <div className="attribute" data-name="Device Group">{item.serial}</div>
                                                                <div className="attribute" data-name="Organization">{item.mac_address}</div>
                                                            </div>
                                                        </div>
                                                        <div className="attribute-container amount-edit">
                                                            <div className="attribute" data-name="User">{item.model}</div>
                                                        </div>
                                                    </li>)
                                                })}
                                            </div>
                                            :
                                            <div className="no-data-available-text">No Records Found</div>
                                        }
                                    </ol>
                                </div>
                                <AddDeviceModal
                                    show={show}
                                    handleClose={handleClose}
                                    gatewayId={gatewayId}
                                    gatewayData={gatewayData}
                                    hardwareData={hardwareData}
                                    getUserId={getUserId}
                                    deviceMacId={deviceMacId}
                                    fetchDevice={fetchDevice}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                </Row>
            </Container>
        </div>
    );
}