import React, { useState, useContext, useEffect } from 'react';
import Select from 'react-select';
import {
   Modal,
   Row,
   Col,
   Card,
   Container,
   Form
} from "react-bootstrap";
import '../../assets/css/pages/table-list.css'
import { addDeviceGroup } from '../../services/deviceGroupService'
import { ToastContainer, toast } from 'react-toastify';
import ToastLayout from '../../views/partials/Toastify';
export default function SubGroupTypeNode({ selectedNode, groupData, setGroupData, organizationData, getUserId, subGroupType, getUserRole ,currentOrganizationName}) {
   let handleChange = (e) => {
      setGroupData({ ...groupData, [e.target.name]: e.target.value });
   }
   let handleSubmit = async (e) => {
      e.preventDefault();
      const newItem = {
         group_name: groupData.group_name,
         group_type_id: 3,
         longitude: groupData.longitude,
         latitude: groupData.latitude,
         organization_id: groupData.organization_id,
         description: groupData.description,
         parent_id: selectedNode.id,
         gateway_type: null,
         created_at: Date.now(),
         created_by: getUserId(),
         modified_at: Date.now(),
         modified_by: getUserId()
      };
      //Need to add validations in client and server side
      //Test properly
      if (newItem.organization_id) {
         if (selectedNode.item.gateway_type == "gateway") {
            await addDeviceGroup(newItem);
            window.location.reload()
         } else {
            toast.error(ToastLayout(`To add group of type 'Node' Please select any group of type 'Gateway'`))
         }
      } else { 
         toast.error(ToastLayout(`Please select organization`)) 
      }
   }
   let getOrganizationName = () => {
      if (getUserRole() === "super-admin") {
         return (
            <Form.Group as={Row} className="mb-3" >
               <Col sm="4">
                  <div className="add-responsive">Organization :</div>
               </Col>
               <Col sm="6">
                  <select id={groupData.organization_id} name="organization_id" className="input-style-responsive" value={groupData.organization_id} onChange={handleChange}>
                     <option selected disabled hidden> Choose organization </option>
                     {organizationData.orgData && organizationData.orgData.map(organization => (
                        <option value={organization.id} >{organization.organization_name} </option>
                     ))}
                  </select>
               </Col>
            </Form.Group>
         )
      }
      else {
         groupData.organization_id = currentOrganizationName && currentOrganizationName.organization_id
         return (
            <Form.Group as={Row} className="mb-3" >
               <Col sm="4">
                  <div className="add-responsive">Organization :</div>
               </Col>
               <Col sm="6">
                  <input
                     disabled
                     name="organization_id"
                     id={groupData.organization_id}
                     value={currentOrganizationName && currentOrganizationName.organization_name}
                     className="input-style-responsive"
                  />
               </Col>
            </Form.Group>
         )
      }
   }
   return (
      <Card.Body className="px-0" >
         <Col lg="9">
            <Form>
               <Form.Group as={Row} className="mb-3" >
                  <Col sm="4">
                     <div className="add-responsive">Group Name :</div>
                  </Col>
                  <Col sm="6">
                     <input
                        name="group_name"
                        placeholder="Group Name"
                        onChange={handleChange}
                        value={groupData.product_name}
                        className="input-style-responsive"
                     />
                  </Col>
               </Form.Group>

               <Form.Group as={Row} className="mb-3" >
                  <Col sm="4">
                     <div className="add-responsive">Longitude :</div>
                  </Col>
                  <Col sm="6">
                     <input
                        name="longitude"
                        placeholder="longitude"
                        onChange={handleChange}
                        value={groupData.longitude}
                        className="input-style-responsive"
                     />
                  </Col>
               </Form.Group>
               <Form.Group as={Row} className="mb-3" >
                  <Col sm="4">
                     <div className="add-responsive">Latitude :</div>
                  </Col>
                  <Col sm="6">
                     <input
                        name="latitude"
                        placeholder="Latitude"
                        onChange={handleChange}
                        value={groupData.latitude}
                        className="input-style-responsive"
                     />
                  </Col>
               </Form.Group>
               <Form.Group as={Row} className="mb-3" >
                  <Col sm="4">
                     <div className="add-responsive">Description :</div>
                  </Col>
                  <Col sm="6">
                     <input
                        name="description"
                        placeholder="Description"
                        onChange={handleChange}
                        value={groupData.description}
                        className="input-style-responsive"
                     />
                  </Col>
               </Form.Group>
               {getOrganizationName()}
               <Form.Group as={Row} className="mb-3" >
                  <Col>
                     <div className="button-container-responsive">
                     <ToastContainer />
                        <button onClick={handleSubmit} className="add-button-style">
                           Add
                        </button> &nbsp;&nbsp;
                        {/* {getDeleteButton(doDelete)}
                               &nbsp;&nbsp;
                              <button onClick={handleCancel} className="cancle-button-style">
                               Cancel
                              </button> */}
                     </div>
                  </Col>
               </Form.Group>
            </Form>
         </Col>
      </Card.Body>

   );
}