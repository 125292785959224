import React, { useState, useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import Select from 'react-select';
import {
    Modal,
    Row,
    Col,
    Card,
    Container,
    Form
} from "react-bootstrap";
import '../../assets/css/pages/table-list.css'
import { updateMeter } from '../../services/meterService';
import { addNode } from '../../services/nodeService';
import { ToastContainer, toast } from 'react-toastify';
import ToastLayout from '../../views/partials/Toastify';


export default function AddNodeToGatewayModal({ show, handleClose,gatewayId,gatewayData,nodeSerialWifiMacId, hardwareData, getUserId }) {
    const history = useHistory();
    const [selectedGateway, setSelectedGateway] = useState({});;
    const [hardwareModel, setHardwareModel] = useState();
    const [nodeData, setNodeData] = useState([]);

    let selectGatewayHub = (e) => {
        setSelectedGateway(e.mac_address)
    }
    let selectHardwareModel = (e) => {
        setNodeData({ ...nodeData, ["hardware_id"]: e.id });
        setHardwareModel(e);
    }
    let handleChange = async (e) => {
        setNodeData({ ...nodeData, [e.target.name]: e.target.value });
    }
    let handleSubmit = async (e) => {
        e.preventDefault();
        if (!nodeSerialWifiMacId.length) {
            return;
        }
        const newItem = {
            mac_address: nodeSerialWifiMacId,
            device_group_id: gatewayData.groupData.device_group_id,
            hardware_id: nodeData.hardware_id,
            organization_id: gatewayData.groupData.owner_organization_id,
            owner_user_id: null,
            created_by: getUserId(),
            created_at: Date.now(),
            modified_by: getUserId(),
            modified_at: Date.now(),
            node_hub_name: nodeData.node_hub_name,
            gateway_mac_address: gatewayId,
            id: Date.now()
        };
            if (newItem.device_group_id) {
                if (newItem.hardware_id) {
                    if (newItem.organization_id) {
                        console.log("newItem", newItem)
                        let response = await addNode(newItem);
                        if (response.result == "fail") {
                            toast.error(ToastLayout("Please enter unique mac_address!"))
                            
                        } else {
                            toast.error(ToastLayout("Node successfully added!"))
                            window.location.reload();
                        }
                    } else {
                        toast.error(ToastLayout("Please select organization"))
                     }
                } else { 
                    toast.error(ToastLayout("Please select hardware model"))
                 }
            } else {
                toast.error(ToastLayout("Please select installation Group"))
                }
        }
    
    const customStyles = {
        menu: styles => ({ ...styles, zIndex: 999 })
    }
    return (<Modal show={show} onHide={handleClose}>
         <ToastContainer />
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
            <div>
                <Form>
                    <Col md="12">
                        <Form.Group as={Row} className="mb-3" >
                            <Col sm="12">
                                <Form.Group as={Row} className="mb-3" >
                                    <Col sm="4">
                                        <div className="add-responsive">Hub Name (Node) :</div>
                                    </Col>
                                    <Col sm="6">
                                        <input
                                            name="node_hub_name"
                                            placeholder="Hub Name (Node)"
                                            onChange={handleChange}
                                            value={nodeData.node_hub_name}
                                            className="input-style-responsive"
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" >
                            <Col sm="4">
                                <div className="add-responsive">Hardware Model :</div>
                            </Col>
                            <Col sm="6" >
                                <Select
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    maxMenuHeight={200}
                                    menuPortalTarget={document.body} 
                                    className="dropDown-style"
                                    value={hardwareModel}
                                    options={hardwareData.hardware}
                                    getOptionLabel={e => e.model}
                                    getOptionValue={e => e.id}
                                    onChange={(e) => selectHardwareModel(e)}
                                />
                            </Col>
                        </Form.Group>
                    </Col>
                </Form>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <br />
            <button onClick={handleSubmit}  className="btn rounded-pill add-btn">Add Node</button>
        </Modal.Footer>
    </Modal>
    );
}