import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { FiEdit } from 'react-icons/fi';
import { BiSort } from 'react-icons/bi';
import { BsSpeedometer2 } from 'react-icons/bs';
import Select from "react-select";
import SearchLayout from '../partials/SearchLayout';
import SortingByColumn from 'views/partials/SortingByColumn';
import {
    Card,
    Container,
    Row,
    Col,
    InputGroup,
} from "react-bootstrap";
import '../../assets/css/pages/table-list.css'
import { getAllCustomerUsers, getCustomerUser, me } from '../../services/userService';
import { getOrganizationId } from 'services/roleService';
import { getOrganization, getOrganizationById } from '../../services/organizationService';
import { getUserType as getUserRole, getRolePermission } from '../../services/roleService';
import { ToastContainer, toast } from 'react-toastify';
import ToastLayout from '../../views/partials/Toastify';

const getAction = () => {
    const history = useHistory();
    if (getRolePermission('customers', getUserRole()) > 1) {
        return (
            <div className="attribute" data-name="Edit"><center>Action</center></div>
        );
    }
}
const getPaymentHeader = () => {
    const history = useHistory();
    if (getRolePermission('payments', getUserRole()) > 1) {
        return (
            <div style={{ fontSize: "15px" }}><center>Payment</center></div>
        );
    }
}
const enableAddButton = () => (getRolePermission('customers', getUserRole()) > 1);

const getPaymentButton = (customerId, history) => {
    if (getRolePermission('payments', getUserRole()) > 1) {
        return (
            <center><button className="btn rounded-pill list-btn" onClick={() => { history.push("/meter/customer/" + customerId) }}>
                + Payment
            </button></center>);
    }
}
const getEditButton = (customerId,history) => {
    if (getRolePermission('customers', getUserRole()) > 1) {
        return (
            <div className='action' style={{textAlign:"center"}}><a href={"#/customer-edit/" + customerId} style={{ color: 'black' }} >
                    <FiEdit />
                </a><a href={"#/meter/customer/" + customerId}  style={{ color: 'black',fontSize: "18px" ,marginLeft:"10px"}}>
                <BsSpeedometer2 />
            </a></div>
        );
    }
}
const searchOptions = {
    searchValues: ["first_name || last_name", "mobile_number_1", "language_name"], //same len as values
    searchNames: ['Name', 'Mobile', 'Language'],
}
export default function CustomerList({ globalSelectOrganization }) {
    var customerScreenSort;
    var users;
    const searchInput = React.useRef();
    const [state, setState] = useState({
        organization_id: ''
    });
    const [organizationName, setOrganizationname] = useState({
        organizationDetails: []
    })
    const [defaultOrganization, setOrganization] = useState({
        org: {}
    });
    const [flag, setFlag] = useState();
    const [items, setItems] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    const [clearField, setClearField] = useState(true);
    const params = useParams();
    const organization = getOrganizationId();
    const history = useHistory();
    const [currentUser, setCurrentUser] = useState({});
    const [organizationid, setOrganizationId] = useState(organization)
    useEffect(async() => {
        if (typeof (getOrganizationId()) !== 'undefined') {
            await fetchUserByOrganization(organization);
        }
        if (localStorage.getItem('customerScreenSort') != null) {
            customerScreenSort = JSON.parse(localStorage.getItem('customerScreenSort'))
        }
        if (customerScreenSort && customerScreenSort.field != null && customerScreenSort.ascFlag != undefined) {
            asecDsec(customerScreenSort.field, customerScreenSort.ascFlag, users);
        }
        // reloadOrganization();
        // organizationbyName();
        getCurrentOrganizationName()
    }, [globalSelectOrganization])
     // sorting    
     let asecDsec = (field, flag, items) => {
        let toggle = flag;
        if (flag == undefined) {
            toggle = 1
        }
        localStorage.setItem("customerScreenSort", JSON.stringify({ ascFlag: toggle, field: field }));
        let sorted = SortingByColumn(field, items, toggle, setFlag)
        setItems(sorted)
    }
    const fetchUserByOrganization = async () => {
        if (globalSelectOrganization.organization_id != 0 && globalSelectOrganization.organization_id != undefined) {
             users = await getCustomerUser(globalSelectOrganization.organization_id); //get Org / Role  from token 
            if (users == "Unauthorized") {
                toast.error(ToastLayout("Your session has expired!"))
                localStorage.clear();
                history.push("/login")
            } else {
                setItems(users);
                setOriginalData(users);
            }
        } else {
             users = await getAllCustomerUsers();
            if (users == "Unauthorized") {
                toast.error(ToastLayout("Your session has expired!"))
                localStorage.clear();   
                history.push("/login")
            }
            setItems(users);
            setOriginalData(users)
        }
    }
    const onSelect = async (e) => {
        let organizations = await getCustomerUser(e.id)
        setItems(organizations)
        let orgName = await getOrganizationById(e.id)
        setOrganization({ ...defaultOrganization, org: orgName });

    }
    // get the details in table when onclick on search
    const searchDetails = async (fieldValue, dataValue) => {
        const searchParams = {
            field: fieldValue,
            data: dataValue
        };
        if (getUserRole() == "super-admin") {
            if (globalSelectOrganization.organization_id != 0 && globalSelectOrganization.organization_id != undefined) {
                searchParams.organizationId = globalSelectOrganization.organization_id
            } else {
                searchParams.organizationId = null
            }
        } else {
            searchParams.organizationId = organization
        }
        let searchItems = await getAllCustomerUsers(searchParams);
        setItems(searchItems);
    }

    //Clear the details
    const clearDetails = async () => {
        setItems(originalData);
        searchInput.current.value = "";       
    }
    const getCurrentOrganizationName = async () => {
        let record = await me();
        setCurrentUser(...record)
    }
    return (
        <div>
             <ToastContainer />
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Card style={{ boxShadow: '0 2px 10px -1px rgb(69 90 100 / 30%)' }}>
                            <Card.Header>
                                <SearchLayout
                                    searchInput={searchInput}
                                    searchOptions={searchOptions}
                                    doSearch={searchDetails}
                                    clearDetails={clearDetails}
                                    addButtonCallback={() => { history.push("/customer-add") }}
                                    isAddButtonEnable={enableAddButton()} showHide={true} />
                            </Card.Header>
                            <Card.Body className="table-full-width table-responsive px-0" style={{ marginTop: "0px" }}>
                                <DisplayCustomerList items={items}
                                    setItems={setItems}
                                    asecDsec={asecDsec}
                                    flag={flag}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
function DisplayCustomerList({ items, setItems,asecDsec,flag }) {
    const history = useHistory();
    return (
        <ol className="collection collection-container" style={{ marginTop: "0px", paddingTop: "0px" }}>
            <li className="item item-container table-header">
                <div className="attribute-container information">
                    <div className="attribute-container price-currency">
                        <div className="attribute" data-name="Name">Name
                            <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("first_name",flag,items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
                        </div>
                        <div className="attribute" data-name="Mobile">Mobile
                            <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("mobile_number_1",flag,items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
                        </div>
                        <div className="attribute" data-name="Language">Language
                            <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("language_name",flag,items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
                        </div>
                    </div>
                </div>
                <div className="attribute-container amount-edit">
                    {getAction()}
                    {/* {getPaymentHeader()} */}
                </div>
            </li>
            {(items.length > 0) ?
                <div>
                    {items.map((item) => {
                        return (<li key={item.id} className="item item-container hover">
                            <div className="attribute-container information">
                                <div className="attribute-container amount-edit">
                                    <div className="attribute" data-name=" Name">{item.first_name} {item.last_name}</div>
                                    <div className="attribute" data-name="Mobile">{item.mobile_number_1}</div>
                                    <div className="attribute" data-name="Language">{item.language_name}</div>
                                </div>
                            </div>
                            <div className="attribute-container amount-edit">
                                <div className="attribute" data-name="Actions">
                                    {getEditButton(item.id,history)}
                                </div>
                            </div>
                        </li>)
                    })}
                </div>
                :
                <div className="no-data-available-text">No Records Found</div>
            }
        </ol>
    );
}

