import React, { useState, useContext, useEffect } from 'react';
import Select from 'react-select';
import {
    Modal,
    Row,
    Col,
    Card,
    Container,
    Form
} from "react-bootstrap";
import '../../assets/css/pages/table-list.css'
import { addMeter } from '../../services/meterService';
import { ToastContainer, toast } from 'react-toastify';
import ToastLayout from '../../views/partials/Toastify';
export default function AddMeter({ itemMeter, setItemMeter, organizationData, userData, deviceGroupData, modeData, hardwareData, currentOrganizationName, getUserRole, getUserId, reloadAllTree, selectedNode }) {

    let handleChange = (e) => {
        let name = e.target.name
        setItemMeter((preData) => {
            preData[e.target.name] = e.target.value
            return { ...preData };
        })
    }
    let handleSubmit = (e) => {
        e.preventDefault();
        const newItem = {
            serial: itemMeter.serial,
            hardware_id: itemMeter.hardware_id,
            mode_id: itemMeter.mode_id,
            device_group_id: selectedNode.item.id,
            customer_id: itemMeter.customer_id,
            owner_organization_id: itemMeter.owner_organization_id,
            created_at: Date.now(),
            created_by: getUserId(),
            modified_at: Date.now(),
            modified_by: getUserId()
        };
        newItem.serial = itemMeter.serial;
        if (newItem.hardware_id) {
            if (newItem.mode_id) {
                if (newItem.device_group_id) {
                    if (newItem.customer_id) {
                        if (newItem.owner_organization_id) {
                            addMeter(newItem);
                            window.location.reload();
                        } else {
                            toast.error(ToastLayout("Please select organnization"))
                        }
                    } else {
                        toast.error(ToastLayout("Please select coustomer"))
                    }
                } else {
                    toast.error(ToastLayout("Please select device group"))
                }
            } else {
                toast.error(ToastLayout("Please select mode"))
            }
        } else {
            toast.error(ToastLayout("Please select hardware model"))
        }

    }
    let getOrganizationName = () => {
        if (getUserRole() === "super-admin") {
            return (
                <Form.Group as={Row} className="mb-3" >
                    <Col sm="4">
                        <div className="add-responsive">Organization :</div>
                    </Col>
                    <Col sm="6">
                        <select id={itemMeter.owner_organization_id} name="owner_organization_id" className="input-style-responsive" value={itemMeter.owner_organization_id} onChange={handleChange}>
                            <option disabled selected hidden> Choose organization </option>
                            {organizationData.orgData && organizationData.orgData.map(organization => (
                                <option value={organization.id} >{organization.organization_name} </option>
                            ))}
                        </select>
                    </Col>
                </Form.Group>
            )
        }
        else {
            itemMeter.owner_organization_id = currentOrganizationName && currentOrganizationName.organization_id
            return (
                <Form.Group as={Row} className="mb-3" >
                    <Col sm="4">
                        <div className="add-responsive">Organization :</div>
                    </Col>
                    <Col sm="4">
                        <input
                            disabled
                            name="organization_id"
                            id={itemMeter.owner_organization_id}
                            value={currentOrganizationName && currentOrganizationName.organization_name}
                            className="input-style-responsive"
                        />
                    </Col>
                </Form.Group>
            )
        }
    }
    return (
        <Card.Body className="table-full-width table-responsive px-0">
            <ToastContainer />
            <Col lg="9">
                <Form >
                    <Form.Group as={Row} className="mb-3" >
                        <Col sm="4">
                            <div className="add-responsive">Serial :</div>
                        </Col>
                        <Col sm="6">
                            <input
                                name="serial"
                                placeholder="Serial"
                                onChange={handleChange}
                                defaultValue=""
                                value={itemMeter.serial}
                                className="input-style-responsive"
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                        <Col sm="4">
                            <div className="add-responsive">Hardware Model :</div>
                        </Col>
                        <Col sm="6">
                            <select id={itemMeter.hardware_id} name="hardware_id" className="input-style-responsive" value={itemMeter.hardware_id} onChange={handleChange}>
                                <option disabled selected hidden> Choose hardware model</option>
                                {hardwareData.hardware.map(hardware => (
                                    <option value={hardware.id} >{hardware.model} </option>
                                ))}
                            </select>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                        <Col sm="4">
                            <div className="add-responsive">Mode :</div>
                        </Col>
                        <Col sm="6">
                            <select id={itemMeter.mode_id} name="mode_id" className="input-style-responsive" value={itemMeter.mode_id} onChange={handleChange}>
                                <option disabled selected hidden> Choose mode</option>
                                {modeData.mode.map(mode => (
                                    <option value={mode.id} >{mode.mode} </option>
                                ))}
                            </select>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                        <Col sm="4">
                            <div className="add-responsive">Device Group :</div>
                        </Col>
                        <Col sm="6">
                            <input
                                disabled
                                name="device_group_id"
                                // onChange={handleChange}
                                defaultValue={selectedNode && selectedNode.item.group_name}
                                // value={itemMeter.serial}
                                className="input-style-responsive"
                            />
                            {/* <select name="device_group_id" className="input-style-responsive" value={selectedNode && selectedNode.item.id} onChange={handleChange}>
                                <option disabled selected hidden> Choose device group</option>
                                {deviceGroupData.groupData.map(deviceGroup => (
                                    <option value={deviceGroup.id} >{deviceGroup.group_name} </option>
                                ))}
                            </select> */}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                        <Col sm="4">
                            <div className="add-responsive">Customer :</div>
                        </Col>
                        <Col sm="6">
                            <select id={itemMeter.customer_id} name="customer_id" className="input-style-responsive" value={itemMeter.customer_id} onChange={handleChange}>
                                <option disabled selected hidden> Choose customer </option>
                                {userData.data.map(user => (
                                    <option value={user.id} >{user.username} </option>
                                ))}
                            </select>
                        </Col>
                    </Form.Group>
                    {getOrganizationName()}
                    <Form.Group as={Row} className="mb-3" >
                        <Col>
                            <div className="button-container-responsive">
                                <button onClick={handleSubmit} className="add-button-style">Add</button> &nbsp;&nbsp;
                            </div>
                        </Col>
                    </Form.Group>
                </Form>
            </Col>
        </Card.Body>
    );
}