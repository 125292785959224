import React, { Component,useState } from "react";
import { useLocation,useHistory, NavLink, Link } from "react-router-dom";
import { Nav,Accordion,NavDropdown } from "react-bootstrap";
import { getUserType } from "services/roleService";

function Sidebar({ color, image, routes }) {
  const location = useLocation();
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  return (
    <div className="sidebar" data-image={image} data-color={color}>
      <div
        className="sidebar-background"
        style={{
          backgroundImage: "url('/powerblox_sidebar_image.jpg')",
        }}
      />
      <div className="sidebar-wrapper">
        <div className="logo d-flex align-items-center justify-content-start">
          <a
            className="simple-text logo-mini mx-1"
          >
              <img
                src="/PowerBlox_logo_img.png"
                style={{width:"195px",height:"35px"}}
              />
          </a>
        </div>
        <Nav>
          {routes.map((prop, key) => {
            if (!prop.redirect)
              return (
                <li
                  className={
                    prop.upgrade
                      ? "active active-pro"
                      : activeRoute(prop.path)
                  }
                  key={key}
                >
                  {prop.subitem ? 
                  <Accordion defaultActiveKey="0">
                  <Accordion.Item style={{background:"transparent",border:"none"}} eventKey="1" >
                  <Accordion.Header className="nav-link" style={{padding:"0px"}}>
                  <a style={{padding:'0px',color:"black"}}>
                    <i style={{color:"#ed1b24",fontWeight:"bold"}} className={prop.icon} />
                    <i style={{color:"#ed1b24",fontWeight:"bold"}}>{prop.themeicon}</i>          
                    <p>{prop.name}</p>
                  </a>
                  </Accordion.Header>
                   <Accordion.Body style={{padding:"0rem 1.5rem",background: '#f0f8ff47'}}>
                   {prop.subitem.map((prop, index) => {
                     return(
                     <NavLink
                      key={index}
                      to={prop.path}
                      className="nav-link"
                      style={{padding:'10px',background:'#00000000'}}
                      activeClassName="active"
                    >
                      <i style={{color:"#ed1b24",fontWeight:"bold"}} className={prop.icon} />
                    <i style={{color:"#ed1b24",fontWeight:"bold"}}>{prop.themeicon}</i>          
                      <p>{prop.name}</p>
                    </NavLink>
                     )
                   })}
                   </Accordion.Body>
                 </Accordion.Item>
                 </Accordion>
                 : 
                  <NavLink
                    to={prop.path}
                    className="nav-link"
                    activeClassName="active"
                  >
                    <i style={{color:"#ed1b24",fontWeight:"bold"}} className={prop.icon} />
                    <i style={{color:"#ed1b24",fontWeight:"bold"}}>{prop.themeicon}</i>          

                    <p>{prop.name}</p>
                  </NavLink>
                  }
                </li>
              );
            return null;
          })
          }
        </Nav>
      </div>
    </div>
  );
}

export default Sidebar;