import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { FiEdit } from 'react-icons/fi';
import { BiSort } from 'react-icons/bi';
import { getUserType as getUserRole, getRolePermission,getOrganizationId } from '../../services/roleService'
import * as moment from 'moment'
import SearchLayout from '../partials/SearchLayout';
import {
    Card,
    Container,
    Row,
    Col
} from "react-bootstrap";
import '../../assets/css/pages/table-list.css'
import { getGateway, searchGateway, getGatewayByGroup, getGatewayInstallationOrg, getGatewayInOrganization, sortGateway } from '../../services/gatewayService';
import { isTemplateHead } from 'typescript';
import SortingByColumn from 'views/partials/SortingByColumn';
import { ToastContainer, toast } from 'react-toastify';
import ToastLayout from '../../views/partials/Toastify';
const searchOptions = {
    searchValues: ["group_name", "hub_name", "serial", "mac_address", "model"], //same len as values
    searchNames: ['Installation', 'Hub Name', "Serial", 'Mac Address', "Model"],
}
const enableAddButton = () => (getRolePermission('gateways', getUserRole()) > 1);
const getEditButton = (mac_address) => {
    return (
        <center><a href={"#/gateway-edit/" + mac_address} style={{ color: 'black' }} >
            <FiEdit />
        </a></center>
    );
}
export default function GatewayList({ globalSelect, globalSelectOrganization }) {
    var gatewayScreenSort;
    var gateway;
    const searchInput = React.useRef();
    const [state, setState] = useState({
        items: []
    });
    const [items, setItems] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    const [clearField, setClearField] = useState(true);
    const [inputField, setInputField] = useState({ selectField: "mac_address" });
    const [flag, setFlag] = useState();
    const history = useHistory();
    const organization = getOrganizationId();
    useEffect(async () => {
        await reloadGateway();
        if (localStorage.getItem('gatewayScreenSort') != null) {
            gatewayScreenSort = JSON.parse(localStorage.getItem('gatewayScreenSort'))
        }
        if (gatewayScreenSort && gatewayScreenSort.field != null && gatewayScreenSort.ascFlag != undefined) {
            asecDsec(gatewayScreenSort.field, gatewayScreenSort.ascFlag, gateway);
        }
    }, [globalSelect, globalSelectOrganization])
    // sorting    
    let asecDsec = (field, flag, items) => {
        let toggle = flag;
        if (flag == undefined) {
            toggle = 1
        }
        localStorage.setItem("gatewayScreenSort", JSON.stringify({ ascFlag: toggle, field: field }));
        let sorted = SortingByColumn(field, items, toggle, setFlag)
        setItems(sorted);
        setOriginalData(sorted);
    }
    let reloadGateway = async () => {
        //Global filter Installation: Any one installation selected as option 
        //Global filter Organization: All organizations selected as option
        if ((globalSelect.installation_id != 0 && globalSelect.installation_id != undefined) &&
            (globalSelectOrganization.organization_id == 0 || globalSelectOrganization.organization_id == undefined)) {
            gateway = await getGatewayByGroup(globalSelect.installation_id);
            if (gateway == "Unauthorized") {
                toast.error(ToastLayout("Your session has expired!"))
                localStorage.clear();
                history.push("/login")
            }
            setItems(gateway);
            setOriginalData(gateway);
        }
        //Global filter Installation: All installations selected as option 
        //Global filter Organization: Any one organization selected as option
        else if ((globalSelect.installation_id == 0 || globalSelect.installation_id == undefined) &&
            (globalSelectOrganization.organization_id != 0 && globalSelectOrganization.organization_id != undefined)) {
            gateway = await getGatewayInOrganization(globalSelectOrganization.organization_id);
            if (gateway == "Unauthorized") {
                toast.error(ToastLayout("Your session has expired!"))
                localStorage.clear();
                history.push("/login")
            }
            setItems(gateway);
            setOriginalData(gateway);
        }
        //Global filter Installation: Any one installation selected as option 
        //Global filter Organization: Any one organization selected as option
        else if ((globalSelect.installation_id != 0 && globalSelect.installation_id != undefined) &&
            (globalSelectOrganization.organization_id != 0 && globalSelectOrganization.organization_id != undefined)) {
            let record = {
                organizationId: globalSelectOrganization.organization_id,
                deviceGroupId: globalSelect.installation_id
            }
            gateway = await getGatewayInstallationOrg(record);
            if (gateway == "Unauthorized") {
                toast.error(ToastLayout("Your session has expired!"))
                localStorage.clear();
                history.push("/login")
            }
            setItems(gateway);
            setOriginalData(gateway);
        }
        //Global filter Installation: All installations selected as option 
        //Global filter Organization: All organizations selected as option
        else {
            gateway = await getGateway();
            if (gateway == "Unauthorized") {
                toast.error(ToastLayout("Your session has expired!"))
                localStorage.clear();
                history.push("/login")
            }
            setItems(gateway);
            setOriginalData(gateway);
        }
    }

    // search details
    const searchDetails = async (fieldValue, dataValue) => {
        const searchParams = {
            field: fieldValue,
            data: dataValue
        };
        if (getUserRole() == "super-admin") {
            if (globalSelectOrganization.organization_id != 0 && globalSelectOrganization.organization_id != undefined) {
                searchParams.organizationId = globalSelectOrganization.organization_id
            } else {
                searchParams.organizationId = null
            }
            if(globalSelect.installation_id != 0 && globalSelect.installation_id != undefined){
                searchParams.installationId = globalSelect.installation_id
            }else{
                searchParams.installationId = null
            }
        } else {
            searchParams.organizationId = organization
        }
        let searchItems = await searchGateway(searchParams);
        setItems(searchItems);
    }
    // Clear the details
    let clearDetails = async () => {
        setItems(originalData);
        searchInput.current.value = "";
    }
    return (
        <div>
             <ToastContainer />
            <Container fluid>
                <Row>
                    <Col md="12">
                        <Card className="striped-table-with-hover">
                            <Card.Header>
                                <SearchLayout
                                    searchInput={searchInput}
                                    searchOptions={searchOptions}
                                    doSearch={searchDetails}
                                    clearDetails={clearDetails}
                                    addButtonCallback={() => { history.push("/gateway-add") }}
                                    isAddButtonEnable={enableAddButton()} showHide={true}
                                />
                            </Card.Header>
                            <Card.Body className="table-full-width table-responsive px-0" style={{ marginTop: "0" }}>
                                <SetGatewayList items={items}
                                    setItems={setItems}
                                    asecDsec={asecDsec}
                                    flag={flag}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                </Row>
            </Container>
        </div>
    );
}
function SetGatewayList({ items, setItems, asecDsec, flag }) {

    // const [flag, setFlag] = useState(1);
    // var sort = "asc";
    // let asecDsec = (field) => {
    //     let sorted = SortingByColumn(field, items, flag, setFlag, sort)
    //     setItems(sorted)
    // }
    return (
        <div>
            <ol className="collection collection-container">
                <li className="item item-container table-header">
                    <div className="attribute-container information">
                        <div className="attribute-container name-organization">
                            <div className="attribute" data-name="Installation">Installation
                                <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("group_name", flag, items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
                            </div>
                            <div className="attribute" data-name="Hub Name">Hub Name
                                <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("hub_name", flag, items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
                            </div>
                        </div>
                        <div className="attribute-container price-currency">
                            <div className="attribute" data-name="Serial">Serial
                                <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("serial", flag, items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
                            </div>
                            <div className="attribute" data-name="Mac Address">Mac Address
                                <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("mac_address", flag, items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
                            </div>
                        </div>
                    </div>
                    <div className="attribute-container amount-edit">
                        <div className="attribute" data-name="Model">Model
                            <a style={{ marginLeft: "5px" }} onClick={() => { asecDsec("model", flag, items) }}>{<BiSort style={{ marginBottom: "4px", fontSize: "16px" }} />}</a>
                        </div>
                        <div className="attribute" data-name="Edit"><center>Actions</center></div>
                    </div>
                </li>
                {(items.length > 0) ?
                    <div>
                        {items.map((item) => {
                            return (<li key={item.id} className="item item-container hover">
                                <div className="attribute-container information">
                                    <div className="attribute-container name-organization">
                                        <div className="attribute" data-name="Installation">{item.group_name}</div>
                                        <div className="attribute" data-name="Hub Name">{item.hub_name}</div>
                                    </div>
                                    <div className="attribute-container price-currency">
                                        <div className="attribute" data-name="Serial">{item.serial}</div>
                                        <div className="attribute" data-name="Mac Address">{item.mac_address}</div>
                                    </div>
                                </div>
                                <div className="attribute-container amount-edit">
                                    <div className="attribute" data-name="Model">{item.model}</div>
                                    {getEditButton(item.mac_address)}
                                </div>
                            </li>)
                        })}
                    </div>
                    :
                    <div className="no-data-available-text">No Records Found</div>
                }
            </ol>
        </div>
    );
}