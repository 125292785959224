import { apiUrl } from "./globalService";
import { getUserToken } from "./roleService";
let apiMeter = apiUrl + "energy-meter";
let apiMeterWallet = apiUrl + "meter-wallet-history";
let apiDeviceGroupEnergyMeter = apiUrl + "device-group-energy-meter";
let apiMeterInOrganization = apiUrl + "energy-meter-from-organization";
let apiMeterInstallationOrg = apiUrl + "energy-meter-installation-organization";
let  apiMeterByGatewayMacAddress= apiUrl+"energy-meter-from-gateway-mac-address";
let apiMeterStateBySerial=apiUrl+"energy-meter-state-from-serial"
let apiMeterSerial=apiUrl+"energy-meter-serial"
let apiMeterUpdateFromGateway=apiUrl+"energy-meter-update-from-gateway"
let apiMeterSerialWifiMacId=apiUrl+"serial-wifi-mac-id-from-gateway"

export let getMeter = async () => {
  return fetch(apiMeter, {
    method: 'get',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    }
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
      return "Unauthorized"
    });
}
export let getMeterSerial = async () => {
  return fetch(apiMeterSerial, {
    method: 'get',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    }
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
      return "Unauthorized"
    });
}
export let getMeterByGatewayMacAddress = async (gatewayMacId) => {
  return fetch(apiMeterByGatewayMacAddress+ "/" + gatewayMacId, {
    method: 'get',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    }
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
      return "Unauthorized"
    });
}
export let getSerialWifiMacIdFromGatewayMacAddress = async (gatewayMacId) => {
  return fetch(apiMeterSerialWifiMacId+ "/" + gatewayMacId, {
    method: 'get',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    }
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
      return "Unauthorized"
    });
}
export let getMeterStateBySerial = async (serial) => {
  return fetch(apiMeterStateBySerial+ "/" + serial, {
    method: 'get',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    }
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
      return "Unauthorized"
    });
}
export let addMeter = async (meter) => {
  return fetch(apiMeter, {
    method: 'post',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify(meter)
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}
export let deleteMeter = async ({ serial }) => {
  return fetch(apiMeter + "/" + serial, {
    method: 'delete',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify({ serial })
  })
    .then(response => response.json())
    .then(response => {
      alert(response.msg)
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}
export let getMeterInOrganization = (orgId) => {
  return fetch(apiMeterInOrganization + "/" + orgId, {
    method: 'get',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
      return "Unauthorized"
    });
}
export let getMeterInstallationOrg = (records) => {
  return fetch(apiMeterInstallationOrg, {
    method: 'post',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify(records)
  })
    .then(response => response.json())
    .then(response => {
      console.log("response:::",response.msg)
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}
export let getMeterBySerial = async (Serial) => {
  return fetch(apiMeter + "/" + Serial, {
    method: 'get',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}
export let updateMeter = async (meter) => {
  return fetch(apiMeter, {
    method: 'put',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify(meter)
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}
export let updateMeterAddFromGateway = async (meter) => {
  return fetch(apiMeterUpdateFromGateway, {
    method: 'put',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify(meter)
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}
export let searchMeter = async (meter) => {
  return fetch(apiMeter+"/search", {
    method: 'post',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify(meter)
  })
    .then(response => response.json())
    .then(response => {
      console.log("meter search")
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}
export let getMeterByCustomer = async (Serial) => {
  return fetch(apiMeter + "/customer/" + Serial, {
    method: 'get',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}
export let getMeterWalletBySerial = async (Serial) => {
  return fetch(apiMeterWallet + "/" + Serial, {
    method: 'get',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}
export let searchMeterwallet = async(meterwallet) => {
  return fetch(apiMeterWallet +"/search", {
    method: 'post',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify(meterwallet)
  })
    .then(response => response.json())
    .then(response => {
      console.log("meterwallet search")
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}
export let getMeterWallet = async() => {
  return fetch(apiMeterWallet, {
    method: 'get',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    }
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
      return "Unauthorized"
    });
}
export let getMeterByGroup = async(groupId) => {
  return fetch(apiDeviceGroupEnergyMeter + "/" + groupId, {
    method: 'get',
    headers: {
      'Authorization' : getUserToken(),
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}