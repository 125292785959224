import { apiUrl } from "./globalService";
import { getUserToken } from "./roleService";

const apiUser = apiUrl + "users";
const apiAllUser = apiUrl + "all-users";
const apiByOrganization = apiUrl + "users-organization";
const apiRolesInOrganization = apiUrl + "users-roles-organization";

export const getUsers = async () => {
  return fetch(apiUser, {
    method: 'get',
    headers: {
      'Authorization': getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    }
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
      return "Unauthorized"
    });
}
//All non-customer users from all the organizations
export const getAllUsers = async () => {
  return fetch(apiAllUser, {
    method: 'get',
    headers: {
      'Authorization': getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    }
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
      return "Unauthorized"
    });
}

export const addUser = async (user) => {
  return fetch(apiUser, {
    method: 'post',
    headers: {
      'Authorization': getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify(user)
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}

export const updateUser = async (user) => {
  return fetch(apiUser, {
    method: 'put',
    headers: {
      'Authorization': getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify(user)
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}
export const deleteUser = async (id) => {
  return fetch(apiUser + "/" + id, {
    method: 'delete',
    headers: {
      'Authorization': getUserToken(),
      'Content-Type': 'application/json'
    },
  })
    .then(response => response.json())
    .then(response => {
      console.log("userresponse",response)
      return response;
    }).catch(function (error) {
      console.log("error", error);
    });
}
export const getUserById = async (id) => {
  return fetch(apiUser + "/" + id, {
    method: 'get',
    headers: {
      'Authorization': getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    }
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
      return "Unauthorized"
    });
}
export const getUserByOrganizationId = async (organizationId) => {
  return fetch(apiByOrganization + "/" + organizationId, {
    method: 'get',
    headers: {
      'Authorization': getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    }
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
      return "Unauthorized"

    });
}
export const getUserByRoleOrganization = async (organizationId) => {
  return fetch(apiRolesInOrganization + "/" + organizationId, {
    method: 'get',
    headers: {
      'Authorization': getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    }
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
      return "Unauthorized"

    });
}

export const getAllCustomerUsers = async (searchArray) => {
  // all customer should use this , all org , etc
  if (typeof (searchArray) === 'undefined') {
    searchArray = {};
  }
  return fetch(apiUser + "/customers", {
    method: 'post',
    headers: {
      'Authorization': getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify(searchArray)
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
      return "Unauthorized"
    });
}

export const getCustomerById = async (customerId) => {
  let userList = await getAllCustomerUsers({ field: 'user.id', data: customerId });
  if (userList.length > 0) {
    return userList[0];
  }
  return {};
}

export const getCustomerUser = async (tokenOrganization) => {
  return fetch(apiUser + "/customer/" + tokenOrganization, {
    method: 'get',
    headers: {
      'Authorization': getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    }
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
      return "Unauthorized"
    });
}
export const getUserByOrganization = async (organizationId) => {
  return fetch(apiUser + "/non-customer-user/" + organizationId, {
    method: 'get',
    headers: {
      'Authorization': getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    }
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
      return "Unauthorized"

    });
}
export const searchUserByOrganization = async (user, organizationId) => {
  return fetch(apiByOrganization + "/search/" + organizationId, {
    method: 'post',
    headers: {
      'Authorization': getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify(user)
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}
//search API for select field
export const searchCustomerForSelect = async (searchData) => {
  return fetch(apiUser + "/customer/search-customer-for-select", {
    method: 'post',
    headers: {
      'Authorization': getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify(searchData)
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}
export const searchCustomer = async (user, organizationId) => {
  return fetch(apiUser + "/customer/search/" + organizationId, {
    method: 'post',
    headers: {
      'Authorization': getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify(user)
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}

export const searchNonCustomerUsers = async (username) => {
  return fetch(apiUser + "/non-customer-user/search", {
    method: 'post',
    headers: {
      'Authorization': getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify({ username })
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}

export const searchNonCustomerUser = async (field) => {
  return fetch(apiUser + "/non-customer-user/search-with-organization", {
    method: 'post',
    headers: {
      'Authorization': getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify(field)
  })
    .then(response => response.json())
    .then(response => {
      return response;
    }).catch(function (error) {
      console.log(error);
    });
}

let meUser = null; //current login user
export const clearMe = async () => {
  meUser = null;
};
export const isRoleAdminManager = () => {
  if (meUser != null && meUser.length > 0) {
    let { role_type } = meUser[0];
    if (role_type == 'manager'
      || role_type == 'admin'
      || role_type == 'super-admin') {
      return true;
    }
  }
  return false;
}
export const isRoleSuperAdmin = () => {
  if (meUser != null && meUser.length > 0) {
    let { role_type } = meUser[0];
    if (role_type == 'manager') {
      return true;
    }
  }
  return false;
}

export const me = async () => {
  if (meUser != null) {
    return meUser;
  }
  return fetch(apiUrl + "me", {
    method: 'get',
    headers: {
      'Authorization': getUserToken(),
      'Content-Type': 'application/json;charset=utf-8'
    }
  })
    .then(response => response.json())
    .then(response => {
      if (typeof response === 'object' && response.length > 0) {
        meUser = response; //set meUser
      }
      return response;
    }).catch(function (error) {
      console.log(error);
      return "Unauthorized"
    });
}