import React, { useState, useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import Select from 'react-select';
import {
    Modal,
    Row,
    Col,
    Card,
    Container,
    Form
} from "react-bootstrap";
import '../../assets/css/pages/table-list.css'
import { addPbxDevice } from '../../services/deviceService';
import { ToastContainer, toast } from 'react-toastify';
import ToastLayout from '../../views/partials/Toastify';

export default function AddDeviceModal({ show, handleClose, gatewayId, gatewayData, hardwareData, getUserId, deviceMacId, fetchDevice }) {
    const history = useHistory();
    const [hardwareModel, setHardwareModel] = useState();
    const [deviceData, setDeviceData] = useState([]);

    let selectHardwareModel = (e) => {
        setDeviceData({ ...deviceData, ["hardware_id"]: e.id });
        setHardwareModel(e);
    }
    let handleChange = async (e) => {
        setDeviceData({ ...deviceData, [e.target.name]: e.target.value });
    }
    let handleSubmit = async (e) => {
        e.preventDefault();
        if (!deviceMacId.length) {
            return;
        }
        const newItem = {
            mac_address: deviceMacId,
            serial: deviceData.serial,
            hardware_revision: deviceData.hardware_revision,
            device_group_id: gatewayData.groupData.device_group_id,
            hardware_id: deviceData.hardware_id,
            software_revision: deviceData.software_revision,
            owner_organization_id: gatewayData.groupData.owner_organization_id,
            owner_user_id: null,
            gateway_mac_address: gatewayId,
            created_at: Date.now(),
            created_by: getUserId(),
            modified_at: Date.now(),
            modified_by: getUserId(),
            id: Date.now()
        };
        //add
        if (newItem.device_group_id) {
            if (newItem.hardware_id) {
                if (newItem.owner_organization_id) {
                    let response = await addPbxDevice(newItem);
                    if (response.result == "fail") {
                        if (response.msg.errors[0].message == "PRIMARY must be unique") {
                            toast.error(ToastLayout("Please enter unique mac_address!"))
                        } else if (response.msg.errors[0].message == "unique_serial must be unique") {
                            toast.error(ToastLayout("Please enter unique serial!"))
                        } else {
                            toast.error(ToastLayout("Mac address or Serial already exists!"))
                        }
                    }
                    else {
                        // alert("Device successfully added!");
                        // fetchDevice(deviceMacId);
                        handleClose();
                        window.location.reload();
                        // history.push("/pbx-device", newItem);
                    }
                } else {
                    toast.error(ToastLayout("Please select organization"))
                }
            } else {
                toast.error(ToastLayout("Please select hardware model"))
            }
        } else {
            toast.error(ToastLayout("Please select installation group"))
        }
    }
    const customStyles = {
        menu: styles => ({ ...styles, zIndex: 999 })
    }
    return (<Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
            <div>
                <Form>
                    <Col md="12">
                        <Form.Group as={Row} className="mb-3" >
                            <Col sm="4">
                                <div className="add-responsive">Serial :</div>
                            </Col>
                            <Col sm="6">
                                <input
                                    name="serial"
                                    placeholder="Serial e.g: 1771.3.193"
                                    onChange={handleChange}
                                    value={deviceData.serial}
                                    className="input-style-responsive"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" >
                            <Col sm="4">
                                <div className="add-responsive">Hardware Version :</div>
                            </Col>
                            <Col sm="6">
                                <input
                                    name="hardware_revision"
                                    placeholder="Hardware version e.g: 4"
                                    onChange={handleChange}
                                    value={deviceData.hardware_revision}
                                    className="input-style-responsive"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" >
                            <Col sm="4">
                                <div className="add-responsive">Software Version :</div>
                            </Col>
                            <Col sm="6">
                                <input
                                    name="software_revision"
                                    placeholder="Software version e.g: 307"
                                    onChange={handleChange}
                                    value={deviceData.software_revision}
                                    className="input-style-responsive"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" >
                            <Col sm="4">
                                <div className="add-responsive">Hardware Model :</div>
                            </Col>
                            <Col sm="6">
                                <Select
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    maxMenuHeight={200}
                                    menuPortalTarget={document.body}
                                    className="dropDown-style"
                                    value={hardwareModel}
                                    options={hardwareData.hardware}
                                    getOptionLabel={e => e.model}
                                    getOptionValue={e => e.id}
                                    onChange={(e) => selectHardwareModel(e)}
                                />
                            </Col>
                        </Form.Group>
                    </Col>
                </Form>
            </div>
        </Modal.Body>
        <ToastContainer />
        <Modal.Footer>
            <br />
            <button onClick={handleSubmit} className="btn rounded-pill add-btn">Add Device</button>
        </Modal.Footer>
    </Modal>
    );
}