import React, { useState, useContext, useEffect } from 'react';
import Select from 'react-select';
import {
   Modal,
   Row,
   Col,
   Card,
   Container,
   Form
} from "react-bootstrap";
import '../../assets/css/pages/table-list.css'
import { addDeviceGroup } from '../../services/deviceGroupService'
import SubGroupTypeGateway from './SubGroupTypeGateway';
import SubGroupTypeNode from './SubGroupTypeNode';

// 1.root group "installations" - done
// 2.Allow only "gateway" type of groups under "installations" - done
// 3."Node" type of groups are only allowed under "gateway" group
// 4.One installation can have multiple "gateway" group - done
// 5.Meters and gateways can not be added directly under installations
// 6.Meters and gateways are allowed to be added under gateway and node groups
// 7.If one removes the gateway from the gateway group it should be left as node group
export default function AddSubGroupModal({ show, selectedNode, handleClose, groupData, setGroupData, organizationData, getUserId, subGroupType, SubGroup, setSubGroup, userData, hardwareData, getUserRole,currentOrganizationName }) {
   let subHeading = "Add Subgroup " + selectedNode.addLabel;
   const onSelect = async (e) => {
      if (e.value == "Gateway") {
         setSubGroup("Gateway")
      } else if (e.value == "Node") {
         setSubGroup("Node")
      }
   }
   const checkSubGroupType = () => {
      if (SubGroup === "Gateway") {
         return <SubGroupTypeGateway
            selectedNode={selectedNode}
            groupData={groupData}
            setGroupData={setGroupData}
            organizationData={organizationData}
            getUserId={getUserId}
            subGroupType={subGroupType}
            userData={userData}
            hardwareData={hardwareData}
            getUserRole={getUserRole}
            currentOrganizationName={currentOrganizationName}
         />
      } else if (SubGroup === "Node") {
         return <SubGroupTypeNode
            selectedNode={selectedNode}
            groupData={groupData}
            setGroupData={setGroupData}
            organizationData={organizationData}
            getUserId={getUserId}
            subGroupType={subGroupType}
            userData={userData}
            hardwareData={hardwareData}
            getUserRole={getUserRole}
            currentOrganizationName={currentOrganizationName}
         />
      }
   }
   return (<Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
         <div>
            <Container fluid>
               <Row>
                  <Col md="12">
                     <Col sm="12">
                        <div className="form-header-container form-header" style={{ marginTop: "05px" }}>
                           {subHeading}
                        </div>
                     </Col>
                     <Card className="striped-table-with-hover">
                        <Card.Header>
                           <Select
                              className="installation-asset-dropDown-style"
                              defaultValue={subGroupType[0]}
                              options={subGroupType}
                              onChange={onSelect}
                           />
                        </Card.Header>
                        {checkSubGroupType()}
                     </Card>
                  </Col>
               </Row>
            </Container>
         </div>
      </Modal.Body>
      <Modal.Footer>
         <br />
      </Modal.Footer>
   </Modal>
   );
}