
  //generate uuid
  export const  uuid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  export const getAmountSign = (value) => {
    if(value > 0 ){
      return(<>+</>)
    }else if(value < 0 ){
      return(<></>)
    } else{
      return(<></>)
    }
  }